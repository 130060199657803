import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import "../Assets/css/Dashboard.css";
import LoaderRounded from "./LoaderRounded";
import ReactPaginate from "react-paginate";
import LoaderRoundedWithText from "./LoaderRoundedWithText";
import './CustomToggle.css';
import { ToastProvider, useToasts, addToast } from "react-toast-notifications";

function TabDetails() {
    const [tablist, setTablist] = useState([]);
    const [loader, setLoader] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => setLoading(false), 1000);
        getTabDetails();
    }, []);

    const togglePropertyAndSave = async (id, isAvailable, tabSelected) => {
        const data = {
            Id: id,
            isAvailable: isAvailable,
            tabSelected: tabSelected,
        };

        try {
            const response = await axios.put(`http://yin-config-api.foxberry.link/v1/tabdetails/update`, data);
            getTabDetails(); // Refresh the tab details after update


        } catch (error) {
            console.error("Error updating:", error);
        }
    };

    const handleToggleIsAvailable = (e, id, isAvailable, tabSelected) => {
        const newIsAvailable = e.target.checked;
        togglePropertyAndSave(id, newIsAvailable, tabSelected);
    };

    const handleToggleTabSelected = (e, id, isAvailable, tabSelected) => {
        const newTabSelected = e.target.checked;
        togglePropertyAndSave(id, isAvailable, newTabSelected);
    };


    const getTabDetails = () => {
        setLoader(true)
        axios.get("https://yin-config-api.foxberry.link/v1/tabdetails/getTabDetails")
            .then((res) => {
                setTablist(res.data);
                setLoader(false);
            })
            .catch((err) => {
                setLoader(false);
                console.log("err in getData", err);
            });
    };

    let tab_list = (
        <tr>
            <td colSpan="6" className="text-center">No Data Found....</td>
        </tr>
    );

    if (tablist && tablist.length > 0) {
        tab_list = tablist.map((tab, _id) => (
            <tr key={_id} className="text-center">
                <td>{tab.tabOrder}</td>
                <td>{tab.tabName}</td>
                <td>{tab.tabColor}</td>
                <td>{tab.tabIcon}</td>
                <td>
                    <label className="custom-toggle">
                        <input
                            type="checkbox"
                            checked={tab.isAvailable}
                            value={tab.isAvailable ? "ON" : "OFF"}
                            onChange={(e) => handleToggleIsAvailable(e, tab._id, tab.isAvailable, tab.tabSelected)}
                        />
                        <div className="slider"></div>
                        <span className={`toggle-label ${tab.isAvailable ? 'checked' : ''}`}>
                            {tab.isAvailable ? 'ON' : 'OFF'}
                        </span>
                    </label>
                </td>
                <td>
                    <label className="custom-toggle">
                        <input
                            type="checkbox"
                            checked={tab.tabSelected}
                            value={tab.tabSelected ? "ON" : "OFF"}
                            onChange={(e) => handleToggleTabSelected(e, tab._id, tab.isAvailable, tab.tabSelected)}
                        />
                        <div className="slider"></div>
                        <span className={`toggle-label ${tab.tabSelected ? 'checked' : ''}`}>
                            {tab.tabSelected ? 'ON' : 'OFF'}
                        </span>
                    </label>
                </td>
            </tr>
        ));
    }

    return (
        <>
            {loading ? (
                <LoaderRoundedWithText />
            ) : (
                <div>
                    <Sidebar />

                    <div className="main">
                        <div className="topbar">
                            <Navbar title="Tab Details" />
                        </div>

                        <div className="container">
                            <div className="row mt-3">
                                <div className="col-12">
                                    <div className="table-responsive-sm">
                                        <table className="table table-bordered table-responsive">
                                            <thead>
                                                <tr className="text-center">
                                                    <th>Tab Order</th>
                                                    <th>Tab Name</th>
                                                    <th>Tab Color</th>
                                                    <th>Tab Icon</th>
                                                    <th>isAvailable</th>
                                                    <th>TabSelected</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loader ?
                                                    <tr>
                                                        <td colSpan={6}>
                                                            <LoaderRounded />
                                                        </td>
                                                    </tr> :
                                                    tab_list}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default TabDetails;
