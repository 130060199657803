import React, { Component } from "react";
import axios from "axios";
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import S3 from "react-aws-s3";
import Compressor from 'compressorjs';
import logo from "../Assets/images/avatar1.png";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";

export default class AddAttendee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobile: '',
            event_amount: 999,
            loader: false,
            receipt_Photo: '',
            payment_type: 'OFFLINE',
            changeToView: false,
            memberList: [],
            memberData: '',
            receiptNumber: '',
            receipt_number_error: '',
            receipt_photo_error: '',
            receipt_photo_errors: '',
            disabledd: false,
            errors: {},
            event: JSON.parse(localStorage.getItem('selected_event')),
            loader: false,
        };
        this.handleFormChange = this.handleFormChange.bind(this);
        this.uploadImageToS3 = this.uploadImageToS3.bind(this);
        // this.handleSubmit = this.handleSubmit.bind(this);
        this.getMemberListbyMobileNumber = this.getMemberListbyMobileNumber.bind(this);
    }

    handleFormChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    getMemberListbyMobileNumber = async (mobile) => {
        const response = await axios.post(process.env.REACT_APP_TALK_API + `/eventTeam/getUserDetails`, { mobile });
        if (response.data.Status === 200) {
            let members = {
                member: {
                    ...response.data.data,
                    designation: "member",
                    is_attended: false,
                    is_cancelled: false,
                    is_registered: true,
                    is_payment_done: true
                }
            }
            this.setState({
                // changeToView: true,
                member: response.data.data,
                memberData: members
            });

        }
        else {
            alert('Record not found');
            this.props.history.push("/registration-form");

        }

    }



    handleSubmit = async (event) => {
        event.preventDefault();

        if (this.state.receiptNumber === null || this.state.receiptNumber === '') {
            this.setState({
                receipt_number_error: "Please enter receipt number"
            });
        }

        if (this.state.receipt_Photo === null || this.state.receipt_Photo === '') {
            this.setState({
                receipt_number_error: '',
                receipt_photo_error: "Please enter receipt photo"
            });
        } else {

            try {
                const transactionDetails = {
                    amount: Number(this.state.event.event_amount),
                    event_id: this.state.event.event_id, //Event ID
                    order_id: "ORDER_OFFLINE" + Math.floor(Math.random() * (100000 - 999999)),
                    orderStatus: "SUCCESS",
                    mobile: this.state.member.mobile,
                    email: this.state.member.email ? this.state.member.email : 'younginspiratorsnetwork@gmail.com',
                    type: 'LIVE',
                    platform: 'OTHER',
                    transaction_receipt_id: this.state.receiptNumber,
                    transaction_receipt_image: this.state.receipt_Photo
                };

                let transactionData = await axios.post(process.env.REACT_APP_APIURLV2 + "/payments/transactions/offline", transactionDetails);
                await axios.post(process.env.REACT_APP_TALK_API + `/registerevent/add/event/member`,
                    {
                        event_id: this.state.event.event_id,
                        member: this.state.memberData.member
                    }
                ).then((res) => {
                    if (res.status === 200) {
                        axios.post(process.env.REACT_APP_APIURL + "/eventpdf/create-pdf", {
                            yin_id: this.state.member.yin_id,
                            mobile: this.state.member.mobile,
                            event_id: this.state.event.event_id
                        }).then((data) => {
                            alert("New Attendee Added successfully");
                        }).catch((error) => {
                            console.log("-------------error in send sms and create pdf-------------", error);
                        });
                    }
                    else if (res.status !== 200) {
                        alert("Member already added");
                    }
                })
                    .catch((error) => {
                        console.log("----error--------", error);
                    });

            } catch (error) {
                console.log("------error------", error);
            }
        }

    }



    //upload image to S3
    uploadImageToS3(event) {
        this.setState({
            receipt_photo_error: "",
            receipt_photo_errors: "Image uploading...",
            disabledd: true
        })
        const config = {
            bucketName: process.env.REACT_APP_BUCKET_NAME,
            dirName: "yin/receipt_Photo",
            region: process.env.REACT_APP_REGION,
            accessKeyId: process.env.REACT_APP_ACCESS_ID,
            secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
            s3Url: process.env.REACT_APP_S3URL
        };

        var fileInput = false;
        const image = event.target.files[0];
        if (image === undefined) return false;
        if (event.target.files[0]) {
            fileInput = true;
        }
        if (fileInput) {

            if (event.target.files[0].type === 'image/png' || event.target.files[0].type === 'image/jpeg') {
                new Compressor(image, {
                    quality: 0.6,
                    success: (compressedResult) => {

                        let newFileName = event.target.files[0].name.replace(/\..+$/, "");
                        const finalFileName = newFileName;
                        const ReactS3Client = new S3(config);
                        // const newFileName = 'test-file';

                        ReactS3Client.uploadFile(compressedResult, newFileName).then((res) => {
                            this.setState({
                                url: res.location,
                                receipt_Photo: res.location,
                                receipt_photo_errors: "image uploaded",
                                disabledd: false
                            })
                            // this.addNewImage({
                            //     type: 'IMAGE',
                            //     url: res.location,
                            //     name: finalFileName,
                            //     id: Math.floor(Math.random() * 9999999999)
                            // });
                        }).catch(err => console.error(err));
                    },
                });
            }
        }
    }


    render() {
        return (
            <>
                <Sidebar />
                <div className="main">
                    <div className="topbar">
                        <Navbar />
                    </div>

                    <div className="container">
                        <BackAndBreadCrmOneParent backLink="Sub-memberlist" backName="Sub-MemberList" currentTabName="Add Member" />
                        <div className="row mt-4">
                            <div
                                style={{
                                    heigh: "800vh",
                                    overflow: "hidden",
                                }}
                                className="col-lg-8 col-md-8 col-12 paddingAll10 mx-auto ">

                                <div className="card rounded shadow shadow-sm " >
                                    <div className="card-header">
                                        <h3 className="mb-0 center">Add New Attendee</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="mobile" className="control-label"> <span style={{ color: "#ff0000" }}>*</span>Mobile Number</label>
                                            <input type="text" name="mobile" value={this.state.mobile} onChange={this.handleFormChange} className="form-control" placeholder="Type here"  ></input>
                                            <div className="errorMsg">{this.state.errors.mobile}</div>
                                        </div>
                                        <div className="form-group">
                                            <button onClick={() => this.getMemberListbyMobileNumber(this.state.mobile)} id="btnSubmitSignup" className="btn btn-primary btn-lg btn-block" style={{ marginLeft: "300px" }}>Submit</button>
                                        </div>
                                        <br />
                                        <form id="AddAttendee" onSubmit={this.handleSubmit}>
                                            {this.state.member && (
                                                <div>
                                                    <div className="row mt-5">
                                                        <div className="col-lg-5 col-md-5 mx-auto">
                                                            <div className="card rounded" >
                                                                <div className="card-header1">
                                                                    <h3 className="card-title mb-0 text-center"><b>Profile</b></h3>
                                                                    <div className="row align-items-center">
                                                                        <div className="col d-flex justify-content-center">
                                                                            {this.state.member.profile_image[0] ? <img src={this.state.member.profile_image[0]} alt="imhe" width="250px" height="140px" /> :
                                                                                <img src={logo} alt="image_logo" />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="card-header1  text-center">
                                                                        <div>
                                                                            <label htmlFor="name"><h5><b>{this.state.member.first_name}&nbsp;{this.state.member.last_name}</b></h5></label><br />
                                                                            <label htmlFor="college_name">{this.state.member.college_name}</label><br />
                                                                            <p></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="card">

                                                        <div className="card-body">
                                                            <h5 className="card-title">Event Title: <b>{this.state.event.event_title}</b></h5>
                                                            <p className="card-text">Event Amount: <b>{this.state.event.event_amount}</b></p>
                                                            <div className="form-group">
                                                                <label htmlFor="type of payment" className="control-label">Type Of Payment</label>
                                                                <select className="form-select" name="payment_type" value={this.state.payment_type} readOnly onChange={this.handleFormChange} >
                                                                    <option value="OFFLINE">OFFLINE</option>
                                                                </select>
                                                            </div>
                                                            <br />
                                                            <div className="form-group">
                                                                <label htmlFor="receiptNumberID" className="control-label">Receipt Number</label>
                                                                <input type="text" name="receiptNumber" id="receiptNumberID" value={this.state.receiptNumber} onChange={this.handleFormChange} className="form-control" placeholder="Type here"  ></input>
                                                                <div style={{ color: "#ff0000", fontSize: "12px" }}>{this.state.receipt_number_error}</div>
                                                            </div>
                                                            <br />
                                                            <div className="form-group">
                                                                <label htmlFor="receipt_Photo">Receipt Photo</label> &nbsp;&nbsp;&nbsp;
                                                                <input type="file" accept="image/png, image/gif, image/jpeg" id="receipt_Photo" name="receipt_Photo" className="form-control-file" onChange={this.uploadImageToS3} />
                                                                <div style={{ color: "#ff0000", fontSize: "12px" }}>{this.state.receipt_photo_error}</div>
                                                                <div style={{ color: "#000000", fontSize: "12px" }}>{this.state.receipt_photo_errors}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="form-group">
                                                        <button type="submit" id="btnSubmit" className="btn btn-primary btn-lg btn-block" style={{ marginLeft: "300px" }} disabled={this.state.disabledd}>Add Attendee</button>
                                                    </div>
                                                </div>
                                            )
                                            }
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}