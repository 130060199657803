import React, { Component } from "react";
// import { Redirect } from 'react-router-dom';
import axios from "axios";
// import { Form, Radio, Input } from "antd";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import S3 from "react-aws-s3";
import Compressor from "compressorjs";
// import moment from "moment";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRounded from "./LoaderRounded";
import { connect } from "react-redux";

class EditForum extends Component {

  constructor(props) {
    super(props);
    this.state = {
      forumId: "",
      name: "",
      college_code: "",
      CollegeData: [],
      diaplay_name: "",
      forum_full_description: "",
      forum_images: [],
      forum_type: "",
      is_published: "",
      MemberData: [],
      forum_member_details: [],
      redirect: false,
      forum_created_by: "",
      errors: {},
      arr: [],
      loader: false,
      districtsLocal: "",
      subCityName: "",
    };
    this.uploadImageToS3 = this.uploadImageToS3.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.selectMember = this.selectMember.bind(this);
    // Create the ref object
    this.nameInputRef = React.createRef();
    this.getForumData = this.getForumData.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.getMember();
    this.getCollege();
    this.getForumData();
  }

  getForumData() {
    let forumid;
    if (this.props.location.data) {
      localStorage.setItem("forumid", this.props.location.data.forum_id);
      forumid = this.props.location.data.forum_id;
    } else {
      forumid = localStorage.getItem("forumid");
    }

    axios
      .get(
        `https://yin-talk-api.foxberry.link/v1/forum/get/forum/info/${forumid}`
      )
      .then((res) => {
        let responses = res.data;
        if (responses.length > 0) {
          let response = responses[0];
          this.setState({
            forumId: response?.forum_id,
            name: response?.name,
            diaplay_name: response?.diaplay_name,
            college_name: response?.college_name,
            college_code: response?.college_code,
            forum_full_description: response?.forum_full_description,
            forum_type: response?.forum_type,
            is_published: response?.is_published,
            forum_created_by: response?.forum_created_by,
            forum_images: response?.forum_images,

          });
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error("Error fetching data", error);
      });
  }


  handleFormChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  addNewImage(newImage) {
    let img_arr = this.state.forum_images;
    img_arr.push(newImage);
    this.setState({
      forum_images: img_arr,
    });
  }

  removeImageURL(id) {
    let img_arr = this.state.forum_images;
    img_arr = img_arr.filter((img) => img.id !== id);
    this.setState({
      forum_images: img_arr,
    });
  }

  uploadImageToS3(event) {
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: "yin/forum_images",
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      s3Url: process.env.REACT_APP_S3URL,
    };

    var fileInput = false;
    const image = event.target.files[0];
    if (image === undefined) return;

    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      if (
        event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpeg"
      ) {
        new Compressor(image, {
          quality: 0.6,
          success: (compressedResult) => {
            let newFileName = event.target.files[0].name.replace(/\..+$/, "");
            const finalFileName = newFileName;
            const ReactS3Client = new S3(config);
            // const newFileName = 'test-file';

            ReactS3Client.uploadFile(compressedResult, newFileName)
              .then((res) => {
                this.addNewImage({
                  type: "IMAGE",
                  url: res.location,
                  name: finalFileName,
                  id: Math.floor(Math.random() * 9999999999),
                });
              })
              .catch((err) => console.error(err));
          },
        });
      }
    }
  }

  handleFormValidation = () => {
    const {
      name,
      college_code,
      college_name,
      forum_full_description,
      forum_created_by,
      forum_type,
      is_published,
      forum_images,
    } = this.state;
    let errors = {};
    let formIsValid = true;
    let arr = [];

    // Empty forum name
    if (!name) {
      errors["name"] = "Give Your Answer.";
      formIsValid = false;
      arr.push({ field: "Forum Name", message: "Forum Name" });
    } else if (!name.match(/^[a-zA-Z ]*$/)) {
      formIsValid = false;
      errors["name"] = "Please enter alphabet characters only.";
      arr.push({ field: "Forum Name", message: "Forum Name" });
    }

    // Empty college_name
    if (!college_code) {
      formIsValid = false;
      errors["college_name"] = "Give Your Answer.";
      arr.push({ field: "college_name", message: "college_name" });
    }

    // Empty description
    if (!forum_full_description) {
      formIsValid = false;
      errors["forum_full_description"] = "Give Your Answer.";
      arr.push({
        field: "forum_full_description",
        message: "forum_full_description",
      });
    }

    // Empty forum_type
    if (!forum_type) {
      formIsValid = false;
      errors["forum_type"] = "Give Your Answer.";
      arr.push({ field: "forum_type", message: "forum_type" });
    }

    // Empty is_published
    if (!is_published) {
      formIsValid = false;
      errors["is_published"] = "Give Your Answer.";
      arr.push({ field: "is_published", message: "Is Published" });
    }

    //Empty created by
    if (!forum_created_by) {
      formIsValid = false;
      errors["forum_created_by"] = "Give Your Answer.";
      arr.push({ field: "forum_created_by", message: "forum_created_by" });
    }

    // Empty forum_images
    if (!forum_images) {
      formIsValid = false;
      errors["forum_images"] = "Give Your Answer.";
      arr.push({ field: "forum_images", message: "forum_images" });
    }

    this.setState({
      errors: errors,
    });

    // Focus on the first input field with an error message
    if (arr.length > 0) {
      const firstErrorField = document.getElementById(`${arr[0].field}`);
      if (firstErrorField && firstErrorField.focus) {
        firstErrorField.focus();
        //alert(arr[0].message); // Display error message
      }
    }

    return formIsValid;
  };
  handleDelete = async (forum_id) => {
    let statesSet = this;
    let result = window.confirm("Are sure want to delete issue");
    this.setState({
      loder: true,
    });
    if (result) {
      await axios
        .post(process.env.REACT_APP_TALK_API + "/forum/delete", {
          forumId: forum_id,
        })
        .then((res) => {
          console.log("res from issue delete", res);
          if (res.error == null) {
            this.props.history.push("/forumlist");
            statesSet.setState({
              changeToView: true,
              redirect: true,
              loader: false,
            });
          }
        })
        .catch((err) => {
          this.setState({
            loder: false,
          });
        });
    }
  };


  getMember() {
    axios
      .get(
        process.env.REACT_APP_TALK_API +
        `/forum/get/forum/member-details/${this?.props?.location?.data?.forum_id}`
      )
      .then((response) => {
        this.setState({
          MemberData: response.data,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    if (this.handleFormValidation()) {
      const updatecontent = {
        forumId: localStorage.getItem("forumid"),
        name: this?.state?.name,
        diaplay_name: this?.state?.diaplay_name,
        college_name: this?.state?.college_name,
        college_code: this?.state?.college_code,
        forum_full_description: this?.state?.forum_full_description,
        forum_type: this?.state?.forum_type,
        is_published: this?.state?.is_published,
        forum_created_by: this?.state?.forum_created_by,
        forum_images: this?.state?.forum_images,
      };

      let statesSet = this;

      await axios
        .post(process.env.REACT_APP_TALK_API + "/forum/edit", updatecontent)
        .then((res) => {
          if (res.status === 200) {
            this.props.history.push("/forumlist");
            alert("Forum Updated successfully");
            statesSet.setState({
              changeToView: true,
              redirect: true,
              loader: false,
            });
            localStorage.removeItem("forumid")
          }
        })
        .then((err) => {
          console.log("err", err);
        });
    } else {
      // Focus on the first input field with an error
      // if (this.nameInputRef.current) {
      //   this.nameInputRef.current.focus();
      // }
    }
  };
  selectMember = (e) => {
    let memberlist = e.target.value;

    this.setState({
      forum_member_details: memberlist,
    });
  };

  getCollege() {
    let districts = this.props.UserData.districts;

    axios
      .post(process.env.REACT_APP_APIURL + `/coordinator/college/all/city`, {
        districts,
      })
      .then((response) => {
        localStorage.getItem("collegecode", response?.data?.college_code);
        this.setState({
          CollegeData: response?.data,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }


  render() {
    return (
      <>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>
          {/* <div>
                    <select type="button" className="mx-2 btn btn-secondary btn-md dropdown-toggle" onChange={this.selectMember} id="Member" style={{ float: "right", width: "120px",marginTop:"10px" }}>
                                    <option value={null}> --Member-- </option>
                                    {this.state.MemberData.map((e, key) => {
                                        return <option key={key} value={e.first_name.toString() + e.last_name.toString()}>{e.first_name.toString() + e.last_name.toString()}  </option>;
                                    })}
                                </select>

                    </div> */}
          <div className="container">
            <BackAndBreadCrmOneParent
              backLink={"/ForumList"}
              backName={"ForumList"}
              currentTabName="Edit Forum"
            />
            <div className="row mt-4">
              <div
                style={{
                  heigh: "800vh",
                  overflow: "hidden",
                }}
                className="col-lg-8 col-md-8 col-12 paddingAll10 mx-auto "
              >
                <div className="card rounded shadow shadow-sm ">
                  <div className="card-header">
                    <h3 className="mb-0 center">Edit Forum</h3>
                  </div>
                  <div className="card-body">
                    <form
                      id="candidate"
                      method="post"
                      onSubmit={this.handleSubmit}
                      encType="multipart/form-data"
                    >
                      <div className="form-group">
                        <label htmlFor="Forum Name" className="control-label">
                          Forum Title<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <input
                          id="Forum Name"
                          type="text"
                          className={`form-control ${this.state.errors.name ? "error" : ""
                            }`}
                          name="name"
                          value={this.state.name}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                          ref={this.nameInputRef}
                        ></input>
                        {this.state.errors.name && (
                          <div className="errorMsg">
                            {this.state.errors.name}
                          </div>
                        )}
                      </div>
                      <br />
                      <hr className="mx-n3" />
                      <div className="form-group">
                        <label htmlFor="diaplay_name" className="control-label">
                          Forum Diaplay Name
                        </label>
                        <input
                          ref={this.nameInputRef}
                          id="diaplay_name"
                          type="text"
                          className={`form-control ${this.state.errors.diaplay_name ? "error" : ""
                            }`}
                          name="diaplay_name"
                          value={this.state.diaplay_name}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                        ></input>
                        {/* this.state.errors.diaplay_name && <div className="errorMsg">
                        //   {this.state.errors.diaplay_name}
                        // </div>*/}
                      </div>
                      <br />
                      <hr className="mx-n3" />

                      <div className="form-group">
                        <label htmlFor="college_name" className="control-label">
                          College Name
                          {/* <span style={{ color: "#ff0000" }}>*</span> */}
                        </label>
                        <select
                          onChange={(e) => {
                            this.setState({
                              college_code:
                                e.target.selectedOptions[0].attributes.name
                                  .nodeValue,
                              college_name: e.target.selectedOptions[0].value,
                            });
                          }}
                          ref={this.nameInputRef}
                          id="college_name"
                          type="button"
                          className={`form-select ${this.state.errors.college_name ? "error" : ""
                            }`}
                          name="college_name"
                        >
                          <option value={null}>--Select College--</option>
                          {this.state.CollegeData.map((e, key) => {
                            return (
                              <option
                                style={{ textAlign: "left" }}
                                key={key}
                                value={e.college_name}
                                name={e.college_code}
                              >
                                {e.college_name}
                              </option>
                            );
                          })}
                        </select>
                        <div className="errorMsg">
                          {this.state.errors.college_name}
                        </div>
                      </div>
                      <br />
                      <hr className="mx-n3" />

                      <div className="form-group">
                        <label htmlFor="college_code" className="control-label">
                          College Code
                        </label>
                        <input
                          id="college_code"
                          type="text"
                          className="form-control"
                          name="college_code"
                          value={this.state.college_code}
                          onChange={this.handleFormChange}
                          readOnly
                        ></input>
                      </div>

                      <br />
                      <hr className="mx-n3" />

                      <div className="form-group">
                        <label
                          htmlFor="forum_full_description"
                          className="control-label"
                        >
                          Forum Description
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <input
                          ref={this.nameInputRef}
                          id="forum_full_description"
                          type="text"
                          className={`form-control ${this.state.errors.forum_full_description
                            ? "error"
                            : ""
                            }`}
                          name="forum_full_description"
                          value={this.state.forum_full_description}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                        ></input>
                        <div className="errorMsg">
                          {this.state.errors.forum_full_description}
                        </div>
                      </div>
                      <br />
                      <hr className="mx-n3" />

                      <div className="form-group">
                        <label htmlFor="forum_type" className="control-label">
                          Forum Type
                        </label>
                        <input
                          ref={this.nameInputRef}
                          id="forum_type"
                          type="text"
                          className={`form-control ${this.state.errors.forum_type ? "error" : ""
                            }`}
                          name="forum_type"
                          value={this.state.forum_type}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                        ></input>
                        {/*this.state.errors.forum_type && <div className="errorMsg">
                          {this.state.errors.forum_type}
                      </div>*/}
                      </div>
                      <br />
                      <hr className="mx-n3" />
                      <div className="form-group">
                        <label htmlFor="Is Published" className="control-label">
                          Is Published
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <select
                          id="Is Published"
                          className={`form-select ${this.state.errors.is_published ? "error" : ""
                            }`}
                          name="is_published"
                          value={this.state.is_published}
                          onChange={this.handleFormChange}
                          disabled={
                            this.state.status === false ? "disabled" : null
                          }
                        >
                          <option value="">--Choose--</option>
                          <option value="true">YES</option>
                          <option value="false">NO</option>
                        </select>
                        {this.state.errors.is_published && (
                          <div className="errorMsg">
                            {this.state.errors.is_published}
                          </div>
                        )}
                      </div>
                      <br />
                      <hr className="mx-n3" />
                      <div className="form-group">
                        <label
                          htmlFor="forum_created_by"
                          className="control-label"
                        >
                          <span style={{ color: "#ff0000" }}>*</span> Forum
                          Created Person Name
                        </label>
                        <input
                          ref={this.nameInputRef}
                          id="forum_created_by"
                          type="text"
                          className={`form-control ${this.state.errors.forum_created_by ? "error" : ""
                            }`}
                          name="forum_created_by"
                          value={this.state.forum_created_by}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                        ></input>
                        {this.state.errors.forum_created_by && (
                          <div className="errorMsg">
                            {this.state.errors.forum_created_by}
                          </div>
                        )}
                      </div>
                      <br />
                      <hr className="mx-n3" />

                      <div className="textOnInputs">
                        <div className="col-md-12 mt-3">
                          <label htmlFor="image">
                            <span style={{ color: "#ff0000" }}>*</span>Issue
                            Image
                          </label>
                          <input
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            id="image"
                            name="issue_images"
                            className="form-control-file"
                            onChange={this.uploadImageToS3}
                          />
                          {this.state.forum_images.length > 0
                            ? this.state.forum_images.map((img) => {
                              return (
                                <div
                                  className="card"
                                  style={{ margin: 10, width: 200 }}
                                >
                                  <img
                                    src={img.url}
                                    key={img.url}
                                    className="card-img-top"
                                    alt={img.name}
                                    width="100px"
                                    height="100px"
                                  />
                                  <button
                                    onClick={() => {
                                      this.removeImageURL(img.id);
                                    }}
                                    type="button"
                                    className="btn btn-danger"
                                  >
                                    Remove
                                  </button>
                                </div>

                                // <img
                                //   id="image"
                                //   src={item.url}
                                //   alt="No Image"
                                //   width="100px"
                                //   height="80px"
                                // />
                              );
                            })
                            : ""}
                          <div className="errorMsg">
                            {this.state.errors.issue_images}
                          </div>
                        </div>
                      </div>
                      <br />
                      <hr className="mx-n3" />

                      <div className="form-group">
                        <button
                          type="submit"
                          id="btnSubmitSignup"
                          className="btn btn-warning btn-lg btn-block mb-2 mt-4"
                          style={{ margin: "4px" }}
                        >
                          {this.state.loader ? <LoaderRounded /> : "Edit"}
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger btn-lg btn-block mb-2 mt-4"
                          style={{ margin: "4px" }}
                          onClick={() => {
                            this.handleDelete(this?.state?.forumId);
                          }}
                        >
                          {this.state.loader ? <LoaderRounded /> : "Delete"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    UserData: state.PersistReducer.UserData.UserData,
    Selected_collgege: state.PersistReducer.UserData.selected_college,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditForum);
