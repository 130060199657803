import React from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { Edit } from "@material-ui/icons";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRounded from "./LoaderRounded";
import LoaderRoundedWithText from "./LoaderRoundedWithText";
import ReactPaginate from "react-paginate";
import { Col, Row } from "react-bootstrap";
class PendingCandidateList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFilter: null,
      candidate: "",
      year: 2025,
      years: [],
      selectValue: "all",
      redirect: false,
      loader: false,
      loading: true,
      limitVal: 10,
      allpage: "",
      page_selected: 0,
    };

    this.applyFiter = this.applyFilter.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.Editcandidate = this.Editcandidate.bind(this);
    this.getData = this.getData.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  applyFilter(arr) {
    if (!this.state.currentFilter) {
      return arr;
    }

    return arr.filter((x) => x.Status === this.state.currentFilter);
  }

  setFilter(e) {
    e.preventDefault();
    this.setState({
      currentFilter: e.target.value,
    });
  }

  UNSAFE_componentWillMount() {
    this.getData();
  }

  componentWillMount() {
    setTimeout(() => this.setState({ loading: false }), 1000);
  }

  getData(selectedYear) {
    let currentComponent = this;
    this.setState({
      loader: true
    })
    axios.post(process.env.REACT_APP_APIURL + `/candidate/list/status/PENDING`, {
      districts: JSON.parse(localStorage.getItem("districts")),
      year: selectedYear ? selectedYear : this.state.year
    })
      .then((res) => {
        let responses = res.data
        // .slice(
        //   this.state.page_selected * this.state.limitVal,
        //   this.state.limitVal * (this.state.page_selected + 1)
        // );
        let con = Math.ceil(parseInt(res.data.length) / this.state.limitVal);
        currentComponent.setState({
          allpage: con,
          candidate: responses,
          loader: false,
        });
      })
      .catch((error) => {
        console.log("error", error);
        currentComponent.setState({
          loader: false
        })
      })
  }

  Editcandidate(yinID, candidateID) {
    localStorage.setItem("candidate_id", candidateID);
    localStorage.setItem("yin_id", yinID);
    this.setState({
      redirect: true,
    });
  }

  handlePageChange(page) {
    this.setState({
      page_selected: page.selected,
    });
    // this.getData(page.selected);
  }

  // year dropdown code
  componentDidMount() {
    const currentYear = new Date().getFullYear();
    const initialYears = this.generateYears(2021, currentYear + 1);
    this.setState({ years: initialYears });
  }

  generateYears(start, end) {
    const yearsArray = [];
    for (let year = end; year >= start; year--) { 
      yearsArray.push(year);
    }
    return yearsArray;
  }

  handleCompleteYear = () => {
    const currentYear = new Date().getFullYear();
    this.setState((prevState) => ({
      years: [...prevState.years, currentYear + 1],
    }));
  };

  //  handle year onChange
  getCollegeWithYear = (event) => {
    const selectedYear = event.target.value;
    this.setState({
      year:selectedYear,
    },()=>{
      this.getData(selectedYear)
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/edit_candidate" />;
    }

    if (!localStorage.getItem("mobile")) {
      return <Redirect to="/login" />;
    }

    const getDistData = localStorage.getItem("districts");
    const districtData = JSON.parse(getDistData)
    const getNewDistData = districtData?.map((itm, index) => {
      return itm
    })


    return (
      <>
        {this.state.loading ? (
          <LoaderRoundedWithText />
        ) : (
          <div>
            <Sidebar />
            <div className="main">
              <div className="topbar">
                <Navbar title="Pending Candidate List" />
              </div>

              <div className="container">
                <BackAndBreadCrmOneParent currentTabName="Pending candidate list" />

                {/* <br></br> */}

                {/* <div className="text-center">
                            <Link to="/Addcandidate" className="btn btn-secondary btn-sm" style={{ float: "right" }}><Add />Add New</Link>

                        </div> */}
                {/* <br></br> */}
                <div className="container">
                  {/* <div>
                  Current Filter: {this.state.currentFilter ?? "NO FIlter"}{" "}
                </div> */}
                  <Row md={12} className="mt-3 mb-3 justify-content-end">
                    <Col md={3} className="mb-2 mb-sm-0">
                      <select
                        name="year"
                        className="btn dropdown-toggle mb-2 w-100"
                        style={{ backgroundColor: "#003147", color: "white" }}
                        onChange={this.getCollegeWithYear}
                        value={this.state.year}
                      >
                        <option value="">All Years</option>
                        {this.state.years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </Row>
                  {this.state.loader ? (
                    <LoaderRounded />
                  ) : (
                    <div className="row">
                      <div className="col-12">
                        <div style={{ overflowx: "auto" }}>
                          <div className="table-responsive-sm">
                            <table className="table table-bordered table-responsive">
                              <thead>
                                <tr>
                                  <th>ID</th>
                                  <th>YIN ID</th>
                                  <th>Name</th>
                                  <th>College</th>
                                  <th>Districts</th>
                                  <th>Mobile</th>
                                  <th>Status</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.candidate.length > 0 ?
                                  this.applyFilter(this.state.candidate).slice(
                                    this.state.page_selected * this.state.limitVal,
                                    this.state.limitVal * (this.state.page_selected + 1)
                                  ).map(
                                    (candidates, id) => (
                                      <tr key={id}>
                                        <td>
                                          {this.state.page_selected *
                                            this.state.limitVal +
                                            id +
                                            1}
                                        </td>
                                        <td>{candidates.yin_id}</td>
                                        <td>{candidates.first_name}</td>
                                        <td>{candidates.college_name}</td>
                                        <td>{candidates.city}</td>
                                        <td className="noselect">
                                          {candidates.mobile}
                                        </td>
                                        {candidates.application_status ===
                                          "APPROVED" ? (
                                          <td className="text-success">
                                            APPROVED
                                          </td>
                                        ) : (
                                          ""
                                        )}
                                        {candidates.application_status ===
                                          "REJECT" ? (
                                          <td className="text-danger">
                                            REJECT
                                          </td>
                                        ) : (
                                          ""
                                        )}
                                        {candidates.application_status ===
                                          "PENDING" ? (
                                          <td className="text-warning">
                                            PENDING
                                          </td>
                                        ) : (
                                          ""
                                        )}
                                        <td className="text-center">
                                          <span
                                            onClick={() =>
                                              this.Editcandidate(
                                                candidates.yin_id,
                                                candidates.college_code
                                              )
                                            }
                                          >
                                            <Edit />
                                          </span>
                                        </td>
                                      </tr>
                                    )
                                  ) :
                                  <>
                                    <td>
                                      <th>No Data Found</th>
                                    </td>
                                  </>
                                }
                              </tbody>
                            </table>
                          </div>
                          {
                            <ReactPaginate
                              nextLabel="Next"
                              previousLabel="Previous"
                              pageCount={
                                // candidateList.length / this.state.renderPageLimit
                                this.state.allpage
                              }
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              containerClassName="pagination"
                              activeClassName="btn btn-primary"
                              pageClassName="btn"
                              previousClassName="btn"
                              nextClassName="btn"
                              onPageChange={this.handlePageChange}
                            />
                          }
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default PendingCandidateList;
