import React, { Component } from 'react';
import '../Assets/css/Addcollege.css';
import axios from 'axios';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import { Redirect } from 'react-router-dom';
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRounded from './LoaderRounded';

export default class EditPendingCollege extends Component {
    constructor(props) {
        super(props);
        this.state = {
            college_id: '',
            college_name: localStorage.getItem("college_name"),
            college_name_old: localStorage.getItem("college_name"),
            type: '',
            college_code: '',
            college_type: '',
            college_key: '',
            address1: '',
            address2: '',
            states: '',
            city: '',
            district: '',
            pincode: '',
            contact: '',
            website: '',
            principle_name: '',
            principle_number: '',
            approve_status: '',
            college_logo: '',
            state_code: '',
            city_code: '',
            state_name: '',
            city_name: '',
            sub_city: '',
            StateData: [],
            CityData: [],
            errors: [],
            country: "IN",
            approver_status: "",
            errorUpdate: "",
            status: true,
            changeToView: false,
            loader: false,

        }
        this.initialState = this.state;
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.ChangeCity = this.ChangeCity.bind(this);
        this.showCity = this.showCity.bind(this);
        this.imageChangedHandler = this.imageChangedHandler.bind(this);
        this.deleteCollege = this.deleteCollege.bind(this);
    };



    UNSAFE_componentWillMount() {
        if (this.props.location.query) {
            localStorage.setItem("college_name", this.props.location.query.college_name);
            this.setState({
                college_name: localStorage.getItem("college_name"),
                college_code: localStorage.getItem("college_code"),
            })
        } else {
            this.setState({
                college_name: localStorage.getItem("college_name"),
                college_code: localStorage.getItem("college_code"),
            })
        }
        let collegName = localStorage.getItem("college_name");
        let collegeCode = localStorage.getItem("college_code");
        this.getData();
    }

    getData() {
        let currentComponent = this;
        let collegeID = localStorage.getItem("college_code");
        axios.get(process.env.REACT_APP_APIURL + `/college/college_code/` + collegeID)
            .then(res => {
                let responses = res.data;
                currentComponent.setState({
                    college_id: responses[0]._id,
                    college_code: responses[0].college_code,
                    college_name: responses[0].college_name,
                    college_name_old: responses[0].college_name,
                    address1: responses[0].address1,
                    address2: responses[0].address2,
                    states: responses[0].state,
                    city: responses[0].city,
                    pincode: responses[0].pincode,
                    contact: responses[0].contact,
                    website: responses[0].website,
                    principle_name: responses[0].principle_name,
                    principle_number: responses[0].principle_number,
                    college_logo: responses[0].college_logo,
                    type: responses[0].type,
                    country: "IN",
                    approver_status: responses[0].approver_status,
                    status: true,
                    college_key: responses[0].college_key,
                });

            });
    }

    // title for form
    getTitle() {
        return <h3 className="text-center">Edit College</h3>
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    //upload image and resize
    imageChangedHandler(event) {
        var fileInput = false;
        if (event.target.files[0]) {
            fileInput = true;
        }
        if (fileInput) {
            this.setState({
                images: event.target.files[0],
            })

        }
    }

    ChangeCity = (e) => {
        e.preventDefault();
        axios.get(process.env.REACT_APP_APIURL + '/address/city/' + e.target.value).then(response => {
            this.setState({
                CityData: response.data,
            })
        }).catch(error => {
            console.log("Error", error);
        });
    }

    showCity = (e) => {
        e.preventDefault();
        let index = e.target.selectedIndex;
        this.setState({ city: e.target[index].innerText });
    }

    handleFormValidation = () => {
        const { college_name, type, approver_status } = this.state;
        let errors = {};
        let formIsValid = true;

        //empty college name
        if (!college_name) {
            formIsValid = false;
            errors["college_name"] = "*Please enter college name.";
        }

        //empty college type
        if (!type) {
            formIsValid = false;
            errors["college_type"] = "*Please select college type.";
        }

        //empty approve status
        if (!approver_status) {
            formIsValid = false;
            errors["approver_status"] = "*Please enter approve status.";
        }

        this.setState({
            errors: errors
        });
        return formIsValid;

    }


    //save form data
    handleSubmit(event) {

        event.preventDefault();

        if (this.handleFormValidation()) {

            let formData = new FormData();
            formData.append("college_id", this.state.college_id);
            formData.append("college_name", this.state.college_name);
            formData.append("college_name_old", this.state.college_name_old);
            formData.append("college_code", this.state.college_code);
            formData.append("college_key", this.state.college_key);
            formData.append("type", this.state.type);
            formData.append("address1", this.state.address1);
            formData.append("address2", this.state.address2);
            formData.append("state", this.state.states ? this.state.states : "MH");
            formData.append("city", this.state.city_name ? this.state.city_name : this.state.city);
            formData.append("sub_city", this.state.sub_city ? this.state.sub_city : "");
            formData.append("pincode", this.state.pincode ? this.state.pincode : "");
            formData.append("contact", this.state.contact ? this.state.contact : "");
            formData.append("website", this.state.website ? this.state.website : "");
            formData.append("principle_name", this.state.principle_name ? this.state.principle_name : "");
            formData.append("principle_number", this.state.principle_number ? this.state.principle_number : "");
            formData.append("approver_status", this.state.approver_status);
            formData.append("college_logo", this.state.college_logo);
            let statesSet = this;

            if (this.state.approver_status === "REJECTED") {
                this.deleteCollege(this.state.college_code);
            }
            //post college data
            axios.post(process.env.REACT_APP_APIURL + `/coordinator/college/update`, formData)
                .then(res => {
                    if (res.status === 200) {
                        alert('College update successfully');
                        statesSet.setState({
                            changeToView: true,
                            loader: false
                        })

                    }
                }).catch((error) => {
                    this.setState({
                        errorUpdate: "Error in updating college information"
                    });
                    console.log(error);
                })

        }
    }

    deleteCollege(collegeCode) {
        let college_code = collegeCode;
        axios.post(process.env.REACT_APP_APIURL + `/coordinator/college/delete`, { college_code })
            .then(res => {
                this.setState({
                    redirect: true,
                    loader: false
                })
            }).catch(error => {
                console.log(error);
            });
    }


    render() {
        if (this.state.changeToView) {
            return <Redirect to='/pending-college' />

        }

        let subCitys = localStorage.getItem("sub_city_name") ?
            <div id="stateDiv" className="col-12 col-md-6 pt-md-0 pt-4">
                <div className="form-group">
                    <label htmlFor="subCity" className="control-label">Sub City</label>
                    <select id="subCity" name="sub_city" className="form-select" onChange={this.handleChange} >
                        <option>--Choose your sub city--</option>
                        <option value="PCMC">PCMC</option>
                        <option value="PUNE_CITY">PUNE_CITY</option>
                        <option value="PUNE_RURAL">PUNE_RURAL</option>
                    </select>
                    <div className="errorMsg">{this.state.errors.subCity}</div>
                </div>
            </div> : "";

        return (

            <>
                <Sidebar />
                <div className="main">
                    <div className="topbar">
                        <Navbar />
                    </div>
                    <div className="container">
                        <BackAndBreadCrmOneParent backLink="/pending-college" backName="Pending College List" currentTabName="Edit Pending College" />
                        <div className="row mt-4">
                            <div className="col-lg-8 col-md-8 col-12 paddingAll10 mx-auto">
                                <div className="card rounded shadow shadow-sm">
                                    <div className="card-header">
                                        <h3 className="mb-0">College</h3>
                                    </div>
                                    <div className="card-body">
                                        <div>
                                            <form id="formCollege" onSubmit={this.handleSubmit} method="post" encType="multipart/form-data">

                                                <div id="collegenameDiv" className="form-group">
                                                    <label htmlFor="college_name" className="control-label">College Name</label>
                                                    <input type="text" id="" value={this.state.college_name} name="college_name" className="form-control rounded-1" onChange={this.handleChange} placeholder="College Name" />
                                                    <div className="errorMsg">{this.state.errors.college_name}</div>
                                                </div>

                                                <div id="collegetypeDiv" className="form-group">
                                                    <label htmlFor="collegetypes" className="control-label">Select College Type</label>
                                                    <select id="collegetypes" name="type" className="form-select" onChange={this.handleChange}>
                                                        <option value="">{this.state.type} </option>
                                                        <option value="RURAL">Rural</option>
                                                        <option value="URBAN">Urban</option>
                                                    </select>
                                                </div>

                                                <div id="address1Div" className="form-group">
                                                    <label htmlFor="adddress1" className="control-label">Address line 1</label>
                                                    <input type="text" id="address1" value={this.state.address1 ? this.state.address1 : ""} name="address1" className="form-control rounded-1" onChange={this.handleChange} placeholder="Address1" />
                                                    <div className="errorMsg">{this.state.errors.address1}</div>
                                                </div>

                                                <div id="address2Div" className="form-group">
                                                    <label htmlFor="adddress2" className="control-label">Address line 2</label>
                                                    <input type="text" id="address2" value={this.state.address2 ? this.state.address2 : ""} name="address2" className="form-control rounded-1" onChange={this.handleChange} placeholder="Address2" />
                                                    <div className="errorMsg">{this.state.errors.address2}</div>
                                                </div>

                                                <div>
                                                    <div id="stateDiv" className="form-group">
                                                        <label htmlFor="states" className="control-label">Select State</label>
                                                        <select id="states" className="form-select" name="states" onChange={this.ChangeCity} >
                                                            <option value={this.state.states}>{this.state.states === "MH" ? "Maharashtra" : "Maharashtra"}</option>
                                                            <option value="MH">Maharashtra</option>
                                                            {/* <option value="GA">Goa</option> */}
                                                        </select>
                                                    </div>
                                                </div>


                                                <div className="row my-md-4 my-2">

                                                    <div className="col-md-6 col-12 pt-md-0 pt-4">
                                                        <div id="stateDiv" className="form-group">
                                                            <label htmlFor="citys" id="cityLabel" className="control-label">Select City</label>
                                                            <select id="citys" name="district" className="form-select" onChange={this.showCity}>
                                                                <option>{this.state.city}</option>
                                                                {this.state.CityData.map((e, key) => {
                                                                    return <option key={key} value={e.countryCode}>{e.name}</option>;
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {subCitys}

                                                    <div id="stateDiv" className="col-md-6 col-12 pt-md-0 pt-4" >
                                                        <label htmlFor="pincode" className="control-label">Pincode</label>
                                                        <input type="text" className="form-control" name="pincode" onChange={this.handleChange} placeholder="411013" />
                                                    </div>

                                                </div>

                                                {/* <div className="row">
                                                    <div className="col-12 divider-10" >
                                                        <label htmlFor="pincode" className="control-label">Pincode</label>
                                                        <input type="text" className="form-control" name="pincode" onChange={this.handleChange} placeholder="Ex. 411013"  />
                                                    </div>
                                                </div> */}

                                                <div id="phoneDiv" className="form-group">
                                                    <label htmlFor="phone_no" className="control-label">Mobile Number:</label>
                                                    <input type="number" name="contact" id="contact"
                                                        onChange={this.handleChange}
                                                        value={this.state.contact ? this.state.contact : ""}
                                                        className="form-control"
                                                        placeholder="Mobile Number"
                                                        readOnly={true} />
                                                    <div className="errorMsg">{this.state.errors.contact}</div>
                                                </div>

                                                <div id="websiteDiv" className="form-group">
                                                    <label htmlFor="website" className="control-label">Website</label>
                                                    <input type="text" id="website" value={this.state.website ? this.state.website : ""} name="website" className="form-control rounded-1" onChange={this.handleChange} placeholder="Website" />
                                                    <div className="errorMsg">{this.state.errors.website}</div>
                                                </div>

                                                <div id="priciplenameDiv" className="form-group">
                                                    <label htmlFor="principle_name" className="control-label">Principle Name</label>
                                                    <input type="text" id="principle_name" value={this.state.principle_name ? this.state.principle_name : ""} name="principle_name" className="form-control rounded-1" onChange={this.handleChange} placeholder="Principle Name" />
                                                    <div className="errorMsg">{this.state.errors.principle_name}</div>
                                                </div>

                                                <div id="priciplenumberDiv" className="form-group">
                                                    <label htmlFor="principle_name" className="control-label">Principle Mobile Number</label>
                                                    <input type="text" id="principle_number" value={this.state.principle_number ? this.state.principle_number : ""} name="principle_number" className="form-control rounded-1" onChange={this.handleChange} placeholder="Principle Contact Number" />
                                                    <div className="errorMsg">{this.state.errors.principle_number}</div>
                                                </div>

                                                <div id="approvestatusDiv" className="form-group">
                                                    <label htmlFor="approve_status" className="control-label">Approve Status</label>
                                                    {/* <input type="text" id="approve_status" value={this.state.approve_status} name="approve_status" className="form-control rounded-1" onChange={this.handleChange} placeholder="Approve Status" /> */}
                                                    <select id="approve_status" className="form-select" name="approver_status" onChange={this.handleChange} >
                                                        <option>{this.state.approver_status}</option>
                                                        <option value="PENDING">Pending</option>
                                                        <option value="APPROVED">Approved</option>
                                                        <option value="REJECTED">Rejected</option>
                                                        {/* <option value="GA">Goa</option> */}
                                                    </select>
                                                    <div className="errorMsg">{this.state.errors.approver_status}</div>
                                                </div>
                                                <div className="errorMsg">{this.state.errorUpdate}</div>


                                                <button type="submit" name="submit" id="btnSubmitCollege" className="btn btn-success">{this.state.loader ? <LoaderRounded /> : "Update college"}</button>
                                                <button type="reset" id="btnCancelCollege" className="btn btn-danger" onClick={this.deleteCollege} >{this.state.loader ? <LoaderRounded /> : "Delete college"}</button>

                                            </form>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>


                </div>

            </>

        )
    }
}
