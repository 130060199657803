import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import axios from "axios";
import { Button, Col, Form, Row, Spinner, Table } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import LoaderRounded from "./LoaderRounded";
import Moment from "react-moment";
import LoaderRoundedWithText from "./LoaderRoundedWithText";
import { Edit } from "@material-ui/icons";
Moment.globalFormat = "D MMM YYYY HH:MM:ss";

const TransactionListAll = () => {
  const [payments, setPayments] = useState([]);
  const [renderPageLimit, setRenderPageLimit] = useState(10);
  const [selectedPage, setSelectedPage] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [skip, setSkip] = useState(0);
  const [filterListByMobile, setFilterListByMobile] = useState("");
  const [filterdata, setFilterData] = useState([]);
  const [candidate, setCandidate] = useState([]);
  const [loading, setloading] = useState(true);
  const [loader, setLoader] = useState(false);

  // const navigate = useNavigate();

  let date = new Date();

  const getallData = () => {
    let currentDay = String(date.getDate()).padStart(2, "0");
    let currentDay2 = String(date.getDate() + 1).padStart(2, "0");

    let currentMonth = String(date.getMonth() + 1).padStart(2, "0");

    let currentYear = date.getFullYear();

    // we will display the date as DD-MM-YYYY

    let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
    let currentDate2 = `${currentYear}-${currentMonth}-${currentDay2}`;

    setLoader(true);
    const data = {
      // startdate: !startDate ? currentDate : startDate,
      // enddate: !endDate ? currentDate2 : endDate,
      startdate : startDate ? startDate : "",
      enddate : endDate ? endDate : "",
      // count: 100,
      // skip: skip1,
    };

    axios.post(process.env.REACT_APP_APIURL + "/pay/allpayment", data)
      .then((res) => {
        if (res.data.items) {
          setPayments(res.data.items);
          setFilterData(res.data.items);
          setCandidate(res.data.items);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const searchList = (char) => {
    let f = filterdata?.filter((item) =>
      item?.contact
        ?.substring(3, 13)
        .toString()
        .toLowerCase()
        ?.startsWith(char?.toLowerCase())
    );
    setPayments(f);
  };

  const handlePageChange = (page) => {
    setSelectedPage(page.selected);
  };

  useEffect(() => {
    getallData();
    setTimeout(() => setloading(false), 1000);
  }, []);

  return (
    <div className="payments">
      {loading ? (
        <LoaderRoundedWithText />
      ) : (
        <div>
          <Sidebar />
          <div className="main">
            <div className="topbar mb-3">
              <Navbar title="Razorpay Transaction List"/>
            </div>

            <BackAndBreadCrmOneParent
              backLink="dashboard"
              currentTabName="Trasaction List"
            />

            <Row className="mx-3 my-4 justify-content-between">
              <Col md={8}>
                <Row md={12}>
                  <Col md={4}>
                  From:
                  <Form.Control
                    onChange={(e) => setStartDate(e.target.value)}
                    type="date"
                  />
                  </Col>

                  <Col md={4}>
                  To:
                  <Form.Control
                    onChange={(e) => setEndDate(e.target.value)}
                    type="date"
                  />
                  </Col>

                  <Col md={2} className="d-flex align-items-center mt-4">
                  <Button onClick={getallData} className="secondaryBtn">Submit</Button>
                  </Col>
                </Row>
              </Col>

              <Col md={2} className="mt-4">
              <Form.Control
                  name="filterListByMobile"
                  placeholder="Search By Mobile"
                  type="text"
                  onChange={(e) => {
                    setFilterListByMobile(e.target.value);
                    searchList(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <div className="container text-center">
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive-sm">
                    <table className="table table-bordered table-responsive">
                      <thead>
                        <tr className="text-center">
                          <th>No</th>
                          <th>Event Name</th>
                          <th>Mobile</th>
                          {/* <th>Email</th> */}
                          <th>Status</th>
                          <th>Amount</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loader ? (
                          <tr key={loader}>
                            <td colSpan={7}>
                              <LoaderRounded />
                            </td>
                          </tr>
                        ) :   
                        payments.length > 0  ?
                        payments?.slice(
                              selectedPage * renderPageLimit,
                              renderPageLimit + selectedPage * renderPageLimit
                            )
                            .map((item, i) => {
                              return (
                                  <tr key={item.id}>
                                    <td>{selectedPage * renderPageLimit + i + 1}</td>
                                    <td>{item.description}</td>
                                    <td>{item.contact}</td>
                                    <td>
                                      {item.status === "captured" ? (
                                        <span className="text-center text-success">
                                          {item.status}
                                        </span>
                                      ) : (
                                        <span className="text-center text-danger">
                                          {item.status}
                                        </span>
                                      )}
                                    </td>
                                    <td>{item.amount / 100}</td>
                                    <td>
                                      <Moment unix>{item.created_at}</Moment>
                                    </td>
                                    <td className="icanostyle text-center">
                                      <Link
                                        to={{
                                          pathname: "/checkTransactionStatus",
                                          data: item,
                                        }}
                                      >
                                        <button className="btn btn-outline-info btn-sm me-2">
                                          <Edit />
                                        </button>
                                      </Link>
                                    </td>
                                  </tr>
                              );
                            }) : 
                            (
                              <tr><td colSpan="6" className="text-center">No Data Found....</td></tr>
                            )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <ReactPaginate
                  nextLabel="Next"
                  previousLabel="Previous"
                  pageCount={payments?.length / renderPageLimit}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  containerClassName="pagination"
                  activeClassName="btn btn-primary"
                  pageClassName="btn"
                  previousClassName="btn"
                  nextClassName="btn"
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TransactionListAll;
