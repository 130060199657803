import { LOGIN_SUCCESS } from '../Constant/Authconstant'
import { ADMIN_MOBILE ,LOGOUT} from '../Constant/Authconstant';

export const loginSuccess = data => {
    return {
        type: LOGIN_SUCCESS,
        payload: data
    }
};

export const adminmobile = data => {
    return {
        type: ADMIN_MOBILE,
        payload: data
    }
};
export const logout = () => {
    return {
        type: LOGOUT,
    }
};
