import React, { Component } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import "../Assets/css/Dashboard.css";
import Card from "./Card";
import { Redirect } from "react-router";

export default class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      islogged: false,
    };
  }

  // UNSAFE_componentWillMount(){
  //     localStorage.clear();
  // }

  render() {
    if (!localStorage.getItem("mobile")) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>
          <Card />
        </div>
        
      </div>
    );
  }
}
