module.exports = [
    {
        ID: 1,
        Name: 'Sherya',
        College:'JSPM',
        Email:'abcd@gmail.com',
        Mobile: 9876543221,
        Address:'Moshi' ,
        DOB: '11/8/1998',
        College_id: 1064,
        Status:'Pending'
    },
    {
        ID: 2,
        Name: 'Pravin',
        College:'DYPCOE',
        Email:'bcde@gmail.com',
        Mobile: 9873428961,
        Address:'Akurdi' ,
        DOB: '16/9/1996',
        College_id: 106,
        Status:'Approved'
    },
    {
        ID: 3,
        Name: 'Pooja',
        College:'MIT',
        Email:'cdef@gmail.com',
        Mobile: 992824651,
        Address:'Kothrud' ,
        DOB: '11/10/1997',
        College_id: 1098,
        Status:'Rejected'
    },
    {
        ID: 4,
        Name: 'shivani',
        College:'PCCOE',
        Email:'defg@gmail.com',
        Mobile: 7841938341,
        Address:'Nigdi' ,
        DOB: '31/12/2000',
        College_id: 2274,
        Status:'Pending'
    },
    {
        ID: 5,
        Name: 'afrin',
        College:'COEP',
        Email:'effgh@gmail.com',
        Mobile: 9920210204,
        Address:'ShivajiNagar' ,
        DOB: '12/1/2001',
        College_id: 101,
        Status:'Rejected'
    },
    {
     ID: 6,
     Name: 'Ajinkya',
     College:'DYPIEMR',
     Email:'fghi@gmail.com',
     Mobile: 7689567231,
     Address:'Akurdi' ,
     DOB: '19/1/1999',
     College_id: 1094,
     Status:'Approved'
    },
    {
     ID: 7,
     Name: 'Vinod',
     College:'PCCOER',
     Email:'ghij@gmail.com',
     Mobile: 7843526829,
     Address:'Ravet' ,
     DOB: '16/6/1996',
     College_id: 1021,
     Status:'Approved'
    },
    {
     ID: 8,
     Name: 'Manasi',
     College:'Modern',
     Email:'hijk@gmail.com',
     Mobile: 8989342310,
     Address:'ShivajiNagar' ,
     DOB: '28/5/1999',
     College_id: 1031,
     Status:'Rejected'
    },
    {
     ID: 9,
     Name: 'Deep',
     College:'MIT',
     Email:'ijkl@gmail.com',
     Mobile: 90423764301,
     Address:'Alandi' ,
     DOB: '20/6/1999',
     College_id: 1041,
     Status:'Pending'
    }
]