import React, { Component } from "react";
import "../Assets/css/Dashboard.css";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import axios from "axios";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import CardCount from "./CardCount";
import LoaderRounded from "./LoaderRounded";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Button, Col, Nav, Row, Tab } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

export default class Allcards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      Candidates: "",
      electionStartEnd: "",
      LdpStatus: "",
      viewStudent: "",
      electionList: "",
      disputeList: "",
      electionResult: "",
      role: "",
      allcounts: "",
      college_code: localStorage.getItem("collegeCode"),
      pdfdownload: false,
      pdfdownloadforApproved: false,
      loader: false,
      loading: true,
      ldp_start_date: "",
      ldp_end_date: "",
      ldp_start_time: "",
      ldp_end_time: "",
      isVotingStart: false,
      viewVoterList: false,
      collegeInfo: [],
      registrationStatus: "",
      //    ldp_started:false,
      //    ldp_ended:true
      is_ldpStart: localStorage.getItem("ldpstart"),
      year: this.props.location.state?.year || null,
      cities: this.props.location.state?.city || null,
    };
    this.electionSingle = this.electionSingle.bind(this);
    this.ldpStart = this.ldpStart.bind(this);
    this.handlecreatepdf = this.handlecreatepdf.bind(this);
    this.onButtonClick = this.onButtonClick.bind(this);
    this.handleApprovedcreatePdf = this.handleApprovedcreatePdf.bind(this);
    this.onButtonClickApproved = this.onButtonClickApproved.bind(this);
    this.handleLDPStart = this.handleLDPStart.bind(this);
    this.LDPStop = this.LDPStop.bind(this);
    this.handleVoterRegistrationStatus = this.handleVoterRegistrationStatus.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({ role: localStorage.getItem("role") });
    this.getData();
    this.getCollegeData();
  }
  getCollegeData = () => {
    try {
      //college/college_code/COLPUNE002892
      axios.get(process.env.REACT_APP_APIURL + `/college/college_code/` + localStorage.getItem("collegeCode"))
        .then((res) => {
          let collegeInfos = res.data[0];
          this.setState({
            collegeInfo: collegeInfos,
            viewVoterList: collegeInfos.viewVoterList
          });
        }).catch((error) => {
          console.log("error", error);
        })

    } catch (error) {
      console.log("---college data not found-------", error)
    }
  }

  getData = () => {
    axios.get(process.env.REACT_APP_APIURL + `/coordinator/dashboard/count/college/` + localStorage.getItem("collegeCode"))
      .then((res) => {
        let allcounts = res.data;
        this.setState({
          allcounts: allcounts,
          isVotingStart: allcounts?.isVoterIdRegistrationStarted
        });
      }).catch((error) => {
        console.log("error", error);
      })
  };

  startDispute = (event) => {
    event.preventDefault();
    let formData = {
      disputes_status: "true",
      college_code: this.state.college_code,
    };
    axios.post(process.env.REACT_APP_APIURL + "/coordinator/election/dispute/control", formData)
      .then((res) => {
        if (res.status === 200) {
          alert("Dispute Started successfully.");
          this.setState({ redirect: true });
        }
      }).catch((error) => {
        console.log("error", error)
      })
  };

  endDispute = (event) => {
    event.preventDefault();
    let formData = {
      disputes_status: false,
      college_code: this.state.college_code,
    };
    axios.post(process.env.REACT_APP_APIURL + "/coordinator/election/dispute/control", formData)
      .then((res) => {
        if (res.status === 200) {
          alert("Dispute End successfully.");
          this.setState({ redirect: true });
        }
      }).catch((error) => {
        console.log("error", error);
      })
  };

  showElection = (event) => {
    event.preventDefault();
    let formData = {
      results_available_status: true,
      college_code: this.state.college_code,
    };
    axios.post(process.env.REACT_APP_APIURL + "/coordinator/election/result/control", formData)
      .then((res) => {
        if (res.status === 200) {
          alert("Show Election Started successfully.");
          this.setState({ redirect: true });
        }
      }).then((error) => {
        console.log("error", error);
      })
  };

  stopElection = (event) => {
    event.preventDefault();
    let formData = {
      results_available_status: false,
      college_code: this.state.college_code,
    };
    axios.post(process.env.REACT_APP_APIURL + "/coordinator/election/result/control", formData)
      .then((res) => {
        if (res.status === 200) {
          alert("Stop Showing election successfully.");
          this.setState({ redirect: true });
        }
      }).then((error) => {
        console.log("error", error);
      })
  };
  electionSingle() {
    localStorage.setItem("multipleElection", 0);
  }
  ldpStart() {
    localStorage.setItem("multipleLDP", 0);
  }

  handlecreatepdf(event) {
    event.preventDefault();
    this.setState({
      pdfdownload: true,
    });
    let college_code = this.state.college_code;
    axios.post(process.env.REACT_APP_APIURL + "/candidate/create/pending/candidatelist/pdf", { college_code: [college_code] })
      .then((res) => {
        if (res.status === 200) {
          this.onButtonClick(res.data);
        }
      }).then((error) => {
        console.log("error", error);
      })
  }

  onButtonClick = (link) => {
    fetch(link).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "PendingCandidate.pdf";
        alink.click();
        this.setState({
          pdfdownload: false,
        });
      });
    });
  };

  handleApprovedcreatePdf(event) {
    event.preventDefault();
    this.setState({
      pdfdownloadforApproved: true,
    });

    let college_code = this.state.college_code;
    axios.post(process.env.REACT_APP_APIURL + "/candidate/create/candidatelist/pdf", { college_code: college_code })
      .then((res) => {
        if (res.status === 200) {
          this.onButtonClickApproved(res.data);
        }
      }).then((error) => {
        console.log("error", error);
      })
  }

  onButtonClickApproved = (link) => {
    fetch(link).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "CandidateApproved.pdf";
        alink.click();
      });
      this.setState({
        pdfdownloadforApproved: false,
      });
    });
  };

  handleLDPStart(event) {
    event.preventDefault();
    let formData = {
      college_code: [this.state.college_code],
      ldp_start_date: "2023-12-19",
      ldp_end_date: "2024-01-07",
      ldp_start_time: "07:30",
      ldp_end_time: "23:59",
      ldp_started: true,
      ldp_ended: false,
    };

    axios.post(process.env.REACT_APP_APIURL + "/coordinator/ldp/start/selected", formData)
      .then((res) => {
        if (res.status === 200) {
          alert("LDP start successfully.");
          this.setState({
            redirect: true,
            // loader:true,
          });
        }
      }).then((error) => {
        console.log("error", error);
      })
  }

  LDPStop(event) {
    event.preventDefault();
    let formData = {
      college_code: [this.state.college_code],
      ldp_start_date: "2023-12-19",
      ldp_end_date: "2024-01-07",
      ldp_start_time: "07:30",
      ldp_end_time: "23:59",
      ldp_started: false,
      ldp_ended: true,
    };

    axios.post(process.env.REACT_APP_APIURL + "/coordinator/ldp/start/selected", formData)
      .then((res) => {
        if (res.status === 200) {
          alert("LDP stop successfully.");

          this.setState({
            redirect: true,
            // loader:true,
          });
        }
      }).then((error) => {
        console.log("error", error);
      })
  }


  handleVoterRegistrationStatus() {
    const { isVotingStart } = this.state;
    // Toggle the value of isVotingStart
    const updatedIsVotingStart = !isVotingStart;
    // Update the state with the new value
    this.setState({ isVotingStart: updatedIsVotingStart });
    // Make the API call with the updated value
    const payload = {
      collage_code: this.state.college_code,
      is_start: updatedIsVotingStart,
    };
    axios.post(process.env.REACT_APP_APIURL + "/college/user/VoterRegistration", payload)
      .then((res) => {
        this.setState({
          // registrationStatus : res.data.isVoterIdRegistrationStarted,
          isVotingStart: res.data.isVoterIdRegistrationStarted,
        })

      }).catch((error) => console.log("error", error))
  }

  render() {
    return (
      <>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>

          <div className="container">
            <BackAndBreadCrmOneParent
              backLink="/collegeList"
              backName="College List"
              currentTabName="All cards"
              param1={this.state.year}
              param2={this.state.cities}
            />

            <div className="row text-center divider-40">
              {/* <u> */}
              <h2 style={{ fontWeight: '600', }}>{localStorage.getItem("college_name")}</h2>
              {/* </u> */}
            </div>

            <Row md={12} className="mt-5">
              {/* <Col md={12} className="d-flex"> */}
              <Col md={6} lg={4} sm={12} className="mb-3 sm-mb-3">
                <button
                  type="button"
                  id="btnSubmitSignup"
                  className="btn btn-danger"
                  style={{
                    minWidth: "230px",
                    fontSize: "18px",
                    padding: "10px",
                  }}
                  disabled={
                    this.state.allcounts.allCandidates === 0 ? true : false
                  }
                  onClick={this.handlecreatepdf}
                >
                  {this.state.pdfdownload ? (
                    <LoaderRounded />
                  ) : (
                    " All Candidate PDF"
                  )}
                </button>
                {this.state.pdfdownload && <span>Genrating pdf.....</span>}
              </Col>

              <Col md={6} lg={4} sm={12} className="mb-3 sm-mb-3">
                <button
                  type="button"
                  id="btnSubmitSignup"
                  className="btn btn-danger"
                  style={{
                    minWidth: "230px",
                    fontSize: "18px",
                    padding: "10px",
                  }}
                  disabled={
                    this.state.allcounts.allCandidates === 0 ? true : false
                  }
                  onClick={this.handleApprovedcreatePdf}
                >
                  {this.state.pdfdownloadforApproved ? (
                    <LoaderRounded />
                  ) : (
                    "Approved Candidate PDF"
                  )}
                </button>
              </Col>
              {this.state.role === 'COORDINATOR' ? "" :
                <Col md={6} lg={4} sm={12}>
                  {this.state.is_ldpStart == "true" ? (
                    <button
                      type="button"
                      id="btnSubmitSignup"
                      className="btn btn-success"
                      style={{ minWidth: "230px", fontSize: "18px", padding: "10px" }}
                      onClick={this.handleLDPStart}
                    >
                      LDP Start
                    </button>
                  ) : (
                    <button
                      type="button"
                      id="btnSubmitSignup"
                      className="btn btn-danger"
                      style={{ minWidth: "230px", padding: "10px 0" }}
                      onClick={this.LDPStop}
                    >
                      LDP Stop
                    </button>
                  )}
                </Col>}
            </Row>

            {/* <div className="row mt-4 justify-content-center">
              <div className="col-12 col-md-4 col-lg-4">
                <button
                  type="button"
                  id="btnSubmitSignup"
                  className="btn btn-danger btn-lg  m-2"
                  style={{
                    minWidth: "230px",
                    fontSize: "14px",
                    padding: "10px",
                  }}
                  disabled={
                    this.state.allcounts.allCandidates === 0 ? true : false
                  }
                  onClick={this.handlecreatepdf}
                >
                  {this.state.pdfdownload ? (
                    <LoaderRounded />
                  ) : (
                    " All Candidate PDF"
                  )}
                </button>
                {this.state.pdfdownload && <span>Genrating pdf.....</span>}
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <button
                  type="button"
                  id="btnSubmitSignup"
                  className="btn btn-danger btn-lg  m-2"
                  style={{
                    minWidth: "230px",
                    fontSize: "14px",
                    padding: "10px",
                  }}
                  disabled={
                    this.state.allcounts.allCandidates === 0 ? true : false
                  }
                  onClick={this.handleApprovedcreatePdf}
                >
                  {this.state.pdfdownloadforApproved ? (
                    <LoaderRounded />
                  ) : (
                    "Approved Candidate PDF"
                  )}
                </button>
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                {this.state.is_ldpStart == "true" ? (
                  <button
                    type="button"
                    id="btnSubmitSignup"
                    className="btn btn-success btn-sm  m-2"
                    style={{ minWidth: "230px", padding: "10px 0" }}
                    onClick={this.handleLDPStart}
                  >
                    LDP Start
                  </button>
                ) : (
                  <button
                    type="button"
                    id="btnSubmitSignup"
                    className="btn btn-danger btn-sm  m-2"
                    style={{ minWidth: "230px", padding: "10px 0" }}
                    onClick={this.LDPStop}
                  >
                    LDP Stop
                  </button>
                )}
                {console.log("ffgkgvjm", this.state.is_ldpStart)}
              </div>
            </div> */}

            <div className="row mx-2 mt-4 sm-mt-3">
              <CardCount
                colorCardBackground="numberCircle-center1"
                cardCount={this.state.allcounts.allRegistration}
                cardLink="/all-students"
                cardName="All YIN Member"
              />
              <CardCount
                colorCardBackground="numberCircle-center2"
                cardCount={this.state.allcounts.allCandidates}
                cardLink="/CandidateListByCollege"
                cardName="All Candidate"
              />
              <CardCount
                colorCardBackground="numberCircle-center3"
                cardCount={this.state.allcounts.allVoters}
                cardLink="/all-students"
                cardName="YIN Member"
              />

              {/* <CardCount colorCardBackground="numberCircle-center4" cardCount={this.state.allcounts.allVotes} cardLink="#" cardName="All Votes"/> */}
              {/* {this.state.role === 'COORDINATOR' ? "" :
                            <CardCount colorCardBackground="numberCircle-center1" cardCount={0} cardLink="/dispute-list" cardName="All Dispute"/>} */}
            </div>

            {this.state.role === 'COORDINATOR' ? "" :
              <Link to="#" onClick={() => this.handleVoterRegistrationStatus()} style={{ color: "black" }}>
                <Tab.Container id="voterRegistrationTabs" activeKey={this.state.isVotingStart ? "start" : "end"}>
                  <Nav variant="pills" className="flex-row justify-content-end mx-auto mt-4">
                    <Nav.Item>
                      <Nav.Link eventKey="start">
                        <div className="card-container">
                          <div className="d-flex p-1 justify-content-center rounded">
                            <Icon.FileEarmarkCheck className="me-2" size={20} />
                            <h6>Voter Registration Start</h6>
                          </div>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="end">
                        <div className="card-container">
                          <div className="d-flex p-1 justify-content-center rounded">
                            <Icon.StopCircleFill className="me-2" size={20} />
                            <h6>Voter Registration End</h6>
                          </div>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Tab.Container>
              </Link>
            }

            <Row md={12} className="mb-4 mt-5 mx-2">
              <Col md={3} className="mb-3 sm-mb-3">
                <Link to="/CandidateListByCollege" style={{ color: "black" }}>
                  <div className="card-container">
                    <div className="card-header d-flex p-3 justify-content-center rounded">
                      <Icon.People className="me-3" size={26} />
                      <h5>Candidates</h5>
                    </div>
                  </div>
                </Link>
              </Col>

              <Col md={3} className="mb-3 sm-mb-3">
                <Link to="/all-students" style={{ color: "black" }}>
                  <div className="card-container">
                    <div className="card-header d-flex p-3 justify-content-center rounded">
                      <Icon.Person className="me-3" size={26} />
                      <h5>YIN Member</h5>
                    </div>
                  </div>
                </Link>
              </Col>

              {this.state.role === 'COORDINATOR' ? "" :
                <Col md={3} className="mb-3 sm-mb-3">
                  <Link to="/college/dispute/list" style={{ color: "black" }}>
                    <div className="card-container">
                      <div className="card-header d-flex p-3 justify-content-center rounded">
                        <Icon.HandIndex className="me-3" size={26} />
                        <h5 >Dispute voters</h5>
                      </div>
                    </div>
                  </Link>
                </Col>
              }

              {this.state.role === 'COORDINATOR' ? "" :
                <Col md={3} className="mb-3 sm-mb-3">
                  <Link to="/election" style={{ color: "black" }} onClick={this.electionSingle}>
                    <div className="card-container">
                      <div className="card-header d-flex p-3 justify-content-center rounded">
                        <Icon.Power className="me-3" size={26} />
                        <h5>Election start / end</h5>
                      </div>
                    </div>
                  </Link>
                </Col>}

            </Row>

            <Row md={12} className="mb-4 mx-2">
              {this.state.role === 'COORDINATOR' ? "" : <Col md={3} className="mb-3 sm-mb-3">
                <Link to="/ldp" style={{ color: "black" }} onClick={this.ldpStart}>
                  <div className="card-container">
                    <div className="card-header d-flex p-3 justify-content-center rounded">
                      <Icon.ArrowUpRightCircle className="me-3" size={26} />
                      <h5>LDP Status</h5>
                    </div>
                  </div>
                </Link>
              </Col>}

              {this?.state?.viewVoterList ?
                <Col md={3} className="mb-3 sm-mb-3">
                  <Link to="/yinVoterList" style={{ color: "black" }}>
                    <div className="card-container">
                      <div className="card-header d-flex p-3 justify-content-center rounded">
                        <Icon.Person className="me-3" size={26} />
                        <h5>YIN Voter List</h5>
                      </div>
                    </div>
                  </Link>
                </Col> :
                ""}

              {this.state.role === 'COORDINATOR' ? "" :
                <Col md={3} className="mb-3 sm-mb-3">
                  <Link to="/electionlist" style={{ color: "black" }}>
                    <div className="card-container">
                      <div className="card-header d-flex p-3 justify-content-center rounded">
                        <Icon.CardList className="me-3" size={26} />
                        <h5>Election List</h5>
                      </div>
                    </div>
                  </Link>
                </Col>
              }

              <Col md={3} className="mb-3 sm-mb-3">
                <Link to="/result" style={{ color: "black" }}>
                  <div className="card-container">
                    <div className="card-header d-flex p-3 justify-content-center rounded">
                      <Icon.Clipboard2Check className="me-3" size={26} />
                      <h5>View Election Result</h5>
                    </div>
                  </div>
                </Link>
              </Col>

              {this.state.role === 'COORDINATOR' ? "" :
                <>
                  <Col md={3} className="mb-3 sm-mb-3">
                    <Link to="#" onClick={this.showElection.bind(this)} style={{ color: "black" }}>
                      <div className="card-container">
                        <div className="card-header d-flex p-3 justify-content-center rounded">
                          <Icon.JournalCheck className="me-3" size={26} />
                          <h5>Show Election Result on app</h5>
                        </div>
                      </div>
                    </Link>
                  </Col>

                  {/* <Col md={3} className="mb-3 sm-mb-3">
                <Link to="/ElectionResult" style={{ color: "black" }}>
                  <div className="card-container">
                    <div className="card-header d-flex p-3 justify-content-center rounded">
                      <Icon.Eye className="me-3" size={26} />
                      <h5>View Election Result</h5>
                    </div>
                  </div>
                </Link>
              </Col> */}
                </>
              }

              {this.state.role === 'COORDINATOR' ? "" :
                <Col md={3}>
                  <Link to="#" onClick={this.stopElection.bind(this)} style={{ color: "black" }}>
                    <div className="card-container">
                      <div className="card-header d-flex p-3 justify-content-center rounded">
                        <Icon.StopCircle className="me-3" size={26} />
                        <h5>Stop Election Result</h5>
                      </div>
                    </div>
                  </Link>
                </Col>}

            </Row>

            {/* <Row md={12} className="mb-5 mx-2"> */}


            {/* {this.state.role === 'COORDINATOR' ? "" :<Col md={3}>
                <Link to="#" onClick={() => this.handleVoterRegistrationStatus()} style={{ color: "black" }}>
                  {
                    this.state.isVotingStart === true ?
                      <div className="card-container">
                        <div className="card-header d-flex p-3 justify-content-center rounded">
                          <Icon.FileEarmarkCheck className="me-3" size={26} />
                          <h5>Voter Registration start</h5>
                        </div>
                      </div>
                      :
                      <div className="card-container">
                        <div className="card-header-stop d-flex p-3 justify-content-center rounded">
                          <Icon.StopCircleFill className="me-3" size={26} />
                          <h5>Voter Registration end</h5>
                        </div>
                      </div>
                  }
                </Link>
              </Col>} */}

            {/* { this.state.allcounts.isVoterIdRegistrationStarted === true ?
            <Col md={3}>
                <Link to="#" onClick={()=>this.handleVoterRegistrationStatus()}
                 style={{ color: "black" }}>
                  <div className="card-container">
                    <div className="card-header d-flex p-3 justify-content-center rounded">
                        <Icon.FileEarmarkCheck className="me-3" size={26}/>
                        {
                          this.state.registrationStatus === true ?
                          <h5>Voter Registration start</h5> : 
                          <h5>Voter Registration end</h5>
                        }
                    </div>
                  </div>
                </Link>
              </Col> 
              : 
              <Col md={3}>
                <Link to="#" onClick={()=>this.handleVoterRegistrationStatus()}
                 style={{ color: "black" }}>
                  <div className="card-container">
                    <div className="card-header d-flex p-3 justify-content-center rounded">
                        <Icon.FileEarmarkCheck className="me-3" size={26}/>
                        {
                          this.state.registrationStatus === true ?
                          <h5>Voter Registration start</h5> : 
                          <h5>Voter Registration end</h5>
                        }
                    </div>
                  </div>
                </Link>
              </Col>} */}


            {/* <Col md={3} className="mb-3 sm-mb-3">
              <Link to="#" onClick={this.endDispute.bind(this)} style={{ color: "black" }}>
              <div className="card-container">
              <div className="card-header d-flex p-3 justify-content-center rounded">
                    <Icon.XCircle className="me-3" size={26}/>
                        <h5>End Dispute</h5>
                    </div>
              </div>
                  </Link>
              </Col> */}
            {/* </Row> */}

            {/* <div className="col-sm-3">
              <div className="card mb-3 shadow-lg" style={{ maxwidth: "30rem", marginTop: "40px",border:"0"}}>
                <div className="card text-center">
                  <Link to="/CandidateListByCollege" style={{ color: "black" }}>
                    <div className="card-header ">
                      <div className="row align-items-center">
                        <h5>Candidates</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-sm-3">
              <div className="card border-red  mb-3 shadow-lg" style={{ maxwidth: "10rem", marginTop: "40px",border:"0" }}>
                <div className="card text-center">
                  <Link to="/all-students" style={{ color: "black" }}>
                    <div className="card-header">
                      <div className="row align-items-center">
                        <h5>YIN Member</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            {this.state.role === 'COORDINATOR' ?"":<div className="col-sm-3">
              <div className="card border-red  mb-3 shadow-lg" style={{ maxwidth: "10rem", marginTop: "40px",border:"0" }}>
                <div className="card text-center">
                  <Link to="/college/dispute/list" style={{ color: "black" }}>
                    <div className="card-header">
                      <div className="row align-items-center">
                        <h5>Dispute voters</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>}

            <div className="col-sm-3">
              <div className="card  mb-3 shadow-lg" style={{ maxwidth: "18rem", marginTop: "40px",border:"0" }}>
                <div className="card text-center">
                  <Link to="/election" style={{ color: "black" }} onClick={this.electionSingle}>
                    <div className="card-header">
                      <div className="row align-items-center">
                        <h5>Election start /end</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-sm-3">
              <div className="card rounded mb-3 shadow-lg" style={{ maxwidth: "10rem", marginTop: "40px",border:"0" }}>
                <div className="card text-center">
                  <Link to="/ldp" style={{ color: "black" }} onClick={this.ldpStart}>
                    <div className="card-header">
                      <div className="row align-items-center">
                        <h5>LDP Status</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-sm-3">
              <div className="card border-red  mb-3 shadow-lg" style={{ maxwidth: "10rem", marginTop: "40px",border:"0" }}>
                <div className="card text-center">
                  <Link to="/electionlist" style={{ color: "black" }}>
                    <div className="card-header">
                      <div className="row align-items-center">
                        <h5>Election List</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-sm-3">
              <div className="card border-red  mb-3 shadow-lg" style={{ maxwidth: "10rem", marginTop: "40px",border:"0" }}>
                <div className="card text-center">
                  <Link to="/result" style={{ color: "black" }}>
                    <div className="card-header">
                      <div className="row align-items-center">
                        <h5>Election Result</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-sm-3">
              <div className="card border-red  mb-3 shadow-lg" style={{ maxwidth: "10rem", marginTop: "40px",border:"0" }}>
                <div className="card text-center">
                  <Link to="#" onClick={this.startDispute.bind(this)} style={{ color: "black" }}>
                    <div className="card-header">
                      <div className="row align-items-center">
                        <h5>Start Dispute</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-sm-3">
              <div className="card border-red  mb-3 shadow-lg" style={{ maxwidth: "10rem", marginTop: "40px",border:"0" }}>
                <div className="card text-center">
                  <Link to="#" onClick={this.endDispute.bind(this)} style={{ color: "black" }}>
                    <div className="card-header">
                      <div className="row align-items-center">
                        <h5>End Dispute</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-sm-3">
              <div className="card border-red  mb-3 shadow-lg" style={{ maxwidth: "10rem", marginTop: "40px",border:"0" }}>
                <div className="card text-center">
                  <Link to="#" onClick={this.showElection.bind(this)} style={{ color: "black" }}>
                    <div className="card-header">
                      <div className="row align-items-center">
                        <h5>Show Election Result</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-sm-3">
              <div className="card border-red mb-3 shadow-lg" style={{ maxwidth: "10rem", marginTop: "40px",border:"0" }}>
                <div className="card text-center">
                  <Link to="#" onClick={this.stopElection.bind(this)} style={{ color: "black" }}>
                    <div className="card-header">
                      <div className="row align-items-center">
                        <h5>Stop Election Result</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div> */}

          </div>
        </div>
      </>
    );
  }
}
