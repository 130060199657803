import React, { useEffect, useState } from 'react';
import LoaderRoundedWithText from './LoaderRoundedWithText';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRounded from './LoaderRounded';
import ReactPaginate from 'react-paginate';
import * as Icon from 'react-bootstrap-icons'
import { Button, Col, Row } from 'react-bootstrap';
import axios from 'axios';
import { Visibility } from '@material-ui/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Select from 'react-select'

const CoordinatorEventCount = () => {
    const history = useHistory();

    const [loading, setLoading] = useState(false)
    const [skip, setSkip] = useState(0)
    const [ page_selected, setPageSelected] = useState(0)
    const [limit, setLimit] = useState(10)
    const [allcount, setAllcount] = useState()
    const [dropdownEvent, setDropdownEvent] = useState([])

    const [cityData, setCityData] = useState([])
    const [eventListData, setEventListData] = useState([])
    const [cityName, setCityName] = useState("")
    const [eventName, setEventName] = useState("")

    useEffect(() =>{
        showCity()
        getEvents()
    }, [])

    useEffect(() =>{
        getEventList()
    }, [eventName.label ? eventName.label : "", cityName ? cityName : "", limit ? limit : "", skip ? skip : "",])

    const showCity = () =>{
        let districts = JSON.parse(localStorage.getItem("districts"));
            if (Array.isArray(districts) && districts[0] !== "*") {
                setCityData(districts)
            }else {
                axios.get(process.env.REACT_APP_APIURL + `/address/city/MH`)
                .then((response) => {
                    if (Array.isArray(response.data) && response.data.length > 0) {
                            setCityData(response.data)
                    }})
                .catch((error) => {
                    console.log("error", error);
                });
            }
    }

    const getEvents = async() =>{
        setLoading(true)
        await axios.get(process.env.REACT_APP_APIURL + `/eventpdf/eventDropdown`)
        .then((res) =>{
            setDropdownEvent(res?.data?.data)
            setLoading(false)
        })
        .catch((error) =>{
            console.log("error",error);
            setLoading(false)
        })
    }

    const getEventList = () =>{
        const payload = {
            event_title : eventName.label ? eventName.label : "",
            city : cityName ? cityName : "",
            skip: skip ? skip : "",
            limit: limit ? limit : "",
            state : "",
        }
        axios.post(process.env.REACT_APP_APIURL + `/eventpdf/getAllEvents`,payload)
        .then((res) =>{
            setEventListData(res?.data?.data)
            setAllcount(res?.data?.noOfPages)
        })
        .catch((error) =>{
            console.log("error",error);
        })
    }

    let eventList = "";
    if (eventListData?.length > 0) {
        eventList = eventListData && eventListData.map((event, id) => (
          <tr key={id}>
            <td>{page_selected * limit + id + 1}</td>
            <td>{event?.event_title}</td>
            <td>{event?.coordinatorNames ?  event?.coordinatorNames.join(" , ") :" -"}</td>
            <td>{event?.cities.join(" , ")}</td>
            <td>
                <span className="circle">
                    {event?.registeredUsers}
                </span>
            </td>
          </tr>
        ));
    } else {
        eventList = (
        <tr>
          <td colSpan="6" className="text-center">
            No Data Found....
          </td>
        </tr>
      );
    }

   const  handlePageChange = (page) => {
        let val = page.selected === 0 ? 0 : page.selected * limit;
        setSkip(val)
        setPageSelected( page.selected)
        // getEventList(eventName, cityName, skip, limit)
      }

  return (
    <div>
      {
        loading ? <LoaderRoundedWithText /> :
        <>
            <div>
                <Sidebar/>
                <div className="main">
                    <div className="topbar">
                        <Navbar title={"Co-ordinator Event Count"} />
                    </div>

                    <div className="container mt-4">
                    <Row className="ms-3 justify-content-between" md={12}>
                        <Col md={3} className="mb-2 mb-sm-0">
                        <select
                            type="button"
                            name="city"
                            value={cityName}
                            className="btn btn-md dropdown-toggle w-100"
                            style={{ backgroundColor: "#003147", color: "white" }}
                            onChange={(e) =>setCityName(e.target.value)}
                            >
                        <option value="">All Cities</option>
                        {
                            cityData!== undefined && cityData !== null && cityData.length > 0 ? 
                            cityData?.map((itm) =>{
                                return  <option>{itm.name}</option>
                            }) : "No data"
                        }
                      </select>

                        {/* <Select
                            name="city"
                            value={cityName}
                            options={city}
                            onChange={(option) =>{
                                setCityName(option)
                            }}
                        /> */}
                        </Col>

                        <Col md={9} className='d-flex justify-content-end'>
                        <Col md={6} className="mb-2 mb-sm-0 me-3">
                            <Select
                                isSearchable
                                name="event_title"
                                value={eventName}
                                options={dropdownEvent}
                                onChange={(option) =>{
                                    setEventName(option)
                                }}
                            />
                        </Col>
                        <Col md={3}>
                            <Button onClick={()=>setEventName("")}
                            style={{ backgroundColor: "#003147", color: "white" }}>Reset Event</Button>
                        </Col>
                        </Col> 
                    </Row>

                    <div className="container mt-5" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="table-responsive-sm"
                              style={{ maxHeight: 1600, overflowY: "scroll" }}>
                                <table className="table table-bordered table-responsive table-hover">
                                    <thead>
                                        <tr>
                                        <th>No.</th>
                                        <th>Event Name</th>
                                        <th>Coordinator name</th>
                                        <th style={{ textAlign: "center" }}>City</th>
                                        <th style={{ textAlign: "center" }}>
                                            Event Registerd Count
                                        </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {loading ? 
                                        <tr>
                                            <td colSpan={6}>
                                                <LoaderRounded />
                                            </td>
                                        </tr> : eventList
                                       
                                        }
                                    </tbody>
                                </table>
                            </div>
                            </div>
                            <ReactPaginate
                                nextLabel="Next"
                                previousLabel="Previous"
                                pageCount={allcount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                containerClassName="pagination"
                                activeClassName="btn btn-primary"
                                pageClassName="btn"
                                previousClassName="btn"
                                nextClassName="btn"
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                    <button
                        className="reaload btn btn-success btn-sm mt-3 mb-3"
                        onClick={() => window.location.reload(true)}
                    >
                    <Icon.ArrowClockwise size={18} className="me-2"/>
                        Refresh
                    </button>
                    </div>
                </div>
            </div>
        </>
      }
    </div>
  )
}

export default CoordinatorEventCount
