import React from "react";
// import { Link } from 'react-router-dom';
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import LoaderRounded from "./LoaderRounded";
import LoaderRoundedWithText from "./LoaderRoundedWithText";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import ReactPaginate from "react-paginate";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";

//import { Link } from 'react-router-dom';
//import { Add,Edit,Visibility } from '@material-ui/icons';

class TransactionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      transactionlist: [],
      value: this.props.selected_event,
      currentPage: "",
      counts: "",
      totalPages: "",
      previousLimits: 100,
      renderPageLimit: 20,
      selectedPage: 0,
      filterListByMobile: "",
      filterdata: [],
      candidate: [],
    };
    this.handlePageChange = this.handlePageChange.bind(this);
    this.searchList = this.searchList.bind(this);
  }
  UNSAFE_componentWillMount() {
    this.getData();
    // setTimeout(
    //   () =>
    //     this.setState({
    //       loader: false,
    //       msg: false,
    //     }),
    //   2500
    // );
    setTimeout(() => this.setState({ loading: false }), 1000);
  }

  handlePageChange(page) {
    this.setState({ selectedPage: page.selected });
  }

  getData() {
    //let Event_ID = localStorage.getItem("event_id");
    let currentComponent = this;
    axios
      .get(
        process.env.REACT_APP_TALK_API +
        `/event/transactions/` +
        this.props.selected_event.event_id
      )
      .then((res) => {
        if (res.data.data) {
          let TransactionList = res.data.data;

          currentComponent.setState({
            transactionlist: TransactionList.reverse(),
            filterdata: TransactionList,
            candidate: TransactionList,
            loader: false,
          });
        }
      });
  }
  searchList(char) {
    let f = this.state.filterdata?.filter((item) =>
      item?.mobile?.toString().toLowerCase()?.startsWith(char?.toLowerCase())
    );
    this.setState({
      candidate: f,
    });
    console.log("Filter", f);
  }
  handleChange = (e) => {
    this.setState({ filterListByMobile: e.target.value });
  };

  render() {
    let i = 0;
    let transaction_list = "";

    if (this.state.candidate.length > 0) {

      transaction_list = this.state.candidate.map((item) => {
        return (
          <tr>
            <td> {(i += 1)} </td>
            <td> {item.order_id} </td>
            <td className="noselect"> {item.mobile} </td>
            {/* <td> {item.email} </td> */}
            {item.orderStatus === "SUCCESS" ? (
              <td className="text-center text-success">SUCCESS</td>
            ) : (
              ""
            )}
            {item.orderStatus === "FAILED" ? (
              <td className="text-center  text-danger">FAILED</td>
            ) : (
              ""
            )}
            {item.orderStatus === "DISCARDED_FOR_RETRY_PAYMENT" ? (
              <td className="text-center text-warning">
                DISCARDED_FOR_RETRY_PAYMENT
              </td>
            ) : (
              ""
            )}
            {item.orderStatus === "PENDING" ? (
              <td className="text-center text-warning">PENDING</td>
            ) : (
              ""
            )}
            <td> {item.amount} </td>
            <td> {new Date(item.createdAt).toDateString()} </td>
            {/* <td><Link to="/viewTransaction"><Button>View</Button></Link></td> */}
            <Link to={{ pathname: "/viewTransaction", data: { item } }}>
              <button type="button" className="btn btn-primary  btn-sm m-1">
                View
              </button>
            </Link>
          </tr>
        );
      });
    }
    else {
      transaction_list = (
        <tr>
          <td>No Data Found....</td>
        </tr>
      );
    }

    return (
      <>
        {this.state.loading ? (
          <LoaderRoundedWithText />
        ) : (
          <div>
            <Sidebar />
            <div className="main">
              <div className="topbar">
                <Navbar title="Transaction List" />
              </div>
              {/* <div>
                    <Link to="/addnewevent" className="btn btn-secondary btn-sm" style={{ float:"right",marginRight:"40px",marginTop:"20px"}}><Add />Add New Event</Link>
                    </div> */}


              <br></br>
              <div className="container" style={{ display: "flex" }}>
                <Col xs={4}>
                  <BackAndBreadCrmOneParent
                    backLink="/eventlist"
                    backName="EventList"
                    currentTabName="Trasaction List"
                  />   </Col>
                <Col xs={4}></Col>
                <Col xs={4}>
                  <input
                    className="form-control"
                    name="filterListByMobile"
                    placeholder="Search By Mobile"
                    type="text"
                    style={{ width: "80%", marginLeft: "80px" }}
                    onChange={(e) => {
                      this.setState({ filterListByMobile: e.target.value });
                      this.searchList(e.target.value);
                    }}
                  />
                </Col>
              </div>
              <br></br>
              <div className="container text-center">
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive-sm">
                      <table className="table table-bordered table-responsive">
                        <thead>
                          <tr className="text-center">
                            <th>No</th>
                            <th>Order ID</th>
                            <th>Mobile</th>
                            {/* <th>Email</th> */}
                            <th>Status</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.loader ? (
                            <LoaderRounded />
                          ) : (
                            transaction_list.length > 0 ?
                              transaction_list.slice(
                                this.state.selectedPage *
                                this.state.renderPageLimit,
                                this.state.renderPageLimit +
                                this.state.selectedPage *
                                this.state.renderPageLimit
                              )
                              : <tr>
                                <td>No Data Found....</td>
                              </tr>)}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <ReactPaginate
                    nextLabel="Next"
                    previousLabel="Previous"
                    pageCount={
                      transaction_list.length / this.state.renderPageLimit
                    }
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    containerClassName="pagination"
                    activeClassName="btn btn-primary"
                    pageClassName="btn"
                    previousClassName="btn"
                    nextClassName="btn"
                    onPageChange={this.handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    selected_event: state.PersistReducer.UserData.selected_event,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionList);
