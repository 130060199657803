import React from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import "../Assets/css/Dashboard.css";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import ReactPaginate from "react-paginate";
import LoaderRoundedWithText from "./LoaderRoundedWithText";
import { setSelectedEvent } from "../Redux/Slice/UserDataSlice";
import { connect } from "react-redux";

class Eventlist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventHeadTitle: "",
      eventlist: [],
      city: [],
      is_navigation_added: "",
      loader: true,
      currentPage: "",
      counts: "",
      totalCount: 0,
      totalPages: "",
      previousLimits: 100,
      renderPageLimit: 20,
      selectedPage: 0,
      loading: true,
      role: localStorage.getItem("role"),
      Loc: window.location,
    };
    this.getData = this.getData.bind(this);
    this.checkCity = this.checkCity.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  handlePageChange(page) {
    this.setState({ selectedPage: page.selected });
  }

  componentWillMount() {
    localStorage.setItem("Prev", this.state.Loc.pathname);
    // setTimeout(
    //   () =>
    //     this.setState({
    //       loader: false,
    //       msg: false,
    //     }),
    //   2000
    // );
    setTimeout(() => this.setState({ loading: false }), 1000);
  }
  UNSAFE_componentWillMount() {
    this.getData();

    let role = localStorage.getItem("role");
    this.setState({ role });
  }

  getData() {
    let eventid;
    if (this.props.location.data) {
      localStorage.setItem("geteventid", this.props.location.data.event_id);
      eventid = this.props.location.data.event_id;
    } else {
      eventid = localStorage.getItem("geteventid");
    }
    let currentComponent = this;
    axios
      .post(
        process.env.REACT_APP_TALK_API +
        "/event/get/event/with/event/head/id/web",
        { event_id: eventid }
      )
      .then((res) => {
        let EventList = res.data.data;
        if (EventList && EventList.length > 0) {
          currentComponent.setState({
            eventHeadTitle: EventList[0].event_title,
            eventlist: EventList[0].SubEventsCount.filter(row => row.is_published),
            totalCount: EventList[0].allCount,
            city: JSON.parse(localStorage.getItem("districts")),
            loader: false,
          });
        } else {
          console.error("EventList is undefined or empty.");
          // Handle the case where EventList is undefined or empty.
        }
      }).catch((err) => {
        this.setState({
          loader: false,
        });
        console.log("err in getData", err);
      });
  }

  async getDataAttandance(eventid) {
    let attandance = 0;
    let eventID = {
      event_id: eventid,
    };
    let datsa = await axios.post(
      process.env.REACT_APP_TALK_API +
      `/registerevent/get/attendance/event/all`,
      eventID
    );
    attandance = datsa.data.allCount ? datsa.data.allCount : 0;
    this.setState({
      eventAttandanceCount: attandance,
    });
    return attandance;
  }

  checkCity(arrayOne, secondArray) {
    if (secondArray[0] === "*") {
      return true;
    } else {
      let jsonArray = arrayOne.map((i) => {
        return secondArray.includes(i);
      });
      if (jsonArray.find((value) => value === true)) {
        return true;
      } else {
        return false;
      }
    }
  }

  render() {

    let event_list = "";
    if (this.state.eventlist) {
      event_list = this.state.eventlist.map(
        (eventlist, index) =>
          this.checkCity(eventlist.cities, this.state.city) ? (
            <tr key={index}>
              <td> {eventlist.event_title} </td>
              <td> {eventlist.event_type} </td>
              <td>
                {" "}
                {new Date(eventlist.event_date).toDateString()} -{" "}
                {new Date(eventlist.event_end_date).toDateString()}{" "}
              </td>
              <td>
                {" "}
                {eventlist.event_start_time} - {eventlist.event_end_time}{" "}
              </td>
              <td>
                <span className="circle">{eventlist.participationCount}</span>
              </td>
              <td className="text-center">
                {
                  <Link to={{ pathname: "/view-event", data: eventlist, back: "event", }}>
                    <button
                      type="button"
                      className="btn btn-primary  btn-sm m-1"
                    >
                      Edit
                    </button>
                  </Link>
                }
                <Link
                  onClick={() =>
                    localStorage.setItem(
                      "selected_event",
                      JSON.stringify(eventlist)
                    )
                  }
                  to="/Sub-memberlist"
                >
                  <button type="button" className="btn btn-success btn-sm m-1">
                    Registration
                  </button>
                </Link>
                <Link to={{ pathname: "./transactionlist", state: eventlist }}>
                  <button type="button" className="btn btn-warning btn-sm m-1">
                    Transaction
                  </button>
                </Link>

                <Link to={{ pathname: "/Add-Location", data: eventlist, back: "eventlist", }}>
                  <button type="button" className="btn btn-info  btn-sm m-1">
                    Edit Location
                  </button>
                </Link>

              </td>
            </tr>
          ) : (
            <tr>
              <td>No Data Found....</td>
            </tr>
          )
      );
    } else {
      event_list = (
        <tr>
          <td>No Data Found....</td>
        </tr>
      );
    }

    return (
      <>
        {this.state.loading ? (
          <LoaderRoundedWithText />
        ) : (
          <div>
            <Sidebar />
            <div className="main">
              <div className="topbar">
                <Navbar title="Sub Event List" />
              </div>
              {/* <div>
              <Link
                to="/addnewevent"
                className="btn btn-secondary btn-sm"
                style={{
                  float: "right",
                  marginRight: "40px",
                  marginTop: "20px",
                }}
              >
                <Add />
                Add New Event
              </Link>
            </div> */}
              <BackAndBreadCrmOneParent
                // backLink="/eventlist"
                backLink="/eventlist"
                backName="Eventlist"
                currentTabName="Sub-EventList"
              />
              <div className="container-fluid">
                <div className="row text-center">
                  <div className="col-12 ">
                    <div>
                      <h3>{this.state.eventHeadTitle}</h3>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div>
                      Total {this.state.eventHeadTitle} event registration -{" "}
                      <b>{this.state.totalCount}</b>
                    </div>
                    <div className="table-responsive-sm">
                      <table className="table table-bordered table-responsive">
                        <thead>
                          <tr className="text-center">
                            <th>Event Title</th>
                            <th>Event Type</th>
                            <th>Event Date</th>
                            <th>Event time</th>
                            {/* <th>Event City</th> */}
                            <th>Registration Count</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>{event_list.length > 0 ? event_list : <tr>
                          <td>No Data Found....</td>
                        </tr>}</tbody>
                      </table>
                    </div>
                  </div>
                  <ReactPaginate
                    nextLabel="Next"
                    previousLabel="Previous"
                    pageCount={event_list.length / this.state.renderPageLimit}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    containerClassName="pagination"
                    activeClassName="btn btn-primary"
                    pageClassName="btn"
                    previousClassName="btn"
                    nextClassName="btn"
                    onPageChange={this.handlePageChange}
                  />
                </div>
              </div>
              <button
                className=" reaload btn  btn-success btn-sm "
                onClick={() => window.location.reload(true)}
              >
                Click to reload!
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
}


const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    Setseleted_event: (data) => dispatch(setSelectedEvent(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Eventlist);
