import React from 'react'
import Sidebar from '../../../Sidebar'
import Navbar from '../../../Navbar'
import BackAndBreadCumOneParent from '../../../BackAndBreadCumOneParent';

const ViewVoterRegist = () => {
  return (
    <>
      <Sidebar/>
        <div className="main">
          <div className="topbar">
            <Navbar title="View Voter Registration"/>
          </div>

          <div className="container">
                <BackAndBreadCumOneParent
                    backLink="/electionPage"
                    backName="Election"
                    currentTabName="Voter Registration"
              />
          </div>

        </div>
    </>
  )
}

export default ViewVoterRegist
