import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  awsCreds: null,
};

const awsCreds = createSlice({
  name: "awsCreds",
  initialState,
  reducers: {
    setAwsCreds(state, action) {
      state.awsCreds = action?.payload;
    },
   }
});

export const {setAwsCreds} = awsCreds.actions;
export default awsCreds.reducer;
