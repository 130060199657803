import React, { Component } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import S3 from "react-aws-s3";
import Compressor from "compressorjs";
import LoaderRounded from "./LoaderRounded";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import { Buffer } from "buffer";

Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;

export default class AddLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CityData: [],
      event_address: "",
      event_address2: "",
      State: "",
      cities: "",
      event_pincode: "",
      event_latitude: "",
      event_longitude: "",
      loader: false,
      promotion_banner_images: [],
      errors: {},
    };

    this.handleSubmitLocation = this.handleSubmitLocation.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormValidation = this.handleFormValidation.bind(this);
    this.handleSubmitImage = this.handleSubmitImage.bind(this);
    this.showCity = this.showCity.bind(this);
    this.uploadImageToS3 = this.uploadImageToS3.bind(this);
  }
  UNSAFE_componentWillMount() {
    this.showCity();
  }
  handleFormChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleFormValidation = () => {
    const {
      event_address,
      event_address2,
      event_latitude,
      event_longitude,
      event_pincode,
      State,
      cities,
    } = this.state;
    let errors = {};
    let formIsValid = true;
    let arr = [];
    if (!event_address) {
      formIsValid = false;
      errors["event_address"] = "*Give Your Answer.";
      arr.push({ field: "event_address", msg: "*Give Your Answer." });
    }
    if (!event_address2) {
      formIsValid = false;
      arr.push({ field: "event_address2", msg: "*Give Your Answer." });
      errors["event_address2"] = "*Give Your Answer.";
    }

    if (!State || State === "--Choose State--") {
      formIsValid = false;
      arr.push({ field: "State", msg: "*Give Your Answer." });
      errors["State"] = "*Give Your Answer.";
    }
    if (!cities || cities === "--Choose City--") {
      formIsValid = false;
      errors["cities"] = "*Give Your Answer.";
      arr.push({ field: "cities", msg: "*Give Your Answer." });
    }

    if (!event_longitude) {
      formIsValid = false;
      errors["event_longitude"] = "*Give Your Answer.";
      arr.push({ field: "event_longitude", msg: "*Give Your Answer." });
    }
    if (!event_latitude) {
      formIsValid = false;
      errors["event_latitude"] = "*Give Your Answer.";
      arr.push({ field: "event_longitude", msg: "*Give Your Answer." });
    }
    if (!event_pincode) {
      formIsValid = false;
      errors["event_pincode"] = "*Give Your Answer.";
      arr.push({ field: "event_pincode", msg: "*Give Your Answer." });
    }

    this.setState({
      errors: errors,
    });
    if (arr.length > 0) {
      const firstErrorField = document.querySelector(
        `[name="${arr[0].field}"]`
      );
      if (firstErrorField && firstErrorField.focus) {
        firstErrorField.focus();
      }
    }
    return formIsValid;
  };

  handleSubmitLocation = async (event) => {
    event.preventDefault();
    if (this.handleFormValidation()) {
      const EventLocation = {
        event_id: this.props.location.data.event_id,
        ...this.state,
      };

      let statesSet = this;

      await axios
        .post(
          process.env.REACT_APP_TALK_API + "/event/add/event/location",
          EventLocation
        )
        .then((res) => {
          if (res.status === 200) {
            this.props.history.push("/eventlist");
            alert(res.data.message);
            statesSet.setState({
              changeToView: true,
              redirect: true,
              loader: true,
            });
          }
        });
    } else {
      this.handleFormValidation();
    }
  };
  showCity() {
    axios
      .get(process.env.REACT_APP_APIURL + "/address/city/MH")
      .then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          this.setState({
            CityData: response.data,
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }
  addNewImage(newImage) {
    let img_arr = this.state.promotion_banner_images;
    img_arr.push(newImage);
    this.setState({
      promotion_banner_images: img_arr,
    });
  }

  removeImageURL(id) {
    this.setState({
      promotion_banner_images: [],
    });
  }

  uploadImageToS3(event) {
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: "yin/ promotion_banner_images",
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      s3Url: process.env.REACT_APP_S3URL,
    };

    var fileInput = false;
    const image = event.target.files[0];
    if (image === undefined) return;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      if (
        event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpeg"
      ) {
        new Compressor(image, {
          quality: 0.6,
          success: (compressedResult) => {


            let newFileName = event.target.files[0].name.replace(/\..+$/, "");
            const finalFileName = newFileName;

            const ReactS3Client = new S3(config);
            // const newFileName = 'test-file';

            ReactS3Client.uploadFile(compressedResult, newFileName)
              .then((res) => {
                this.addNewImage({
                  type: "IMAGE",
                  url: res.location,
                  name: finalFileName,
                  id: Math.floor(Math.random() * 9999999999),
                });
              })
              .catch((err) => console.error(err));
          },
        });
      }
    }
  }

  handleSubmitImage = async (event) => {
    event.preventDefault();

    const Image = {
      event_id: this.props.location.data.event_id,
      promotion_banner_images: this.state.promotion_banner_images,
      is_promotion_banner_added: true,
    };

    let statesSet = this;
    if (this.state.promotion_banner_images.length > 0) {
      await axios
        .post(
          process.env.REACT_APP_TALK_API + "/event/add/banner/images",
          Image
        )
        .then((res) => {
          if (res.data) {
            alert("Image Added successfully");
            statesSet.setState({
              changeToView: true,
              redirect: true,
            });
          }
        });
    } else {
      alert("Please select Image");
    }
  };

  render() {
    let BannerImages = this.state.promotion_banner_images;
    localStorage.setItem("Images", JSON.stringify(BannerImages));
    return (
      <>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>
          <div className="container">
            <div className="row">
              <BackAndBreadCrmOneParent
                backLink={this.props.location.back ? "/eventlist" : "/Subevent-List"}
                backName={this.props.location.back ? "EventList" : "Sub Event List"}
                currentTabName="Add-Location"
              />
              <div className="col-lg-6 col-md-6 col-6">
                <div
                  className="card"
                  style={{
                    marginTop: "20px",
                    paddingBottom: "10px",
                  }}
                >
                  <div className="card-header">
                    <h2>
                      Add Location
                    </h2>
                  </div>

                  <div className="card-body paddingAll20">
                    <form
                      id="candidate"
                      method="post"
                      encType="multipart/form-data"
                    >
                      <div className="row">
                        <div className="form-group">
                          <label htmlFor="Address2" className="control-label">
                            <span style={{ color: "#ff0000" }}>*</span>Address Lane
                            1
                          </label>
                          <input
                            type="text"
                            id="Address2"
                            name="event_address"
                            value={this.state.event_address}
                            onChange={this.handleFormChange}
                            className="form-control"
                            placeholder="Type here"
                            required
                          ></input>
                          <div className="errorMsg">
                            {this.state.errors.event_address}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group">
                          <label htmlFor="Address2" className="control-label">
                            <span style={{ color: "#ff0000" }}>*</span> Address Lane
                            2
                          </label>
                          <input
                            type="text"
                            id="Address2"
                            name="event_address2"
                            value={this.state.event_address2}
                            onChange={this.handleFormChange}
                            className="form-control"
                            placeholder="Type here"
                            required
                          ></input>
                          <div className="errorMsg">
                            {this.state.errors.event_address2}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6">
                          <div className="form-group">
                            <label htmlFor="State" className="control-label">
                              <span style={{ color: "#ff0000" }}>*</span>Select State
                            </label>
                            <select
                              id="states"
                              className="form-select"
                              name="State"
                              onChange={this.handleFormChange}
                            >
                              <option value={null}>--Choose State--</option>
                              <option value="MH">Maharashtra</option>
                              {/* <option value="GA">Goa</option> */}
                            </select>

                            <div className="errorMsg">{this.state.errors.State}</div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                          <div className="form-group">
                            <label
                              htmlFor="citys"
                              id="cityLabel"
                              className="control-label"
                            >
                              <span style={{ color: "#ff0000" }}>*</span>Select City
                            </label>
                            <select
                              id="citys"
                              name="cities"
                              className={`form-select ${this.state.errors.cities ? "error" : ""
                                }`}
                              onChange={this.handleFormChange}
                            >
                              <option value={null}>--Choose City--</option>
                              {Array.isArray(this.state.CityData) &&
                                this.state.CityData.length > 0 &&
                                this.state.CityData.map((e, key) => {
                                  return (
                                    <option key={key} value={e.name}>
                                      {e.name}
                                    </option>
                                  );
                                })}
                            </select>
                            <div className="errorMsg">{this.state.errors.cities}</div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6">
                          <div className="form-group">
                            <label htmlFor="pincode" className="control-label">
                              <span style={{ color: "#ff0000" }}>*</span> Pincode
                            </label>
                            <input
                              type="text"
                              id="Pincode"
                              name="event_pincode"
                              value={this.state.event_pincode}
                              onChange={this.handleFormChange}
                              className="form-control"
                              placeholder="Type here"
                              required
                            ></input>
                            <div className="errorMsg">
                              {this.state.errors.event_pincode}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6">
                          <div className="form-group">
                            <label htmlFor="latitude" className="control-label">
                              <span style={{ color: "#ff0000" }}>*</span> Latitude
                            </label>
                            <input
                              type="text"
                              id="latitude"
                              name="event_latitude"
                              value={this.state.event_latitude}
                              onChange={this.handleFormChange}
                              className="form-control"
                              placeholder="Type here"
                              required
                            ></input>
                            <div className="errorMsg">
                              {this.state.errors.event_latitude}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                          <div className="form-group">
                            <label htmlFor="logitude" className="control-label">
                              <span style={{ color: "#ff0000" }}>*</span> longitude
                            </label>
                            <input
                              type="text"
                              id="logitude"
                              name="event_longitude"
                              value={this.state.event_longitude}
                              onChange={this.handleFormChange}
                              className="form-control"
                              placeholder="Type here"
                              required
                            ></input>
                            <div className="errorMsg">
                              {this.state.errors.event_longitude}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <button
                          onClick={this.handleSubmitLocation}
                          id="btnSubmitSignup"
                          className="btn btn-primary btn-lg btn-block mb-2 mt-4"
                          style={{ margin: "4px" }}
                        >
                          {this.state.loader ? <LoaderRounded /> : "Add"}
                        </button>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-6">
                <div
                  className="card"
                  style={{
                    marginTop: "20px",
                    paddingBottom: "10px",
                  }}
                >
                  <div className="card-header">
                    <h2>Add Banners</h2>
                  </div>

                  <div className="card-body paddingAll20">

                    <form
                      id="banner image"
                      method="post"
                      encType="multipart/form-data"
                    >
                      <div className="textOnInputs">
                        <div className="col-md-12 mt-3">
                          <label htmlFor="image">
                            <span style={{ color: "#ff0000" }}>*</span>Promotional
                            Campaign Photo
                          </label>

                          <input
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            id="image"
                            name="college_id"
                            className="form-control-file"
                            onChange={this.uploadImageToS3}
                          />
                          {/* {this.state. promotion_banner_images ? <img id="image" src={this.state. promotion_banner_images} alt="" width="200px" height="80px" /> : ""} */}
                          <div className="errorMsg">
                            {this.state.errors.promotion_banner_images}
                          </div>
                        </div>

                        {this.state.promotion_banner_images.length === 0
                          ? null
                          : this.state.promotion_banner_images.map((img) => {
                            return (
                              <>
                                <div
                                  className="card"
                                  style={{ margin: 10, width: 200 }}
                                >
                                  <img
                                    src={img.url}
                                    key={img.url}
                                    className="card-img-top"
                                    alt={img.name}
                                    width="100px"
                                    height="100px"
                                  />
                                </div>
                              </>
                            );
                          })}
                        {this.state.promotion_banner_images.length ===
                          0 ? null : (
                          <button
                            type="button"
                            onClick={() => {
                              this.removeImageURL();
                            }}
                            class="btn btn-danger"
                          >
                            Remove
                          </button>
                        )}
                      </div>

                      <br />


                      {/* <Link onClick={() => localStorage.setItem('EventListData',JSON.stringify(eventlist))} to="/Add-Location"><button type="button" className="btn btn-success btn-sm m-1">Add Location</button></Link> */}

                      <div class="row justify-content-center">
                        <button
                          onClick={this.handleSubmitImage}
                          class="btn btn-default btn-primary"
                          style={{ width: "120px", padding: "10px" }}
                        >
                          {this.state.loader ? <LoaderRounded /> : "Submit"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
