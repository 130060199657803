import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { Edit } from "@material-ui/icons";
// import LoaderRounded from "./LoaderRounded";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";

export default class CandidateListByCollegWise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      college_code: "",
      yin_id: localStorage.getItem("yin_id"),
      candidates: [],
      redirect: false,
      // loader: false,
    };

    this.getData = this.getData.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.getData();
  }

  getData = () => {
    //   axios.get(process.env.REACT_APP_APIURL+`/candidate/candidates?college_code=`+localStorage.getItem("collegeCode"))

    axios.get(process.env.REACT_APP_APIURL + `/candidate/candidates/foradminpanel?college_code=` + localStorage.getItem("collegeCode"))
      .then((res) => {
        let candidatesInfo = res.data.data;
        this.setState({
          candidates: candidatesInfo,
        });
      });
  };

  Editcandidate(yinID, candidateID) {
    localStorage.setItem("candidate_id", candidateID);
    localStorage.setItem("yin_id", yinID);
    this.setState({
      redirect: true,
    
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/candidate-edit" />;
    }

    let candidate_list = "";
    if (this.state.candidates) {
      candidate_list = this.state.candidates.map((candidate, index) => (
        <tr key={candidate.yin_id}>
          <td> {index + 1} </td>
          <td>
            {" "}
            {candidate.first_name} {candidate.last_name}
          </td>
          <td className="noselect"> {candidate.mobile} </td>
          <td> {candidate.email} </td>
          <td> {candidate.college_name} </td>
          <td> {candidate.electionYear} </td>
          {candidate.application_status === "APPROVED" ? (
            <td className="text-success">APPROVED</td>
          ) : (
            ""
          )}
          {candidate.application_status === "REJECTED" ? (
            <td className="text-danger">REJECTED</td>
          ) : (
            ""
          )}
          {candidate.application_status === "PENDING" ? (
            <td className="text-warning">PENDING</td>
          ) : (
            ""
          )}
          <td>
            <span
              onClick={() =>
                this.Editcandidate(candidate.yin_id, candidate.college_code)
              }
            >
              <Edit />
            </span>
            {/* <Link to=""><Delete /></Link> */}
          </td>
        </tr>
      ));
    } else {
      candidate_list = (
        <tr>
          <td>No Data Found....</td>
        </tr>
      );
    }

    return (
      <>
        <div>
          <Sidebar />
          <div className="main">
            <div className="topbar">
              <Navbar />
            </div>

            <div className="container">
              <BackAndBreadCrmOneParent
                backLink="/view-card"
                backName="View card"
                currentTabName="Candidate list"
              />

              <h2 className="text-center">Candidates List</h2>
              <br></br>

              <div className="row">
                <div className="col-12">
                  <table className="table table-bordered table-responsive">
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Full Name</th>
                        <th>Mobile</th>
                        <th>Email</th>
                        <th>College Name</th>
                        <th>Election Year</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      { candidate_list}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
