import React, { Component } from "react";
import profile from "../Assets/images/profileimage.jpg";
import "../Assets/css/Dashboard.css";
import { connect } from "react-redux";
import * as actions from "./../actions/authAction";
import { setLogout } from "../Redux/Slice/UserDataSlice";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobile: localStorage.getItem("mobile"),
      displayMenu: false,
    };

    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.handleDropdownMenu = this.handleDropdownMenu.bind(this);
    this.logout = this.logout.bind(this);
  }

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false });
  }

  handleDropdownMenu(event) {
    event.preventDefault();
    this.setState({
      displayMenu: !this.state.displayMenu,
    });
  }

  logout() {
    this.props.UserLogout(null);
    localStorage.clear();
    window.location.href = "/";
  }

  render() {
    function toggleMenu() {
      let toggle = document.querySelector(".toggle");
      let navigation = document.querySelector(".navigation");
      let main = document.querySelector(".main");
      toggle.classList.toggle("active");
      navigation.classList.toggle("active");
      main.classList.toggle("active");
    }
    return (
      <div className="container-fluid">
        <div className="topbar">
          <div className="navbar-title">
            <div className="toggle" onClick={toggleMenu}></div>
            <h2>{this.props.title ? this.props.title : ""}</h2>
          </div>
          <div className="dropdown">
            <img
              src={profile}
              alt="photos"
              style={{ cursor: "pointer" }}
              onClick={this.handleDropdownMenu}
              width="60"
            />

            {this.state.displayMenu ? (
              <ul className="dropdownOnNavbarUL mt-4">
                {/* <Link to="/Profile">
                                    <span className="title">Profile</span>
                                    </Link> */}
                <a href="/Profile" onClick={this.handleDropdownMenu}>
                  <li className="dropdownOnNavbarLI">Profile</li>
                </a>

                <a onClick={this.logout}>
                  <li className="dropdownOnNavbarLI">Log Out</li>
                </a>
              </ul>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    UserLogout: (data) => dispatch(setLogout(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
