import React from "react";
// import { Link } from 'react-router-dom';
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import { Add, Delete, Edit, Visibility } from "@material-ui/icons";
import "../Assets/css/Dashboard.css";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRounded from "./LoaderRounded";
import LoaderRoundedWithText from "./LoaderRoundedWithText";
// import ReactPaginate from 'react-paginate';
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import moment from "moment";

class Eventlist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activity_list: [],
      city: [],
      Issue: this.props.selected_activity,
      loader: true,
      currentPage: "",
      counts: "",
      totalPages: "",
      previousLimits: 100,
      renderPageLimit: 20,
      selectedPage: 0,
    };

    this.getData = this.getData.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.getData();
  }
  handlePageChange(page) {
    this.setState({ selectedPage: page.selected });
  }

  componentWillMount() {
    setTimeout(() => this.setState({ loader: false, msg: false }), 1500);
    setTimeout(() => this.setState({ loading: false }), 1000);
  }
  getData() {
    let currentComponent = this;
    let IssueId = this?.state?.Issue?.issue_id;
    let selected_forum = this.props.selected_forum;
    axios
      .get(
        process.env.REACT_APP_TALK_API +
        `/activity/list/activity/issue/${selected_forum}/${IssueId}`
      )
      .then((res) => {
        let ActivityList = res.data;
        if (Array.isArray(ActivityList)) {
          currentComponent.setState({
            activity_list: ActivityList,
            loader: false,
          });
        }
      });
  }

  render() {
    let activity_list = "";
    if (this.state.activity_list.length > 0) {
      activity_list = this.state.activity_list.map((activity_list) => (
        <tr className="text-center">
          <td> {activity_list.activity_title} </td>
          <td> {activity_list.activity_start_time} </td>
          <td> {activity_list.activity_end_time} </td>
          <td>
            {" "}
            {moment(activity_list.activity_created_date).format("DD-MM-yyyy")}
          </td>

          <td className="text-center">
            &nbsp;&nbsp;&nbsp;
            <Link to={{ pathname: "/Editactivity", data: activity_list }}>
              <button className="btn btn-outline-success btn-sm  ">
                {" "}
                <Edit />
              </button>
            </Link>
            &nbsp;&nbsp;&nbsp;
            <Link to={{ pathname: "/ViewActivity", data: activity_list }}>
              <button className="btn btn-outline-info btn-sm ">
                {" "}
                <Visibility />
              </button>
            </Link>
            &nbsp;&nbsp;&nbsp;
          </td>
        </tr>
      ));
    } else {
      activity_list = (
        <tr>
          <td>No Data Found....</td>
        </tr>
      );
    }

    return (
      <>
        {this.state.loading ? (
          <LoaderRoundedWithText />
        ) : (
          <div>
            <Sidebar />
            <div className="main">
              <div className="topbar">
                <Navbar />
              </div>
              <div>
                <Link
                  to="/Add-Activity"
                  className="btn btn-secondary btn-sm"
                  style={{
                    float: "right",
                    marginRight: "40px",
                    marginTop: "20px",
                  }}
                >
                  <Add />
                  Add New Activity
                </Link>
              </div>

              {/* <select type="button" name="city" className="mx-2 btn btn-secondary btn-md dropdown-toggle" onChange={this.getCollegeWithCity} id="district" style={{ float: "right", width: "120px" }}>
                        <option value={null}> --College List-- </option>
                        {this.state.collegedata.map((e, key) => {
                                        return <option key={key} value={e.college_name}>{e.college_name}  </option>;
                                    })}
                    </select> */}
              <div className="container">
                <BackAndBreadCrmOneParent
                  backLink="/Issue-List"
                  backName="Issue List"
                  currentTabName="Activity List"
                />
                <div className="row">
                  <div className="col-12">
                    <h2 className="text-center">Activity List</h2>
                    <br></br>
                  </div>
                </div>
              </div>
              <br></br>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive-sm">
                      <table className="table table-bordered table-responsive">
                        <thead>
                          <tr className="text-center">
                            <th>Activity Name</th>
                            <th>Activity Start Time</th>
                            <th>Activity End Time</th>
                            <th>Activity Date</th>
                            <th>Activity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.loader ? (
                            <LoaderRounded />
                          ) : activity_list.length > 0 ? (
                            activity_list.slice(
                              this.state.selectedPage *
                              this.state.renderPageLimit,
                              this.state.renderPageLimit +
                              this.state.selectedPage *
                              this.state.renderPageLimit
                            )
                          ) : (
                            <tr>No Data Found</tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <ReactPaginate
                    nextLabel="Next"
                    previousLabel="Previous"
                    pageCount={
                      activity_list.length / this.state.renderPageLimit
                    }
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    containerClassName="pagination"
                    activeClassName="btn btn-primary"
                    pageClassName="btn"
                    previousClassName="btn"
                    nextClassName="btn"
                    onPageChange={this.handlePageChange}
                  />
                </div>
              </div>
              <button
                className=" reaload btn  btn-success btn-sm "
                onClick={() => window.location.reload(true)}
              >
                Click to reload!
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    selected_activity: state.PersistReducer.UserData.selected_activity,
    selected_forum: state.PersistReducer.UserData.selected_forum,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Eventlist);
