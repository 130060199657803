import React, { Component } from "react";
import "../Assets/css/View.css";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import logo from "../Assets/images/avatar1.png";

class Viewprofile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: localStorage.getItem("first_name"),
      last_name: localStorage.getItem("last_name"),
      profile_image: localStorage.getItem("profile_image"),
      email: localStorage.getItem("email"),
      college_name: localStorage.getItem("college_name"),
      city: localStorage.getItem("city_name"),
      mobile: localStorage.getItem("mobile"),
      // FirstName: '',
    };

    // this.getdata = this.getdata.bind(this);
  }

  // UNSAFE_componentWillMount(){
  //     this.getData();
  // }

  // getData(){
  //     let currentComponent = this;
  //     let first_name = localStorage.getItem("first_name");
  //     currentComponent.setState({
  //         first_name: this.state.first_name
  //     })
  // }

  render() {
    return (
      <>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>

          <div className="container ">
            <div className="row mt-5">
              <div className="col-lg-5 col-md-5 mx-auto">
                <div
                  className="card rounded shadow shadow-sm"
                  style={{ minHeight: "200px" }}
                >
                  <div className="card-header1">
                    <h3 className="card-title mb-0 text-center text-primary">
                      <b>Profile</b>
                    </h3>
                    <div
                      className="row align-items-center"
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      <div className="col-6 text-center">
                        {this.state.profile_image ? (
                          <img src={this.state.profile_image} alt="imhe" />
                        ) : (
                          <img
                            src={logo}
                            alt="image_logo"
                            className="img-fluid"
                          />
                        )}
                      </div>
                      <div className="col-6  ">
                        <label htmlFor="name">
                          <h6>
                            <b>
                              {this.state.first_name}&nbsp;
                              {this.state.last_name}
                            </b>
                          </h6>
                        </label>
                        <br />
                        <label htmlFor="email">{this.state.email}</label>
                        <br />
                        <label htmlFor="college_name">
                          {this.state.college_name}
                        </label>{" "}
                        <br />
                        <label htmlFor="city">{this.state.city}</label> <br />
                        <label htmlFor="mobile" className="noselect">{this.state.mobile}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Viewprofile;
