import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import axios from 'axios';
import * as Icon from "react-bootstrap-icons"
import { Button, Col, Row } from 'react-bootstrap';
import * as XLSX from "xlsx";
import LoaderRounded from './LoaderRounded';

const YinVoterList = () => {

  const [loading, setLoading] = useState(true)
  const [loader, setLoader] = useState(false)
  const [candidates, setCandidates] = useState([])
  const [district, setDistrict] = useState("")
  const [page_selected, setPage_selected] = useState("")
  const [limitVal, setLimitVal] = useState("");
  const [sheetLoader, setSheetLoader] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000);
    getData();
    setDistrict(JSON.parse(localStorage.getItem("districts")))
  }, [])

  const getData = () => {
    setLoader(true)
    axios.get(process.env.REACT_APP_APIURL +
          `/coordinator/user/college_id/unapproved/idcard?cities=${JSON.parse(localStorage.getItem("districts"))}&status=APPROVED&college_code=` +
          localStorage.getItem("collegeCode")
      )
      .then((res) => {
        let candidatesInfo = res.data.data;
        setCandidates(candidatesInfo);
        setLoader(false);
      })
      .catch((error) =>{
        console.log("error",error);
        setLoader(false);
      })
  };

  let candidate_list = "";
    if (candidates) {
      candidate_list = candidates.map((candidate, index) => (
        <tr key={candidate.yin_id}>
          <td> {index + 1} </td>
          <td>
            {" "}
            {candidate.first_name} {candidate.last_name}
          </td>
          <td> {candidate.mobile} </td>
          <td> {candidate.college_name} </td>
          {candidate.isVoterIdCardStatus === "APPROVED" ? (
            <td className="text-success">APPROVED</td>
          ) : (
            ""
          )}
          {candidate.isVoterIdCardStatus === "REJECTED" ? (
            <td className="text-danger">REJECTED</td>
          ) : (
            ""
          )}
          {candidate.isVoterIdCardStatus === "INPROCESS" ? (
            <td className="text-warning">PENDING</td>
          ) : (
            ""
          )}
        </tr>
      ));
    } else {
      candidate_list = (
        <tr>
          <td>No Data Found....</td>
        </tr>
      );
    }

    
const downloadExcel = (customHeadings) => {
  let dataSet = [];
  dataSet = customHeadings;
  const worksheet = XLSX.utils.json_to_sheet(dataSet);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, candidates[0].college_name+"_VoterList.xlsx");
  setSheetLoader(false);
};

const CustomExcel = () => {
  setSheetLoader(true);
  const customHeadings =
  candidates !== undefined && candidates.length > 0
      ? candidates.map((data,index) => ({
          Id:index+1,
          Name: data?.first_name + " " + data?.last_name ?? "-",
          College_Name: data?.college_name ?? "-",
          College_year: data?.college_year ?? "-",
          Course_name: data?.course_name ?? "-",
        }))
      : "";
  downloadExcel(customHeadings);
};

  return (
    
    <>
        <div>
          <Sidebar />
          <div className="main">
            <div className="topbar">
              <Navbar />
            </div>

            <div className="container">
              <BackAndBreadCrmOneParent backLink="/view-card" backName="View card" currentTabName="Candidate list"/>

              <Row md={12}>
                <Col md={4}>

                </Col>

                <Col md={4}>
                  <h2 className="text-center">Voter List</h2>
                </Col>

                <Col md={4}>
                  <div className='d-flex justify-content-end mx-2'>
                    <Button onClick={() => CustomExcel()} className='excelBtn'><Icon.BoxArrowDown className='me-2'/>Export</Button>
                  </div>
                </Col>
              </Row>

              
              <br></br>

              <div className="row">
                <div className="col-12">
                  <table className="table table-bordered table-responsive">
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Full Name</th>
                        <th>Mobile</th>
                        <th>College Name</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      { loader ? <LoaderRounded/> : candidate_list}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  )
}

export default YinVoterList
