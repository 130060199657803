import React, { Component } from "react";
import axios from "axios";
// import Resizer from "react-image-file-resizer";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

class Addcandidate extends Component {
  constructor(pops) {
    super(pops);

    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      college_name: "",
      mobile: "",
      gender: "",
      address1: "",
      address2: "",
      taluka: "",
      password: "",
      pincode: "",
      dob: "",
      application_status: "",
      college_id: "",
      user_role: "",
      description: "",
      image: "",
      //for dropdown purpose
      state_name: "",
      state_code: "",
      city_name: "",
      StateData: [],
      CityData: [],
      Allcollagedata: [],
      errors: [],
      loader: false,
    };

    this.initialState = this.state;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.ChangeCity = this.ChangeCity.bind(this);

    this.handleFormValidation = this.handleFormValidation.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      this.setState({
        image: URL.createObjectURL(img),
        college_id: img,
      });
    }
  };

  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];

      this.setState({
        image: URL.createObjectURL(img),
        YIN_ID: img,
      });
    }
  };
  ChangeCity = (e) => {
    e.preventDefault();
    axios
      .get(process.env.REACT_APP_APIURL + "/address/city/" + e.target.value)
      .then((response) => {
        this.setState({
          CityData: response.data,
          state_name: e.target.value,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  showCity() {
    axios
      .get(process.env.REACT_APP_APIURL + "/address/city/MH")
      .then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          this.setState({
            CityData: response.data,
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }
  async getallcollage() {
    await axios
      .get(process.env.REACT_APP_APIURL + "/college/list/all")
      .then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          this.setState({
            Allcollagedata: response.data,
          });
        }
      })
      .catch((error) => {
        alert(error);
        console.log("error in get all collage", error);
      });
  }
  handleFormValidation = () => {
    const {
      first_name,
      last_name,
      password,
      candidate_name,
      mobile,
      college_name,
      address1,
      address2,
      state_name,
      city_name,
      pincode,
      email,
      gender,
      taluka,
      application_status,
      dob,
      user_role,
      description,
      college_id,
    } = this.state;
    let errors = {};
    let formIsValid = true;

    //empty candidate name
    // const regName = /^[a-zA-Z]+ [a-zA-Z]+$/
    let arr = [];
    if (!first_name) {
      errors["first_name"] = "*Please enter first name.";
      arr.push({
        field: "first_name",
      });
      formIsValid = false;
    } else if (!first_name.match(/^[a-zA-Z ]*$/)) {
      formIsValid = false;
      errors["first_name"] = "*Please enter alphabet characters only.";
      arr.push({
        field: "first_name",
      });
    }

    if (!last_name) {
      formIsValid = false;
      errors["last_name"] = "*Please enter candidate name.";
      arr.push({
        field: "last_name",
      });
    } else if (!last_name.match(/^[a-zA-Z ]*$/)) {
      formIsValid = false;
      errors["last_name"] = "*Please enter alphabet characters only.";
      arr.push({
        field: "last_name",
      });
    }
    if (!email) {
      errors["email"] = "*Please enter your email.";
      formIsValid = false;
      arr.push({
        field: "email",
      });
    } else if (
      !email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      formIsValid = false;
      errors["email"] = "*Please enter alphabet characters only.";
      arr.push({
        field: "email",
      });
    }
    //empty mobile no
    if (!mobile) {
      formIsValid = false;
      errors["mobile"] = "*Please enter mobile no.";
      arr.push({
        field: "mobile",
      });
    } else if (!mobile.match(/^[0-9]{10}$/)) {
      formIsValid = false;
      errors["mobile"] = "*Please enter valid mobile no.";
      arr.push({
        field: "mobile",
      });
    }

    if (!college_name) {
      formIsValid = false;
      errors["college_name"] = "*Please enter college name.";
      arr.push({
        field: "college_name",
      });
    }
    if (!gender) {
      formIsValid = false;
      errors["gender"] = "*Please select gender.";
      arr.push({
        field: "gender",
      });
    }
    //empty Address1
    if (!address1) {
      formIsValid = false;
      errors["address1"] = "*Please enter address.";
      arr.push({
        field: "address1",
      });
    }
    if (!taluka) {
      formIsValid = false;
      errors["taluka"] = "*Please enter taluka.";
      arr.push({
        field: "taluka",
      });
    }

    //empty state
    if (state_name === "" || state_name === "--Choose State--") {
      formIsValid = false;
      errors["state_name"] = "Please Choose State.";
      arr.push({
        field: "state_name",
      });
    }
    //empty city
    if (city_name === "" || city_name === "--Choose City--") {
      formIsValid = false;
      errors["city_name"] = "Please Choose city.";
      arr.push({
        field: "city_name",
      });
    }

    var PincodePattern = new RegExp(/(^\d{6}$)/);
    //empty pincode
    if (!pincode) {
      formIsValid = false;
      errors["pincode"] = "*Please enter pincode.";
      arr.push({
        field: "pincode",
      });
    } else if (!PincodePattern.test(pincode)) {
      formIsValid = false;
      errors["pincode"] = "*Please enter valid pincode";
      arr.push({
        field: "pincode",
      });
    }
    if (!password) {
      formIsValid = false;
      errors["password"] = "*Please enter Password.";
      arr.push({
        field: "password",
      });
    }
    if (!dob) {
      formIsValid = false;
      errors["dob"] = "*Please enter dob.";
      arr.push({
        field: "dob",
      });
    }
    if (!application_status) {
      formIsValid = false;
      errors["application_status"] = "*Please enter application_status.";
      arr.push({
        field: "application_status",
      });
    }
    if (!user_role) {
      formIsValid = false;
      errors["user_role"] = "*Please enter user_role.";
      arr.push({
        field: "user_role",
      });
    }
    if (!description) {
      formIsValid = false;
      errors["description"] = "*Please enter description.";
      arr.push({
        field: "description",
      });
    }
    if (!college_id) {
      formIsValid = false;
      errors["college_id"] = "*Please enter college_id.";
      arr.push({
        field: "college_id",
      });
    }
    if (arr.length > 0) {
      const firstErrorField = document.querySelector(
        `[name="${arr[0].field}"]`
      );
      if (firstErrorField && firstErrorField.focus) {
        firstErrorField.focus();
      }
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  handleSubmit(event) {
    event.preventDefault();
    if (this.handleFormValidation()) {
      const candidate = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        college_name: this.state.college_name,
        mobile: this.state.mobile,
        gender: this.state.gender,
        address1: this.state.address1,
        address2: this.state.address2,
        city: this.state.city_name,
        taluka: this.state.taluka,
        state: this.state.state_name,
        state_code: this.state.state_code,
        password: this.state.password,
        pincode: this.state.pincode,
        dob: this.state.dob,
        application_status: this.state.application_status,
        user_role: this.state.user_role,
        description: this.state.description,
        college_id: this.state.college_id,
      };

      let statesSet = this;

      axios
        .post(process.env.REACT_APP_APIURL + `/users/add-new`, candidate)
        .then((res) => {
          if (res.status === 200) {
            this.props.history.push("/candidateList");
            alert("Candidate add successfully.");

            statesSet.setState({
              candidate: res.data,
              changeToView: true,
            });
          }
        })
        .catch((err) => {
          console.log("err in handlesubmit", err);
        });
    } else {
      this.handleFormValidation();
    }
  }
  UNSAFE_componentWillMount() {
    this.showCity();
    this.getallcollage();
  }
  render() {
    if (
      !this.props.UserData.mobile === "9637972851" &&
      !this.props.UserData.mobile === "7517096209"
    ) {
      return <Redirect to="/login" />;
    }

    return (
      <>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>
          <div className="container">
            <BackAndBreadCrmOneParent
              backLink="/candidateList"
              backName="Candidate List"
              currentTabName="Add New Candidate"
            />
            <div className="row mt-4">
              <div style={{
                marginBottom: "50px",
                overflow: "hidden",
              }}
                className="col-lg-10 col-md-10 col-10 paddingAll10 mx-auto"
              >
                <div className="card rounded shadow shadow-sm">
                  <div className="card-header">
                    <h3 className="mb-0 center">Add New Candidate</h3>
                  </div>
                  <div className="card-body paddingAll20">
                    <form id="Addcandidate">

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div className="form-group">
                            <label htmlFor="first_name" className="control-label">
                              First Name<span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <input
                              type="text"
                              id="first_name"
                              name="first_name"
                              className="form-control rounded-1"
                              value={this.state.first_name}
                              onChange={this.handleChange}
                              placeholder="Enter your name"
                              required
                            />
                            <div className="errorMsg">
                              {this.state.errors.first_name}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div className="form-group">
                            <label htmlFor="last_name" className="control-label">
                              Last Name<span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <input
                              type="text"
                              id="last_name"
                              name="last_name"
                              className="form-control rounded-1"
                              value={this.state.last_name}
                              onChange={this.handleChange}
                              placeholder="Enter your name"
                              required
                            />
                            <div className="errorMsg">
                              {this.state.errors.last_name}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div className="form-group">
                            <label
                              htmlFor="email"
                              id="currentadd"
                              className="control-label"
                            >
                              Email Address<span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <input
                              type="text"
                              id="email"
                              name="email"
                              value={this.state.email}
                              onChange={this.handleChange}
                              className="form-control"
                              placeholder="abc@gmail.com"
                              required
                            />
                            <div className="errorMsg">
                              {this.state.errors.email}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div className="form-group">
                            <label
                              htmlFor="mobile"
                              id="mobile"
                              className="control-label"
                            >
                              Mobile Number<span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <input
                              type="number"
                              name="mobile"
                              className="form-control"
                              value={this.state.mobile}
                              onChange={this.handleChange}
                              placeholder="Enter mobile number"
                              maxLength={10}
                            />
                            <div className="errorMsg">
                              {this.state.errors.mobile}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div className="form-group">
                            <label
                              htmlFor="college_name"
                              id="college_name"
                              className="control-label"
                            >
                              Collage Name<span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <select
                              id="citys"
                              name="district"
                              className="form-select"
                              onChange={(e) => {
                                this.setState({
                                  college_name: e.target.value,
                                });
                              }}
                            >
                              <option>--Select College</option>
                              {this.state.Allcollagedata?.map((e, key) => {
                                return (
                                  <option key={key} value={e.college_name}>
                                    {e.college_name}
                                  </option>
                                );
                              })}
                            </select>
                            <div className="errorMsg">
                              {this.state.errors.college_name}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div>
                            {" "}
                            Gender &nbsp;&nbsp;
                            <br />
                            <input
                              style={{ marginTop: "10px" }}
                              type="radio"
                              id="html"
                              name="gender"
                              value="male"
                              onChange={this.handleChange}
                            />
                            <label for="male">Male</label>
                            <input
                              type="radio"
                              id="female"
                              name="gender"
                              value="female"
                              onChange={this.handleChange}
                              style={{
                                marginLeft: "5px",
                              }}
                            />
                            <label for="female">Female</label>
                            <input
                              type="radio"
                              id="other"
                              name="gender"
                              value="other"
                              onChange={this.handleChange}
                              style={{
                                marginLeft: "5px",
                              }}
                            />
                            <label for="javascript">other</label>
                            <div className="errorMsg">
                              {this.state.errors.gender}
                            </div>
                          </div>
                        </div>{" "}
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div id="address1" className="form-group">
                            <label
                              htmlFor="address1"
                              id="address1"
                              className="control-label"
                            >
                              Address1<span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <input
                              type="text"
                              name="address1"
                              value={this.state.address1}
                              onChange={this.handleChange}
                              className="form-control"
                            ></input>
                            <div className="errorMsg">
                              {this.state.errors.address1}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div id="address2" className="form-group">
                            <label
                              htmlFor="address2"
                              id="Address2"
                              className="control-label"
                            >
                              Address2
                            </label>
                            <input
                              type="text"
                              name="address2"
                              value={this.state.address2}
                              onChange={this.handleChange}
                              className="form-control"
                            ></input>
                            <div className="errorMsg">
                              {this.state.errors.address2}
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div id="stateDiv" className="form-group">
                            <label htmlFor="states" className="control-label">
                              Select State <span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <select
                              id="states"
                              className="form-select"
                              name="state"
                              onChange={(e) => {
                                this.setState({
                                  state_code: e.target.value,
                                  state_name: "Maharashtra",
                                });
                              }}
                            >
                              <option>--Choose State--</option>
                              <option value="MH">Maharashtra</option>
                              {/* <option value="GA">Goa</option> */}
                            </select>
                            <div className="errorMsg">
                              {this.state.errors.state_name}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div id="stateDiv" className="form-group">
                            <label
                              htmlFor="citys"
                              id="cityLabel"
                              className="control-label"
                            >
                              Select City <span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <select
                              id="citys"
                              name="district"
                              className="form-select"
                              onChange={(e) => {
                                this.setState({
                                  city_name: e.target.value,
                                });
                              }}
                            >
                              <option>--Choose City--</option>
                              {this.state.CityData?.map((e, key) => {
                                return (
                                  <option key={key} value={e.name}>
                                    {e.name}
                                  </option>
                                );
                              })}
                            </select>
                            <div className="errorMsg">
                              {this.state.errors.city_name}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6">
                          <div className="form-group">
                            <label htmlFor="pincode" className="control-label">
                              Pincode <span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              name="pincode"
                              onChange={this.handleChange}
                              placeholder="411013"
                            />
                            <div className="errorMsg">
                              {this.state.errors.pincode}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-6 ">
                          <div className="form-group">
                            <label
                              htmlFor="taluka"
                              id="taluka"
                              className="control-label"
                            >
                              Taluka<span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <input
                              type="text"
                              name="taluka"
                              value={this.state.taluka}
                              onChange={this.handleChange}
                              className="form-control"
                            ></input>
                            <div className="errorMsg">
                              {this.state.errors.taluka}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6">
                          <div className="form-group">
                            <label
                              htmlFor="password"
                              id="password"
                              className="control-label"
                            >
                              Password <span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <input
                              type="text"
                              name="password"
                              value={this.state.password}
                              onChange={this.handleChange}
                              className="form-control"
                            ></input>
                            <div className="errorMsg">
                              {this.state.errors.password}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                          <div className="form-group">
                            <label htmlFor="dob" id="dob" className="control-label">
                              Date Of Birth<span style={{ color: "#ff0000" }}>*</span>{" "}
                            </label>
                            <input
                              type="date"
                              name="dob"
                              value={this.state.dob}
                              onChange={this.handleChange}
                              className="form-control"
                            />
                            <div className="errorMsg">{this.state.errors.dob}</div>
                          </div>
                        </div>
                      </div>


                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6">
                          <div className="form-group">
                            <label
                              htmlFor="application_status"
                              id="application_status"
                              className="control-label"
                            >
                              Application Status <span style={{ color: "#ff0000" }}>*</span>
                            </label><br />
                            <select
                              id="status"
                              name="application_status"
                              value={this.state.application_status}
                              onChange={this.handleChange}
                              className="form-select"
                            >
                              <option value="LDPNOTAPPLIED">LDPNOTAPPLIED</option>
                              <option value="APPROVED">Approved</option>
                              <option value="PENDING">Pending</option>
                              <option value="REJECTED">Rejected</option>
                            </select>
                            <div className="errorMsg">
                              {this.state.errors.application_status}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-6">
                          <div className="form-group">
                            <label htmlFor="user_role" className="control-label">
                              User Role <span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <input
                              type="text"
                              id="user_role"
                              name="user_role"
                              value={this.state.user_role}
                              className="form-control rounded-1"
                              onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                              {this.state.errors.user_role}
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6">
                          <div className="form-group">
                            <label htmlFor="description" className="control-label">
                              Description<span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <input
                              type="text"
                              id="description"
                              name="description"
                              className="form-control rounded-1"
                              value={this.state.description}
                              onChange={this.handleChange}
                              placeholder="Enter description"
                            />
                            <div className="errorMsg">
                              {this.state.errors.description}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                          <div className="form-group">
                            <label htmlFor="college_id">
                              College ID<span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <input
                              type="file"
                              id="college_id"
                              name="college_id"
                              value={this.state.college_id}
                              className="form-control"
                              onChange={this.handleChange}
                            />
                            <div className="errorMsg">
                              {this.state.errors.college_id}
                            </div>
                          </div>
                        </div>
                      </div>


                      <br />
                      <button
                        type="button"
                        id="btnSubmitSignup"
                        onClick={this.handleSubmit}
                        className="btn btn-dark btn-lg btn-block mt-4"
                        style={{ backgroundColor: "#003147" }}
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    UserData: state.PersistReducer.UserData.UserData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Addcandidate);
