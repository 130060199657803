import React, { Component } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Select from "react-select";
// import qs from "query-string";
import LoaderRounded from "./LoaderRounded";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import { Redirect } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
export default class Editcontent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      _id: "",
      title: "",
      category: "",
      tags: "",
      description: "",
      image: "",
      url: "",
      source: "",
      college_id_image: "",
      dropDownOptCategoryList: [],
      isSelect: false,
      loader: false,
      redirect: false,
      category1: ""
    };


    this.initialState = this.state;
    this.imageChangedHandler = this.imageChangedHandler.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getData = this.getData.bind(this);
    this.getcontentData = this.getcontentData.bind(this);
  }

  componentWillMount() {
    (async () => {
      // let { id } = qs.parse(this.props.location.search);
      await this.getData("id");
    })();
  }

  async getData(id) {
    let currentComponent = this;
    // let contentID = localStorage.getItem("_id");
    await axios
      .get(process.env.REACT_APP_APIURL + "/Contents/getSingleContent/" + id)
      .then((res) => {

        let responses = res.data;
        currentComponent.setState({
          _id: responses._id,
          // title: responses.title,
          category: responses.category,
          tags: responses.tags,
          responses: responses.description,
          image: responses.image,
          url: responses.url,
          source: responses.source,
        });
      });
  }
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  imageChangedHandler(event) {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      this.setState({
        college_id_image: event.target.files[0],
        image: URL.createObjectURL(event.target.files[0]),
      });
    }
  }

  UNSAFE_componentWillMount() {
    this.getCategory();
    this.getcontentData();
  }

  getCategory = async () => {
    const API = await axios.get(
      // process.env.REACT_APP_APIURL + `/Contents/getcategory`
      process.env.REACT_APP_APIURL + `/Contents/get/category/all`
    );
    const serverResponse = API.data;
    const dropDownValue = serverResponse.map((response) => ({
      value: response.title,
      label: response.title,
      // value: response.id,
      // label: response.category,
      name: "category",
    }));

    this.setState({
      dropDownOptCategoryList: dropDownValue,
    });
  };

  onChangeForCategoryListDropDown(event) {
    const selectedCategory = event.label;

    this.setState({
      id: event.value,
      category: selectedCategory,
      category1: selectedCategory,  // Set category1 directly
      isSelect: true,
    });

    localStorage.setItem("id", event.value);
    localStorage.setItem("category", selectedCategory);
    localStorage.setItem("category1", selectedCategory);
  }

  getcontentData() {
    let contentid;
    let currentComponent = this;
    if (this.props.location.data) {
      localStorage.setItem("contentid", this.props.location.data._id);
      contentid = this.props.location.data._id;
    } else {
      contentid = localStorage.getItem("contentid");
    }

    axios
      .get(
        process.env.REACT_APP_APIURL + "/contents/getSingleContent/" + contentid
      )
      .then((res) => {
        let responses = res.data;
        console.log("response333", responses);

        let category1 = this.state.dropDownOptCategoryList.filter((item) => item.label === responses.category)[0];


        this.setState({
          _id: responses._id,
          title: responses.title,
          category: category1?.label,
          tags: responses.tags,
          description: responses.description,
          image: responses.image,
          url: responses.articleUrl,
          source: responses.source
        });

      });
  }

  handleSubmit = (event) => {

    event.preventDefault();
    const updatecontent = {
      id: localStorage.getItem("contentid"),
      title: this.state.title,
      category: this.state.category,
      tags: this.state.tags,
      description: this.state.description,
      url: this.state.url,
      source: this.state.source,
      image: this.state.college_id_image,
    };
    axios.put(process.env.REACT_APP_APIURL + `/contents/updateContent`, updatecontent)
      .then((res) => {
        this.setState({
          redirect: true,
          changeToView: true,
          loader: true,
        });
      });
  };



  render() {
    if (this.state.redirect) {
      return <Redirect to="/contentlist" />;
    }
    return (
      <>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>
          <div className="container" style={{ marginBottom: "50px" }}>
            <BackAndBreadCrmOneParent
              backLink="/contentlist"
              backName="ContentList"
              currentTabName="Edit Content"
            />
            <div className="row mt-3">
              <div className="col-12 col-md-8 col-lg-8 m-auto">
                <div className="card rounded shadow shadow-sm">
                  <div className="card-header">
                    <h3 className="mb-0"> Edit Content </h3>
                  </div>
                  <div className="card-body mt-2">
                    <form id="formcontent" onSubmit={this.handleSubmit}>
                      <Row>
                        <Col sm={12} md={6}>
                          <label className="control-label" htmlFor="Titles">
                            Title
                          </label>
                          <input
                            type="text"
                            name="title"
                            value={this.state.title}
                            placeholder="Title"
                            className="form-control"
                            onChange={this.handleChange}
                            required
                          />
                        </Col>
                        <Col sm={12} md={6}>
                          <label
                            htmlFor="category_names"
                            className="control-label"
                          >
                            <span style={{ color: "#ff0000" }}>*</span> Select
                            Category
                          </label>

                          <Select
                            value={this.state.dropDownOptCategoryList.find(option => option.label === this.state.category)}
                            options={this.state.dropDownOptCategoryList}
                            onChange={this.onChangeForCategoryListDropDown.bind(this)}
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col sm={10} md={12}>
                          <label className="control-label" htmlFor="Tags">
                            Tags
                          </label>
                          <input
                            type="Text"
                            name="tags"
                            placeholder="tag"
                            className="form-control"
                            onChange={this.handleChange}
                            value={this.state.tags}
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col sm={12} md={12} xl={6}>
                          <label htmlFor="image">
                            <span style={{ color: "#ff0000" }}>*</span> Upload
                            Photo
                          </label>
                          <input
                            type="file"
                            id="image"
                            name="college_id"
                            className="form-control-file"
                            onChange={this.imageChangedHandler}
                          />
                          {this.state.image ? (
                            <img
                              id="image"
                              src={this.state.image}
                              alt=""
                              width="200px"
                              height="80px"
                            />
                          ) : (
                            ""
                          )}
                        </Col>

                        <Col sm={12} md={12} xl={6}>
                          <label
                            className="control-label"
                            htmlFor="source"
                          >
                            Image Source
                          </label>
                          <input
                            type="Text"
                            name="source"
                            placeholder="Image Source"
                            className="form-control"
                            onChange={this.handleChange}
                            value={this.state.source}
                            required />
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={12} md={12}>
                          <label
                            className="control-label"
                            htmlFor="Purpose Of Travel"
                          >
                            Url
                          </label>
                          <input
                            type="Text"
                            name="url"
                            placeholder="https://www.abc.com"
                            onChange={this.handleChange}
                            className="form-control"
                            value={this.state.url}
                            required
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={12} md={12}>
                          <label
                            className="control-label"
                            htmlFor="Description"
                          >
                            Description
                          </label>
                          <input
                            type="Text"
                            name="description"
                            placeholder="Lorem Ipsum, sometimes referred to as 'lipsum', is the placeholder text used in design when creating content. "
                            onChange={this.handleChange}
                            value={this.state.description}
                            className="form-control"
                            required
                          />
                        </Col>
                      </Row>

                      <div className="row">
                        <div className=" col-12 col-md-12 d-flex justify-content-center align-items-center">
                          <button
                            type="submit"
                            id="btnSubmitProfile"
                            className="btn btn-dark btn-lg btn-block mt-4 m-auto"
                          >Update
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
