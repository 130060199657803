
import React, { Component } from "react";
// import { Redirect } from 'react-router-dom';
import axios from "axios";
// import { Form, Radio, Input } from "antd";
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import S3 from "react-aws-s3";
import Compressor from 'compressorjs';
// import SearchLocationInput from "./SearchLocationInput";
// import moment from 'moment';
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";




export default class Position extends Component {
    constructor(props) {
        super(props);
        this.state = {
            event_address: "",
            event_address2: "",
            State: "",
            city: "",
            event_pincode: "",
            event_latitude: "",
            event_longitude: "",
            event: JSON.parse(localStorage.getItem('EventListData')),
            latitude: "",
            longitude: "",
            promotion_banner_images: [],

            Banner: JSON.parse(localStorage.getItem('Images')),
            errors: {},
            loader: false,

        };

        this.handleSubmitLocation = this.handleSubmitLocation.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleFormValidation = this.handleFormValidation.bind(this);
        this.handleSubmitImage = this.handleSubmitImage.bind(this);



        this.uploadImageToS3 = this.uploadImageToS3.bind(this);
    }
    handleFormChange(event) {

        this.setState({
            [event.target.name]: event.target.value

        });
    }
    handleFormValidation = () => {
        const { event_address, event_address2, event_latitude, event_longitude, event_pincode } = this.state;
        let errors = {};
        let formIsValid = true;


        if (!event_address) {
            formIsValid = false;
            errors["event_address"] = "*Give Your Answer.";
        }
        if (!event_address2) {
            formIsValid = false;
            errors["event_address2"] = "*Give Your Answer.";
        }
        if (!event_latitude) {
            formIsValid = false;
            errors["event_latitude"] = "*Give Your Answer.";
        }

        if (!event_longitude) {
            formIsValid = false;
            errors["event_longitude"] = "*Give Your Answer.";
        }
        if (!event_pincode) {
            formIsValid = false;
            errors["event_pincode"] = "*Give Your Answer.";
        }

        this.setState({
            errors: errors
        });
        return formIsValid;

    }



    handleSubmitLocation = async event => {
        event.preventDefault();
        if (this.handleFormValidation()) {

            const EventLocation = {
                "event_address": this.state.event_address,
                "event_address2": this.state.event_address2,
                "event_pincode": this.state.event_pincode,
                "event_latitude": this.state.event_latitude,
                "event_longitude": this.state.event_longitude,

            };

            let statesSet = this;

            await axios.post(process.env.REACT_APP_TALK_API + "/event/add/event/location", EventLocation)
                .then(res => {
                    if (res.status === 200) {
                        this.props.history.push("/eventlist");
                        alert('Event Added successfully');
                        statesSet.setState({
                            changeToView: true,
                            redirect: true,
                        })
                    }
                })
        }
        else {
            alert("error");
        }


    }



    addNewImage(newImage) {
        let img_arr = this.state.promotion_banner_images
        img_arr.push(newImage);
        this.setState({
            promotion_banner_images: img_arr,

        })
    }

    removeImageURL(id) {
        let img_arr = this.state.promotion_banner_images
        img_arr = img_arr.filter(img => img.id !== id);
        this.setState({
            promotion_banner_images: img_arr,

        })
    }




    uploadImageToS3(event) {
        const config = {
            bucketName: process.env.REACT_APP_BUCKET_NAME,
            dirName: "yin/ promotion_banner_images",
            region: process.env.REACT_APP_REGION,
            accessKeyId: process.env.REACT_APP_ACCESS_ID,
            secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
            s3Url: process.env.REACT_APP_S3URL
        };

        var fileInput = false;
        const image = event.target.files[0];
        if (image === undefined) return
        if (event.target.files[0]) {
            fileInput = true;
        }
        if (fileInput) {

            if (event.target.files[0].type === 'image/png' || event.target.files[0].type === 'image/jpeg') {
                new Compressor(image, {
                    quality: 0.6,
                    success: (compressedResult) => {

                        let newFileName = event.target.files[0].name.replace(/\..+$/, "");
                        const finalFileName = newFileName;
                        const ReactS3Client = new S3(config);
                        // const newFileName = 'test-file';

                        ReactS3Client.uploadFile(compressedResult, newFileName).then((res) => {
                            this.addNewImage({
                                type: 'IMAGE',
                                url: res.location,
                                name: finalFileName,
                                id: Math.floor(Math.random() * 9999999999)
                            });
                        }).catch(err => console.error(err));
                    },
                });
                console.log("--------------- Upload image -----------");
            }
        }
    }

    handleSubmitImage = async event => {
        event.preventDefault();


        const Image = {
            ...this.state

        };

        let statesSet = this;


        await axios.post(process.env.REACT_APP_TALK_API + "/event/add/banner/images", Image)
            .then(res => {
                let BannerImages = this.state.promotion_banner_images
                localStorage.setItem("Images", JSON.stringify(BannerImages))
                if (res.status === 200) {
                    alert('Image Added successfully');
                    statesSet.setState({
                        BannerImages: this.state.promotion_banner_images,
                        changeToView: true,
                        redirect: true,
                    })
                }
            })



    }





    render() {


        return (
            <>
                <Sidebar />
                <div className="main">
                    <div className="topbar">
                        <Navbar />
                    </div>
                    <div className="container">
                        <BackAndBreadCrmOneParent backLink="/eventlist" backName="Event List" currentTabName="Event List" />
                        <div className="row mt-4">
                            <div
                                style={{
                                    heigh: "800vh",
                                    overflow: "hidden",
                                }}
                                className="col-lg-8 col-md-8 col-12 paddingAll10 mx-auto ">

                                <div className="card rounded shadow shadow-sm " >
                                    <div className="card-header">

                                        <h3 className="mb-0 center">Add Location</h3>
                                    </div>
                                    <div className="card-body">
                                        {/* <center>
                                        <img src={this.state.profile_image} onChange={this.handleFormChange} width="100px" height="100px" alt="avtar" className="rounded-circle" />
                                    </center> */}
                                        <form id="candidate" method="post" encType="multipart/form-data">

                                            <div className="form-group">
                                                <label htmlFor="Address2" className="control-label"> <span style={{ color: "#ff0000" }}>*</span>Address Lane 1</label>
                                                <input type="text" id="Address2" name="event_address" value={this.state.event_address} onChange={this.handleFormChange} className="form-control" placeholder="Type here" required ></input>
                                                <div className="errorMsg">{this.state.errors.event_address}</div>
                                            </div>
                                            <br />
                                            <hr className="mx-n3" />

                                            <div className="form-group">
                                                <label htmlFor="Address2" className="control-label"> <span style={{ color: "#ff0000" }}>*</span>Address Lane 2</label>
                                                <input type="text" id="Address2" name="event_address2" value={this.state.event_address2} onChange={this.handleFormChange} className="form-control" placeholder="Type here" required ></input>
                                                <div className="errorMsg">{this.state.errors.event_address2}</div>
                                            </div>
                                            <br />
                                            <hr className="mx-n3" />

                                            <div className="form-group">
                                                <label htmlFor="Eventcity" className="control-label"> <span style={{ color: "#ff0000" }}>*</span>Event State</label>
                                                <input type="text" id="Eventstate" name="event_title" value={this.state.event.state} readOnly onChange={this.handleFormChange} className="form-control" placeholder="Type here" required ></input>

                                            </div>
                                            <br />
                                            <hr className="mx-n3" />

                                            <div className="form-group">
                                                <label htmlFor="Eventcity" className="control-label"> <span style={{ color: "#ff0000" }}>*</span>Event City</label>
                                                <input type="text" id="Eventcity" name="event_title" value={this.state.event.cities[0]} readOnly onChange={this.handleFormChange} className="form-control" placeholder="Type here" required  ></input>
                                                {/* <div className="errorMsg">{this.state.errors.event_title}</div> */}
                                            </div>
                                            <br />
                                            <hr className="mx-n3" />

                                            <div className="form-group">
                                                <label htmlFor="pincode" className="control-label"> <span style={{ color: "#ff0000" }}>*</span>Pincode</label>
                                                <input type="text" id="Pincode" name="event_pincode" value={this.state.event_pincode} onChange={this.handleFormChange} className="form-control" placeholder="Type here" required></input>
                                                <div className="errorMsg">{this.state.errors.event_pincode}</div>
                                            </div>
                                            <br />
                                            <hr className="mx-n3" />

                                            <div className="form-group">
                                                <label htmlFor="latitude" className="control-label"> <span style={{ color: "#ff0000" }}>*</span>Latitude</label>
                                                <input type="text" id="latitude" name="event_latitude" value={this.state.event_latitude} onChange={this.handleFormChange} className="form-control" placeholder="Type here" required ></input>
                                                <div className="errorMsg">{this.state.errors.event_latitude}</div>
                                            </div>
                                            <br />
                                            <hr className="mx-n3" />

                                            <div className="form-group">
                                                <label htmlFor="logitude" className="control-label">longitude</label>
                                                <input type="text" id="logitude" name="event_longitude" value={this.state.event_longitude} onChange={this.handleFormChange} className="form-control" placeholder="Type here" required ></input>
                                                <div className="errorMsg">{this.state.errors.event_longitude}</div>
                                            </div>
                                            <br />
                                            <hr className="mx-n3" />


                                            <div className="form-group">
                                                {/* <button type="button" onClick={this.handleSubmitLocation}  className="btn btn-primary btn-lg btn-block mb-2 mt-4" style={{ margin: "4px" }}>Update</button> */}
                                                <button onClick={this.handleSubmitLocation} id="btnSubmitSignup" className="btn btn-primary btn-lg btn-block mb-2 mt-4" style={{ margin: "4px" }}>Add</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="split right">
                                    <div className="centered">

                                        <form id="banner image" method="post" encType="multipart/form-data">


                                            <div className="textOnInputs">
                                                <div className="col-md-12 mt-3">
                                                    <label htmlFor="image"><span style={{ color: "#ff0000" }}>*</span>Promotional Campaign Photo</label>

                                                    <input type="file" accept="image/png, image/gif, image/jpeg" id="image" name="college_id" className="form-control-file" onChange={this.uploadImageToS3} />
                                                    {/* {this.state. promotion_banner_images ? <img id="image" src={this.state. promotion_banner_images} alt="" width="200px" height="80px" /> : ""} */}
                                                    <div className="errorMsg">{this.state.errors.promotion_banner_images}</div>
                                                </div>

                                                {
                                                    this.state.promotion_banner_images.length === 0 ? null : (
                                                        this.state.promotion_banner_images.map(img => {
                                                            return (
                                                                <>
                                                                    <div className="card" style={{ margin: 10, width: 200 }} >
                                                                        <img src={img.url} key={img.url} className="card-img-top" alt={img.name} width="100px" height="100px" />


                                                                    </div>

                                                                </>
                                                            )
                                                        })
                                                    )}


                                            </div>

                                            <br />
                                            <hr className="mx-n3" />

                                            {/* <Link onClick={() => localStorage.setItem('EventListData',JSON.stringify(eventlist))} to="/Add-Location"><button type="button" className="btn btn-success btn-sm m-1">Add Location</button></Link> */}


                                            <div class="row justify-content-center">
                                                <button onClick={this.handleSubmitImage} class="btn btn-default btn-primary" style={{ width: "120px", padding: "10px" }}>submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}



