import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { Edit, Add } from '@material-ui/icons';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import candidate_old from "../data/static-candidate";
// import Editcandidate from './Editcandidate';
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRounded from "./LoaderRounded";
import * as Icon from 'react-bootstrap-icons';

class CandidatePhaseList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentFilter: null,
            candidate_old: candidate_old,
            candidate: [],
            CityData: [],
            selectValue: "all",
            redirect: false,
            loader: true,
            phaseSet: this.props.match.params.phase ? this.props.match.params.phase : "PHASE2",
            districtsLocal: JSON.parse(localStorage.getItem("districts")),
        };

        this.applyFiter = this.applyFilter.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.Editcandidate = this.Editcandidate.bind(this);
        this.searchFromList = this.searchFromList.bind(this);
        this.getCollegeWithCity = this.getCollegeWithCity.bind(this)
    }

    applyFilter(arr) {
        if (!this.state.currentFilter) {
            return arr;
        }
        let filteron = this.state.currentFilterBy;
        if (filteron === "city") {
            return arr.filter((x) => x.city === this.state.currentFilter);
        } else {
            var condition = new RegExp(this.state.currentFilter);
            var result = arr.filter(function (el) {
                return condition.test(el.first_name);
            });

            return result;
        }
    }

    setFilter(e) {
        e.preventDefault();
        this.setState({
            currentFilter: e.target.value
        })

    }

    UNSAFE_componentWillMount() {
        this.getData(this.props.match.params.phase);
        this.showCity();
    }


    getData(phase) {
        let currentComponent = this;
        let a = JSON.parse(localStorage.getItem('districts'));
        let subcity = localStorage.getItem("sub_city_name");
        this.setState({
            loader: true
        });
        if (subcity) {
            axios.get(process.env.REACT_APP_APIURL + `/candidate/list/city/` + a + `/` + subcity + `?phase=` + phase)
                .then(res => {
                    let responses = res.data;
                    currentComponent.setState({
                        candidate: responses,
                        loader: false
                    });
                });

        } else {
            axios.get(process.env.REACT_APP_APIURL + `/candidate/list/city/` + a + `?phase=` + phase)
                .then(res => {
                    let responses = res.data;
                    currentComponent.setState({
                        candidate: responses,
                        loader: false,
                    });
                });
        }
    }

    showCity() {
        let districts = JSON.parse(localStorage.getItem("districts"));
        if (districts[0] !== "*") {
            this.setState({
                CityData: districts,
            });
        } else {
            axios.get(process.env.REACT_APP_APIURL + `/address/city/MH`).then(response => {
                this.setState({
                    CityData: response.data,
                })
            }).catch(error => {
                console.log("Error", error);
            });
        }

    }

    Editcandidate(yinID, candidateID) {
        localStorage.setItem("candidate_id", candidateID);
        localStorage.setItem("yin_id", yinID);
        this.setState({
            redirect: true,
        })
    }

    getCollegeWithCity(event) {
        this.setState({
            currentFilter: event.target.value,
            currentFilterBy: event.target.name
        })
    }

    searchFromList(event) {
        this.setState({
            currentFilter: event.target.value,
            currentFilterBy: event.target.name
        })

    }

    render() {
        if (this.state.redirect) {
            return <Redirect to="/edit_candidate" />
        }

        if (!localStorage.getItem("mobile")) {
            return <Redirect to="/login" />
        }

        let candidateList = '';
        if (this.state.candidate.length > 0) {
            candidateList = this.state.candidate && this.applyFilter(this.state.candidate).map((candidates, id) => (
                <tr key={id} >
                    <td>{id + 1}</td>
                    <td>{candidates.yin_id}</td>
                    <td>{candidates.first_name} &nbsp;{candidates.last_name}</td>
                    <td>{candidates.college_name}</td>
                    {/* <td>{candidates.email}</td> */}
                    <td className="noselect">{candidates.mobile}</td>
                    <td>{candidates.gender}</td>
                    <td>{candidates.city}</td>
                    {candidates.application_status === "APPROVED" ? <td className="text-success">APPROVED</td> : ""}
                    {candidates.application_status === "REJECTED" ? <td className="text-danger">REJECTED</td> : ""}
                    {candidates.application_status === "PENDING" ? <td className="text-warning">PENDING</td> : ""}
                    <td className="text-center">
                        <span onClick={() => this.Editcandidate(candidates.yin_id, candidates.college_code)}><Edit /></span>
                        {/* <Link to=""><Delete /></Link> */}
                    </td>

                </tr>
            ));
        } else {
            candidateList = "No data found";
        }

        let cities;
        if (this.state.districtsLocal[0] !== "*") {
            cities = this.state.CityData.map((e, key) => {
                return <option key={key} value={e}>{e}</option>;
            })
        } else {
            cities = this.state.CityData.map((e, key) => {
                return <option key={key} value={e.name}>{e.name}</option>;
            })
        }


        return (
            <>
                <div>
                    <Sidebar />
                    <div className="main">
                        <div className="topbar">
                            <Navbar />
                        </div>

                        <div className="container">
                            <BackAndBreadCrmOneParent currentTabName="All candidate list" />

                            <h2 className="text-center"><u>All {this.state.phaseSet} CANDIDATE LIST</u></h2>
                            <br />

                            {/* <div className="text-center"/>
                            <Link to="/Addcandidate" className="btn btn-secondary btn-sm" style={{ float: "right" }}><Add />Add New</Link>
                        </div> */}

                            <div className="row text-center mt-4">
                                <div className="col-6">
                                    <input type="text" placeholder="Search here" name="search" onChange={this.searchFromList} className="form-control"></input>
                                </div>
                                <div className="col-6">
                                    <select type="button" name="city" className="mx-2 btn btn-secondary btn-md dropdown-toggle" onChange={this.getCollegeWithCity} id="district" style={{ float: "right", width: "120px" }}>
                                        <option value={null}> --District-- </option>
                                        {cities}
                                    </select>

                                    <Link to="/Addcandidate" className="btn btn-secondary btn-sm" style={{ float: "right" }}><Add />Add New</Link>

                                </div>
                            </div>

                            <br />
                            <div className="container">
                                <div>Current Filter: {this.state.currentFilter ?? "NO FIlter"}  </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div style={{ overflowx: "auto" }}>
                                            <div className="table-responsive-sm">

                                                <table className="table table-bordered table-responsive">
                                                    <thead>
                                                        <tr>
                                                            <th>ID</th>
                                                            <th>YIN ID</th>
                                                            <th>Name</th>
                                                            <th>College</th>
                                                            {/* <th>Email</th> */}
                                                            <th>Mobile</th>
                                                            <th>Gender</th>
                                                            <th>City</th>
                                                            <th>Status</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.loader ? <LoaderRounded /> : candidateList}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}
export default CandidatePhaseList;