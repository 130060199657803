import React from "react";
import "./PolicyStyles.css"

export const PolicyPage = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        textAlign: "justify",
        marginTop: 30,
        marginBottom: 30,
        fontSize: 19,
        padding: 30
      }}
    >
      <div style={{ maxWidth: 800 }}>
        <center>
          <br />
          <br />
          <h2 className="main-h2"><u>Young Inspirators Network (YIN)</u></h2>
          <br />
          <br />
        </center>
        <div className="block-div">
          India is a nation of youngsters. When the youth are motivated to arise
          and act, they have the strength and dynamism to change the world. With
          this objective, Mr. Abhijit Pawar started <strong>Young Inspirators Network
            (YIN)</strong> under Sakal Media Group’s “Delivering Change Foundation” (DCF).
        </div>
        <div className="block-div">
          <strong>YIN</strong> is a platform where thousands of young minds come together and
          take up multitudes of nation transformation projects, social
          initiatives, fun activities & events, and do social service, with a
          common objective of achieving personal, professional and societal
          progress. <strong>YIN</strong> is presently distributed over 26+ districts in
          Maharashtra, 1000+ colleges, and has a membership base of over 10,000+
          students, and is rapidly expanding. Various events took place under
          YIN are “Heroes for Change”, “Voice for Change”, “Samvad”, “Vha
          Bindhast”, “Dream Catchers” and projects like “YIN Footpath School”,
          “Chala Gavakade”, “Daru-bandi Rally”, “Police Mitra”, and many more
        </div>
        <div className="block-div" style={{ fontSize: 20 }} ><strong>YIN</strong> has come up a <strong><u>Leadership Development Program (LDP)</u></strong></div>
        <div className="block-div">
          <strong><u>Introduction:</u></strong> India is a nation of youngsters, hence, the voice of the youth must
          be heard and the youth need to be put at the centre stage of nation development.
          With this objective,<strong>YIN</strong>  has come up with the <strong>Leadership Development Program (LDP)</strong>
          so as to nurture young minds to become socially responsible leaders, and also serve as a
          bridge between the society and government by taking up nation development initiatives.
        </div>
        <div className="block-div">
          We know this sound like something huge. You would think,
          “How can we students take on so much of responsibility?” “Who will listen to us?” Well
          , fear not, because <strong>YIN</strong> has thought everything about how to do it (the process), the
          objectives we intend to achieve, the support system for this initiative, and your role in it.
        </div>
        <div className="block-div">
          <strong><u>The Process:</u></strong> YIN is going to identify YIN Leaders through Student
          Elections. This is not going to be like regular, political elections;
          the objective of these elections is to identify effective leaders in
          diverse fields such as Business, Politics, Arts, Science, Medicine, etc.
          These Leaders would be Student Representatives, who will be your voice in
          front of the government, the society, and the college management. Whatever
          problems you, as a student, face or the problems that you notice in our society
          can be shared with these Leaders and these leaders will raise it up in front of the correct authority.
        </div>
        <div className="block-div"><u>Process of Election: </u></div>
        <div className="block-div" style={{ marginLeft: 40, textAlign: 'justify' }}>
          <ul style={{ listStyleType: 'square' }} >
            <li>
              Per college minimum 4 candidates can be selected as contesting
              Candidates.
            </li>
            <li>
              <u>NOT ALLOWED:</u> campaigning slogans, class-to-class meetings,
              sessions, paper posters, corner meetings, processions are not
              allowed. Public sabha, wall paintings (graffiti), hoardings &
              cut-outs, commercial ads (TV, Radio, Newspapers, Cable, etc.) are
              not allowed.
            </li>
            <li>
              College students can cast their votes only for the candidates from
              their college.
            </li>
            <li>“Right to Vote” for all college students. </li>
            <li>
              Only 1 Candidate will finally be elected as the YIN Student Leader
              in each college.
            </li>
            <li>Elections would be taken by consensus. </li>
            <li>
              Tenure of all Student Leaders would be 1 academic year. Assembly
              will be dissolved at the completion of that year.
            </li>
            <li>
              All YIN Student Leaders can contest for YIN District Leader
              position.
            </li>
            <li>
              All YIN District Leaders can contest for YIN State Leader
              position.
            </li>
          </ul>
        </div>
        <div className="block-div">
          <u>Nomination Form & Documents Required from Nominated Candidates:</u>
        </div>
        <div className="block-div" style={{ textAlign: 'justify', marginLeft: 30 }}>
          <ul>
            <li>Name & Address </li>
            <li>College name, Stream & Year </li>
            <li>Date of Birth </li>
            <li>Terms & Conditions Form (completely filled & signed) </li>
            <li>Candidate Signature </li>
            <li>Principal Signature </li>
            <li>Witness name and Signature </li>
            <li>Photo ID Proof (College & YIN) </li>
            <li>Address Proof </li>
          </ul>
        </div>
        <div className="block-div"><strong>Rules of Online Elections:</strong></div>
        <div className="block-div">
          <div className="block-div" style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ marginRight: 10 }}> &#x2B9A;</div>
            <div>
              Due to COVID-19 precautions & to ensure privacy and accuracy,
              elections will be held over the internet and will be enabled through
              a secure & reliable App/web site to be designed by YIN committee in
              conjunction with the appropriate resources available.
            </div>
          </div>
          <div className="block-div" style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ marginRight: 10 }}>&#x2B9A;</div>
            <div>
              Online voting shall open and close at times to be announced by the YIN Committee of elections.
            </div>
          </div>
          <div className="block-div" style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ marginRight: 10 }}>&#x2B9A;</div>
            <div>
              To vote in the online election, voters must have completed the
              registration procedure on the YIN App/Website (platform).
            </div>
          </div>
          <div className="block-div" style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ marginRight: 10 }}>&#x2B9A;</div>
            <div>
              Voters may vote only for the appropriate candidate of their
              respective college.
            </div>
          </div>
          <div className="block-div" style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ marginRight: 10 }}>&#x2B9A;</div>
            <div>
              Candidates are not permitted to log on to the voting website/app on
              behalf of voters, or to direct a voter to the voting website/app
              section and demonstrate how to vote.
            </div>
          </div>
          <div className="block-div" style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ marginRight: 10 }}>&#x2B9A;</div>
            <div>
              No person shall cast a vote on behalf of another except to
              facilitate voting for individuals with disabilities after getting
              appropriate permissions from YIN committee.
            </div>
          </div>
          <div className="block-div" style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ marginRight: 10 }}>&#x2B9A;</div>
            <div>
              If a student has any problems or questions about the online ballot
              or how to access it, a YIN voting assistant shall assist the student
              in the voting process.
            </div>
          </div>
          <div className="block-div" style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ marginRight: 10 }}>&#x2B9A;</div>
            <div>
              The identity of any individual suspected of tampering with or
              promoting illegal voting through the online voting system, or
              otherwise sabotaging or attempting to unduly influence the outcome
              of the election through electronic or other means, will be
              immediately forwarded to the appropriate college/authority for
              appropriate action to be taken.
            </div>
          </div>
          <div className="block-div" style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ marginRight: 10 }}>&#x2B9A;</div>
            <div>
              Promoting illegal voting shall be strictly prohibited.</div>
          </div>
          <div className="block-div" style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ marginRight: 10 }}>&#x2B9A;</div>
            <div>
              The winners of the elected positions shall be those candidates that
              receive a plurality of the valid votes cast.
            </div>
          </div>
          <div className="block-div" style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ marginRight: 10 }}>&#x2B9A;</div>
            <div>
              Results of the election shall not be posted on the
              college/university web site or any other place prior to the results
              being announced to the candidates.
            </div>
          </div>
        </div>
        <div className="block-div"><strong>Adjudication:</strong></div>
        <div className="block-div" style={{ marginLeft:20 }}>
          <ul>
            <li>
              Violation of any of the above election rules will result in
              sanctions at the discretion of the YIN Committee.
            </li>
            <li>
              The YIN Committee shall have absolute jurisdiction to adjudicate any
              grievance filed in relation to any election, candidate, or campaign
              staff member.
            </li>
            <li>
              Election grievances shall be filed with the YIN Committee.
            </li>
            <li>
              Grievance forms must be typed and signed. All grievances must be
              submitted in writing to the YIN Committee on xxxxxxx
            </li>
            <li>
              Anonymous election grievances may not be filed.
            </li>
            <li>
              Grievances that challenge voting or request recounts must be made
              within three (3) business days following the election.
            </li>
          </ul>
        </div>
        <div className="block-div">
          <i>
            It is impossible to foresee all possible incidents. Therefore, the
            YIN Management reserves the right to make any additional decisions.
            Also, any infractions can result in either a vote deduction or
            disqualification. Be aware that any slander of any other candidate
            will be treated with the utmost seriousness and will result in
            disqualification.{" "}
          </i>
        </div>
      </div>
    </div>
  );
};
