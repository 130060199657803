import React, { Component } from 'react'
import candidate from "../data/static-pending";
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import { Redirect } from 'react-router';
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";

export default class Pendingc extends Component {
    render() {
        if(!localStorage.getItem("mobile")){
            return <Redirect to="/login"/>
        }

        return (
          <>
            <Sidebar />
            <div className="main">
                    <div className="topbar">
                        <Navbar />
                    </div> 

                <div className="container">
                <BackAndBreadCrmOneParent  currentTabName="Pending College list"/>
                    <div className="text-center">
                    <u><h2 className="text-center" style={{ marginTop: "10px" }}>Pending College</h2></u>
                        <br></br>
                        <div className="row">
                            <div className="col-12">
                                <div style={{ overflowx: "auto" }}>
                                    <div class="table-responsive-sm">
                                        <table className="table table-bordered table-responsive " style={{ marginTop: "10px" }}>
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Name</th>
                                                    <th>College</th>
                                                    <th>Email</th>
                                                    <th>Mobile</th>
                                                    <th>Address</th>
                                                    <th>DOB</th>
                                                    <th>College_id</th>
                                                    <th>Status</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(candidate).map(candidates => (
                                                    <tr >
                                                        <td>{candidates.ID}</td>
                                                        <td>{candidates.Name}</td>
                                                        <td>{candidates.College}</td>
                                                        <td>{candidates.Email}</td>
                                                        <td className="noselect">{candidates.Mobile}</td>
                                                        <td>{candidates.Address}</td>
                                                        <td>{candidates.DOB}</td>
                                                        <td>{candidates.College_id}</td>
                                                        <td>{candidates.Status}</td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </>
           
        )
    }
}






