import React, { Component } from 'react';
import axios from 'axios';
import ResultChildCom from './ResultChildCom';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";

export default class Result extends Component {
    constructor(props) {
        super(props);
        this.state = {
            college_code: localStorage.getItem("collegeCode") ? localStorage.getItem("collegeCode") : '',
            yin_id: '',
            first_name: '',
            last_name: '',
            votecount: '',
            profile_image: [],
            candidateData: [],
            state_code: '',
            city_code: '',
            state_name: '',
            city_name: '',
            StateData: [],
            CityData: [],
            country: "IN",
            id: "",
            email: ''
        };
        this.candidates = this.candidates.bind(this);
        this.ChangeCity = this.ChangeCity.bind(this);
        this.showCity = this.showCity.bind(this);
    }

    UNSAFE_componentWillMount() {
        let CollegeCode = localStorage.getItem("collegeCode");
        this.candidates(CollegeCode);
    }

    ChangeCity = (e) => {
        e.preventDefault();
        axios.get(process.env.REACT_APP_APIURL + '/address/city/' + e.target.value).then(response => {
            this.setState({
                CityData: response.data,
            })
        }).catch(error => {
            console.log("Error", error);
        });
    }

    showCity = (e) => {
        e.preventDefault();
        let index = e.target.selectedIndex;
        this.setState({ city_name: e.target[index].innerText });
    }

    async candidates(college_code) {
        axios.get(process.env.REACT_APP_VOTINGAPI + `/voting/resultByCollegeCode/` + this.state.college_code)
            .then(res => {
                let candidates = res.data;
                let a = [];

                if (candidates?.length > 0) {
                    a.push(candidates);
                    this.setState({
                        candidateData: candidates,
                    });
                }
                else {
                    let error = "No Data Found"
                    console.log("error in result----", error);
                }
            });
    }

    render() {
        let candidate_data_list = '';
        if (this.state.candidateData.length > 0) {
            candidate_data_list = this.state.candidateData.map((candidates, index) => (
                <div key={index}>
                    <ResultChildCom profile_image={candidates.profile_image[0]} first_name={candidates.first_name} last_name={candidates.last_name} votecount={candidates.votecount} yin_id={candidates.yin_id} />
                </div>
            ))
        }
        else {
            candidate_data_list =
                <tr>
                    <td>No Data Found....</td>
                </tr>
        }

        return (
            <>
                <Sidebar />
                <div className="main">
                    <div className="topbar">
                        <Navbar />
                    </div>

                    <div className="container divider-12">
                        <BackAndBreadCrmOneParent backLink="/view-card" backName="View card" currentTabName="Election result" />
                        <div className="row">
                            <div className="col-6" >
                                <div style={{ display: "flex" }}>
                                    {/* <div id="stateDiv" style={{ width: "1500px" }}>
                                        <label htmlFor="states" className="control-label">Select State</label>
                                        <select id="states" className="form-select" name="state" onChange={this.ChangeCity} >
                                            <option>--Choose State--</option>
                                            <option value="MH">Maharashtra</option>
                                            <option value="GA">Goa</option>
                                        </select>
                                    </div>

                                    <div style={{ marginLeft: "20px", marginTop: "15px", width: "1500px" }}>
                                        <label htmlFor="citys" id="cityLabel" className="control-label">Select City</label>
                                        <select id="citys" name="district" className="form-select" onChange={this.showCity}>
                                            <option>--Choose City--</option>
                                            {this.state.CityData.map((e, key) => {
                                                return <option key={key} value={e.countryCode}> {e.name}  </option>;
                                            })}
                                        </select>
                                    </div>

                                    <div style={{ marginLeft: "15px", marginTop: "15px", width: "1300px" }}>
                                        <label htmlFor="type" className="control-label">Type</label>
                                        <select id="type" className="form-select" name="type">
                                            <option>--Choose Type--</option>
                                            <option value="rural">Rural</option>
                                            <option value="urban">Urban</option>
                                        </select>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                        <div className="col-6 mt-3">
                            {
                                candidate_data_list
                            }
                        </div>

                        <div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
