import React from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import { Add } from "@material-ui/icons";
import LoaderRounded from "./LoaderRounded";
import profileImage from "../Assets/images/noimahe.png";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import ReactPaginate from "react-paginate";
import { Col, Row, Modal, Button } from "react-bootstrap";
import * as XLSX from "xlsx";
import { connect } from "react-redux";
import { setSelectedEvent } from "../Redux/Slice/UserDataSlice";
import Form from 'react-bootstrap/Form';
import EventTransfer from "./EventTransfer";

class GetMemberList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      event_id: this.props.selected_event.event_id
        ? this.props.selected_event.event_id
        : "",
      first_name: "",
      event: this.props.selected_event,
      college_id: "",
      last_name: "",
      profile_image: [],
      college_name: "",
      city: "",
      cities: [],
      memberList: [],
      CityData: [],
      loader: false,
      eventLoader: false,
      counts: "",
      totalPages: "",
      previousLimits: 100,
      renderPageLimit: 10,
      allpage: "",
      selectedPage: 0,
      limitVal: 10,
      view_count: 0,
      page_selected: 0,
      filterListByMobile: "",
      filterdata: [],
      candidate: [],
      modalShow: false,
      dropDownValue: "",
      eventList: []
    };
    this.listBycity = this.listBycity.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.CustomExcel = this.CustomExcel.bind(this);
    this.downloadExcel = this.downloadExcel.bind(this);
    this.getEventList = this.getEventList.bind(this)
    this.hanldedivchange = this.hanldedivchange.bind(this);
  }

  handlePageChange(page) {
    let val = page.selected === 0 ? 0 : page.selected * this.state.limitVal;
    this.setState({
      view_count: val,
      page_selected: page.selected,
    });
    this.getMemberListbyeventid(
      this.state.limitVal,
      val,)
  }

  hanldedivchange(event) {
    this.setState({
      limitVal: event,
      selectedPage: 0,
    });
    this.getMemberListbyeventid(
      event,
      this.state.view_count,

    );
  }

  componentWillMount() {
    setTimeout(() => this.setState({ loading: false }), 1000);
    this.getEventList()
  }

  //code for to get member lists
  componentDidMount() {
    this.getMemberListbyeventid(
      this.state.limitVal,
      this.state.view_count
    );
    this.getEventList()
  }

  showCity() {
    axios
      .get(process.env.REACT_APP_FOXBERRY_STG_API + "/address/city/MH")
      .then((response) => {

        this.setState({
          CityData: response.data,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }
  selectCity = (e) => {
    //    e.preventDefault();
    let city = e.currentTarget.value;
  };

  handleChange = (e) => {
    this.setState({ filterListByMobile: e.target.value });
  };

  selectCity = (e) => {
    //    e.preventDefault();
    let city = e.currentTarget.value;
    this.addCity(city);
  };

  listBycity = async (event) => {
    event.preventDefault();
    this.setState({
      city: event.target.value,
      memberList: [],
    });

    let currentComponent = this;
    await axios
      .get(
        process.env.REACT_APP_TALK_API +
        "event/get/event/member-details/adminpanel?EventId=" +
        this.state.event.event_id +
        `&city=` +
        event.target.value
      )
      .then((res) => {
        currentComponent.setState({
          memberList: res.data.data,
          filterdata: res.data.data,
          candidate: res.data.data,
        });
      });
  };

  getEventList = async (renderPageLimit, selectedPage) => {
    this.setState({
      eventLoader: true
    })
    await axios.get(process.env.REACT_APP_TALK_API + `/event/all/eventhead/web?limit=${10}&view_count=${20}&year=${""}&cities=${"*"}`)
      .then((res) => {
        this.setState({
          eventList: res.data.data,
          eventLoader: false
        })
      })
      .catch((error) => {
        console.log("error", error);
      })
  }

  getMemberListbyeventid(limitVal, skip_value) {
    let currentComponent = this;
    axios
      .get(
        process.env.REACT_APP_TALK_API +
        `/event/get/event/member-details/adminpanel/registered?viewCounts=${skip_value}&limits=${limitVal}&event_id=${this.state.event.event_id}`
      )
      .then((res) => {
        let con = Math.ceil(
          parseInt(res?.data?.count) / this.state.limitVal
        );
        let memberList = res.data.data;
        currentComponent.setState({
          allpage: con,
          memberList: memberList,
          filterdata: memberList,
          candidate: memberList,
          loader: false,
        });
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  // getMemberListbyeventid() {
  //   let currentComponent = this;
  //   axios
  //     .get(
  //       process.env.REACT_APP_TALK_API +
  //       `/event/get/event/member-details/` +
  //       this.state.event.event_id
  //     )
  //     .then((res) => {
  //       console.log("res.data.data;",res.data);
  //       let memberList = res.data.data;
  //       currentComponent.setState({
  //         memberList: memberList,
  //         filterdata: memberList,
  //         candidate: memberList,
  //         loader: false,
  //       });
  //     });
  // }

  handleFormChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  downloadExcel = (customHeadings) => {
    let dataSet = [];
    dataSet = customHeadings;
    const worksheet = XLSX.utils.json_to_sheet(dataSet);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "WebinarReport.xlsx");
  };

  CustomExcel = () => {
    if (this.state.memberList && this.state.memberList.length > 0) {
      const customHeadings = this.state.memberList.map((item) => ({
        "Event Name": this.state.event.event_title,
        Name:
          item.first_name && item.last_name
            ? item.first_name + " " + item.last_name
            : "-",
        Mobile: item.mobile ? item.mobile : "-",
        City: item.city ? item.city : "-",
        Gender: item.gender ? item.gender : "-",
        "College Name": item.college_name ? item.college_name : "-",
        // "Webinar Date":item.createdAt ? moment(item.createdAt).format("DD MMM YYYY hh:mm a") : '-'
      }));

      this.downloadExcel(customHeadings);
    } else {
      alert("No data Found");
    }
  };

  searchList(char) {
    let f = this.state.filterdata?.filter((item) =>
      item?.mobile?.toString().toLowerCase()?.startsWith(char?.toLowerCase())
    );
    this.setState({
      candidate: f,
    });
    console.log("Filter", f);
  }

  render() {

    let member_list = "";
    let i = 0;
    if (this.state.candidate !== null && this.state.candidate !== undefined &&
      this.state.candidate && this.state.candidate.length > 0) {
      member_list = this.state.candidate.map((memberList, id) =>
        memberList ? (
          <tr key={id}>
            <td>{this.state.page_selected * this.state.limitVal + id + 1}</td>
            <td style={{ display: "flex", justifyContent: "center" }}>
              <Col xs={4}>
                {memberList.profile_image &&
                  memberList.profile_image.length > 0 ? (
                  <img
                    src={memberList.profile_image}
                    width="50"
                    height="50"
                    style={{ borderRadius: "50%" }}
                    alt="profilephoto"
                  />
                ) : (
                  <img
                    src={profileImage}
                    width="50"
                    height="50"
                    style={{ borderRadius: "50%" }}
                    alt="noprofile"
                  />
                )}
              </Col>
              <Col xs={8}>
                {memberList.first_name} {memberList.last_name}
              </Col>
            </td>
            <td> {memberList.mobile} </td>
            {/* <td className="noselect"> {memberList.city} </td> */}
            <td style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              {memberList.city}{" "}
            </td>
            <td> {memberList.college_name} </td>
            <td>
              <EventTransfer tranferFrom={memberList} eventId={this.state.event_id} eventList={this.state.eventList} />
            </td>
          </tr>
        ) : (
          ""
        )
      );
    } else {
      member_list = (
        <tr>
          <td>No Data Found....</td>
        </tr>
      );
    }

    return (
      <>
        <div>
          <Sidebar />
          <div className="main">
            <div className="topbar">
              <Navbar title={"Member List"} />
            </div>

            <div>
              <Link
                to={{
                  pathname: "/addattendee",
                }}
                onClick={() =>
                  this.props.Setseleted_event(this.props.selected_event)
                }
                className="btn btn-secondary btn-sm"
                style={{
                  float: "right",
                  marginRight: "40px",
                  marginTop: "20px",
                }}
              >
                <Add />
                Add New Attendee
              </Link>
              <button
                className="btn btn-dark btn-sm float-right"
                style={{
                  float: "right",
                  marginTop: "20px",
                  marginRight: "5px",
                }}
                onClick={this.CustomExcel}
              >
                Export Data
              </button>

              {/* <select type="button" className="mx-2 btn btn-secondary btn-md dropdown-toggle" id="dropdown" style={{ float: "right", marginRight: "40px", marginTop: "20px" }} onChange={this.listBycity}>
                                    <option value={null}> -- City-- </option>
                                    {this.state.CityData.map((e, key) => {
                                        return <option key={key} value={e.name}>{e.name}  </option>;
                                    })}
                                </select> */}
            </div>

            <div className="container">
              <BackAndBreadCrmOneParent
                backLink="/eventlist"
                backName="Event List"
                currentTabName="Event List"
              />
              {/* <BackAndBreadCrmOneParent backLink="/view-card" backName="View card" currentTabName="Election List"/> */}
              <div className="row">
                <div className="col-12">
                  <h2 className="text-center">
                    {this.state.event.event_title}
                  </h2>
                  <hr />
                </div>
              </div>
            </div>

            <Row style={{ marginBottom: "15px", display: "flex", justifyContent: "end" }}>
              <Col md={4} className="mb-2 mb-sm-0">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <span>Records Per Page:</span>
                  <Form.Control
                    className="w-50 ms-1"
                    id="pageselect"
                    as="select"
                    onChange={(event) =>
                      this.hanldedivchange(event.target.value)
                    }
                  >
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="300">300</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                    <option value="5000">5000</option>
                  </Form.Control>
                </div>
              </Col>

              <Col md={3} className="mb-2 mb-sm-0 mx-3">
                <input
                  name="filterListByMobile"
                  placeholder="Search By Mobile"
                  // type="text"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ filterListByMobile: e.target.value });
                    this.searchList(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive-sm">
                    <table className="table table-bordered table-responsive">
                      <thead>
                        <tr className="text-center">
                          <th>No</th>
                          {/* <th>Profile Image</th> */}
                          <th>Full Name</th>
                          <th>Mobile</th>
                          <th>City</th>
                          <th>College Name</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.loader || this.state.eventLoader ?
                          (
                            <>
                              <tr>
                                <td colSpan="6">
                                  <LoaderRounded />
                                </td>
                              </tr>
                            </>
                          ) : (
                            member_list
                          )
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                {this.state.loader || this.state.eventLoader ? null : <ReactPaginate
                  nextLabel="Next"
                  previousLabel="Previous"
                  // pageCount={member_list.length / this.state.renderPageLimit}
                  pageCount={this.state.allpage}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={10}
                  containerClassName="pagination"
                  activeClassName="btn btn-primary"
                  pageClassName="btn"
                  previousClassName="btn"
                  nextClassName="btn"
                  onPageChange={this.handlePageChange}
                />}
              </div>
            </div>
            <button
              className=" reaload btn  btn-success btn-sm "
              onClick={() => window.location.reload(true)}
            >
              Click to reload!
            </button>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selected_event: state.PersistReducer.UserData.selected_event,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Setseleted_event: (data) => dispatch(setSelectedEvent(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GetMemberList);
