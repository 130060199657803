import React, { Component } from "react";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.css";
import logo from "../Assets/images/yin-full.png";
import axios from "axios";
import { Redirect } from "react-router-dom";

class Login extends Component {
  constructor() {
    super();

    this.state = {
      islogged: false,
      mobile: "",
      password: "",
      first_name: "",
      last_name: "",
      profile_image: "",
      email: "",
      college_name: "",
      city: "",
      role: "",
      districts: "",
      errros: "",
      isloggedAccount: false,
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    localStorage.clear();
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn) {
      window.location.href = "/dashboard";
    }
  }

  //code for handle forms input
  handleFormChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleFormValidation = () => {
    const {
      college_name,
      college_type,
      address1,
      address2,
      state_name,
      city_name,
      pincode,
      contact,
      website,
      principle_name,
      principle_number,
      approve_status,
      college_logo,
      select_college_type,
    } = this.state;
    let errors = {};
    let formIsValid = true;

    //empty mobile no
    if (!contact) {
      formIsValid = false;
      errors["contact"] = "*Please enter mobile no.";
    } else if (!contact.match(/[^0-9]/g)) {
      formIsValid = false;
      errors["contact"] = "*Please enter valid mobile no.";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  };




  //code to submit data -------------------------------
  handleSubmit(event) {
    event.preventDefault();

    let formData = new FormData();

    formData.append("mobile", this.state.mobile);
    formData.append("password", this.state.password);

    let statesSet = this;
    axios
      .post(
        process.env.REACT_APP_APIURL + `/coordinator/cordinator-login`,
        formData
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.errors === "No user found") {
            statesSet.setState({
              errros: "Please enter correct credentials!!!",
            });
          } else {
            localStorage.setItem("mobile", this.state.mobile);
            localStorage.setItem("first_name", res.data.user.first_name);
            localStorage.setItem("last_name", res.data.user.last_name);
            localStorage.setItem("email", res.data.user.email);
            localStorage.setItem("profile_image", res.data.user.profile_image);
            localStorage.setItem("city_name", res.data.user.city);
            if (res.data.user.sub_city) {
              localStorage.setItem("sub_city_name", res.data.user.sub_city);
            }
            localStorage.setItem("college_name", res.data.user.college_name);
            localStorage.setItem("role", res.data.user.role);
            localStorage.setItem(
              "districts",
              JSON.stringify(res.data.user.districts)
            );
            localStorage.setItem("isLoggedIn", true);

            if (res.data.user.is_accountent) {
              statesSet.setState({
                isloggedAccount: true,
              });
            } else {
              statesSet.setState({
                islogged: true,
              });
            }
          }
        } else {
          // alert("Invalid credetials");
          statesSet.setState({
            errros: "Please enter correct credentials!!!",
          });
        }
      })
      .catch((error) => {
        statesSet.setState({
          errros: "Please enter correct credentials!!! or Try again later...",
        });
      });

  }

  render() {
    if (this.state.islogged) {
      return <Redirect to="/dashboard" />;
    }

    if (this.state.isloggedAccount) {
      return <Redirect to="/view/qrcode/list" />;
    }
    return (
      <div className="row m-2">
        <div className="col-md-4 col-12 m-auto p-0">
          <form
            id="loginForm"
            className="selectBox p-4"
            onSubmit={this.handleSubmit}
            method="post"
            encType="multipart/form-data"
          >
            <div className="col-12 m-auto p-0 text-center">
              <img src={logo} alt="YIN Logo" width="300px" />
            </div>
            <hr />
            <h2 className="text-center">Sign In</h2>
            <br />
            <div className="form-group">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <span
                      className="fa fa-user icon"
                      style={{ height: "30px" }}
                    ></span>
                  </span>
                </div>
                <input
                  type="text"
                  maxLength={10}
                  name="mobile"
                  className="form-control"
                  onChange={this.handleFormChange}
                  placeholder="Enter Mobile Number"
                  required={true}
                />
              </div>
            </div>
            <br />
            <div className="form-group">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i
                      className="fa fa-lock icon"
                      style={{ height: "30px" }}
                    ></i>
                  </span>
                </div>
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  onChange={this.handleFormChange}
                  placeholder="Enter Password"
                  required={true}
                />
              </div>
            </div>
            <div className="form-group text-center">
              <div className="errorMsg divider-20">{this.state.errros}</div>
              <button
                type="submit"
                name="submit"
                className="btn btn-primary btn-padding-new divider-10"
              >
                Log in
              </button>
              {/* <div className="errorMsg divider-20">Your log in has been disabled for until further notice.</div> */}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default Login;
