import React, { Component } from 'react';
import axios from 'axios';

export default class ValidateIdcards extends Component {


    constructor(props) {
        super(props);
        this.state = {
            name: "",
            mobile: "",
            collegeName: "",
            first_Name: '',
            last_Name: '',
            date: "",
            upload_photo: "",
            url: "",
            start_time: '',
            end_time: '',
            card_url: '',
            students: [],
            idcard: [],
            image_url_set: '',
            image_url_id: '',
            isChecked: false,
            doneIndex: 0,
            doneId: '',
            operatorData: JSON.stringify(localStorage.getItem("operator")),
            isLoading: true,
            operatorEmailAddress: 'true',
            selected_date: '2021-11-30T',

        };
        this.handleChange = this.handleChange.bind(this);
        this.getUserInformation = this.getUserInformation.bind(this);
        this.getUserInfo = this.getUserInfo.bind(this);
        this.updateData = this.updateData.bind(this);
        this.handlePhotoChange = this.handlePhotoChange.bind(this);
        this.imageDone = this.imageDone.bind(this);
        this.imageReject = this.imageReject.bind(this);
        this.setImageUrl = this.setImageUrl.bind(this);
        this.searchByName = this.searchByName.bind(this);
        this.unMatchData = this.unMatchData.bind(this);
        this.setIndexValue = this.setIndexValue.bind(this);
    }
    doSomethingBeforeUnload = () => {
        // Do something
        alert("--------unload browser---------");
    }

    // Setup the `beforeunload` event listener
    setupBeforeUnloadListener = () => {
        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
            return this.doSomethingBeforeUnload();
        });
    };

    componentDidMount() {
        // Activate the event listener
        this.setupBeforeUnloadListener();
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });

    }
    handlePhotoChange(event) {
        this.setState({
            card_url: event.target.value,
        })
    }

    setImageUrl(event) {
        this.setState({
            image_url_set: event.target.value,
            image_url_id: event.target.id,
            isChecked: true,
        });

    }

    setIndexValue(indexValue, idDone) {
        this.setState({
            doneIndex: indexValue,
            doneId: idDone,
        });
    }

    UNSAFE_componentWillMount() {
        let operator = JSON.parse(localStorage.getItem("operator"));
        let operatorEmailAddress = '';
        try {
            operatorEmailAddress = operator.email;
            this.setState({ operatorEmailAddress, isLoading: false });
        } catch (error) {
            console.error(error);
            window.location.href = "/operator/login";
            return;
        }
        this.getUserInformation();
    }

    async getUserInformation() {
        axios.get(process.env.REACT_APP_APIURL + `/coordinator/get/user/data/date?start_time=12:01:00&end_time=12:05:00&date=` + this.state.selected_date)
            .then(res => {
                let studentsInfo = res.data.studentData;
                let idCard = res.data.idcardData;
                this.setState({
                    students: studentsInfo,
                    idcard: idCard
                });
            });
    }

    async getUserInfo() {
        this.setState({
            students: [],
            idcard: []
        });

        axios.get(process.env.REACT_APP_APIURL + `/coordinator/get/user/data/date?start_time=` + this.state.start_time + `:00&end_time=` + this.state.end_time + `:00&date=` + this.state.selected_date)
            .then(res => {
                let studentsInfo = res.data.studentData;
                let idCard = res.data.idcardData;
                this.setState({
                    students: studentsInfo,
                    idcard: idCard
                });

            });
    }

    async updateData(student) {
        //    alert(student);
        if (this.state.image_url_set === "") {
            alert("Please select image");
        } else {
            this.setState({ student })
            let studentUpdatData = {
                yin_id: student,
                collegeidcard: this.state.image_url_set,
            }
            axios.post(process.env.REACT_APP_APIURL + `/coordinator/update/user/college/idcard`, studentUpdatData)
                .then(res => {
                    this.imageDone(this.state.doneIndex, this.state.doneId);
                    this.log('ADD_URL', student, this.state.image_url_id);
                    this.setState({
                        image_url_set: '',
                        isChecked: false,
                        doneIndex: '',
                        collegeidcard: '',
                        doneId: ''
                    });
                    alert("Data updated");

                }).catch((error) => {
                    console.log("Error", error);
                });
        }

    }

    async imageDone(doneIndex, done) {
        //    alert(done);
        let studentUpdatData = {
            id: done,
        }
        axios.post(process.env.REACT_APP_APIURL + `/coordinator/update/image/card/done`, studentUpdatData)
            .then(res => {
                this.setState({
                    image_url_set: '',
                    isChecked: false
                });
                this.log('IMAGE_DONE', this.state.student, done);
                let datas = this.state.idcard.filter((e, i) => i !== doneIndex);
                this.setState({ idcard: datas });
            }).catch((error) => {
                console.log("----------", error);
            });
    }

    async imageReject(rejectIndex, reject) {
        //    alert(reject);
        let studentUpdatData = {
            id: reject,
        }
        axios.post(process.env.REACT_APP_APIURL + `/coordinator/update/image/card/reject`, studentUpdatData)
            .then(res => {
                this.log('IMAGE_REJECTED', 'REJECTED-IMAGE', reject);
                //alert("id card rejected");
                let datas = this.state.idcard.filter((e, i) => i !== rejectIndex);
                this.setState({ idcard: datas });

            }).catch((error) => {
                console.log("Error", error);
            });
    }

    async unMatchData(unMatchIndex, unmatch) {
        let studentUpdatData = {
            id: unmatch,
        }
        axios.post(process.env.REACT_APP_APIURL + `/coordinator/update/image/card/unmatch`, studentUpdatData)
            .then(res => {
                this.log('IMAGE_UNMATCH', 'UNMATCHED-IMAGE', unmatch);
                let datas = this.state.idcard.filter((e, i) => i !== unMatchIndex);
                this.setState({ idcard: datas });
            }).catch((error) => {
                console.log("Error", error);
            });
    }

    async searchByName() {
        let FirstAndLast = {
            firstName: this.state.first_Name,
            lastName: this.state.last_Name,
            mobile: this.state.mobile
        }
        if (this.state.first_Name === "" && this.state.last_Name === "" && this.state.mobile === "") {
            alert("Please enter first or last name");
        } else {
            // axios.post(process.env.REACT_APP_APIURL+`/coordinator/search/user/name`,FirstAndLast)
            axios.post(process.env.REACT_APP_APIURL + `/coordinator/search/user/name`, FirstAndLast)
                .then(res => {
                    let studentsInfo = res.data;
                    console.log("students list by college wise", studentsInfo);
                    this.setState({
                        students: studentsInfo,
                    });
                });
        }

    }

    async log(type, user, id) {
        let operatorData = JSON.parse(localStorage.getItem('operator'));
        let operator = operatorData.email;
        let params = {
            type, user, id, operator
        }
        try {
            await axios.post(process.env.REACT_APP_APIURL + `/audit/ops/log`, params)
        } catch (error) {
            console.log('Error', error);
        }
    }



    render() {
        if (this.state.isLoading) {
            return <>Loading</>;
        }
        let student_list;
        let college_idCard;

        student_list = this.state.students.map((student, index) =>
            <div className=" mx-auto" key={index}>
                <form className="form-control p-2">
                    <input type="text" name="yin_id" value={student.yin_id} className="form-control mb-2" readOnly={true} />
                    <div className="col-md-12 mb-2">
                        <h3>{student.first_name} {student.last_name}</h3>
                    </div>
                    <div className="col-md-12 mb-2">
                        <span>{student.mobile}</span>
                    </div>
                    <div className="col-md-12 mb-2">
                        <span>{student.city}</span>
                    </div>
                    <div className="col-md-12 mb-2">
                        <h2>{student.college_name}</h2>
                    </div>
                    <div className="col-md-12 mb-2">
                        <span>{student.created_at}</span>
                    </div>
                    <div className="textOnInput">
                        <div className="col-md-12 mt-4">
                            <input className="form-control" name="card_url" id="idCard" type="text" value={this.state.image_url_set} readOnly={true}></input>
                        </div>
                    </div>
                    <div className="form-group text-center mb-5">
                        <button type="button" onClick={() => this.updateData(student.yin_id)} className="btn btn-primary btn-padding-new divider-20">Add URL</button>
                    </div>
                </form>
            </div>
        )


        college_idCard = this.state.idcard.map((card, index) =>
            <div className="mt-2" key={index}>
                <div style={{ display: "flex" }} >
                    <img src={card.url} alt="logo1" style={{ marginLeft: "40px", width: "100%", height: "700px" }} />
                </div>
                <div className="mb-2 mt-2">
                    <input style={{ marginLeft: "20px" }} type="checkbox" id={card._id} name="card_url" value={card.url} onChange={(event) => { this.setImageUrl(event); this.setIndexValue(index, card._id) }} checked={this.state.isChecked} />
                    <label htmlFor={card._id}>Select Image url </label>
                    {/* <span className="setUnselect" style={{marginLeft:"10px"}}>{card.url}</span> */}
                </div>
                <div className="mb-2 mt-3">
                    <button type="button" className="m-1 btn btn-success" onClick={() => this.imageDone(index, card._id)} >Done</button>
                    <button type="button" className="m-1 btn btn-danger" onClick={() => this.imageReject(index, card._id)}>Reject</button>
                    <button type="button" className="m-1 btn btn-warning" onClick={() => this.unMatchData(index, card._id)}>Un Match</button>
                </div>
            </div>
        )

        return (
            <div className="p-4">
                <div className="row mx-auto" >
                    <div className="col-8 border">
                        <div>
                            <label htmlFor="selected_date">Select a date</label>
                            <select className="form-control" name="selected_date" id="selected_date" onChange={this.handleChange}>
                                <option value="2021-11-30T">30th November, 2021</option>
                                <option value="2021-12-01T">1st December, 2021</option>
                            </select>
                        </div>
                        <div className="row">
                            <div className="col-4 border">
                                <label htmlFor="start_time">Select Start Time:Ex- 06:00</label>
                                <input type="text" name="start_time" onChange={this.handleChange} id="start_time" className="form-control" placeholder="Ex- 06:00" />
                            </div>
                            <div className="col-4 border">
                                <label htmlFor="end_time">End Time:Ex- 06:10</label>
                                <input type="text" name="end_time" onChange={this.handleChange} id="end_time" className="form-control" placeholder="Ex- 06:00" />
                            </div>
                            <div className="col-4 text-center">
                                <button onClick={this.getUserInfo} className="btn btn-warning btn-lg mt-3">Get Data</button>
                            </div>

                        </div>
                    </div>
                    <div className="col-4 border">
                        <div className="row">
                            <div className="col-6">
                                <input type="text" name="first_Name" onChange={this.handleChange} id="firstName" className="form-control" placeholder="First name" />
                            </div>
                            <div className="col-6">
                                <input type="text" name="last_Name" onChange={this.handleChange} id="lastName" className="form-control" placeholder="Last name" />
                            </div>
                            <div className="col-12 mt-2 mb-2">
                                <input type="text" name="mobile" onChange={this.handleChange} id="mobile" className="form-control" placeholder="Search mobile number" />
                            </div>
                            <div className="col-12 mt-2 text-center">
                                <button onClick={this.searchByName} className="btn btn-success btn-sm">Search</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row d-flex">
                    <div className="col-8" style={{ height: "700px", overflow: "scroll" }}>{college_idCard}</div>
                    <div className="col-4" style={{ height: "700px", overflow: "scroll" }}>{student_list}</div>

                </div>



                {/* <div className="col-6 mt-2">
                        <div className=" mx-auto">
                            <form className="form-control p-2" >
                            <input type="text" name="yin_id" value="MHAKO00023" className="form-control mb-2" readOnly={true}/>
                                <div className="col-md-12 mb-2">
                                    <h3>Name</h3>
                                </div>

                                <div className="col-md-12 mb-2">
                                    <span>Mobile</span>
                                </div>


                                <div className="col-md-12 mb-2">
                                    <h2>CollegeName</h2>
                                </div>


                                <div className="col-md-12 mb-2">
                                    <span>Date</span>
                                </div>

                                <div className="textOnInput">
                                    <div className="col-md-12 mt-4">
                                    <label htmlFor="idCard">Add College id Card</label>
                                    <input className="form-control" id="idCard" type="text" placeholder="Upload id card AWS url here"></input>
                                    </div>
                                </div>
                                <div className="form-group text-center mb-5">
                                    <button type="submit" name="submit" className="btn btn-primary btn-padding-new divider-20">Add URL</button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="col-6 mt-2">
                        <div style={{ display: "flex" }} >
                            <img src={logo1} alt="logo1" style={{ marginLeft: "40px", width: "100%", height: "400px" }} />
                        </div>
                        <div className="mb-2 mt-2">
                            <span style={{marginLeft:"50px"}}> htttp/Assets/images/profileimage.jpg </span>
                        </div>
                    </div> */}


            </div>
        )
    }
}
