import React, { Component } from "react";
import "../Assets/css/Addcollege.css";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { Redirect } from "react-router-dom";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRounded from "./LoaderRounded";

export default class Editcollege extends Component {
  constructor(props) {
    super(props);
    this.state = {
      college_id: "",
      college_name: localStorage.getItem("college_name"),
      type: "",
      college_code: "",
      college_type: "",
      select_college_type: "",
      address1: "",
      address2: "",
      states: "",
      city: "",
      district: "",
      pincode: "",
      contact: "",
      website: "",
      principle_name: "",
      principle_number: "",
      approve_status: "",
      college_logo: "",
      //for dropdown purpose
      state_code: "",
      city_code: "",
      states: "",
      city_name: "",
      sub_city: "",
      StateData: [],
      CityData: [],
      errors: [],
      country: "IN",
      approver_status: "",
      status: true,
      college_key: "",
      changeToView: false,
      redirect: false,
      loader: false,
      year: this.props.location.state?.year || null,
      cities: this.props.location.state?.city || null,

    };
    this.initialState = this.state;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.ChangeCity = this.ChangeCity.bind(this);
    this.showCity = this.showCity.bind(this);
    this.imageChangedHandler = this.imageChangedHandler.bind(this);
    this.deleteCollege = this.deleteCollege.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.location.query) {
      localStorage.setItem(
        "college_name",
        this.props.location.query.college_name
      );
      this.setState({
        college_name: localStorage.getItem("college_name"),
        college_code: localStorage.getItem("college_code"),
      });
    } else {
      this.setState({
        college_name: localStorage.getItem("college_name"),
        college_code: localStorage.getItem("college_code"),
      });
    }
    let collegName = localStorage.getItem("college_name");
    let collegeCode = localStorage.getItem("college_code");
    this.getData();
    this.showCity();
  }

  getData() {
    let currentComponent = this;
    let collegeID = localStorage.getItem("college_code");
    axios
      .get(process.env.REACT_APP_APIURL + `/college/college_code/` + collegeID)
      .then((res) => {
        let responses = res.data;
        currentComponent.setState({
          college_id: responses[0]._id,
          college_code: responses[0].college_code,
          college_name: responses[0].college_name,
          select_college_type: responses[0]?.college_type,
          address1: responses[0].address1,
          address2: responses[0].address2,
          states: responses[0].state,
          city_name: responses[0].city,
          pincode: responses[0].pincode,
          contact: responses[0].contact,
          website: responses[0].website,
          principle_name: responses[0].principle_name,
          principle_number: responses[0].principle_number,
          college_logo: responses[0].college_logo,
          type: responses[0].type,
          country: "IN",
          approver_status: responses[0].approver_status,
          status: true,
          college_key: responses[0].college_key,
        });
      });
  }

  // title for form
  getTitle() {
    return <h3 className="text-center">Edit College</h3>;
  }

  // handleChange(event) {
  //   console.log({
  //     [event.target.name]: event.target.value,
  //   });
  //   this.setState({
  //     [event.target.name]: event.target.value,
  //   });
  // }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  //upload image and resize
  imageChangedHandler(event) {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      this.setState({
        images: event.target.files[0],
      });
    }
  }

  ChangeCity = (e) => {
    e.preventDefault();
    axios
      .get(process.env.REACT_APP_APIURL + "/address/city/" + e.target.value)
      .then((response) => {
        this.setState({
          CityData: response.data,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  showCity() {
    axios
      .get(process.env.REACT_APP_APIURL + "/address/city/MH")
      .then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          this.setState({
            CityData: response.data,
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }

  handleFormValidation = () => {
    const {
      college_name,
      select_college_type,
      address1,
      states,
      city_name,
      pincode,
      contact,
      principle_number,
      principle_name,
      website,
    } = this.state;
    let errors = {};
    let formIsValid = true;
    let errorFields = []; // Array to store field names with errors

    // Empty college name
    if (!college_name) {
      formIsValid = false;
      errors["college_name"] = "Please enter college name.";
      errorFields.push("college_name"); // Add field name to the errorFields array
    }

    // Empty college type
    if (!select_college_type) {
      formIsValid = false;
      errors["select_college_type"] = "Please select college type.";
      errorFields.push("select_college_type");
    }

    // Empty address1
    if (!address1) {
      formIsValid = false;
      errors["address1"] = "Please enter address.";
      errorFields.push("address1");
    }

    // Empty state
    if (states === "" || states === "--Choose State--") {
      formIsValid = false;
      errors["states"] = "Please Choose State.";
      errorFields.push("states");
    }

    // Empty city
    if (city_name === "" || city_name === "--Choose City--") {
      formIsValid = false;
      errors["city_name"] = "Please Choose City.";
      errorFields.push("city_name");
    }

    // Empty pincode
    if (pincode === "") {
      errors["pincode"] = "Please enter pincode.";
      formIsValid = false;
      errorFields.push("pincode");
    }

    let regforContact = /^[0-9]{10}$/;

    if (!contact) {
      formIsValid = false;
      errors["contact"] = "Please enter mobile no.";
      errorFields.push("contact");
    }
    // if (!website) {
    //   errors["website"] = "Please enter website.";
    //   formIsValid = false;
    //   errorFields.push("website");
    // }
    // if (!principle_number) {
    //   errors["principle_number"] = "Please enter principle_number.";
    //   formIsValid = false;
    //   errorFields.push("principle_number");
    // }
    // if (!principle_name) {
    //   errors["principle_name"] = "Please enter principle_name.";
    //   formIsValid = false;
    //   errorFields.push("principle_name");
    // }
    //   principle_number
    //   principle_name
    //   website
    this.setState({
      errors: errors,
    });

    // Focus on the first input field with an error message
    if (errorFields.length > 0) {
      const firstErrorField = document.querySelector(
        `[name="${errorFields[0]}"]`
      );
      if (firstErrorField && firstErrorField.focus) {
        firstErrorField.focus();
      }
    }

    return formIsValid;
  };

  //save form data
  handleSubmit(event) {
    event.preventDefault();

    if (this.handleFormValidation()) {
      let formData = new FormData();
      formData.append("college_id", this.state.college_id);
      formData.append("college_name", this.state.college_name);
      formData.append("college_code", this.state.college_code);
      formData.append("select_college_type", this.state.select_college_type);
      formData.append("type", this.state.type);
      formData.append("address1", this.state.address1);
      formData.append("address2", this.state.address2);
      formData.append("state", this.state.states ? this.state.states : "MH");
      formData.append("city", this.state.city_name ? this.state.city_name : "");
      formData.append(
        "sub_city",
        this.state.sub_city ? this.state.sub_city : ""
      );
      formData.append("pincode", this.state.pincode ? this.state.pincode : "");
      formData.append("contact", this.state.contact ? this.state.contact : "");
      formData.append("website", this.state.website ? this.state.website : "");
      formData.append(
        "principle_name",
        this.state.principle_name ? this.state.principle_name : ""
      );
      formData.append(
        "principle_number",
        this.state.principle_number ? this.state.principle_number : ""
      );
      formData.append("approver_status", this.state.approver_status);
      formData.append("college_logo", this.state.college_logo);
      formData.append("updated_by", "coordinator");
      let statesSet = this;

      //post college data
      axios
        .post(
          process.env.REACT_APP_APIURL + `/coordinator/college/update`,
          formData
        )
        .then((res) => {
          if (res.status === 200) {
            alert("College update successfully");
            statesSet.setState({
              changeToView: true,
              loader: true,
            });
          }
        })
        .catch((err) => {
          console.log("err in update", err);
        });
    } else {
      this.handleFormValidation();
    }
  }

  deleteCollege() {
    let formData = new FormData();
    formData.append("college_code", this.state.college_code);

    // axios.post(process.env.REACT_APP_APIURL+"/coordinator/college/delete",formData)
    axios.post(process.env.REACT_APP_APIURL + `/coordinator/college/delete`, formData)
      .then((res) => {
        alert("College deleted!!");
        this.setState({
          redirect: true,
        });
      })
      .catch((error) => {
        console.log("error in delete college ----------- ", error);
      });
  }

  render() {
    const { year } = this.state;
    const { cities } = this.state

    if (this.state.changeToView) {
      return <Redirect to={{ pathname: '/collegeList', state: { year, cities } }} />;
      // return <Redirect to="/collegeList" />;
    }

    if (this.state.redirect) {
      return <Redirect to={{ pathname: '/collegeList', state: { year } }} />;
      // return <Redirect to="/collegeList" />;
    }

    let subCitys = localStorage.getItem("sub_city_name") ? (
      <div id="stateDiv" className="col-md-6 col-12 pt-md-0 pt-4">
        <div className="form-group">
          <label htmlFor="subCity" className="control-label">
            Sub City
          </label>
          <select
            id="subCity"
            name="sub_city"
            className="form-select"
            onChange={this.handleChange}
          >
            <option>--Choose your sub city--</option>
            <option value="PCMC">PCMC</option>
            <option value="PUNE_CITY">PUNE_CITY</option>
            <option value="PUNE_RURAL">PUNE_RURAL</option>
          </select>
          <div className="errorMsg">{this.state.errors.subCity}</div>
        </div>
      </div>
    ) : (
      ""
    );

    return (
      <>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>
          <div className="container">
            <BackAndBreadCrmOneParent
              backLink="/collegeList"
              backName="College List"
              currentTabName="Edit College"
              param1={year}
              param2={cities}
            />
            <div className="row mt-4">
              <div className="col-lg-8 col-md-8 col-12 paddingAll10 mx-auto">
                <div className="card rounded shadow shadow-sm">
                  <div className="card-header">
                    <h3 className="mb-0"> Edit College Info</h3>
                  </div>
                  <div className="card-body">
                    <form
                      id="formCollege"
                      onSubmit={this.handleSubmit}
                      method="post"
                      encType="multipart/form-data"
                    >

                      <div className="row">
                        <div id="collegenameDiv" className="form-group">
                          <label htmlFor="college_name" className="control-label">
                            <span style={{ color: "#ff0000" }}>*</span>College
                            Name
                          </label>
                          <input
                            type="text"
                            id=""
                            value={this.state.college_name}
                            name="college_name"
                            className="form-control rounded-1"
                            onChange={this.handleChange}
                            placeholder="College Name"
                          />
                          <div className="errorMsg">
                            {this.state.errors.college_name}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xxl-6 col-lg-12 col-md-12 col-12">
                          <div id="collegetypeDiv" className="form-group">
                            <label htmlFor="collegetypes" className="control-label">
                              <span style={{ color: "#ff0000" }}>*</span>Select
                              College Type
                            </label>
                            <select
                              id="collegetypes"
                              name="type"
                              className="form-select"
                              onChange={this.handleChange}
                              value={this.state.type}
                            // ? this.state.type.charAt(0).toUpperCase() +
                            //   this.state.type.slice(1).toLowerCase()
                            // : ""}
                            >
                              {/* <option value={this.state.type}>
                                {this.state.type
                                  ? this.state.type.charAt(0).toUpperCase() +
                                    this.state.type.slice(1).toLowerCase()
                                  : ""}
                              </option> */}
                              <option value="RURAL">Rural</option>
                              <option value="URBAN">Urban</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-lg-12 col-md-12 col-12">
                          <div id="collegetypeDiv" className="form-group">
                            <label
                              htmlFor="select_college_type"
                              className="control-label"
                            >
                              <span style={{ color: "#ff0000" }}>*</span>Select
                              College Type (Senior college / Junior college ){" "}
                            </label>
                            {/* <select
                              id="select_college_type"
                              name="select_college_type"
                              className="form-select"
                              onChange={this.handleChange}
                              required={true}
                            >
                              <option value={this.state.college_type}>
                                {this.state.select_college_type}
                              </option>
                              <option value="JUNIOR">Junior</option>
                              <option value="SENIOR">Senior</option>
                            </select> */}
                            <select
                              id="select_college_type"
                              name="select_college_type"
                              className="form-select"
                              onChange={this.handleChange}
                              required={true}
                              value={this.state.select_college_type}
                            // ? this.state.select_college_type.charAt(0).toUpperCase() +
                            //   this.state.select_college_type.slice(1).toLowerCase()
                            // : ""}
                            >
                              <option value="JUNIOR">Junior</option>
                              <option value="SENIOR">Senior</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6">
                          <div id="address1Div" className="form-group">
                            <label htmlFor="adddress1" className="control-label">
                              <span style={{ color: "#ff0000" }}>*</span>Address1
                            </label>
                            <input
                              type="text"
                              id="address1"
                              value={this.state.address1 ? this.state.address1 : ""}
                              name="address1"
                              className="form-control rounded-1"
                              onChange={this.handleChange}
                              placeholder="Address1"
                            />
                            <div className="errorMsg">
                              {this.state.errors.address1}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-6">
                          <div id="address2Div" className="form-group">
                            <label htmlFor="adddress2" className="control-label">
                              <span style={{ color: "#ff0000" }}>*</span>Address2
                            </label>
                            <input
                              type="text"
                              id="address2"
                              value={this.state.address2 ? this.state.address2 : ""}
                              name="address2"
                              className="form-control rounded-1"
                              onChange={this.handleChange}
                              placeholder="Address2"
                            />
                            <div className="errorMsg">
                              {this.state.errors.address2}
                            </div>

                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6">
                          <div id="stateDiv" className="form-group">
                            <label htmlFor="states" className="control-label">
                              <span style={{ color: "#ff0000" }}>*</span>Select
                              State
                            </label>
                            <input
                              value={this.state.states} disabled
                              className="form-control rounded-1"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                          <div id="stateDiv" className="form-group">
                            <label
                              htmlFor="citys"
                              id="cityLabel"
                              className="control-label"
                            >
                              <span style={{ color: "#ff0000" }}>*</span>Select
                              City
                            </label>
                            <select
                              id="citys"
                              name="city_name"
                              className="form-select"
                              onChange={this.handleChange}
                            >
                              <option>{this.state.city_name}</option>
                              {this.state.CityData.map((e, key) => {
                                return (
                                  <option key={key} value={e.name}>
                                    {e.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>

                      {subCitys}


                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6">
                          <div
                            id="stateDiv"
                            className="form-group"
                          >
                            <label htmlFor="pincode" className="control-label">
                              <span style={{ color: "#ff0000" }}>*</span>Pincode
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="pincode"
                              onChange={this.handleChange}
                              value={this.state.pincode}
                              maxLength={6}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-6">
                          <div id="phoneDiv" className="form-group">
                            <label htmlFor="phone_no" className="control-label">
                              <span style={{ color: "#ff0000" }}>*</span>Mobile
                              Number:
                            </label>
                            <input
                              name="contact"
                              id="contact"
                              onChange={this.handleChange}
                              value={this.state.contact ? this.state.contact : ""}
                              className="form-control"
                              placeholder="Mobile Number"
                              maxLength={10}
                            />
                            <div className="errorMsg">
                              {this.state.errors.contact}
                            </div>
                          </div>
                        </div>
                      </div>



                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6">
                          <div id="websiteDiv" className="form-group">
                            <label htmlFor="website" className="control-label">
                              <span style={{ color: "#ff0000" }}></span>Website
                            </label>
                            <input
                              type="text"
                              id="website"
                              value={this.state.website ? this.state.website : ""}
                              name="website"
                              className="form-control rounded-1"
                              onChange={this.handleChange}
                              placeholder="Website"
                            />
                            <div className="errorMsg">
                              {this.state.errors.website}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                          <div id="priciplenameDiv" className="form-group">
                            <label
                              htmlFor="principle_name"
                              className="control-label"
                            >
                              <span style={{ color: "#ff0000" }}></span>Principal
                              Name
                            </label>
                            <input
                              type="text"
                              id="principle_name"
                              value={
                                this.state.principle_name
                                  ? this.state.principle_name
                                  : ""
                              }
                              name="principle_name"
                              className="form-control rounded-1"
                              onChange={this.handleChange}
                              placeholder="Principle Name"
                            />
                            <div className="errorMsg">
                              {this.state.errors.principle_name}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6">
                          <div id="priciplenumberDiv" className="form-group">
                            <label
                              htmlFor="principle_name"
                              className="control-label"
                            >
                              <span style={{ color: "#ff0000" }}></span>Principal
                              Mobile Number
                            </label>
                            <input
                              maxLength={10}
                              id="principle_number"
                              value={
                                this.state.principle_number
                                  ? this.state.principle_number
                                  : ""
                              }
                              name="principle_number"
                              className="form-control rounded-1"
                              onChange={this.handleChange}
                              placeholder="Principal Contact Number"
                            />
                            <div className="errorMsg">
                              {this.state.errors.principle_number}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                          <div id="approvestatusDiv" className="form-group">
                            <label
                              htmlFor="approve_status"
                              className="control-label"
                            >
                              <span style={{ color: "#ff0000" }}>*</span>Approve
                              Status
                            </label>
                            {/* <input type="text" id="approve_status" value={this.state.approve_status} name="approve_status" className="form-control rounded-1" onChange={this.handleChange} placeholder="Approve Status" /> */}
                            <select
                              id="approve_status"
                              className="form-select"
                              name="approver_status"
                              onChange={this.handleChange}
                            >
                              <option>{this.state.approver_status}</option>
                              <option value="PENDING">Pending</option>
                              <option value="APPROVED">Approved</option>
                              <option value="REJECTED">Rejected</option>
                              {/* <option value="GA">Goa</option> */}
                            </select>
                            <div className="errorMsg">
                              {this.state.errors.approver_status}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center">
                        <button
                          type="submit"
                          name="submit"
                          // id="btnSubmitCollege"
                          className="btn btn-success mt-4 "
                        >
                          {this.state.loader ? (
                            <LoaderRounded />
                          ) : (
                            "Update college"
                          )}
                        </button>{" "}
                        <button
                          type="button"
                          className="btn btn-danger mt-4 mx-3"
                          onClick={this.deleteCollege}
                        >
                          {this.state.loader ? (
                            <LoaderRounded />
                          ) : (
                            "Delete college"
                          )}
                        </button>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
