import axios from "axios";
import React, { Component } from "react";
import Navbar from "./NavbarVerify";
import LoaderRounded from "../LoaderRounded";
import RejectCollegeIDCardPopup from "../VoterVerification/VoterVerifyPopup";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import Select from "react-select";
import { Redirect } from "react-router-dom";
import * as XLSX from "xlsx";

export default class VoterVerify extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      name: "",
      yinId: "",
      firstName: "",
      lastName: "",
      modalInputName: "",
      mobileNumber: "",
      showModalPopup: false,
      buttonFalse: false,
      showSecondmodel: false,
      isShowSecondModelPopupClose: false,
      redirect: false,
      loader: false,
      userData: [],
      userCount: "",
      inProcessCount: 0,
      approvedCount: 0,
      rejectedCount: 0,
      showVoteDisputetpop: false,
      isDisputed: false,
      disabled: false,
      first_name: "Buddy",
      errorCandidate: "",
      last_name: "",
      start_time: "",
      end_time: "",
      deletedIndex: 0,
      rejectReason: [],
      disputedBY: "",
      selectedDate: "",
      approvedIndex: 0,
      CityData: [],
      CollageData: [],
      districtsLocal: JSON.parse(localStorage.getItem("districts")),
      selected_city: "",
      selectedStatus: "",
      college_code: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.storeRejectIDCardCandidateYinID =
      this.storeRejectIDCardCandidateYinID.bind(this);
    this.closeConfirmModal = this.closeConfirmModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.SetCollegeIDCardReason = this.SetCollegeIDCardReason.bind(this);
    this.selectDate = this.selectDate.bind(this);
    this.getMobileNumber = this.getMobileNumber.bind(this);
    this.getCandidateListDate = this.getCandidateListDate.bind(this);
    this.approvedVotes = this.approvedVotes.bind(this);
    this.getCandidateWithCity = this.getCandidateWithCity.bind(this);
    this.getCandidateWithCollege = this.getCandidateWithCollege.bind(this);
    this.getCandidateListByMobileNumber =
      this.getCandidateListByMobileNumber.bind(this);
    this.handleStatusOnChange = this.handleStatusOnChange.bind(this);
    this.log = this.log.bind(this);
    this.reApproveCandidate = this.reApproveCandidate.bind(this)
  }

  UNSAFE_componentWillMount() {
    let operator = JSON.parse(localStorage.getItem("operator"));
    let operatorEmailAddress = "";
    try {
      operatorEmailAddress = operator.email;
      this.setState({ operatorEmailAddress, isLoading: false });
    } catch (error) {
      console.error(error);
      // window.location.href = "/operator/login";
      // return;
    }
    this.showCity();

    const storedDistrict = localStorage.getItem("searchedDistrict");
    const storedCollege = localStorage.getItem("searchedCollege");
    const storedStatus = localStorage.getItem("searchedStatus");

    this.showCollage(storedDistrict ? storedDistrict :"");
    if(storedDistrict || storedStatus || storedCollege){
      this.setState({selected_city : storedDistrict, selectedStatus: storedStatus, college_code : storedCollege})
    }

    this.getCandidateList(
      storedDistrict ? storedDistrict : this.state.selected_city,
      storedStatus ? storedStatus : this.state.selectedStatus,
      storedCollege ? storedCollege : this.state.college_code,
      this.state.limit,
      this.state.skip
    );
  }

  // year dropdown code
  componentDidMount() {
    const currentYear = new Date().getFullYear();
    const initialYears = this.generateYears(2021, currentYear);
    this.setState({ years: initialYears });
  }

  generateYears(start, end) {
    const yearsArray = [];
    for (let year = end; year >= start; year--) {
      yearsArray.push(year);
    }
    return yearsArray;
  }

  handleCompleteYear = () => {
    const currentYear = new Date().getFullYear();
    this.setState((prevState) => ({
      years: [...prevState.years, currentYear + 1],
    }));
  };

  // Status onChange
  handleStatusOnChange(e) {
    this.setState({
      selectedStatus: e.target.value,
    });
    localStorage.setItem("searchedStatus", e.target.value);
    this.getCandidateList(
      this.state.selected_city,
      e.target.value,
      this.state.college_code,
      this.state.limit,
      this.state.skip
    );
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  // district onChange
  getCandidateWithCity(event) {
    console.log("event.target.value", event.target.value);
    this.showCollage(event.target.value);
    let selected_city = event.target.value === "*" ||event.target.value==="All" ? "*" : event.target.value;
    let selectedCollegeCode = event.target.value === "*" ? this.state.college_code === " " : this.state.college_code;

    localStorage.setItem("searchedDistrict", selected_city);
    this.setState({ selected_city: selected_city, college_code: "", });
    this.getCandidateList(selected_city, this.state.selectedStatus, selectedCollegeCode, this.state.limit, this.state.skip);
  }

  // college onChange
  getCandidateWithCollege(event) {
    const selectedCollegeCode = event.target.value;
  
    this.setState(
      { college_code: selectedCollegeCode },
      () => {
        localStorage.setItem("searchedCollege", selectedCollegeCode);
        this.showCity();
        this.getCandidateList(
          this.state.selected_city,
          this.state.selectedStatus,
          selectedCollegeCode,
          this.state.limit,
          this.state.skip
        );
      }
    );
  
    // The rest of your code remains unchanged...
    let operator = JSON.parse(localStorage.getItem("operator"));
    let operatorEmailAddress = "";
    try {
      operatorEmailAddress = operator.email;
      this.setState({ operatorEmailAddress, isLoading: false });
    } catch (error) {
      console.error(error);
    }
    
    const storedDistrict = localStorage.getItem("searchedDistrict");
  
    if (storedDistrict) {
      this.setState({ selected_city: storedDistrict })
    }
  
    this.getCandidateList(
      storedDistrict ? storedDistrict : this.state.selected_city,
      this.state.selectedStatus,
      selectedCollegeCode,
      this.state.limit,
      this.state.skip
    );
  }

  getCandidateList(selected_city, selected_status, college_code) {
    this.setState({
      loader: true,
    });

    console.log("selected_city", selected_city)
    
    axios.get(process.env.REACT_APP_APIURL +
          `/coordinator/user/college_id/unapproved/idcard?cities=${
            selected_city ? selected_city ==="All" ? "*" : selected_city : "*"
          }&status=${selected_status ? selected_status : ""}&college_code=${
            college_code ? college_code : ""
          }`
      )
      .then((response) => {
        this.setState({
          userData: response?.data?.data,
          userCount: response?.data?.count,
          inProcessCount: response?.data?.inProcessCount,
          approvedCount: response?.data?.approvedCount,
          rejectedCount: response?.data?.rejectedCount,
          loader: false,
        });
      })
      .catch((error) => {
        this.setState({
          loader: false,
          noDisputeList: true,
          buttonFalse: true,
        });
      });
  }

  getCandidateListDate() {
    this.setState({
      loader: true,
    });
    if (this.state.selectedDate === "") {
      alert("Please select date and time");
    } else {
      let dateData = {
        selectedDate: this.state.selectedDate,
      };
      axios.post(process.env.REACT_APP_APIURL +
            `/coordinator/user/college_id/unapproved/idcard/date`,
          dateData
        )
        .then((response) => {
          this.setState({
            userData: response?.data?.data,
            userCount: response?.data?.count,
            approvedCount: response?.data?.approvedCount,
            rejectedCount: response?.data?.rejectedCount,
            loader: false,
          });
        })
        .catch((error) => {
          this.setState({
            loader: false,
            noDisputeList: true,
            buttonFalse: true,
          });
        });
    }
  }

  getCandidateListByMobileNumber() {
    this.setState({
      loader: true,
    });
    if (this.state.mobileNumber === "") {
      alert("Please enter mobile number");
    } else {
      let dateData = {
        mobile: this.state.mobileNumber,
      };
      axios.post(process.env.REACT_APP_APIURL +
            `/coordinator/user/college_id/unapproved/idcard/mobile`,
          dateData
        )
        .then((response) => {
          this.setState({
            userData: response?.data?.data,
            userCount: response?.data?.count,
            approvedCount: response?.data?.approvedCount,
            rejectedCount: response?.data?.rejectedCount,
            loader: false,
          });
        })
        .catch((error) => {
          this.setState({
            loader: false,
            noDisputeList: true,
            buttonFalse: true,
          });
        });
    }
  }

  storeRejectIDCardCandidateYinID(yinID) {
    localStorage.setItem("dispute_candidate_id", yinID);
  }

  isShowPopup = (status, yin_id, indexSelected) => {
    this.setState({
      showModalPopup: true,
      disabled: true,
      yinId: yin_id,
      deletedIndex: indexSelected,
    });
  };

  isClosePopup = () => {
    this.setState({ showModalPopup: false });
  };

  isShowVoteDisputetpop = (status) => {
    this.setState({ showVoteDisputetpop: true });
  };

  closeConfirmModal = () => {
    this.setState({ showModalPopup: false });
  };

  disableButton = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };

  handleSubmit() {
    this.setState({
      showModalPopup: false,
    });
    let nominateUserId = {
      yin_id: this.state.yinId,
      verification_status: false,
      verification_reject_reason: this.state.rejectReason,
    };
    axios.post(process.env.REACT_APP_APIURL +
          `/coordinator/user/college_id/verified_status/reject`,
        nominateUserId
      )
      .then((response) => {
        let datas = this.state.userData?.filter(
          (e, i) => i !== this.state.deletedIndex
        );
        this.setState({ userData: datas });
        this.log("IMAGE_REJECTED", this.state.yinId, "REJECTED");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  approvedVotes(yin_id, approvedIndex) {
    this.setState({
      showModalPopup: false,
    });
    let nominateUserId = {
      yin_id: yin_id,
      verification_status: true,
    };
    axios.put(process.env.REACT_APP_APIURL +
          `/coordinator/user/college_id/verified_status/approved`,
        nominateUserId
      )
      .then((response) => {
        let datas = this.state.userData?.filter((e, i) => i !== approvedIndex);
        this.setState({ userData: datas });
        this.log("IMAGE_VERIFIED", yin_id, "VERIFIED");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  SetCollegeIDCardReason = async (rejectReasonConfirm) => {
    if (this.state.rejectReason.includes(rejectReasonConfirm)) {
    } else {
      this.state.rejectReason.push(rejectReasonConfirm);
    }
  };

  selectDate(event) {
    this.setState({
      selectedDate: event.target.value,
    });
  }

  getMobileNumber(event) {
    this.setState({
      mobileNumber: event.target.value,
    });
  }

  async log(type, user, id) {
    let operatorData = JSON.parse(localStorage.getItem("operator"));
    let operator = operatorData.email;
    let params = {
      type,
      user,
      id,
      operator,
    };
    try {
      await axios.post(process.env.REACT_APP_APIURL + `/audit/ops/log`, params);
    } catch (error) {
      console.log("failed to log", JSON.stringify(params));
    }
  }

  showCity() {
    const operator = JSON.parse(localStorage.getItem(""));
    console.log("districts", operator)
    const districts = operator?.districts || [];
    // if (userData && Array.isArray(userData.districts) && userData.districts.length > 0) {
      if (districts.length > 0 && districts[0] !== "*") {
      this.setState({
        CityData: districts,
      });
    } else {
      axios.get(process.env.REACT_APP_APIURL + `/address/city/MH`)
        .then((response) => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            this.setState({
              CityData: response.data,
            });
          }
        })
        .catch((error) => {
          console.log("error in showCity", error);
        });
    }
  }

  showCollage(city) {
    let citydata = city;
    axios.get(process.env.REACT_APP_APIURL + `/college/district/${citydata}`)
      .then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          this.setState({
            CollageData: response.data,
          });
        } else {
          this.setState({
            CollageData: [],
          });
        }
      })
      .catch((error) => {
        console.log("error in showCity", error);
      });
  }

  reApproveCandidate() {
    this.setState({
      redirect: true,
    });
  }

  render() {
    let cities;
    console.log("districtsLocal", this.state.districtsLocal);
    if (this.state.districtsLocal?.length > 0 && this.state.districtsLocal[0] !== "*") {
      cities = this.state.CityData.map((e, key) => {
        return (
          <option key={key} value={e}>
            {e}
          </option>
        );
      });
    } else {
      cities = this.state.CityData?.map((e, key) => {
        return (
          <option key={key} value={e.name}>
            {e.name}
          </option>
        );
      });
    }
    let CollegeList;
    CollegeList = this.state.CollageData?.map((e, key) => {
      return (
        <option key={key} value={e.college_code}>
          {e.college_name}
        </option>
      );
    });

    // get users district data
    const getDistData = localStorage.getItem("districts");
    const districtData = JSON.parse(getDistData);
    const getNewDistData = districtData?.map((itm, index) => {
      return itm;
    });

    if (this.state.redirect) {
      return (
        <Redirect to={{
          pathname: "/re-approve"
        }}
        />
      );
    }

     // Excel export
     const downloadExcel = (customHeadings) => {
      let dataSet = [];
      dataSet = customHeadings;
      const worksheet = XLSX.utils.json_to_sheet(dataSet);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, this.state.userData[0].college_name + " " + "VoterIDCardList.xlsx");
      // setSheetLoader(false);
    };
    
    const CustomExcel = () => {
      // setSheetLoader(true);
      const customHeadings =
      this.state.userData !== undefined && this.state.userData.length > 0
          ? this.state.userData.map((data,index) => ({
            Id: index + 1,
            Profile_Image: data.profile_image[0] ?? "-", 
            Name: data.first_name + " " + data.last_name ?? "-",
            College_Name: data.college_name ?? "-",
            College_Id_Card: data.college_id[0] ?? "-", 
            Government_Id_Card: data.government_id[0] ?? "-",
            VoterIdCardStatus: data.isVoterIdCardStatus ?? "-"
            }))
          : "";
      downloadExcel(customHeadings);
    };

    return (
      <>
        {/* <Sidebar /> */}
        {/* <div className="main"> */}
        <div className="topbar">
          <Navbar />
        </div>

        {/* <BackAndBreadCrmOneParent backLink="/view-card" backName="View cards" currentTabName="Dispute Voter list"/> */}
        <div className="container">
          <div className="mt-3 mb-3">
            <h3 className="text-center" style={{ fontWeight: "600" }}>
              Voter College ID Card List
            </h3>
            <div className="d-flex mt-3 justify-content-end">
              <h6 className="mt-2">Inprocess: </h6>
              <h6
                style={{
                  display: "flex",
                  borderRadius: "80px",
                  padding: "10px",
                  backgroundColor: "#FFFB00",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "10px",
                }}
              >
                {this.state.inProcessCount}
              </h6>

              <h6 className="mt-2 mr-2"> Approved:</h6>
              <h6
                style={{
                  display: "flex",
                  borderRadius: "80px",
                  padding: "10px",
                  backgroundColor: "#FFFB00",
                  alignItems: "center",
                  marginRight: "10px",
                }}
              >
                {this.state.approvedCount}
              </h6>

              <h6 className="mt-2 mr-2"> Rejected: </h6>
              <h6
                style={{
                  display: "flex",
                  borderRadius: "80px",
                  padding: "10px",
                  backgroundColor: "#FFFB00",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {this.state.rejectedCount}
              </h6>
            </div>
          </div>

          <div className="mx-auto border">
            <Row md={12} className="mx-4 mx-sm-0 mt-2 mb-2">
              <Col md={12} className="d-flex">
                <Row md={12}>
                  <Col md={3} className="mb-2 sm-mb-2">
                    <label>Select by District</label>
                    <select
                      type="button"
                      name="city"
                      value={this.state.selected_city}
                      className="btn dropdown-toggle w-100"
                      style={{ backgroundColor: "#003147", color: "white" }}
                      onChange={this.getCandidateWithCity}
                      id="district"
                    >
                      <option value={getNewDistData}>All</option>
                      {cities}
                    </select>
                  </Col>

                  <Col md={3} className="mb-2 sm-mb-2">
                    <label>Select by Collage</label>
                    <select
                      type="button"
                      name="city"
                      value={this.state.college_code}
                      className="btn dropdown-toggle w-100"
                      style={{ backgroundColor: "#003147", color: "white" }}
                      onChange={this.getCandidateWithCollege}
                      id="district"
                    >
                      <option value={""}>All College</option>
                      {CollegeList}
                    </select>
                  </Col>

                  <Col md={3} className="mb-2 sm-mb-2">
                    <label htmlFor="mobileNumber">Search by Status</label>
                    <select
                      onChange={this.handleStatusOnChange}
                      value={this.state.selectedStatus}
                      name="selectedStatus"
                      type="button"
                      className="btn dropdown-toggle w-100"
                      style={{ backgroundColor: "#003147", color: "white" }}
                    >
                      <option value="INPROCESS">INPROCESS</option>
                      <option value="APPROVED">APPROVED</option>
                      <option value="REJECTED">REJECTED</option>
                    </select>
                  </Col>

                  <Col md={3} className="mb-2 sm-mb-2">
                    <label htmlFor="selectDate">Select Date For list</label>
                    <div className="d-flex">
                      <input
                        type="Date"
                        id="selectDate"
                        className="form-control me-2 w-100"
                        name="date"
                        onChange={this.selectDate}
                      />
                      <Button
                        onClick={this.getCandidateListDate}
                        style={{ backgroundColor: "#003147", color: "white" }}
                      >
                        <Icon.Search />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md={12} className="d-flex">
                  <Col md={3} className="mb-2 sm-mb-2">
                      <label htmlFor="mobileNumber">Enter mobile number</label>
                      <div className="d-flex">
                        <input
                          type="number"
                          id="mobileNumber"
                          className="form-control me-2 w-100"
                          name="mobileNumber"
                          onChange={this.getMobileNumber}
                        />
                        <Button
                          onClick={this.getCandidateListByMobileNumber}
                          style={{ backgroundColor: "#003147", color: "white" }}
                        >
                          <Icon.Search />
                        </Button>
                      </div>
                  </Col>
                  
                  <Col md={9} className="d-flex mt-3 justify-content-end">
                    <span onClick={() => this.reApproveCandidate()}>
                      <button className="btn btn-outline-info btn-md" style={{ backgroundColor: "#003147", color: "white" }}>
                        Re-Approve
                      </button>
                    </span>
                  </Col>
              </Col>
            </Row>
          </div>

          {
            this.state.college_code?.length === 0 ? 
            " " : 
            this.state.userData?.length === 0 ?
            " " :
            <Row className="mx-1">
            <Col md={12} className='mt-4'>
              <div className='d-flex justify-content-end'>
                <Button onClick={() => CustomExcel()} className='excelBtn'><Icon.BoxArrowDown className='me-2'/>Export</Button>
              </div>
            </Col>
          </Row>
          }


          {
            this.state.loader ? (
              <LoaderRounded/>
            ) : this.state.userData.length>0 ? (
              <Container className="mt-4">
                {Array.isArray(this.state.userData) &&
                this.state.userData.map((user, index) => (
                  <Row key={index}  className=" p-4 p-md-3 border rounded-3 mb-5"
                  style={{backgroundColor: "#ffffef"}}>
                    <Col md={3} className="text-center my-auto">
                      {/* <h6 style={{ fontWeight: "600" }}>Profile Image</h6> */}
                      <img alt="Profile" width="65px" height="65px"
                        src={user?.profile_image[0] ? user?.profile_image[0] : require("../../Assets/images/noidcard.jpg").default}
                                style={{ borderRadius: "60px" }} loading="lazy" />

                      <p style={{ fontWeight: "500", marginBottom:'4px' }}> {" "} {user.first_name} {user.last_name}</p>
                      <p style={{ fontSize: "12px" }}>{user.mobile}</p>
                      <p style={{ fontSize: "12px" }}> {user.college_name}</p>
                      <div style={{ fontSize: "10px" }}>Card Status</div>
                        <p  className="btn btn-sm border mb-2"
                          style={{ fontWeight: "700" }}>
                          {user?.isVoterIdCardStatus}
                        </p>

                        <div className="d-flex justify-content-center d-none d-md-flex">
                        <button
                              onClick={() =>
                                this.isShowPopup(true, user.yin_id, index)
                              }
                              disabled={this.state.buttonFalse}
                              type="button"
                              id="btndispute"
                              className="btn btn-sm rounded-pill Disputebtn border me-3">
                              Reject
                            </button>
                            <button
                              onClick={() => this.approvedVotes(user.yin_id, index)}
                              disabled={this.state.buttonFalse}
                              type="button"
                              id="btndispute"
                              className="btn btn-sm rounded-pill DisputeApproved border ">
                              Verify
                            </button>
                        </div>
                    </Col>

                    <Col md={9}>
                <Row className="justify-content-center align-items-center" >
                  {/* College ID Card */}
                  <Col
                    md={6}
                    className="d-flex flex-column align-items-center justify-content-center mt-3 mt-md-0"
                    style={{ height: '100%' }}
                  >
                    <h6 style={{ textAlign: 'center', marginBottom: '10px' }}>College ID Card</h6>
                    <img
                      src={
                        user?.college_id
                          ? user?.college_id
                          : require('../../Assets/images/noidcard.jpg').default
                      }
                      alt="College ID Card"
                      style={{
                        maxWidth: '100%',
                        maxHeight: '320px',
                        objectFit: 'contain',
                        borderRadius: '12px',
                      }}
                      loading="lazy"
                      onClick={() => window.open(user?.college_id, '_blank')}
                    />
              </Col>

              {/* Government ID Card */}
              <Col
                md={6}
                className="d-flex flex-column align-items-center justify-content-center mt-4 mt-md-0"
                style={{ height: '100%' }}
              >
                <h6 style={{ textAlign: 'center', marginBottom: '10px' }}>Government ID Card</h6>
                <img
                  src={
                    user.government_id[0]
                      ? user.government_id[0]
                      : require('../../Assets/images/noidcard.jpg').default
                  }
                  alt="Government ID Card"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '320px',
                    objectFit: 'contain',
                    borderRadius: '12px',
                  }}
                  loading="lazy"
                  onClick={() => window.open(user?.government_id, '_blank')}
                />
    </Col>

                <div className="d-flex justify-content-center  d-md-none mt-3">
                        <button
                              onClick={() =>
                                this.isShowPopup(true, user.yin_id, index)
                              }
                              disabled={this.state.buttonFalse}
                              type="button"
                              id="btndispute"
                              className="btn btn-sm rounded-pill Disputebtn border me-3">
                              Reject
                            </button>
                            <button
                              onClick={() => this.approvedVotes(user.yin_id, index)}
                              disabled={this.state.buttonFalse}
                              type="button"
                              id="btndispute"
                              className="btn btn-sm rounded-pill DisputeApproved border ">
                              Verify
                            </button>
                        </div>
  </Row>
</Col>
                </Row>
                ))}
              </Container>
            ) :(
              <h5 style={{ textAlign: "center", marginTop: "20px" }}>
                No Record Found...
              </h5>
            )
          }

          {/* {this.state.loader ? (
            <LoaderRounded />
          ) : this.state.userData.length > 0 ? (
            <div className="row gy-3">
              {Array.isArray(this.state.userData) &&
                this.state.userData.map((user, index) => (
                  <div className="col-12" key={index} style={{height:'400px'}}>
                    <div
                      className="row p-3 border"
                      style={{
                        borderRadius: "8px",
                        backgroundColor: "#ffffef",
                        marginBottom:'20px'
                      }}>
                      <div className="col-12 col-md-2 col-lg-2">
                        <div className="mt-2">
                          <div className="text-center">
                            <h6 style={{ fontWeight: "600" }}>Profile Image</h6>
                            <img
                              src={user?.profile_image[0] ? user?.profile_image[0] : require("../../Assets/images/noidcard.jpg").default}
                              alt="logo1"
                              width="65px"
                              height="65px"
                              style={{ borderRadius: "60px" }}
                              loading="lazy"
                            />
                          </div>
                          <div className="mt-3 text-center">
                            <p style={{ fontWeight: "500" }}>
                              {" "}
                              {user.first_name} {user.last_name}
                            </p>
                          </div>
                          <div className="text-center">
                            <p style={{ fontSize: "12px" }}>{user.mobile}</p>
                          </div>

                          <div className="mt-3 text-center">
                            <p style={{ fontSize: "12px" }}>
                              {user.college_name}
                            </p>
                          </div>

                          <div className="mt-3 text-center">
                            <div style={{ fontSize: "10px" }}>Card Status</div>
                            <p
                              className="btn btn-sm border mb-4"
                              style={{ fontWeight: "700" }}>
                              {user?.isVoterIdCardStatus}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-8 col-md-8 col-lg-8 mb-2">
                        <div className="d-flex justify-content-between w-100">
                          <div className="d-flex flex-column w-100 mr-5">
                            <h6 style={{textAlign: "center",marginBottom: "10px"}}>
                              College ID Card
                            </h6>
                            <img src={user?.college_id ? user?.college_id
                                  : require("../../Assets/images/noidcard.jpg")
                                      .default
                              }
                              alt="College Id Card"
                              style={{ marginRight: "10px", width: "100%",height: "100%"}}
                              loading="lazy"
                              onClick={()=>window.open(user?.college_id, '_blank')}
                              />
                           
                          </div>

                          <div className="d-flex flex-column w-50">
                            <h6
                              style={{
                                textAlign: "center",
                                marginBottom: "10px",
                              }}
                            >
                              Governoment ID Card
                            </h6>
                            <img src={
                                user.government_id[0]
                                  ? user.government_id[0]
                                  : require("../../Assets/images/noidcard.jpg")
                                      .default
                              }
                              alt="Government Id Card"
                              style={{ marginRight: "10px",width: "100%", height: "60%",}}
                              loading="lazy"
                              onClick={()=>window.open(user?.government_id, '_blank')}
                            />
                           
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-md-2 col-lg-2 text-center">
                        <button
                          onClick={() =>
                            this.isShowPopup(true, user.yin_id, index)
                          }
                          disabled={this.state.buttonFalse}
                          type="button"
                          id="btndispute"
                          className="btn btn-sm rounded-pill Disputebtn border mb-4"
                        >
                          x Reject
                        </button>
                        <button
                          onClick={() => this.approvedVotes(user.yin_id, index)}
                          disabled={this.state.buttonFalse}
                          type="button"
                          id="btndispute"
                          className="btn btn-sm rounded-pill DisputeApproved border mb-4"
                        >
                          Verified
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <h5 style={{ textAlign: "center", marginTop: "20px" }}>
              No Record Found...
            </h5>
          )} */}
        </div>



        <RejectCollegeIDCardPopup
          showModalPopup={this.state.showModalPopup}
          closeModal={this.closeConfirmModal}
          onPopupCloseMain={this.isClosePopup.bind(this)}
          handleSubmit={this.handleSubmit}
          rejectCollegeIdCardReason={this.SetCollegeIDCardReason}
        ></RejectCollegeIDCardPopup>
      </>
    );
  }
}
