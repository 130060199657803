import React, { Component } from 'react';
const avatars = require("../Assets/images/avatar1.png").default;

export default class ViewVotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.addCandidateYinID = this.addCandidateYinID.bind(this)
    }
    async addCandidateYinID(yinid) {
        localStorage.setItem("candidate_id", yinid);
    }

    render() {
        return (
            <div className="row align-items-center border" style={{ margin: "8px", borderRadius: "6px" }}>
                <div className="col-4">
                    <div className="Candidateimage">
                        <img src={this.props.profile_image ? this.props.profile_image : avatars}
                            className="rounded-circle" alt="profile" style={{marginTop:"5px"}} width="80px" height="80px" border-radius="75%" background-size="cover"/>
                    </div>
                </div>
                <div className=" col-8">
                 <h6 className="my-2 pt-2"><b>{this.props.first_name} {this.props.last_name}</b></h6>
                 <p style={{ fontSize: "12px" }}><b>Total Votes:&nbsp;&nbsp;&nbsp;{this.props.votecount}</b></p>
                </div>
            </div>
        )
    }
}
