import React, { useState } from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import LoaderRoundedWithText from './LoaderRoundedWithText';
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";

const CollegeWiseCandidateCount = () => {
    const [loading, setLoading] = useState(true)

    useState(()=>{
        setTimeout(()=>{
            setLoading(false)
        },1000)
    },[])

  return (
    <>
        {loading ? (<LoaderRoundedWithText />) :
        <div>
          <Sidebar/>

            <div className="main">
                <div className="topbar">
                    <Navbar/>
                </div>

                <div className="container">
                    <BackAndBreadCrmOneParent
                    backLink=""
                    backName=""
                    currentTabName="Candidate List"
                    />
                    <h2 className="text-center mb-0">College Wise Candidate Count</h2>
                </div>
            </div>
        </div>  
        }
    </>
  )
}

export default CollegeWiseCandidateCount
