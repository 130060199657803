import React, { Component } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import S3 from "react-aws-s3";
import Compressor from "compressorjs";
import moment from "moment";
import { Table } from "react-bootstrap";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRounded from "./LoaderRounded";
import { Redirect } from "react-router-dom";
import {
  MdOutlineSubtitles,
  MdOutlineDescription,
  MdDateRange,
  MdOutlineCategory,
} from "react-icons/md";
import { AiOutlineLink } from "react-icons/ai";
import { Delete } from "@material-ui/icons";
import { responsiveMap } from "antd/lib/_util/responsiveObserve";

export default class ViewAplify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      commentId: "",
      imageURL: [],
      comment: [],
      category: "",
      eventLink: "",
      amplifierDate: "",
      eventLink: "",
      updatedBy: "",
      redirect: false,
      errors: {},
      loader: false,
      msg: null,
      year: this?.props.location.state.year,
    };
    this.uploadImageToS3 = this.uploadImageToS3.bind(this);

    // this.deleteAmplifierData = this.deleteAmplifierData.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.getAmplifierData = this.getAmplifierData.bind(this);
    this.getCommentData = this.getCommentData.bind(this);
    this.RemoveCommentData = this.RemoveCommentData.bind(this);
  }
  componentDidMount() {
    this.getAmplifierData();
    this.getCommentData();
    this.RemoveCommentData(); // this.deleteAmplifierData();
    // this.handleCallupdate();
  }
  handleFormChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  addNewImage(newImage) {
    let img_arr = this.state.imageURL;
    img_arr.push(newImage);
    this.setState({
      imageURL: img_arr,
    });
  }

  removeImageURL(id) {
    let img_arr = this.state.imageURL;
    img_arr = img_arr.filter((img) => img.id !== id);
    this.setState({
      imageURL: img_arr,
    });
  }

  removePdfURL(id) {
    let pdf = this.state.event_documents;
    pdf = pdf.filter((pdf) => pdf.id !== id);
    this.setState({
      event_documents: pdf,
    });
  }

  uploadImageToS3(event) {
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: "yin/imageURL",
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      s3Url: process.env.REACT_APP_S3URL,
    };

    var fileInput = false;
    const image = event.target.files[0];
    if (image === undefined) return;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      if (
        event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpeg"
      ) {
        new Compressor(image, {
          quality: 0.6,
          success: (compressedResult) => {
            let newFileName = event.target.files[0].name.replace(/\..+$/, "");
            const finalFileName = newFileName;
            const ReactS3Client = new S3(config);
            // const newFileName = 'test-file';

            ReactS3Client.uploadFile(compressedResult, newFileName)
              .then((res) => {
                console.log(res);
                this.addNewImage(res.location);
              })
              .catch((err) => console.error(err));
          },
        });
      }
    }
  }

  getAmplifierData() {
    let amplifier_id;
    let currentComponent = this;
    if (this.props.location.data) {
      localStorage.setItem("amplifier_id", this.props.location.data._id);
      amplifier_id = this.props.location.data._id;
    } else {
      amplifier_id = localStorage.getItem("amplifier_id");
    }
    const amplifierid = {
      amplifier_id: localStorage.getItem("amplifier_id"),
    };
    axios
      .post(process.env.REACT_APP_APIURL + "/amplifier/get", amplifierid)
      .then((res) => {
        let responses = res.data;

        currentComponent.setState({
          ...responses[0],
        });
      });
  }

  // getCommentData() {
  //   // let amplifier_id;
  //   let currentComponent = this;
  //   // if (this.props.location.data) {
  //   //   localStorage.setItem("amplifier_id", this.props.location.data._id);
  //   //   amplifier_id = this.props.location.data._id;
  //   // } else {
  //   //   amplifier_id = localStorage.getItem("amplifier_id");
  //   // }

  //    const  entityid =  localStorage.getItem('amplifier_id')


  //   axios.get(`https://yin-api.foxberry.link/v1/comment/get/all/comments/`+entityid).then((res) => {
  //     let responses = res.data;

  //       if(responses?.message !== "No Message Found"){
  //           currentComponent.setState({
  //               comment: responses.data
  //             });
  //       }else{
  //           this.setState({
  //               msg:'No Message Found'
  //           })
  //       }
  //   });
  // }

  getCommentData() {
    let currentComponent = this;
    const entityid = localStorage.getItem("amplifier_id");
    axios
      .get(
        process.env.REACT_APP_APIURL + `/comment/get/all/comments/` + entityid
      )
      .then((res) => {
        let responses = res.data;
        if (responses?.message !== "No Message Found") {
          currentComponent.setState({
            comment: responses.data,
          });
        } else {
          this.setState({
            msg: "No Message Found",
          });
        }
      });
  }

  RemoveCommentData(commentId) {
    let currentComponent = this;
    axios
      .put(
        process.env.REACT_APP_APIURL +
        `/comment/removeComment` +
        this.state.commentId
      )
      .then((res) => {
        let responses = res.data;
      });
  }

  render() {
    const { year } = this.state;

    if (this.state.redirect) {
      return <Redirect to="/AmplifyList" />;
    }
    return (
      <>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>
          <div></div>
          <div className="container">
            <BackAndBreadCrmOneParent
              backLink="/AmplifyList"
              backName="AmplifyList"
              currentTabName="Add Amplify"
              param1={year}
            />

            <div className="row mt-4">
              <div
                style={{
                  heigh: "800vh",
                  overflow: "hidden",
                }}
                className="col-lg-8 col-md-8 col-12 paddingAll10 mx-auto "
              >
                <div className="card rounded shadow shadow-sm ">
                  <div className="card-header">
                    <h3 className="mb-0 center">View Amplify</h3>
                  </div>
                  <div className="card-body">
                    <form>
                      <Table style={{ fontSize: 18, marginTop: 20 }}>
                        <tbody style={{ width: "190px" }}>
                          <tr>
                            <td
                              style={{
                                fontWeight: "bold",
                                display: "flex",
                                color: "#003147",
                              }}
                            >
                              {" "}
                              <span>
                                <MdOutlineSubtitles
                                  style={{ fontSize: "30px" }}
                                />
                              </span>{" "}
                              Title
                            </td>
                            <td>{this.state.title}</td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                fontWeight: "bold",
                                display: "flex",
                                color: "#003147",
                                alignItems: "center",
                                height: "80px",
                              }}
                            >
                              <span>
                                <MdOutlineDescription
                                  style={{ fontSize: "30px" }}
                                />
                              </span>{" "}
                              Description{" "}
                            </td>
                            <td>{this.state.description}</td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                fontWeight: "bold",
                                display: "flex",
                                color: "#003147",
                              }}
                            >
                              <span>
                                <MdDateRange
                                  style={{
                                    fontSize: "30px",
                                    marginRight: "10px",
                                  }}
                                />
                              </span>
                              AmplifierDate{" "}
                            </td>
                            <td>
                              {moment(this.state.amplifierDate).format(
                                "DD MMM YYYY hh:mm a"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ fontWeight: "bold", color: "#003147" }}
                            >
                              <span>
                                <MdOutlineCategory
                                  style={{ fontSize: "30px" }}
                                />
                              </span>
                              Category
                            </td>
                            <td>{this.state.category}</td>
                          </tr>
                          <tr>
                            <td
                              style={{ fontWeight: "bold", color: "#003147" }}
                            >
                              <span>
                                <AiOutlineLink style={{ fontSize: "30px" }} />
                              </span>
                              EventLink
                            </td>
                            <td>{this.state.eventLink}</td>
                          </tr>
                          <tr>
                            <td
                              style={{ fontWeight: "bold", color: "#003147" }}
                            >
                              <span>
                                <MdOutlineSubtitles
                                  style={{ fontSize: "30px" }}
                                />
                              </span>
                              Images
                            </td>
                            <td>
                              {" "}
                              {this.state.imageURL.length === 0
                                ? null
                                : this.state.imageURL.map((img, index) => {
                                  return (
                                    <>
                                      <div
                                        className="card"
                                        style={{ margin: 10, width: 200 }}
                                      >
                                        <img
                                          src={img}
                                          key={index}
                                          className="card-img-top"
                                          alt={img.name}
                                          width="100px"
                                          height="100px"
                                        />
                                      </div>
                                    </>
                                  );
                                })}
                            </td>
                          </tr>

                          {/* comment code for comment */}
                          {/* <tr>
                            <td style={{ fontWeight: "bold" }}>Comments</td>
                            <td>
                              {" "}
                              {this.state.comment === null
                                ? "No Comment Found"
                                : this.state.comment.map((comment, index) => {
                                    return (
                                      <>
                                        <div
                                          className="card"
                                          style={{ margin: 10, width: 250 }}
                                        >
                                          {comment.message}

                                          <div class="card-body">
                                            <button
                                              className="btn btn-sm btn-outline-danger"
                                              style={{
                                                align: "right",
                                                marginLeft: "120px",
                                                marginTop: "-40px",
                                              }}
                                              onClick={() => {
                                                this.RemoveCommentData(
                                                  comment._id
                                                );
                                                this.setState({
                                                  commentId: comment._id,
                                                });
                                              }}
                                            >
                                              <Delete />
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                            </td>
                          </tr> */}
                        </tbody>
                      </Table>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
