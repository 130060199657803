import React from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { Edit, Add, Delete } from "@material-ui/icons";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import LoaderRounded from "./LoaderRounded";
import ReactPaginate from "react-paginate";
import LoaderRoundedWithText from "./LoaderRoundedWithText";
import { Col, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import confirm from "antd/lib/modal/confirm";
import * as Icon from 'react-bootstrap-icons';

class CoordinatorsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coordinatorsList: "",
      coordinator: [],
      currentFilter: "",
      currentFilterBy: "",
      CityData: [],
      district: [],
      verified: "true",
      city: "",
      selectValue: "all",
      redirect: false,
      redirect1: false,
      districtsLocal: JSON.parse(localStorage.getItem("districts")),
      currentPage: "",
      counts: "",
      totalPages: "",
      previousLimits: 100,
      renderPageLimit: 10,
      selectedPage: 0,
      allpage: "",
      selected_city: null,
      limitVal: 10,
      skip_value: 0,
      page_selected: 0,
      fromdate: null,
      todate: null,
      year: null,
      view_count: 0,
      filterString: "",
      filterdata: [],
      selected_distict: ["*"],
      loading: true,
      loader: false,
    };

    this.applyFiter = this.applyFilter.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.searchFromList = this.searchFromList.bind(this);
    this.getCoordinatorWithCity = this.getCoordinatorWithCity.bind(this);
    this.hanldeRowchange = this.hanldeRowchange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.viewCount = this.viewCount.bind(this);
    this.getData = this.getData.bind(this);
    this.searchList = this.searchList.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  componentWillMount() {
    // setTimeout(() => this.setState({ loader: false }), 1000);
    setTimeout(() => this.setState({ loading: false }), 1000);
  }

  handlePageChange(page) {
    this.setState({ selectedPage: page.selected });
  }

  hanldeRowchange(row) {
    this.setState({
      renderPageLimit: parseInt(row),
    });
  }

  applyFilter(arr) {
    if (!this.state.currentFilter) {
      return arr;
    }
    let filteron = this.state.currentFilterBy;
    if (filteron === "city") {
      return arr.filter((x) => x.city === this.state.currentFilter);
    } else {
      let a = this.state.currentFilter.toLowerCase();
      var condition = new RegExp(a);
      var result = arr.filter(function (el) {
        return (
          condition.test(el.first_name.toLowerCase()) ||
          condition.test(el.last_name.toLowerCase())
        );
      });
      return result;
    }
  }

  setFilter(e) {
    e.preventDefault();
    this.setState({
      currentFilter: e.target.value,
    });
  }

  UNSAFE_componentWillMount() {
    this.getData(this.state.selected_city);
    this.showCity();
  }


  getData(city) {
  this.setState({
    loader: true,
  });
  let currentComponent = this;

  axios
    .get(`${process.env.REACT_APP_APIURL}/coordinator/getall?city=${city || ""}`)
    .then((res) => {
      if (Array.isArray(res.data) && res.data.length) {
          currentComponent.setState({
            loader: false,
          });
          if (res.data && Array.isArray(res.data)) {
            let con = Math.ceil(
              parseInt(res.data.allCount) / this.state.limitVal
            );

            let responses = res.data;

            currentComponent.setState({
              coordinator: responses,
              filterdata: responses,
            });
          }
        }
      })
    .catch((err) => {
      currentComponent.setState({
        loader: false,
      });
      console.log("error in adding coor in list", err);
    });
}

  showCity() {
    let districts = JSON.parse(localStorage.getItem("districts"));
    if (districts[0] !== "*") {
      this.setState({
        CityData: districts,
      });
    } else {
      axios.get(process.env.REACT_APP_APIURL + `/address/city/MH`)
        .then((response) => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            this.setState({
              CityData: response.data,
            });
          }
        })
        .catch((error) => {
          console.log("show city error", error);
        });
    }
  }

 getCoordinatorWithCity(e) {
  let selected_city = e.target.value === "all" ? null : e.target.value;

  this.setState({
    selected_city: selected_city,
  });

  this.getData(selected_city);
}

  searchFromList(event) {
    this.setState({
      coordinator: event.target.value,
      currentFilterBy: event.target.name,
    });
  }

  searchList(char) {
    let f = this.state.filterdata?.filter(
      (item) =>
        item?.mobile
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase()) ||
        item?.gender
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase()) ||
        item?.city?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
        item?.first_name
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase()) ||
        item?.last_name
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase()) ||
        item?.verified
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase()) ||
          item?.districts ?.toString().toLowerCase()?.startsWith(char?.toLowerCase())
    );
    this.setState({
      coordinator: f,
    });
  }

  viewCount() {
  }

  handleRemove(event) {
    let result = window.confirm("Are you sure want to delete co-ordinator?");

    if (result) {
      this.setState({
        loader: true,
      });

      axios.post(process.env.REACT_APP_APIURL + `/coordinator/coordinator/remove`,{mobile: event,})
        .then((res) => {
          this.setState({
            loader: false,
          });
          alert("Coordinator removed successfully");
          this.getData(this.state.selected_city);
        })
        .catch((error) => {
          this.setState({
            loader: false,
          });
          console.log("error in Removing Coordinator ----------- ", error);
        });
    }
  }

  render() {
    const backlink = localStorage.getItem("setbacklink");

    if (this.state.redirect) {
      return <Redirect to="/Editcoordinator" />;
    }
    if (this.state.redirect1) {
      return <Redirect to="/coordinator" />;
    }

    if (!localStorage.getItem("mobile")) {
      return <Redirect to="/login" />;
    }

    let coordinatorsList = "";
    if (this.state.coordinator.length > 0) {
      coordinatorsList =
        this.state.coordinator &&
        this.state.coordinator.map((coordinator, id) => (
          <tr key={id}>
            <td>{this.state.page_selected * this.state.limitVal + id + 1}</td>
            <td>
              {coordinator.first_name} &nbsp;{coordinator.last_name}
            </td>

            <td className="noselect">{coordinator.mobile}</td>
            <td>{coordinator?.gender}</td>
            {/* <td>{coordinator.city}</td> */}
            <td>{coordinator.districts.join(", ")}</td>
            <td
              className={coordinator.verified ? "text-success" : "text-danger"}
            >
              {coordinator.verified ? "True" : "False"}
            </td>

            <td className="text-center">
              <Link to={{ pathname: "/Editcoordinator", data: coordinator }}>
                <button className="btn btn-outline-info btn-sm ">
                  {" "}
                  <Edit />
                </button>
              </Link>
              &nbsp;&nbsp;&nbsp;
              <span>
                <button
                  className="btn btn-outline-danger btn-sm "
                  onClick={() => {
                    this.handleRemove(coordinator.mobile);
                  }}
                >
                  {" "}
                  <Delete />
                </button>
              </span>
              {/* </Link> */}
            </td>
          </tr>
        ));
    } else {
      coordinatorsList = (
        <tr>
          <td>No Data Found....</td>
        </tr>
      );
    }
    

    let cities;
    const CityData = {};
    if (this.state.districtsLocal[0] !== "*") {
      cities = Array.isArray(CityData)
        ? this.state.CityData?.map((e, key) => {
            return (
              <option key={key} value={e}>
                {e}
              </option>
            );
          })
        : null;
    } else {
      cities = this.state.CityData?.map((e, key) => {
        return (
          <option key={key} value={e.name}>
            {e.name}
          </option>
        );
      });
    }

    return (
      <>
        {this.state.loading ? (
          <LoaderRoundedWithText />
        ) : (
          <div>
            <Sidebar />
            <div className="main">
              <div className="topbar">
                <Navbar title="All Coordinator List" />
              </div>

              <div className="container mt-4">
                <Row className="mx-2 justify-content-between">
                  <Col md={3} className="mb-2 mb-sm-0 mt-2">
                  <input
                    type="text"
                    placeholder="Search here"
                    name="search"
                    value={this.state.filterString}
                    onChange={(e) => {
                      this.setState({ filterString: e.target.value });
                      this.searchList(e.target.value);
                    }}
                    className="form-control md-w-75 "
                  ></input>
                  </Col>

                <Col md={6}>
                  <Row md={12} className="d-flex justify-content-end">
                    <Col md={6} className="mb-2 mb-sm-0 mt-2">
                     <select
                        type="button"
                        name="city"
                        className="btn dropdown-toggle w-100"
                        style={{ backgroundColor: "#003147", color: "white" }}
                        onChange={this.getCoordinatorWithCity}
                        id="district"
                      >
                        <option value="all">All</option>
                        {cities}
                      </select>
                    </Col>

                    <Col md={6} className="mb-2 mb-md-0 mt-2">
                      <Link
                        to="/Addcoordinator"
                        className="btn btn-secondary w-100"
                        style={{ backgroundColor: "#003147", color: "white" }}
                      >
                        <Add className="me-1" />
                        Add New
                      </Link>
                    </Col>
                  </Row>
                </Col>

                </Row>

                {/* <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <input
                    style={{ width: "25%" }}
                    type="text"
                    placeholder="Search here"
                    name="search"
                    value={this.state.filterString}
                    onChange={(e) => {
                      this.setState({ filterString: e.target.value });
                      this.searchList(e.target.value);
                    }}
                    //onChange={this.searchFromList}
                    className="form-control"
                  ></input>

                  <select
                    type="button"
                    name="city"
                    className="mx-2 btn btn-secondary btn-md dropdown-toggle"
                    onChange={this.getCoordinatorWithCity}
                    id="district"
                    style={{ float: "right", width: "200px" }}
                  >
                    <option value={null}> Filter By City </option>
                    {cities}
                  </select>

                  <Link
                    to="/Addcoordinator"
                    className="mx-2 btn btn-secondary btn-sm"
                    style={{ float: "right", width: "200px" }}
                  >
                    <Add />
                    Add New
                  </Link>
                </div> */}
              </div>

               {/* custom pagination  */}
               <Row className="mx-4 mb-5 mt-4 justify-content-end" md={12}>
                  <Col md={6} className="d-flex justify-content-end align-items-center ">
                    <span className="cordintorText fs-6" >Records Per Page:</span>
                    <Form.Control
                      className="w-50 ms-1"
                      id="pageselect"
                      as="select"
                      onChange={(event) => this.hanldeRowchange(event.target.value)}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </Form.Control>
                  </Col>
                </Row>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div style={{ overflowx: "auto" }}>
                      <div className="table-responsive-sm">
                        <table className="table table-bordered table-responsive">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Name</th>
                              <th>Mobile</th>
                              <th>Gender</th>
                              {/* <th>City</th> */}
                              <th>Assigned Cities</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.loader ? (
                              <LoaderRounded type="Spinner Type" />
                            ) : coordinatorsList?.length > 0 ? (
                              coordinatorsList?.slice(
                                this.state.selectedPage *
                                  this.state.renderPageLimit,
                                this.state.renderPageLimit +
                                  this.state.selectedPage *
                                    this.state.renderPageLimit
                              )
                            ) : (
                              <tr><td colSpan="6" className="text-center">No Data Found....</td></tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className = "overflow-auto mt-2">
                    <ReactPaginate
                      nextLabel="Next"
                      previousLabel="Previous"
                      pageCount={Math.ceil(coordinatorsList.length / this.state.renderPageLimit)}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      containerClassName="pagination"
                      activeClassName="btn btn-primary"
                      pageClassName="btn"
                      previousClassName="btn"
                      nextClassName="btn"
                      onPageChange={this.handlePageChange}
                    />
                    </div>
                   
                  </div>
                </div>
                <button
                className=" reaload btn btn-success btn-sm mt-3 mb-3"
                onClick={() => window.location.reload(true)}
              >
              <Icon.ArrowClockwise size={18} className="me-2"/>
                Refresh
              </button>
                {/* <button
                  className=" reaload btn  btn-success btn-sm "
                  onClick={() => window.location.reload(true)}
                >
                  Click to reload!
                </button> */}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selected_forum: state.PersistReducer.UserData.selected_forum,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(CoordinatorsList);
