import axios from 'axios';
import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Navbar from '../VoterVerification/NavbarVerify';
import LoaderRounded from "../LoaderRounded";
import BackAndBreadCrmOneParent from "../BackAndBreadCumOneParent";
import DisputePopup from './DisputePopup';

export default class DisputeList extends Component {
    constructor() {
        super();
        this.state = {
            modal: false,
            name: "",
            yinId: '',
            firstName: '',
            lastName: '',
            modalInputName: '',
            showModalPopup: false,
            buttonFalse: false,
            showSecondmodel: false,
            isShowSecondModelPopupClose: false,
            redirect: true,
            loader: true,
            userData: [],
            showVoteDisputetpop: false,
            isDisputed: false,
            disabled: false,
            first_name: 'Buddy',
            errorCandidate: '',
            last_name: '',
            start_time: '',
            end_time: '',
            deletedIndex: 0,
            disputeReason: 'Invalid Image: Non-relevant image upload',
            disputedBY: '',
            selectedDate: '',
            approvedIndex: 0,
        }
        this.handleChange = this.handleChange.bind(this);
        this.storeDisputeCandidateYinID = this.storeDisputeCandidateYinID.bind(this);
        this.closeConfirmModal = this.closeConfirmModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setDisputeReason = this.setDisputeReason.bind(this);
        this.selectDate = this.selectDate.bind(this);
        this.getCandidateListDate = this.getCandidateListDate.bind(this);
        this.approvedVotes = this.approvedVotes.bind(this);
        this.log = this.log.bind(this);
    }

    UNSAFE_componentWillMount() {
        let operator = JSON.parse(localStorage.getItem("operator"));
        let operatorEmailAddress = '';
        try {
            operatorEmailAddress = operator.email;
            this.setState({ operatorEmailAddress, isLoading: false });
        } catch (error) {
            console.error(error);
            window.location.href = "/operator/login";
            return;
        }
        this.getCandidateList();
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    getCandidateList() {
        axios.get(process.env.REACT_APP_VOTINGAPI + `/voting/college/voters/list/all/` + localStorage.getItem("college_code"))
            .then(response => {
                this.setState({
                    userData: response?.data,
                    loader: false
                });
            }).catch(error => {
                console.log(error);
                this.setState({
                    loader: false,
                    noDisputeList: true,
                    buttonFalse: true
                });
            })
    }

    getCandidateListDate() {
        if (this.state.start_time === "" || this.state.end_time === "" || this.state.selectedDate === "") {
            alert("Please select date and time");
        } else {
            let dateData = {
                college_code: localStorage.getItem("college_code"),
                start_time: this.state.start_time,
                end_time: this.state.end_time,
                selectedDate: this.state.selectedDate,
            }
            axios.post(process.env.REACT_APP_APIURL + `/coordinator/college/voters/list/all/date`, dateData)
                .then(response => {
                    this.setState({ userData: response.data, loader: false });
                }).catch(error => {
                    console.log(error);
                    this.setState({
                        loader: false,
                        noDisputeList: true,
                        buttonFalse: true
                    });
                })
        }
    }

    storeDisputeCandidateYinID(yinID) {
        localStorage.setItem("dispute_candidate_id", yinID);
    }

    isShowPopup = (status, yin_id, indexSelected) => {
        this.setState({ showModalPopup: true, disabled: true, yinId: yin_id, deletedIndex: indexSelected });
    };

    isClosePopup = () => {
        this.setState({ showModalPopup: false });
    };

    isShowVoteDisputetpop = (status) => {
        this.setState({ showVoteDisputetpop: true });
    };

    closeConfirmModal = () => {
        this.setState({ showModalPopup: false })
    }

    disableButton = () => {
        this.setState({
            disabled: !this.state.disabled
        })
    }

    handleSubmit() {
        this.setState({
            showModalPopup: false
        });
        let nominateUserId = {
            user_yinid: this.state.yinId,
            disputes_reason: this.state.disputeReason,
        }
        axios.put(process.env.REACT_APP_VOTINGAPI + `/voting/dispute/votes/coordinator`, nominateUserId)
            .then(response => {
                alert(response.data.message)
                this.log('VOTE_DISPUTED', this.state.yinId, "DISPUTED");
                let datas = this.state.userData.filter((e, i) => i !== this.state.deletedIndex);
                this.setState({ userData: datas });
            }).catch(error => {
                console.log(error);
            });
    }

    approvedVotes(yin_id, approvedIndex) {
        this.setState({
            showModalPopup: false
        });
        let nominateUserId = {
            user_yinid: yin_id,
        }
        axios.put(process.env.REACT_APP_APIURL + `/coordinator/approved/votes`, nominateUserId)
            .then(response => {
                let datas = this.state.userData.filter((e, i) => i !== approvedIndex);
                this.setState({ userData: datas });
                this.log('VOTE_APPROVED', this.state.yinId, "VOTE_APPROVED");
            }).catch(error => {
                console.log(error);
            });
    }

    setDisputeReason = async (disputeReasonConfirm) => {
        this.setState({
            disputeReason: disputeReasonConfirm,
        });
    }

    selectDate(event) {
        this.setState({
            selectedDate: event.target.value
        });
    }

    async log(type, user, id) {
        let operatorData = JSON.parse(localStorage.getItem('operator'));
        let operator = operatorData.email;
        let params = {
            type, user, id, operator
        }
        try {
            await axios.post(process.env.REACT_APP_APIURL + `/audit/ops/log`, params)
        } catch (error) {
            console.error("Error:", error);
        }
    }

    render() {
        return (
            <>
                <div className="topbar">
                    <Navbar />
                </div>

                <div className="container">
                    <BackAndBreadCrmOneParent backLink="/view-card" backName="View card" currentTabName="Dispute Voter list" />

                    <h2 className="text-center"><u>Dispute Voter List</u></h2>
                    <br />

                    {this.state.loader ? <LoaderRounded /> : <div className="row mx-5">
                        {this.state.userData !== null && this.state.userData !== undefined && this.state.userData.length > 0 && this.state.userData?.map((user, index) => (
                            <div className="col-12 mb-4" key={index}>
                                <div className="row p-4 border" style={{ borderRadius: "6px", backgroundColor: "#ffffef" }} >
                                    {/* <div className="col-2 text-center">
                            <Link to="/dispute" onClick={()=>this.storeDisputeCandidateYinID(user.yin_id)} style={{textDecoration:"none",color:"#000000"}}>
                                <div style={{paddingLeft:"16px",paddingTop:"10px"}}>
                                    <span disabled={this.state.name} ><h5> {user.first_name}  {user.last_name} </h5>
                                        <h6>{user.mobile}</h6>
                                    </span>
                                </div>
                                <img
                                    src={user?.profile_image[0] ? user?.profile_image[0] : require("../../Assets/images/noidcard.jpg").default}
                                    alt="logo1"
                                    width="65px"
                                    height="65px"
                                    style={{ borderRadius: "60px" }}
                                    loading="lazy"
                                />
                                <div className="mt-3 text-center">
                                    <p style={{ fontSize: "12px" }}>
                                    {user.college_name}
                                    </p>
                                </div>
                            </Link>
                            </div> */}
                                    <div className="col-12 col-md-2 col-lg-2">
                                        <div className="mt-2">
                                            <div className="text-center">
                                                <h6 style={{ fontWeight: "600" }}>Profile Image</h6>
                                                <img
                                                    src={user?.profile_image[0] ? user?.profile_image[0] : require("../../Assets/images/noidcard.jpg").default}
                                                    alt="logo1"
                                                    width="65px"
                                                    height="65px"
                                                    style={{ borderRadius: "60px" }}
                                                    loading="lazy"
                                                />
                                            </div>
                                            <div className="mt-3 text-center">
                                                <p style={{ fontWeight: "500" }}>
                                                    {" "}
                                                    {user.first_name} {user.last_name}
                                                </p>
                                            </div>
                                            <div className="text-center">
                                                <p style={{ fontSize: "12px" }}>{user.mobile}</p>
                                            </div>

                                            <div className="mt-3 text-center">
                                                <p style={{ fontSize: "12px" }}>
                                                    {user.college_name}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-8 col-md-8 col-lg-8 mb-2">
                                        <div className="d-flex justify-content-between w-100">
                                            <div className="d-flex flex-column w-50 mr-5">
                                                <h6 style={{ textAlign: "center", marginBottom: "10px" }}>
                                                    College ID Card
                                                </h6>
                                                <img src={user?.college_id ? user?.college_id
                                                    : require("../../Assets/images/noidcard.jpg")
                                                        .default
                                                }
                                                    alt="College Id Card"
                                                    style={{ marginRight: "10px", width: "100%", height: "100%" }}
                                                    loading="lazy"
                                                    onClick={() => window.open(user?.college_id, '_blank')}
                                                />
                                            </div>

                                            <div className="d-flex flex-column w-50">
                                                <h6 style={{ textAlign: "center", marginBottom: "10px", }}>
                                                    Governoment ID Card
                                                </h6>
                                                <img src={
                                                    user.government_id[0]
                                                        ? user.government_id[0]
                                                        : require("../../Assets/images/noidcard.jpg")
                                                            .default
                                                }
                                                    alt="Government Id Card"
                                                    style={{ marginRight: "10px", width: "100%", height: "100%", }}
                                                    loading="lazy"
                                                    onClick={() => window.open(user?.government_id, '_blank')}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    {/* <div className="col-8 mb-2">
                                <div style={{ width: "100%", height: "650px",borderRadius:"4px",position:"relative",overflow:"scroll"}}>
                                <img src={user.college_id[0]?user.college_id[0]:require("../../Assets/images/noidcard.jpg").default}
                                    alt="logo1" width="100%"/>
                                </div>
                                <div>
                                    <a href={user.college_id[0]} className="btn btn-block btn-secondary" target="_blank" rel="noreferrer" >Open image</a>
                                </div>
                            </div> */}

                                    <div className="col-2 text-center">
                                        <button onClick={() => this.isShowPopup(true, user.yin_id, index)} disabled={this.state.buttonFalse} type="button" id="btndispute" className="btn btn-sm rounded-pill Disputebtn border mb-4">x Dispute</button>
                                        <button onClick={() => this.approvedVotes(user.yin_id, index)} disabled={this.state.buttonFalse} type="button" id="btndispute" className="btn btn-sm rounded-pill DisputeApproved border mb-4">Approved</button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>}
                </div>

                <DisputePopup
                    showModalPopup={this.state.showModalPopup}
                    closeModal={this.closeConfirmModal}
                    onPopupCloseMain={this.isClosePopup.bind(this)}
                    handleSubmit={this.handleSubmit}
                    disputeReason={this.setDisputeReason}
                ></DisputePopup>
            </>
        )
    }


}


