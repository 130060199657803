import axios from 'axios';
import React, { Component } from 'react';
import '../../Assets/css/Dispute.css';
import DisputePopup from './DisputePopup';
import VoteDisputetpop from './VoteDisputetpop';
import { Link, Redirect } from "react-router-dom";
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import BackAndBreadCrmOneParent from "../BackAndBreadCumOneParent";

export default class Dispute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModalPopup: false,
            buttonFalse: false,
            isDisputed: false,
            disabled: false,
            redirect: false,
            yinId: localStorage.getItem("dispute_candidate_id"),
            // showVoteDiputePopup: false,
            showVoteDisputetpop: false,
            userData: [],
            first_name: 'Buddy',
            errorCandidate: '',
            last_name: '',
            disputeReason: 'No reason specify',
        };
        this.closeConfirmModal = this.closeConfirmModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setDisputeReason = this.setDisputeReason.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.getCandidateList();
    }

    getCandidateList() {
        axios.get(process.env.REACT_APP_APIURL + `/candidate/yin/` + localStorage.getItem("dispute_candidate_id"))
            .then(response => {
                this.setState({ userData: response.data });
            }).catch(error => {
                console.log(error);
                this.setState({
                    errorCandidate: "No user data found",
                    buttonFalse: true
                });
            })
    }
    isShowPopup = (status) => {
        this.setState({ showModalPopup: status, disabled: true });
    };

    isClosePopup = () => {
        this.setState({ showModalPopup: false });
    };

    isShowVoteDisputetpop = (status) => {
        this.setState({ showVoteDisputetpop: status });
    };

    closeConfirmModal = () => {
        this.setState({ showModalPopup: !this.state.showModalPopup })
    }

    closeConfirmModal = () => {
        this.setState({ showModalPopup: !this.state.showModalPopup })
    }
    disableButton = () => {
        this.setState({
            disabled: !this.state.disabled
        })
    }

    handleSubmit() {
        // event.preventDefault();
        this.setState({
            showModalPopup: false
        })
        let statesSet = this;
        let nominateUserId = {
            user_yinid: this.state.yinId,
            candidate_Yinid: localStorage.getItem("yin_id"),
            disputes_reason: this.state.disputeReason,
        }
        // axios.put(process.env.REACT_APP_VOTINGAPI+`/voting/dispute`,nominateUserId)
        //FIXME: Disabled the below api
        // axios.put(process.env.REACT_APP_APIURL+`/voting/dispute/votes/coordinator`,nominateUserId)
        //     .then(response => {
        //         console.log("Response form api:", response.data)
        //         statesSet.setState({
        //             showVoteDisputetpop: true,
        //         })
        //     }).catch(error => {
        //         console.log(error);
        //     });


    }

    setDisputeReason = async (disputeReasonConfirm) => {
        this.setState({
            disputeReason: disputeReasonConfirm,
        });
    }

    render() {
        if (this.state.isDisputed) {
            return <Redirect to="/college/dispute/list" />
        }
        return (
            <>
                <Sidebar />
                <div className="main">
                    <div className="topbar">
                        <Navbar />
                    </div>
                    <BackAndBreadCrmOneParent backLink="/college/dispute/list" backName="Voter list" currentTabName="Dispute candidate" />

                    <h2 className="text-center"><u>Dispute candidate</u></h2>
                    <br />
                    <div className="container ">
                        {this.state.userData.length > 0 ? <div className="row">
                            {this.state.userData.map((user, index) => (
                                <div className="col-lg-5 col-md-5 col-10 mx-auto border boxShadowAndRadius mt-2" key={index} style={{ backgroundColor: "#fff", borderRadius: "10px", padding: "10px" }}>
                                    <div className="row pt-1">
                                        <div className="col-4">
                                            <img src={user.profile_image[0] ? user.profile_image[0] : require("../../Assets/images/noimahe.png").default} className="rounded-circle" width="90px" height="90px" border-radius="50%" background-size="cover" box-shadow="0 0 0 1px #ee" alt="" />
                                        </div>
                                        <div className="col-8" style={{ position: "relative" }} >
                                            <div className="candidatenamedispute">
                                                {/* <span style={{fontSize:"12px"}}>Your are viewing</span><br/> */}
                                                <label><h5><b>{user.first_name} {user.last_name}</b></h5></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mt-1"><h6>College ID Card:</h6></div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <div className="Documents">
                                                <img src={user.college_id[0] ? user.college_id[0] : require("../../Assets/images/noidcard.jpg").default} alt="college_id" style={{ width: "100%", height: "100%" }} />
                                            </div>
                                        </div>


                                    </div>
                                    <div className="row text-center">
                                        <div className="errorMsg" style={{ color: "red" }}>{this.state.errorCandidate}</div>
                                    </div>
                                    <div className="text-center">
                                        <Link to="/college/dispute/list" className="btn btn-sm rounded-pill DisputebtnCancel  border mb-4">Cancel</Link>
                                        <button onClick={() => this.isShowPopup(true)} disabled={this.state.buttonFalse} type="button" id="btndispute" className="btn btn-sm rounded-pill Disputebtn border mb-4">x Dispute</button>
                                    </div>
                                </div>
                            ))}


                        </div> : <div className="row text-center">
                            <div className="col-12">No User Found</div>
                        </div>}
                        <div className="divider-40"></div>
                    </div>
                </div>
                <DisputePopup
                    showModalPopup={this.state.showModalPopup}
                    closeModal={this.closeConfirmModal}
                    onPopupCloseMain={this.isClosePopup.bind(this)}
                    showNext={this.isShowVoteDisputetpop.bind(this)}
                    handleSubmit={this.handleSubmit}
                    disputeReason={this.setDisputeReason}
                ></DisputePopup>

                {this.state.showVoteDisputetpop ? <VoteDisputetpop
                    showVoteDisputetpop={this.state.showVoteDisputetpop}
                    showModalPopup={this.state.showModalPopup}
                    onPopupClose={this.isShowVoteDisputetpopMain.bind(this)}
                    disableButton={this.disableButton.bind(this)}
                    showNext={this.isShowVoteDisputetpop.bind(this)}
                ></VoteDisputetpop> : ""}
            </>
        )
    }

    isShowVoteDisputetpopMain() {
        this.isClosePopup();
        this.setState({
            showVoteDisputetpop: false,
            buttonFalse: true,
            isDisputed: true,
        })
    }
}

