import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom'
import axios from 'axios';

class ScannerPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            showVoteCastModal: true,
            showModalPopup2: false,
            operator: JSON.parse(localStorage.getItem("operatorrrr"))
        };

        this.addAttandance = this.addAttandance.bind(this);
        this.popupAlreadyClose = this.popupAlreadyClose.bind(this);
    }
    isShowModal = () => {
        this.handleClose();
    }

    handleClose = () => {
        this.props.onPopupCloseMain();
    }

    handleClose2 = () => {
        this.setState({
            showModalPopup2: false
        });
    }

    UNSAFE_componentWillMount() {
        const scannedData = this.props.result;
        let currentComponent = this;
        let data = {
            "event_id": scannedData.event_id,
            "yin_id": scannedData.yin_id,
            "mobile": scannedData.mobile,
            "email": scannedData.email,
            // "operator":this.state.operator.email?this.state.operator.email:"younginspiratornetwork@gmail.com"
        }
        // this.props.onPopupCloseMain();
        axios.post(process.env.REACT_APP_TALK_API + `/registerevent/set/attendance`, data)
            .then(res => {
                if (res.data === "Already attended") {
                    currentComponent.setState({
                        showModalPopup2: true
                    });
                }
            }).catch((error) => {
                console.log("-----------error UNSAFE_componentWillMount-----------", error);
            });

    }

    addAttandance = (eventId, yinId, mobile) => {
        // let currentComponent = this;
        //     let data={
        //         "event_id":eventId,
        //         "yin_id":yinId,
        //         "mobile":mobile,
        //         "operator":this.state.operator.email?this.state.operator.email:"younginspiratornetwork@gmail.com"
        //     }
        this.props.onPopupCloseMain();
        // axios.post(process.env.REACT_APP_TALK_API + `/registerevent/set/attendance`, data)
        // .then(res=>{
        //     if(res.data === "Already attended"){
        //         currentComponent.setState({
        //             showModalPopup2:true
        //         });
        //     }
        // }).catch((error)=>{
        //     console.log("-----------error-----------",error);
        // });

    }

    popupAlreadyClose() {
        this.setState({
            showModalPopup2: false
        });
    }
    render() {
        const result = this.props.result;
        return (
            <>
                <div className="DisputePopup" >
                    <Modal show={this.props.showModalPopup} onHide={this.handleClose}
                        centered>
                        <Modal.Header closeButton><b>DATA</b></Modal.Header>
                        <Modal.Body style={{ textAlign: "center" }}>
                            {/* <div>
                           <img src={this.props.imageUrl} alt="collegeId" style={{width:"100%",display:"inline-block"}} />
                           "yin_id":"MHNGP9405636528","name":"Yash  Khatri","email":"iamyashkhatri.00@gmail.com","mobile":9405636528,"college_name":"K.D.K.Engineering College","event_id":"EVENT_YINSUMMERYOUTHSUMMIT_1646716283926"
                        </div> */}
                            <h2>{result.name}</h2>
                            <div>{result.yin_id}</div>
                            <div>{result.email}</div>
                            <div>{result.mobile}</div>
                            <div>{result.college_name}</div>
                            <div>{result.event_id}</div>
                        </Modal.Body>
                        <Modal.Footer >
                            <Button onClick={() => this.addAttandance(result.event_id, result.yin_id, result.mobile)} className="btn btn-sm rounded-pill DisputebtnCancel border mb-2">Ok</Button>
                        </Modal.Footer>
                    </Modal>


                    <Modal show={this.state.showModalPopup2} onHide={this.handleClose2}
                        centered>
                        <Modal.Header closeButton><b>KIT already taken warning</b></Modal.Header>
                        <Modal.Body style={{ textAlign: "center" }}>

                            <h2>{result.name}</h2>
                            <div>{result.mobile}</div>
                            <div>KIT Already taken by this student</div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.props.onPopupCloseMain} className="btn btn-sm rounded-pill DisputebtnCancel border mb-2">Close</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </>
        )
    }
}

export default withRouter(ScannerPopup);