import React, { Component } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Select from "react-select";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import { Redirect } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

export default class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      redirect: false,
      id: "",
      title: "",
      category: "",
      mainPoint: "",
      description: "",
      image: "",
      url: "",
      article_image: "",
      imagesource: "",
      attribution: "",
      youtubeVideoId: "",
      dropDownOptCategoryList: [],
      mainPoints: [],
      errors: {},
      isSelect: false,
      spinner: false,
      tags: [
        { id: 1, name: "Apples" },
        { id: 2, name: "Pears" },
      ],
      suggestions: [
        { id: 3, name: "Bananas" },
        { id: 4, name: "Mangos" },
        { id: 5, name: "Lemons" },
        { id: 6, name: "Apricots" },
      ],
    };

    this.reactTags = React.createRef();
    this.initialState = this.state;
    this.imageChangedHandler = this.imageChangedHandler.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeTags = this.handleChangeTags.bind(this);
    this.removeTags = this.removeTags.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      errors: {
        ...this.state.errors,
        [event.target.name]: "",
      },
    });
  };
  handleCategoryChange(event) {
    this.setState({
      [event.name]: event.value,
    });
  }

  onDelete = (i) => {
    const tags = this.state.tags.slice(0);
    tags.splice(i, 1);
    this.setState({ tags });
  };

  onAddition = (tag) => {
    const tags = [].concat(this.state.tags, tag);
    this.setState({ tags });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleChangeTags = (event) => {
    this.setState({
      mainPoint: event.target.value,
    });
  };

  imageChangedHandler = (event) => {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      this.setState({
        article_image: event.target.files[0],
        image: URL.createObjectURL(event.target.files[0]),
      });
    }
  };

  handleFormValidation = () => {
    const {
      title,
      category,
      mainPoints,
      description,
      imagesource,
      image,
      url,
      attribution,
      youtubeVideoId,
    } = this.state;
    let errors = {};
    let formIsValid = true;

    if (!title) {
      errors["title"] = "Please enter the event title.";
      formIsValid = false;
    } else if (!title.match(/^[a-zA-Z ]*$/)) {
      errors["title"] = "Please enter alphabet characters only.";
      formIsValid = false;
    }

    if (!description) {
      errors["description"] = "Please give your answer.";
      formIsValid = false;
    }

    if (!category) {
      errors["category"] = "Please select a category.";
      formIsValid = false;
    }

    if (!this.state.tags) {
      errors["tags"] = "Please give your answer.";
      formIsValid = false;
    }

    if (mainPoints.length === 0) {
      errors["mainPoints"] = "Please give your answer.";
      formIsValid = false;
    }

    if (!image) {
      errors["image"] = "Please upload image.";
      formIsValid = false;
    }

    if (!imagesource) {
      errors["imagesource"] = "Please give your answer.";
      formIsValid = false;
    }

    if (!url) {
      errors["url"] = "Please give your answer.";
      formIsValid = false;
    }

    // if (!attribution) {
    //   errors["attribution"] = "Please give your answer.";
    //   formIsValid = false;
    // }

    // if (!youtubeVideoId) {
    //   errors["youtubeVideoId"] = "Please give your answer.";
    //   formIsValid = false;
    // }

    const firstErrorField = Object.keys(errors)[0];
    const element = document.getElementById(firstErrorField);
    if (element) {
      element.focus();
      const errorElement = document.getElementById(firstErrorField + "error");
      if (errorElement) {
        errorElement.innerText = errors[firstErrorField];
      }
    }

    this.setState({ errors });
    return formIsValid;
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.handleFormValidation()) {
      let formData = new FormData();
      formData.append("title", this.state.title);
      formData.append("category", this.state.category);
      formData.append("tags", this.state.mainPoints);
      formData.append("description", this.state.description);
      formData.append("articleUrl", this.state.url);
      formData.append("image", this.state.article_image);
      formData.append("source", this.state.imagesource);
      //formData.append("attribution", this.state.attribution);
      //formData.append("youtubeVideoId", this.state.youtubeVideoId);

      this.setState({ spinner: true });

      axios
        .post(
          process.env.REACT_APP_APIURL + `/contents/article/create`,
          formData
        )
        .then((res) => {
          if (res.data) {
            alert("Content Added successfully");
            this.setState({
              redirect: true,
              changeToView: true,
            });
          }
        })
        .catch((err) => {
          alert(err);
        });
    } else {
      this.handleFormValidation();
    }
  };

  UNSAFE_componentWillMount() {
    this.getCategory();
  }

  getCategory = async () => {
    const API = await axios.get(
      process.env.REACT_APP_APIURL + `/Contents/get/category/all`
    );
    const serverResponse = API.data;
    const dropDownValue = serverResponse.map((response) => ({
      value: response.title,
      label: response.title,
      name: "category",
    }));

    this.setState({
      dropDownOptCategoryList: dropDownValue,
    });
  };

  onChangeForCategoryListDropDown(event) {
    this.setState({
      id: event.value,
      category: event.label,
      isSelect: true,
    });
    localStorage.setItem("id", event.value);
    localStorage.setItem("category", event.label);
  }

  addTags(event) {
    this.state.mainPoints.push(this.state.mainPoint);
    this.setState({
      mainPoint: "",
    });
  }

  removeTags(index) {
    console.log("----------- index-------", index);
  }

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to="/contentlist" />;
    }
    var listItems = this.state.mainPoints.map(function (d, idx) {
      return (
        <li key={idx} onClick={() => this.removeTags(idx)}>
          {d}
        </li>
      );
    });

    const { errors } = this.state;

    return (
      <>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>
          {this.state.spinner === true ? (
            <div className="text-center mt-5">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="container" style={{ marginBottom: "50px" }}>
              <BackAndBreadCrmOneParent
                backName="contentlist"
                backLink="/contentlist"
                currentTabName="Addnewcontent"
              />

              <div className="row mt-3">
                <div className="col-12 col-md-8 col-lg-8 m-auto">
                  <div className="card rounded shadow shadow-sm">
                    <div className="card-header">
                      <h3 className="mb-0"> Add Content </h3>
                    </div>

                    <div className="card-body mt-2">
                      <form
                        id="formcontent"
                        onSubmit={this.handleSubmit}
                        className="form"
                      >
                        <Row>
                          <Col sm={12} md={6}>
                            <label className="control-label" htmlFor="Titles">
                              Title<span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <input
                              type="text"
                              id="title"
                              name="title"
                              className="form-control"
                              value={this.state.title}
                              onChange={this.handleChange}
                              placeholder="Enter Title"
                            />
                            {errors["title"] && (
                              <div className="errorMsg">{errors["title"]}</div>
                            )}
                          </Col>
                          <Col sm={12} md={6}>
                            <label
                              htmlFor="category_names"
                              className="control-label"
                              name="category"
                              id="category"
                            >
                              Select Category
                              <span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <Select
                              options={this.state.dropDownOptCategoryList}
                              name={this.state.dropDownOptCategoryList.title}
                              onChange={this.handleCategoryChange}
                              className="react-select"
                              classNamePrefix="react-select"
                              id="category"
                            />
                            {errors["category"] && (
                              <div className="errorMsg">
                                {errors["category"]}
                              </div>
                            )}

                          </Col>
                        </Row>

                        <Row className="d-flex align-items-center">
                          <Col sm={10} md={12}>
                            <label>
                              Enter Tags
                              <span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <div className="d-flex">
                              <input
                                type="text"
                                id="mainPoints"
                                name="mainPoints"
                                className="form-control"
                                placeholder="Enter tag"
                                value={this.state.mainPoint}
                                onChange={this.handleChangeTags}
                              />
                              <i
                                className="fa fa-plus fa-2x ms-2" // Add margin to the plus icon
                                id="addPoint"
                                onClick={this.addTags.bind(this)}
                              ></i>
                            </div>
                            {errors["mainPoints"] && (
                              <div className="errorMsg">
                                {errors["mainPoints"]}
                              </div>
                            )}
                            <div className="col-12">
                              <ul>{listItems}</ul>
                            </div>
                          </Col>
                        </Row>

                        <Row className="mt-2">
                          <Col sm={12} md={12} xl={6}>
                            <label htmlFor="image">
                              Upload Photo
                              <span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <input
                              type="file"
                              id="image"
                              name="college_id"
                              className="form-control-file"
                              accept="image/*"
                              onChange={this.imageChangedHandler}
                              style={{ marginLeft: "15px" }}
                            />
                            {this.state.image ? (
                              <img
                                id="image"
                                src={this.state.image}
                                alt=""
                                width="200px"
                                height="80px"
                              />
                            ) : (
                              ""
                            )}

                            {errors["image"] && (
                              <div className="errorMsg">{errors["image"]}</div>
                            )}
                          </Col>
                          <Col sm={12} md={12} xl={6} >
                            <label htmlFor="imagesource">
                              Image Source
                              <span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <input
                              type="text"
                              id="imagesource"
                              name="imagesource"
                              className="form-control"
                              placeholder="Image Source"
                              onChange={this.handleChange}
                            />
                            {errors["imagesource"] && (
                              <div className="errorMsg">
                                {errors["imagesource"]}
                              </div>
                            )}
                          </Col>
                        </Row>


                        {/* <div className="col-md-12 mt-3">
                          <div className="row">
                            <div className="col-10">
                              <div className="form-group">
                                
                              </div>
                            </div>
                            <div className="col-2 mt-4">
                              
                            </div>
                            <div className="col-12">
                              <ul>{listItems}</ul>
                            </div>
                          </div>
                        </div> */}

                        <Row>
                          <Col sm={12} md={12}>
                            <label
                              className="control-label"
                              htmlFor="Purpose Of Travel"
                            >
                              <span style={{ color: "#ff0000" }}>*</span>
                              URL
                            </label>
                            <input
                              type="text"
                              id="url"
                              name="url"
                              className="form-control"
                              placeholder="https://www.abc.com"
                              value={this.state.url}
                              onChange={this.handleChange}
                            />
                            {errors["url"] && (
                              <div className="errorMsg">{errors["url"]}</div>
                            )}
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={12} md={12}>
                            <label>Description  <span style={{ color: "#ff0000" }}>*</span></label>
                            <textarea
                              id="description"
                              name="description"
                              className="form-control"
                              value={this.state.description}
                              onChange={this.handleChange}
                              placeholder="Add description here..."
                            ></textarea>
                            {errors["description"] && (
                              <div className="errorMsg">
                                {errors["description"]}
                              </div>
                            )}
                          </Col>
                        </Row>
                        <div className="row">
                          <div className="buttondispaly text-center">
                            <button
                              type="submit"
                              id="btnSubmitSignup"
                              className="btn btn-dark btn-lg btn-block mt-4"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
