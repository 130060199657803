import React from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";

class ElectionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      college_name: "",
      colleges: [],
      college_code: [],
      collegeCodes: [],
      college_key: [],
      loader: true,
      //for dropdown purpose
      state_code: "",
      city_code: "",
      state: "",
      city: "",
      StateData: [],
      CityData: [],
      errors: {},
    };

    this.showCity = this.showCity.bind(this);
  }

  showCity() {
    axios.get(process.env.REACT_APP_APIURL + `/address/city/MH`)
      .then((response) => {
        this.setState({
          CityData: response.data,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }

  UNSAFE_componentWillMount() {
    this.getData();
    this.showCity();
  }

  getData() {
    let currentComponent = this;
    axios
      .get(process.env.REACT_APP_APIURL + `/college/election/list`)
      .then((res) => {
        let ElectionList = res.data.response;

        currentComponent.setState({
          colleges: ElectionList,
          loader: false,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      })
  }

  render() {
    let election_list = "";
    if (this.state.colleges) {
      election_list = this.state.colleges.map((college, index) => (
        <tr key={college.college_code}>
          <td>{index + 1}</td>
          <td> {college.college_name} </td>
          <td> {college.city} </td>
          <td> {college.type} </td>
          <td> {college.approver_status} </td>
        </tr>
      ));
    } else {
      election_list = (
        <tr>
          <td>No Data Found....</td>
        </tr>
      );
    }

    return (
      <>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>
          <div className="container">
            <BackAndBreadCrmOneParent
              backLink="/view-card"
              backName="View card"
              currentTabName="Election List"
            />
            <div className="row">
              <div className="col-12">
                <h2 className="text-center">Election List</h2>
                <br></br>
              </div>
            </div>
          </div>
          <br></br>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="table-responsive-sm">
                  <table className="table table-bordered table-responsive">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Collegename</th>
                        <th>City</th>
                        <th>Type</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>{election_list}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ElectionList;
