import React from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import ReactPaginate from 'react-paginate';
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRoundedWithText from "./LoaderRoundedWithText";
import LoaderRounded from "./LoaderRounded";
import "../Assets/css/Collagename.css";
import * as Icon from 'react-bootstrap-icons';

class EventCoordinatorCount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collegeData: [],
      CityData: [],
      // cities:[],
      currentFilter: "",
      currentFilterBy: "",
      errors: "",
      isError: false,
      districtsLocal: JSON.parse(localStorage.getItem("districts")),
      currentPage: "",
      counts: "",
      totalPages: "",
      previousLimits: 100,
      renderPageLimit: 10,
      selectedPage: 0,
      loading: true,
      loader: false,
    };
    this.applyFiter = this.applyFilter.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.showCity = this.showCity.bind(this);
    this.getData = this.getData.bind(this);
    this.storeCollegeCode = this.storeCollegeCode.bind(this);
    this.searchFromList = this.searchFromList.bind(this);
    this.getCollegeWithCity = this.getCollegeWithCity.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  handlePageChange(page) {
    this.setState({ selectedPage: page.selected });
  }

  UNSAFE_componentWillMount() {
    this.showCity();
    this.getData();
  }
  componentWillMount() {
    setTimeout(() => this.setState({ loading: false }), 1000);
  }

  getCollegeWithCity(event) {
    this.setState({
      currentFilter: event.target.value,
      currentFilterBy: event.target.name,
    });
  }

  storeCollegeCode(collegeCode, collegeName) {
    localStorage.setItem("collegeCode", collegeCode);
    localStorage.setItem("college_code", collegeCode);
    localStorage.setItem("college_name", collegeName);
  }

  getData() {
    this.setState({
      loader: true,
    });
    let currentComponent = this;
    // let subcity = localStorage.getItem("sub_city_name");
    // let cityData = {
    //     city:this.state.districtsLocal,
    //     sub_city:subcity
    // }
    // axios.get(process.env.REACT_APP_APIURL +"/candidate/get/coordinaor/wise/count").then(res=>{
    axios.get(process.env.REACT_APP_APIURL + "/candidate/get/coordinaor/wise/count/event")
      .then((res) => {
        let response = res.data;
        if (Array.isArray(response)) {
          this.setState({
            collegeData: response,
            loader: false,
            errors: "",
          });
        }
      })
      .catch((error) => {
        this.setState({
          collegeData: [],
          loader: false,
          isError: true,
          errors:
            "While executing query it will take more time to execute. Please refresh page or try again later...",
        });
      });
  }

  showCity() {
    let districts = JSON.parse(localStorage.getItem("districts"));
    if (districts[0] !== "*") {
      this.setState({
        CityData: districts,
      });
    } else {
      axios.get(process.env.REACT_APP_APIURL + `/address/city/MH`)
        .then((response) => {
          this.setState({
            CityData: response.data,
          });
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  }

  applyFilter(arr) {
    if (!this.state.currentFilter) {
      return arr;
    }
    let filteron = this.state.currentFilterBy;
    if (filteron === "city") {
      if (this.state.currentFilter === "Pune") {
        let subcity = localStorage.getItem("sub_city_name");
        if (subcity) {
          return arr.filter((x) => x.sub_city === subcity);
        } else {
          return arr.filter((x) => x.districts === this.state.currentFilter);
        }
      } else {
        return arr.filter((x) => x.first_name === this.state.currentFilter);
      }
    } else {
      var condition = new RegExp(this.state.currentFilter);
      var result = arr.filter(function (el) {
        return condition.test(el.first_name);
      });
      return result;
    }
  }

  setFilter(e) {
    e.preventDefault();
    this.setState({
      currentFilter: e.target.value,
    });
  }

  searchFromList(event) {
    this.setState({
      currentFilter: event.target.value,
      currentFilterBy: event.target.name,
    });
  }

  render() {
    let collegeList = "";
    //let totalCount = 0; 
    if (this.state.collegeData) {
      collegeList = this.applyFilter(this.state.collegeData).map((colleges, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>
            {colleges.first_name} {colleges.last_name}
          </td>
          <td style={{ textAlign: "center" }}>
            {colleges.districts}
            {colleges.sub_city ? <>({colleges.sub_city})</> : ""}
          </td>
          <td>
            <div>{colleges.mobile}</div>
          </td>
          <td>
            <div className="numberCircle-center_count1">
              {colleges.phase1_userCount}
            </div>
          </td>

        </tr>
      ));
    } else {
      collegeList = (
        <tr>
          <td colSpan="6" className="text-center">No Data Found....</td>
        </tr>
      );
    }

    // let cities;
    // if (this.state.districtsLocal[0] !== "*") {
    //   cities = this.state.CityData.map((e, key) => {
    //     return (
    //       <option key={key} value={e}>
    //         {e}
    //       </option>
    //     );
    //   });
    // } else {
    //   cities = this.state.CityData.map((e, key) => {
    //     return (
    //       <option key={key} value={e.name}>
    //         {e.name}
    //       </option>
    //     );
    //   });
    // }

    return (
      <div>
        {this.state.loading ? (
          <LoaderRoundedWithText />
        ) : (
          <div>
            <Sidebar />
            <div className="main">
              <div className="topbar">
                <Navbar title={"Event co-ordinator wise count"} />
              </div>

              <div className="container mt-3">
                <BackAndBreadCrmOneParent
                  backLink="/dashboard"
                  currentTabName="Coordinator wise count"
                />
                <div className="row">
                  <div className="col-12">
                    <br></br>
                  </div>
                </div>
                {/* <div className="row m-4">
              <div className="col-6">
                <input
                  type="text"
                  placeholder="Search here"
                  name="search"
                  onChange={this.searchFromList}
                  className="form-control"
                ></input>
              </div>

              <div className="col-6">
                <select
                  type="button"
                  name="city"
                  className="btn btn-secondary btn-md dropdown-toggle"
                  onChange={this.getCollegeWithCity}
                  id="district"
                  style={{ float: "right", width: "120px" }}
                >
                  <option value={null}> --District-- </option>
                  {cities}
                </select>
              </div>
             </div> */}
              </div>

              <div className="container">
                {/* <input type="checkbox" className="form-check-input" onChange={this.handleCheckbox} /> */}
                {/* <label className="form-check-label ms-2">Select All</label> */}
                {/* {this.state.isError ? (
                  <b>{this.state.errors}</b>
                ) : this.state.loader ? (
                  <LoaderRounded />
                ) : ( */}
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive-sm">
                      <table className="table table-bordered table-responsive">
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>Coordinator name</th>
                            <th style={{ textAlign: "center" }}>City</th>
                            <th style={{ textAlign: "center" }}>Mobile</th>
                            <th style={{ textAlign: "center" }}>
                              Event Member Count
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.loader ?
                            (<LoaderRounded />) :
                            (
                              collegeList.length > 0 &&
                              collegeList.slice(
                                this.state.selectedPage *
                                this.state.renderPageLimit,
                                this.state.renderPageLimit +
                                this.state.selectedPage *
                                this.state.renderPageLimit
                              )
                            )}
                          {/* {this.state.loader ? (
                            <div
                              style={{
                                display: "flex",
                                width: "80vw",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <LoaderRounded />
                            </div>
                          ) : (
                            collegeList.length > 0 &&
                            collegeList.slice(
                              this.state.selectedPage *
                                this.state.renderPageLimit,
                              this.state.renderPageLimit +
                                this.state.selectedPage *
                                  this.state.renderPageLimit
                            )
                          )} */}

                        </tbody>
                      </table>
                    </div>
                  </div>
                  <ReactPaginate
                    nextLabel="Next"
                    previousLabel="Previous"
                    pageCount={collegeList.length / this.state.renderPageLimit}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    containerClassName="pagination"
                    activeClassName="btn btn-primary"
                    pageClassName="btn"
                    previousClassName="btn"
                    nextClassName="btn"
                    onPageChange={this.handlePageChange}
                  />
                </div>
                {/* )} */}
              </div>
              <button
                className="reaload btn btn-success btn-sm mt-3 mb-3"
                onClick={() => window.location.reload(true)}
              >
                <Icon.ArrowClockwise size={18} className="me-2" />
                Refresh
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default EventCoordinatorCount;
