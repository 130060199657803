import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import '../../Assets/css/Dispute.css';
import { withRouter } from 'react-router-dom'

class VoterVerifyPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            showVoteCastModal: true,
            value: 'yes',
            rejectReason:'ID Crad not clear',
            errors:'',
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitForRejectionIDCard = this.handleSubmitForRejectionIDCard.bind(this)
    }

    isShowModal = () => {
        this.handleClose();
    }

    handleClose = () => {
        this.props.onPopupCloseMain();
    }

    handleChange(event){
        this.setState({
            rejectReason: event.target.value
        });
        this.props.rejectCollegeIdCardReason(event.target.value);
    }

    handleSubmitForRejectionIDCard(){
        if(this.state.rejectReason.length>0){
            this.props.handleSubmit();
        }else{
            this.setState({
                errors:"Please select reject reason"
            });
        }
    }

    render() {
        return (
            <>
                {/* DisputePopup model popup*/}
                <div className="DisputePopup" style={{ height:"200px",width:"180px"}}>
                <Modal show={this.props.showModalPopup} onHide={this.handleClose}
                    
                    centered>
                    <Modal.Header closeButton><b>Confirm college ID card rejection?</b></Modal.Header>
                    <Modal.Body>
                        <div>
                            <p><small>Are you sure you want to reject this college ID Card?</small></p>
                            <div className="textOnInput">
                                        <div className="col-md-12 mt-1">
                                            <label htmlFor="inputText"><span style={{ color: "#ff0000" }}>*</span> Select reason of rejection</label>
                                           <div className="p-2">
                                            <input
                                                type="checkbox"
                                                id="reason1"
                                                name="reason1"
                                                value="Image quality: Blurred or poor quality image upload"
                                                onChange={this.handleChange}
                                            />
                                            <label htmlFor="reason1">&nbsp;Image quality: Blurred or poor quality image upload</label>
                                            <br/>
                                            <input
                                                type="checkbox"
                                                id="reason2"
                                                name="reason2"
                                                value="Invalid Image: Non-relevant image upload"
                                                onChange={this.handleChange}
                                                />
                                            <label htmlFor="reason2">&nbsp;Invalid Image: Non-relevant image upload</label>
                                            <br/>
                                            <input
                                                type="checkbox"
                                                id="reason3"
                                                name="reason3"
                                                value="Invalid file: College issued photo identity required"
                                                onChange={this.handleChange}
                                                />
                                            <label htmlFor="reason3">&nbsp;Invalid file: College issued photo identity required</label>
                                            <br/>
                                            <input
                                                type="checkbox"
                                                id="reason4"
                                                name="reason4"
                                                value="Duplicate entries: Identical images observed"
                                                onChange={this.handleChange}
                                                />
                                            <label htmlFor="reason4">&nbsp;Duplicate entries: Identical images observed</label>

                                            <br/>
                                            <input
                                                type="checkbox"
                                                id="reason5"
                                                name="reason5"
                                                value="Data mismatch: Data in the image is incompatible with the user's information"
                                                onChange={this.handleChange}
                                                />
                                            <label htmlFor="reason5">&nbsp;Data mismatch: Data in the image is incompatible with the user's information</label>

                                            <br/>
                                            <input
                                                type="checkbox"
                                                id="reason6"
                                                name="reason6"
                                                value="Multi-image: Multiple images have been posted"
                                                onChange={this.handleChange}
                                                />
                                            <label htmlFor="reason6">&nbsp;Multi-image: Multiple images have been posted</label>

                                            <br/>
                                            <input
                                                type="checkbox"
                                                id="reason7"
                                                name="reason7"
                                                value="Modified/Morphing image"
                                                onChange={this.handleChange}
                                                />
                                            <label htmlFor="reason6">&nbsp;Modified/Morphing image</label>

                                            <br/>
                                            <input
                                                type="checkbox"
                                                id="reason7"
                                                name="reason7"
                                                value="Repeated images"
                                                onChange={this.handleChange}
                                                />
                                            <label htmlFor="reason6">&nbsp;Repeated images</label>


                                           </div>

                                            <div className="errorMsg">{this.state.errors}</div>
                                        </div>
                                    </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.props.closeModal}  className="btn btn-sm rounded-pill DisputebtnCancel border mb-2">No</Button>
                        <Button onClick={this.handleSubmitForRejectionIDCard}  className="btn btn-sm rounded-pill Disputebtn border mb-2">Yes</Button>
                    </Modal.Footer>
                </Modal>
                </div>
            </>
        )
    }
}

export default withRouter(VoterVerifyPopup);