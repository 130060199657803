import React, { Component } from "react";
import profile from "../Assets/images/profileimage.jpg";
import "../Assets/css/Dashboard.css";

export default class NavbarScanner extends Component {
  constructor() {
    super();

    this.state = {
      mobile: localStorage.getItem("mobile"),
      displayMenu: false,
    };

    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
  }

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false });
  }

  logout() {
    localStorage.clear();
  }

  render() {
    function toggleMenu() {
      let toggle = document.querySelector(".toggle");
      let navigation = document.querySelector(".navigation");
      let main = document.querySelector(".main");
      toggle.classList.toggle("active");
      navigation.classList.toggle("active");
      main.classList.toggle("active");
    }
    return (
      <div className="container">
        <div className="topbar">
          <div className="toggle" onClick={toggleMenu}></div>

          <div className="dropdown">
            <img
              src={profile}
              alt="photos"
              onClick={this.showDropdownMenu}
              width="60"
            />

            {this.state.displayMenu ? (
              <ul className="dropdownOnNavbarUL">
                <li className="dropdownOnNavbarLI">
                  <a href="/Profile" onClick={this.hideDropdownMenu}>
                    Profile
                  </a>
                </li>

                <li className="dropdownOnNavbarLI">
                  <a href="/operator/login" onClick={this.logout}>
                    Log Out
                  </a>
                </li>
              </ul>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
