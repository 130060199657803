/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react'
import Navbar from './NavbarScanner';
import Sidebar from './SidebarScanner';
// import QrReader from 'react-qr-scanner';
import QrReader from 'react-web-qr-reader';
import ScannerPopup from './ScannerPopup';
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";



export class Scanner extends Component {

    constructor(props) {
        super(props)
        this.state = {
            result: 'No Result',
            isShowPopup: false,
            showModalPopup: false,
            facingModes: "environment",
            eventiddata: JSON.parse(localStorage.getItem('operatorrrr')),
        }

        this.handleScan = this.handleScan.bind(this);
        this.isShowPopup = this.isShowPopup.bind(this);
        this.cameraMode = this.cameraMode.bind(this)
    }

    isShowPopup = (status) => {
        this.setState({ showModalPopup: status });
    }

    isClosePopup = () => {
        this.setState({ showModalPopup: false });
    }


    handleScan(data) {
        try {
            if (data != null || data !== undefined) {
                this.setState({
                    result: JSON.parse(data.data)
                });
                this.isShowPopup(true);
            }
        } catch (e) {
            alert("Scanned QR is Invalid", e);

        }
    }

    handleEror(err) {
        console.log(err)
    }
    cameraMode(data) {
        this.setState({
            facingModes: data
        })
    }


    render() {
        // if(this.state.result === undefined){
        //     alert("Please Scan the QR code")
        // }
        // else 
        //     {
        //         alert(this.state.result.text)
        //     }
        const previewStyle = {
            height: 300,
            width: 300,
            display: 'flex',
            justifyContent: "center"
        }

        const camStyle = {
            display: 'flex',
            justifyContent: "center",
        }

        return (
            <>
                <Sidebar />
                <div className='main'>
                    <div className='topbar'>
                        <Navbar />
                    </div>


                    <div className="container">
                        <div className='row'>
                            <div className='col-12'>
                                <h2 className="text-center"><u><strong>QR CODE SCANNER</strong></u></h2>
                            </div>
                        </div>

                        <div className='row'>
                            <div style={camStyle}>
                                <QrReader
                                    delay={1000}
                                    style={previewStyle}
                                    onError={this.handleEror}
                                    onScan={this.handleScan}
                                    facingMode={this.state.facingModes}
                                />
                            </div>
                            <div className='row'>
                                <div className="btn-group btn-group-toggle mt-2 mb-5" data-toggle="buttons">
                                    <label className="btn  " id="frontCamera" style={{ color: "#000", border: "1px solid #ccc" }} onClick={() => this.cameraMode("user")}>
                                        <input type="radio" name="options" value="1" /> Front Camera
                                    </label>
                                    <label className="btn " id="backCamera" style={{ color: "#000", border: "1px solid #ccc" }} onClick={() => this.cameraMode("environment")}>
                                        <input type="radio" name="options" value="2" /> Back Camera
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showModalPopup ? <ScannerPopup
                    showModalPopup={this.state.showModalPopup}
                    result={this.state.result}
                    onPopupCloseMain={this.isClosePopup.bind(this)}
                ></ScannerPopup> : ""}
            </>
        )
    }
}

export default Scanner
