import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import '../../Assets/css/Dispute.css';
import { withRouter } from 'react-router-dom'

class DisputePopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            showVoteCastModal: true,
            value: 'yes',
            disputeReason: 'Invalid Image: Non-relevant image upload',
            errors: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitForDispute = this.handleSubmitForDispute.bind(this)
    }

    isShowModal = () => {
        this.handleClose();
    }

    handleClose = () => {
        this.props.onPopupCloseMain();
    }

    handleChange(event) {
        // console.log("dispute user-------------------------");
        this.setState({
            disputeReason: event.target.value
        });
        this.props.disputeReason(event.target.value);

    }

    handleSubmitForDispute() {
        // if(this.state.disputeReason){
        // console.log("dispute user----------------if---------",this.state.disputeReason);
        this.props.handleSubmit();
        // }else{
        //     // console.log("dispute user-----------------else-------");
        //     this.setState({
        //         errors:"Please specify dispute reason"
        //     });
        // }
    }

    render() {
        return (
            <>
                {/* DisputePopup model popup*/}
                <div className="DisputePopup" style={{ height: "200px", width: "180px" }}>
                    <Modal show={this.props.showModalPopup} onHide={this.handleClose}

                        centered>
                        <Modal.Header closeButton><b>Confirm dispute vote?</b></Modal.Header>
                        <Modal.Body>
                            <div>
                                <p><small>Are you sure you want to dispute this vote?<br /> This action cannot be undone!</small></p>
                                <div className="textOnInput">
                                    <div className="col-md-12 mt-5">
                                        <label htmlFor="inputText"><span style={{ color: "#ff0000" }}>*</span> Reason for dispute</label>
                                        {/* <input
                                                className="form-control"
                                                placeholder="Reason for dispute"
                                                onChange={this.handleChange}
                                                type="text"
                                                name="disputes_reason"
                                            /> */}
                                        <textarea id="inputText"
                                            className="form-control"
                                            name="disputes_reason"
                                            rows="2"
                                            onChange={this.handleChange}
                                            placeholder="Reason for dispute"
                                            defaultValue="Invalid Image: Non-relevant image upload"
                                            required={true}
                                        ></textarea>
                                        <div className="errorMsg">{this.state.errors}</div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.props.closeModal} className="btn btn-sm rounded-pill DisputebtnCancel border mb-2">No</Button>
                            <Button onClick={this.handleSubmitForDispute} className="btn btn-sm rounded-pill Disputebtn border mb-2">Yes</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </>
        )
    }
}

export default withRouter(DisputePopup);