import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import S3 from "react-aws-s3";
import Compressor from "compressorjs";
import moment from "moment";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import "../Assets/css/Dashboard.css";
import Select from "react-select";

export default class EditAmplifier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amplifier_id: "",
      title: "",
      description: "",
      imageURL: [],
      category: "",
      eventLink: "",
      redirect: false,
      errors: {},
      loader: false,
      amplifierDate: "",
      dropDownOptCategoryList: [],
      year: this?.props.location.state.year,
    };
    this.uploadImageToS3 = this.uploadImageToS3.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.getAmplifierData = this.getAmplifierData.bind(this);
  }

  handleFormChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  handleCategoryChange(event) {
    this.setState({
      [event.name]: event.value,
    });
  }

  UNSAFE_componentWillMount() {
    this.getCategory();
    this.getAmplifierData();
  }

  getAmplifierData() {
    let amplifierid;
    if (this.props.location.data) {
      localStorage.setItem("amplifierid", this.props.location.data._id);
      amplifierid = this.props.location.data._id;
    } else {
      amplifierid = localStorage.getItem("amplifierid");
    }

    const payload = {
      amplifier_id: amplifierid
    }

    axios
      .post(process.env.REACT_APP_APIURL + "/amplifier/get", payload)
      .then((res) => {
        let responses = res.data;
        if (responses.length > 0) {
          let response = responses[0];
          this.setState({
            amplifier_id: response?._id,
            title: response?.title,
            description: response?.description,
            amplifierDate: response?.amplifierDate,
            category: response?.category,
            eventLink: response?.eventLink,
            imageURL: response?.imageURL,
          });
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error("Error fetching data", error);
      });
  }

  getCategory = async () => {
    const API = await axios.get(
      process.env.REACT_APP_APIURL + `/Contents/get/category/all`
    );
    if (Array.isArray(API.data) && API.data.length > 0) {
      const serverResponse = API.data;

      const dropDownValue = serverResponse.map((response) => ({
        value: response.title,
        label: response.title,
        name: "category",
      }));

      this.setState({
        dropDownOptCategoryList: dropDownValue,
      });
    }
  };

  onChangeForCategoryListDropDown(event) {
    this.setState({
      id: event.value,
      category: event.label,
      isSelect: true,
    });

    localStorage.setItem("id", event.value);
    localStorage.setItem("category", event.label);
  }

  addNewImage(newImage) {
    let img_arr = this.state.imageURL;

    img_arr.push(newImage.imageURL);
    this.setState({
      imageURL: img_arr,
    });
  }

  removeImageURL(Img) {
    let img_arr = this.state.imageURL;

    img_arr = img_arr.filter((img) => img != Img);
    this.setState({
      imageURL: img_arr,
    });
  }

  removePdfURL(id) {
    let pdf = this.state.event_documents;
    pdf = pdf.filter((pdf) => pdf.id !== id);
    this.setState({
      event_documents: pdf,
    });
  }

  uploadImageToS3(event) {
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: "yin/imageURL",
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      s3Url: process.env.REACT_APP_S3URL,
    };

    var fileInput = false;
    const image = event.target.files[0];
    if (image === undefined) return;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      if (
        event.target?.files[0]?.type === "image/png" ||
        event.target?.files[0]?.type === "image/jpeg"
      ) {
        new Compressor(image, {
          quality: 0.6,
          success: (compressedResult) => {

            let newFileName = event.target.files[0].name.replace(/\..+$/, "");
            const finalFileName = newFileName;
            const ReactS3Client = new S3(config);
            // const newFileName = 'test-file';

            ReactS3Client.uploadFile(compressedResult, finalFileName).then(
              (data) => {
                if (data.status === 204) {
                  const newImageURL = {
                    imageURL: data.location,
                    imageID: data.key.split("/")[2],
                  };
                  this.addNewImage(newImageURL);
                } else {
                  console.log("fail");
                }
              }
            );
          },
          error: (err) => {
            console.log(err.message);
          },
        });
      }
    }
  }
  validateForm() {
    const { title, description, category, eventLink, amplifierDate, imageURL } =
      this.state;
    const errors = {};
    let arr = [];

    if (title.trim() === "") {
      errors.title = "Title is required.";
      arr.push("Amplify Name");
    }

    if (description.trim() === "") {
      errors.description = "Description is required.";
      arr.push("description");
    }

    if (category === "") {
      errors.category = "Category is required.";
      arr.push("category");
    }

    if (eventLink.trim() === "") {
      errors.eventLink = "Event link is required.";
      arr.push("eventlink");
    }

    if (amplifierDate.trim() === "") {
      errors.amplifierDate = "Amplifier date is required.";
      arr.push("Amplify date");
    }
    if (imageURL.length <= 0) {
      errors.imageURL = "image is required.";
      arr.push("imageURL");
    }

    // Focus on the first input field with an error message
    const firstErrorField = document.querySelector(`[name="${arr[0]}"]`);
    if (firstErrorField && firstErrorField.focus) {
      firstErrorField.focus();
    }

    return errors;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const errors = this.validateForm();
    if (Object.keys(errors).length === 0) {
      const updatecontent = {
        amplifier_id: localStorage.getItem("amplifierid"),
        title: this?.state?.title,
        description: this?.state?.description,
        amplifierDate: this?.state?.amplifierDate,
        category: this?.state?.category,
        eventLink: this?.state?.eventLink,
        imageURL: this?.state?.imageURL,
      };

      let statesSet = this;

      await axios
        .put(process.env.REACT_APP_APIURL + "/amplifier/update", updatecontent)
        .then((res) => {
          if (res.status === 200) {
            alert("updated successfully");
            statesSet.setState({
              changeToView: true,
              redirect: true,
            });
            localStorage.removeItem("amplifierid")
          }
        });
    } else {
      // Form validation failed, focus on the first input field with an error message
      const firstErrorField = document.querySelector(
        `[name="${Object.keys(errors)[0]}"]`
      );
      if (firstErrorField && firstErrorField.focus) {
        firstErrorField.focus();
      }

      this.setState({ errors });
    }
  };

  render() {
    const { redirect, errors } = this.state;
    const { year } = this.state;

    if (redirect) {
      return <Redirect to="/AmplifyList" />;
    }
    return (
      <div>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>
          <div></div>
          <div className="container">
            <BackAndBreadCrmOneParent
              backLink="/AmplifyList"
              backName="AmplifyList"
              currentTabName="Edit Amplify"
              param1={year}
            />
            <div className="row mt-4">
              <div
                style={{
                  heigh: "800vh",
                  overflow: "hidden",
                }}
                className="col-lg-8 col-md-8 col-12 paddingAll10 mx-auto "
              >
                <div className="card rounded shadow shadow-sm ">
                  <div className="card-header">
                    <h3 className="mb-0 center">Edit Amplify</h3>
                  </div>
                  <div className="card-body">
                    <form
                      id="candidate"
                      method="post"
                      onSubmit={(e) => this.handleSubmit(e)}
                      encType="multipart/form-data"
                    >
                      <div className="form-group">
                        <label htmlFor="Amplify Name" className="control-label">
                          Amplify Title
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errors.title ? " is-invalid" : ""
                            }`}
                          id="Amplify Name"
                          name="title"
                          onChange={this.handleFormChange}
                          value={this.state.title}
                          placeholder="Type here"
                        />
                        {errors.title && (
                          <div className="errorMsg">{errors.title}</div>
                        )}
                      </div>

                      <div className="form-group mt-2">
                        <label htmlFor="description" className="control-label">
                          {" "}
                          Amplify Description
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errors.description ? " is-invalid" : ""
                            }`}
                          id="description"
                          name="description"
                          onChange={this.handleFormChange}
                          value={this.state.description}
                          placeholder="Type here"
                        />
                        {errors.description && (
                          <div className="errorMsg">{errors.description}</div>
                        )}
                      </div>
                      <div className=" row col-12 mt-2" style={{ Padding: "0px" }}>
                        <div className="form-group col-6">
                          <label
                            htmlFor="Amplify date"
                            className="control-label"
                          >
                            Amplifier Date
                            <span style={{ color: "#ff0000" }}>*</span>
                          </label>
                          <input
                            type="date"
                            className={`form-control ${errors.amplifierDate ? " is-invalid" : ""
                              }`}
                            id="Amplify date"
                            name="amplifierDate"
                            onChange={this.handleFormChange}
                            value={moment(this.state.amplifierDate).format(
                              "yyyy-MM-DD"
                            )}
                            placeholder="Type here"
                          />
                          {errors.amplifierDate && (
                            <div className="errorMsg">
                              {errors.amplifierDate}
                            </div>
                          )}
                        </div>

                        <div className="form-group col-6">
                          <label
                            htmlFor="Amplify category"
                            className="control-label"
                          >
                            {" "}
                            Category<span style={{ color: "#ff0000" }}>*</span>
                          </label>
                          <Select
                            className={`react-select ${errors.category ? " is-invalid" : ""
                              }`}
                            classNamePrefix="react-select"
                            id="category"
                            value={this.state.dropDownOptCategoryList.find(option => option.label === this.state.category)}
                            options={this.state.dropDownOptCategoryList}
                            onChange={this.handleCategoryChange}
                          />
                          {errors.category && (
                            <div className="errorMsg">{errors.category}</div>
                          )}
                        </div>
                      </div>

                      <div className="form-group mt-2">
                        <label htmlFor="eventLink" className="control-label">
                          {" "}
                          Event Link<span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control${errors.eventLink ? " is-invalid" : ""
                            }`}
                          id="eventLink"
                          name="eventLink"
                          value={this.state.eventLink}
                          onChange={this.handleFormChange}
                          placeholder="Type here"
                        />
                        {errors.eventLink && (
                          <div className="errorMsg">{errors.eventLink}</div>
                        )}
                      </div>

                      <div className="textOnInputs mt-2">
                        <div className="col-md-12 mt-3">
                          <label htmlFor="imageURL">
                            Image URL
                            <span style={{ color: "#ff0000" }}>*</span>
                          </label>
                          <input
                            type="file"
                            className={`form-control${errors.imageURL ? " is-invalid" : ""
                              }`}
                            id="imageURL"
                            name="college_id"
                            accept="image/*"
                            onChange={this.uploadImageToS3}
                          />
                          {this.state.imageURL.length === 0
                            ? null
                            : this.state.imageURL.map((img) => {
                              return (
                                <>
                                  <div
                                    className="card"
                                    style={{ margin: 10, width: 200 }}
                                  >
                                    <img
                                      src={img}
                                      key={img}
                                      className="card-img-top"
                                      alt={"no image"}
                                      width="100px"
                                      height="100px"
                                    />
                                    <div class="card-body">
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-sm btn-block"
                                        onClick={() =>
                                          this.removeImageURL(img)
                                        }
                                      >
                                        delete
                                      </button>
                                    </div>
                                  </div>

                                </>
                              );
                            })}
                          {errors.imageURL && (
                            <div className="errorMsg">{errors.imageURL}</div>
                          )}
                        </div>
                      </div>

                      <div className="row">
                        <div className=" col-12 col-md-12 d-flex justify-content-center align-items-center">
                          <button
                            type="submit"
                            onClick={this.handleSubmit}
                            id="btnSubmitSignup"
                            className="btn btn-dark btn-lg btn-block mt-4 m-auto"
                          >Update
                          </button>
                        </div>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
