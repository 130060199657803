import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import app from "./App";

import CandidateList from "./CandidateList";
import CollegeList from "./CollegeList";
import Editcandidate from "./Editcandidate";
import EditcandidateCoordinator from "./EditcandidateCoordinator";
import Viewcandidate from "./Viewcandidate";
import Addcandidate from "./Addcandidate";
import Dashboard from "./Dashboard";
import Card from "./Card";
import AddCollege from "./Addcollege";
import EditCollege from "./Editcollege";
import EditPendingCollege from "./EditPendingCollege";
import FileUpload from "./FileUpload";
import Approvedc from "./Approvedc";
import Pendingc from "./Pendingc";
import PendingCandidate from "./PendingCandidateList";
import PendingCollegeList from "./PendingCollegeList";
import Resources from "./Resources";
import Viewprofile from "./Viewprofile";
import Election from "./Election";
import Login from "./Login";
import ElectionStartedCollege from "./ElectionStartedCollege";
import Result from "./Result";
import Allcards from "./Allcards";
import Contentlist from "./Contentlist";
import Content from "./Content";
import UserListAll from "./AllUserList";
import CandidateListByCollegeWise from "./CandidateListByCollegWise";
import AllStudents from "./AllStudents";
import ElectionList from "./ElectionList";
import LDPInformation from "./LDPInformation";
import Editcontent from "./Editcontent";
import DisputeList from "./DisputeList";
import Dispute from "./Dispute/Dispute";
import DisputeListByCandidate from "./DisputeListByCandidate";
import DistrictLDPStart from "./LdpSelectedDistrict";
import ViewPendingCollegeStudent from "./ViewPendingCollegeStudent";
import EditUserList from "./EditUserList";
import EditUserPendingCollege from "./EditUserPendingCollege";
import ViewQrcodePayment from "./ViewQrcodePayment";
import ViewApprovedQrcodePayment from "./ViewApprovedQrcodePayment";
import EditCollegeUserList from "./EditCollegeUser";
import MergeCollegeList from "./MergeCollegeList";
import RejectedCandidateList from "./RejectedCandidateList";
import Collegenamelist from "./Collegenamelist";
import CoordinatorWiseCountList from "./CoordinatorWiseCountList";
import DisputeListCollege from "./Dispute/DisputeList";
import ValidateIdcards from "./Dispute/ValidateIdCards";
import { OperatorLogin } from "./OperatorLogin";
import VoterVerify from "./VoterVerification/VoterVerify";
import CandidatePhaseList from "./CandidatePhaseList";
import ViewLiveElectionCount from "./Election/ViewLiveElectionCount";
import LastDayElectionCollegeList from "./Election/LastDayElectionCollegeList";
import Eventlist from "./Eventlist";
import Addnewevent from "./Addnewevent";

import Viewevent from "./Viewevent";
import TransactionList from "./TransactionList";
import GetMemberList from "./GetMemberList";
import AddAttendee from "./AddAttendee";
import AddAttendeeOver from "./AddAttendeeAfter500List";
import { PolicyPage } from "./Policy";
import AdminLogin from "./AdminLogin";
import AssignEvent from "./AssignEvent";
import Electioncountcards from "./Electioncountcards";
import CoordinatorList from "./CoordinatorList";
import ForumList from "./ForumList";
import AddnewForum from "./AddnewForum";
import AddnewIssue from "./AddnewIssue";
import Scanner from "./Scanner";
import IssueList from "./IssueList";
import ActivityList from "./ActivityList";
import Addlocation from "./Addlocation";
import AddBanner from "./AddBanner";
import EditLocation from "./EditLocation";
import AddActivity from "./AddActivity";
import ViewLocation from "./ViewLocation";
import ScannedList from "./ScannedList";
import SendEventPDFSMS from "./SendEventPDFSMS";
import Amplifylist from "./AmplifyList";
import AddAmplify from "./AddAmplify";
import EditAmplifier from "./EditAmplifier";
import UpdateAmplifier from "./UpdateAmplifier";
import Deletecontent from "./Deletecontent";
import ViewAplify from "./ViewAplify";
import AddSubEvent from "./AddSubEvent";
import ViewForum from "./ViewForum";
import EditForum from "./EditForum";
import SubEventList from "./SubEventList";
import SubEventMemberList from "./SubEventMemberList";
import EditIssue from "./EditIssue";
import NewAttendee from "./NewAttendee";
import Addsubeventattendee from "./Addsubeventattendee";
import Addnewcandidate from "./Addnewcandidate";
import Scanbymobile from "./Scanbymobile";
import TransactionViewDetails from "./TransactionViewDetails";
import TransactionListAll from "./TransactionListAll";
import CheckStatus from "./All Transaction List/CheckStatus";
import EventCoordinatorCount from "./EventCoordinatorCount";
import NotificationList from "./NotificationList";
import SendNotification from "./SendNotification";
import CoordinatorsList from "./CoordinatorsList";
import Addcoordinator from "./Addcoordinator";
import Editcoordinator from "./Editcoordinator";
import ViewIssue from "./ViewIssue";
import EditActivity from "./EditActivity";
import ViewActivity from "./ViewActivity";
import TabDetails from "./TabDetails";
import ElectionDetails from "./ElectionDetails";
import EditEvent from "./Editevent";
import Electioncounttable from "./ElectionCountTable";
import ElectionPahseAdd from "./Election Phase/ElectionPahseAdd";
import ElectionPhaseShow from "./Election Phase/ElectionPhaseShow";
import ElectionPhaseEdit from "./Election Phase/ElectionPhaseEdit";
import Citywisecount from "./CitywiseCount";
import CoordinatorwiseCandidatecount from "./Election/CoordinatorwiseCandidateCount";
import ElectionLoginCount from "./ElectionLoginCount"
import VoterVerifyReApprove from "./VoterVerification/VoterVerifyReApprove";
import YinVoterList from "./YinVoterList";
import CollegeWiseCandidateCount from "./CollegeWiseCandidateCount";
import ElectionPage from "./Election/ElectionTab/ElectionPage";
import AddVoterRegistrMsg from "./Election/ElectionTab/ElectionSubPages/AddVoterRegistrMsg";
import ElectionStart from "./Election/ElectionTab/ElectionSubPages/ElectionStart";
import ElectionOverAllClg from "./Election/ElectionTab/ElectionSubPages/ElectionOverAllClg";
import IsDisputeOpen from "./Election/ElectionTab/ElectionSubPages/DisputeOpen";
import IsElectionVoter from "./Election/ElectionTab/ElectionSubPages/ElectionVoter";
import DisputeOpen from "./Election/ElectionTab/ElectionSubPages/DisputeOpen";
import ElectionVoter from "./Election/ElectionTab/ElectionSubPages/ElectionVoter";
import ResultAvailable from "./Election/ElectionTab/ElectionSubPages/ResultAvailable";
import LDPStart from "./Election/ElectionTab/ElectionSubPages/LDPStart";
import ElectionActive from "./Election/ElectionTab/ElectionSubPages/ElectionActive";
import VoterRegistStart from "./Election/ElectionTab/ElectionSubPages/VoterRegistStart";
import ViewVoterRegist from "./Election/ElectionTab/ElectionSubPages/ViewVoterRegist";
import ElectionPhase from "./Election/ElectionTab/ElectionSubPages/ElectionPhase";
import ElectionResult from "./Election/ElectionResult";
import DeleteAccountPage from "./DeleteAccount/DeleteAccountPage";
import CoordinatorEventCount from "./CoordinatorEventCount";
import EventRegisteredUser from "./EventRegisteredUser";
class Routes extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route path="/admin" component={AdminLogin} />
            <Route exact path="/" component={AdminLogin} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/candidateList" component={CandidateList} />
            <Route
              path="/rejected/candidate/list"
              component={RejectedCandidateList}
            />
            <Route path="/collegeList" component={CollegeList} />
            <Route path="/pending-college" component={PendingCollegeList} />
            <Route path="/edit_candidate" component={Editcandidate} />
            <Route
              path="/candidate-edit"
              component={EditcandidateCoordinator}
            />
            <Route path="/viewcandidate" component={Viewcandidate} />
            <Route path="/view-user" component={UserListAll} />
            <Route path="/addcandidate" component={Addcandidate} />
            <Route path="/card" component={Card} />
            <Route path="/login" component={Login} />
            <Route path="/add-college" component={AddCollege} />
            <Route path="/ViewIssue" component={ViewIssue} />
            <Route
              path="/edit-pending-college"
              component={EditPendingCollege}
            />
            <Route path="/edit-college/:college_name" component={EditCollege} />
            <Route path="/file-Upload" component={FileUpload} />
            <Route path="/approve-candidate" component={Approvedc} />
            <Route path="/pendingCandidate" component={Pendingc} />
            <Route path="/pending-candidate" component={PendingCandidate} />
            <Route path="/resource" component={Resources} />
            <Route path="/profile" component={Viewprofile} />
            <Route path="/election" component={Election} />
            <Route path="/election-list" component={ElectionStartedCollege} />
            <Route path="/result" component={Result} />
            <Route path="/view-card" component={Allcards} />
            <Route path="/CandidateListByCollege" component={CandidateListByCollegeWise} />
            <Route path="/CollegeWiseCandidateCount" component={CollegeWiseCandidateCount} />
            <Route path="/all-students" component={AllStudents} />
            <Route path="/yinVoterList" component={YinVoterList} />
            <Route path="/electionlist" component={ElectionList} />
            <Route path="/ldp" component={LDPInformation} />
            <Route path="/content" component={Content} />
            <Route path="/contentlist" component={Contentlist} />
            <Route path="/editcontent" component={Editcontent} />
            <Route path="/dispute-list" component={DisputeList} />
            <Route path="/candidate/dispute-list" component={DisputeListByCandidate} />
            <Route path="/district/ldp/start" component={DistrictLDPStart} />
            <Route path="/view/pending-student" component={ViewPendingCollegeStudent} />
            <Route path="/edit/user" component={EditUserList} />
            <Route path="/edit/college/user" component={EditCollegeUserList} />
            <Route path="/edit/pending/user" component={EditUserPendingCollege} />
            <Route path="/view/qrcode/list" component={ViewQrcodePayment} />
            <Route path="/view/qrcode/approved/list" component={ViewApprovedQrcodePayment} />
            <Route path="/merge/colleges" component={MergeCollegeList} />
            <Route path="/college/all/count/list" component={Collegenamelist} />
            <Route
              path="/coordinator/all/count/list"
              component={CoordinatorWiseCountList}
            />
            <Route path="/dispute" component={Dispute} />
            <Route path="/college/dispute/list" component={DisputeListCollege} />
            <Route path="/college/card/validate" component={ValidateIdcards} />
            <Route path="/operator/login" component={OperatorLogin} />
            <Route path="/voter/verify" component={VoterVerify} />
            <Route path="/re-approve" component={VoterVerifyReApprove} />
            <Route
              path="/candidate/phase/:phase"
              component={CandidatePhaseList}
            />
            <Route
              path="/live/election/city"
              component={ViewLiveElectionCount}
            />
            <Route
              path="/last/election/day/college/list"
              component={LastDayElectionCollegeList}
            />
            <Route path="/eventlist" component={Eventlist} />
            <Route path="/addnewevent" component={Addnewevent} />
            <Route path="/editevent" component={EditEvent} />
            <Route path="/view-event" component={Viewevent} />
            <Route path="/transactionlist" component={TransactionList} />
            <Route path="/memberlist" component={GetMemberList} />
            <Route path="/addattendee" component={AddAttendee} />
            <Route path="/addattendee-over" component={AddAttendeeOver} />
            <Route path="/assignevent" component={AssignEvent} />
            <Route path="/policy" component={PolicyPage} />
            <Route path="/coordinator-List" component={CoordinatorList} />
            <Route path="/electioncountcards" component={Electioncountcards} />
            <Route path="/electioncounttable" component={Electioncounttable} />
            <Route path="/Citywisecount" component={Citywisecount} />
            <Route path="/Coordinatorwisecandidatecount" component={CoordinatorwiseCandidatecount} />
            <Route path="/ForumList" component={ForumList} />
            <Route path="/Add-forum" component={AddnewForum} />
            <Route path="/Add-Issue" component={AddnewIssue} />
            <Route path="/Issue-List" component={IssueList} />
            <Route path="/Activity-List" component={ActivityList} />
            <Route path="/Scanner" component={Scanner} />
            <Route path="/list/scanner" component={ScannedList} />
            <Route path="/Add-Location" component={Addlocation} />
            <Route path="/Add-Banner" component={AddBanner} />
            <Route path="/edit-location" component={EditLocation} />
            <Route path="/Add-Activity" component={AddActivity} />
            <Route path="/View-location" component={ViewLocation} />
            <Route path="/AmplifyList" component={Amplifylist} />
            <Route path="/addAmplify" component={AddAmplify} />
            <Route path="/EditAmplifier" component={EditAmplifier} />
            <Route path="/send/event/sms" component={SendEventPDFSMS} />
            <Route path="/update" component={UpdateAmplifier} />
            <Route path="/delete" component={Deletecontent} />
            <Route path="/view" component={ViewAplify} />
            <Route path="/addsub-event" component={AddSubEvent} />
            <Route path="/view-forum" component={ViewForum} />
            <Route path="/edit-forum" component={EditForum} />
            <Route path="/Subevent-List" component={SubEventList} />
            <Route path="/Sub-memberlist" component={SubEventMemberList} />
            <Route path="/Edit-issue" component={EditIssue} />
            <Route path="/registration-form" component={NewAttendee} />
            <Route path="/subevent-attendee" component={Addsubeventattendee} />
            <Route path="/addnewcandidate" component={Addnewcandidate} />
            <Route path="/scanedbymobileno" component={Scanbymobile} />
            <Route path="/viewTransaction" component={TransactionViewDetails} />
            <Route path="/allTransactionList" component={TransactionListAll} />
            <Route path="/checkTransactionStatus" component={CheckStatus} />
            <Route path="/eventcoordinatorcount" component={EventCoordinatorCount} />

            <Route path="/notificationlist" component={NotificationList} />
            <Route path="/sendnotification" component={SendNotification} />
            <Route path="/coordinator" component={CoordinatorsList} />
            <Route path="/Addcoordinator" component={Addcoordinator} />
            <Route path="/Editcoordinator" component={Editcoordinator} />
            <Route path="/Editactivity" component={EditActivity} />
            <Route path="/ViewActivity" component={ViewActivity} />
            <Route path="/TabDetails" component={TabDetails} />
            <Route path="/ElectionDetails" component={ElectionDetails} />

            <Route path="/ElectionPhaseAdd" component={ElectionPahseAdd} />
            <Route path="/ElectionPhaseShow" component={ElectionPhaseShow} />
            <Route path="/ElectionPhaseEdit" component={ElectionPhaseEdit} />
            <Route path="/ElectionLoginCount" component={ElectionLoginCount} />
            <Route path="/ElectionResult" component={ElectionResult} />

            <Route path="/ElectionPage" component={ElectionPage} />
            <Route path="/AddVoterRegisterMsg" component={AddVoterRegistrMsg} />
            <Route path="/ElectionOver" component={ElectionOverAllClg} />
            <Route path="/ElectionStart" component={ElectionStart} />
            <Route path="/DisputeOpen" component={DisputeOpen} />
            <Route path="/ElectionVoter" component={ElectionVoter} />
            <Route path="/ResultAvailable" component={ResultAvailable} />
            <Route path="/LDPStart" component={LDPStart} />
            <Route path="/ElectionActive" component={ElectionActive} />
            <Route path="/VoterRegistrStart" component={VoterRegistStart} />
            <Route path="/ViewVoterRegistr" component={ViewVoterRegist} />
            <Route path="/ElectionActive" component={ElectionActive} />
            <Route path="/ElectionPhase" component={ElectionPhase} />

            <Route path="/deletes/users" component={DeleteAccountPage} />
            <Route path="/coordinatorEventCount" component={CoordinatorEventCount} />
          </Switch>
        </Router>
      </div>
    );
  }
}
export default Routes;
