import React, { Component } from 'react'
import axios from 'axios';
import Navbar from './Navbar';
import LoaderRounded from "./LoaderRounded";
import SideBarQRcode from './SideBarQRcode';

export default class ViewApprovedQrcodePayment extends Component {
    constructor(pops) {
        super(pops);

        this.state = {
            allpaymentData: [],
            errors: [],
            address_line_1: "",
            images: "",
            address_line_2: "",
            application_status: "",
            yin_id: "",
            redirect: false,
            showModalPopup: false,
            loader: true,
        }

    }


    isShowPopup = (status, yinId, Mobile, qr_code_upi_id, qr_code_transaction_id, qr_code_transaction_photo) => {
        localStorage.setItem("approved_yin_id", yinId);
        localStorage.setItem("approved_mobile", Mobile);
        localStorage.setItem("approved_qr_code_upi_id", qr_code_upi_id);
        localStorage.setItem("approved_qr_code_transaction_id", qr_code_transaction_id);
        localStorage.setItem("approved_qr_code_transaction_photo", qr_code_transaction_photo);
        this.setState({ showModalPopup: status, disabled: true });
    }

    isClosePopup = () => {
        this.setState({ showModalPopup: false });
    }

    UNSAFE_componentWillMount() {
        this.getData();
    }

    getData() {
        let currentComponent = this;
        axios.get(process.env.REACT_APP_APIURL + `/qrcode/payment/list/approved`)
            .then(res => {
                if (res.data === "No approved list found") {
                    currentComponent.setState({
                        allpaymentData: null,
                        loader: false,
                    });
                } else {
                    let responses = res.data;
                    currentComponent.setState({
                        allpaymentData: responses,
                        loader: false,
                    });
                }
            });
    }



    render() {
        return (
            <>
                <SideBarQRcode />
                <div className="main">
                    <div className="topbar">
                        <Navbar />
                    </div>

                    <div className="container">
                        {/* <BackAndBreadCrmOneParent backLink="/" currentTabName="Pending QR Code Payment for Approved"/> */}
                        <div className="row">
                            <div className="col-12">
                                <u><h2 className="text-center divider-20">Approved Payment List</h2></u>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="table-responsive-sm">
                                    <table className="table table-bordered table-responsive">
                                        <thead>
                                            <tr>
                                                <th>Student Name</th>
                                                <th>Email</th>
                                                <th>Mobile</th>
                                                <th>QR Code UPI ID</th>
                                                <th>Transaction ID</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.loader ? <LoaderRounded /> :
                                                this.state.allpaymentData ? this.state.allpaymentData.map((payments, index) => (
                                                    <tr key={index} >
                                                        <td>{payments.first_name} {payments.last_name}</td>
                                                        <td>{payments.email}</td>
                                                        <td>{payments.mobile}</td>
                                                        <td>{payments.qr_code_upi_id}</td>
                                                        <td>{payments.qr_code_transaction_id}</td>
                                                        <td>
                                                            <span className="btn btn-sm btn-success border" disabled={true}>{payments.ispayment_approved ? "Payment Approved" : "Not Approved"}</span>
                                                        </td>
                                                    </tr>
                                                )) : "No Approved Payment found..."

                                            }
                                        </tbody>
                                    </table>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </>
        )
    }
}
