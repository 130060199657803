import React from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';

class Resources extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
    }

    render() {
        return (
            <>
                <Sidebar />
                <div className="main">
                    <div className="topbar">
                        <Navbar />
                    </div>
                    <h1>Add resources here</h1>
                </div>
            </>
        )
    }
}

export default Resources;