import React, { Component } from "react";
import "../Assets/css/Addcollege.css";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { Redirect } from "react-router-dom";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import { connect } from "react-redux";
import LoaderRounded from "./LoaderRounded";
class Addcollege extends Component {
  constructor(props) {
    super(props);
    this.state = {
      college_name: "",
      type: "",
      address1: "",
      address2: "",
      state: "",
      city: "",
      pincode: "",
      contact: "",
      website: "",
      principle_name: "",
      principle_number: "",
      approve_status: "",
      college_logo: "",
      college_type: "",
      select_college_type: "",
      sub_city: "",
      state_code: "",
      city_code: "",
      state_name: "",
      city_name: "",
      StateData: [],
      CityData: [],
      errors: [],
      changeToView: false,
    };

    this.initialState = this.state;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.ChangeCity = this.ChangeCity.bind(this);
    this.showCity = this.showCity.bind(this);
    this.imageChangedHandler = this.imageChangedHandler.bind(this);
    this.goback = this.goback.bind(this);
    this.handleFormValidation = this.handleFormValidation.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleFormChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  //upload image and resize
  imageChangedHandler(event) {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      this.setState({
        images: event.target.files[0],
      });
    }
  }

  ChangeCity = (e) => {
    e.preventDefault();
    axios
      .get(process.env.REACT_APP_APIURL + "/address/city/" + e.target.value)
      .then((response) => {
        this.setState({
          CityData: response.data,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  handleFormValidation = () => {
    let formIsValid = true;
    const {
      college_name,
      college_type,
      select_college_type,
      address1,
      state_name,
      city_name,
      pincode,
      contact,
    } = this.state;
    let errors = {};
    let errorFields = [];

    // Empty college name
    if (!college_name) {
      formIsValid = false;
      errors["college_name"] = "Please enter college name.";
      errorFields.push("college_name");
    }

    // Empty college type
    if (!college_type) {
      formIsValid = false;
      errors["college_type"] = "Please select college type.";
      errorFields.push("college_type");
    }

    // Empty select_college_type
    if (!select_college_type) {
      formIsValid = false;
      errors["select_college_type"] = "Please select college type(Senior/Junior).";
      errorFields.push("select_college_type");
    }

    // Empty address1
    if (!address1) {
      formIsValid = false;
      errors["address1"] = "Please enter address.";
      errorFields.push("address1");
    }

    // Empty state
    if (state_name === "" || state_name === "--Choose State--") {
      formIsValid = false;
      errors["states"] = "Please Choose State.";
      errorFields.push("states");
    }

    // Empty city
    if (city_name === "" || city_name === "--Choose City--") {
      formIsValid = false;
      errors["city_name"] = "Please Choose City.";
      errorFields.push("city_name");
    }

    // // Empty pincode
    // if (pincode === "") {
    //   errors["pincode"] = "Please enter pincode.";
    //   formIsValid = false;
    //   errorFields.push("pincode");
    // }

    if (pincode === "") {
      errors["pincode"] = "Please enter pincode.";
      formIsValid = false;
      errorFields.push("pincode");
    } else if (isNaN(pincode)) {
      // Check if pincode is not a number
      errors["pincode"] = "Pincode must be a number.";
      formIsValid = false;
      errorFields.push("pincode");
    }
    // Empty mobile no
    if (!contact) {
      formIsValid = false;
      errors["contact"] = "Please enter mobile no.";
      errorFields.push("contact");
    } else {
      if (contact.match(/^[0-9]{10}$/)) {
        formIsValid = true;
      } else {
        formIsValid = false;
        errors["contact"] = "Please enter valid mobile no.";
        errorFields.push("contact");
      }
    }

    this.setState({
      errors: errors,
    });

    // Focus on the first input field with an error message
    if (errorFields.length > 0) {
      const firstErrorField = document.querySelector(
        `[name="${errorFields[0]}"]`
      );
      if (firstErrorField && firstErrorField.focus) {
        firstErrorField.focus();
      }
    }

    return formIsValid;
  };
  showCity() {
    axios
      .get(process.env.REACT_APP_APIURL + "/address/city/MH")
      .then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          this.setState({
            CityData: response.data,
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }
  //save form data
  handleSubmit(event) {
    event.preventDefault();

    if (this.handleFormValidation()) {
      //   let formData = new FormData();
      //   formData.append("college_name", this.state.college_name);
      //   formData.append("select_college_type", this.state.select_college_type);
      //   formData.append("type", "RURAL");
      //   formData.append("address1", this.state.address1);
      //   formData.append("address2", this.state.address2);
      //   formData.append("country", "IN");
      //   formData.append("state", this.state.state_name);
      //   formData.append("city", this.state.city_name);
      //   formData.append("sub_city", this.state.sub_city);
      //   formData.append("pincode", this.state.pincode);
      //   formData.append("contact", this.state.contact);
      //   formData.append("website", this.state.website);
      //   formData.append("principle_name", this.state.principle_name);
      //   formData.append("principle_number", this.state.principle_number);
      //   formData.append("approver_status", "APPROVED");
      //formData.append("college_logo", this.state.college_logo);
      let statesSet = this;
      let formdata = {
        college_name: this.state.college_name,
        select_college_type: this.state.select_college_type,
        type: this.state.college_type,
        address1: this.state.address1,
        address2: this.state.address2,
        country: "IN",
        state: this.state.state_name,
        city: this.state.city_name,
        sub_city: this.state.sub_city,
        pincode: this.state.pincode,
        contact: this.state.contact,
        website: this.state.website,
        principle_name: this.state.principle_name,
        principle_number: this.state.principle_number,
        approver_status: "APPROVED",
        updated_by: "coordinator",
      };

      axios
        .post(
          process.env.REACT_APP_APIURL + "/coordinator/college/new",
          formdata
        )
        .then((res) => {
          if (res.data.message === "College Added successfully") {
            alert("Add college successfully");
            this.props.history.push("/collegeList");
            statesSet.setState({
              changeToView: true,
            });
          }
        })
        .catch((err) => {
          console.log("err in add college", err);
        });
    } else {
      console.log(this.handleFormValidation());
    }
  }

  goback() {
    this.setState({
      changeToView: true,
    });
  }
  UNSAFE_componentWillMount() {
    this.showCity();
  }
  render() {
    if (this.state.changeToView) {
      return <Redirect to="/collegeList" />;
    }

    let subCitys = this.props.UserData.sub_city ? (
      <div id="stateDiv" className="col-12 col-md-6 pt-md-0 pt-4">
        <div className="form-group">
          <label htmlFor="subCity" className="control-label">
            Sub City
          </label>
          <select
            id="subCity"
            name="sub_city"
            className="form-select"
            onChange={this.handleChange}
          >
            <option>--Choose your sub city--</option>
            <option value="PCMC">PCMC</option>
            <option value="PUNE_CITY">PUNE_CITY</option>
            <option value="PUNE_RURAL">PUNE_RURAL</option>
          </select>
          <div className="errorMsg">{this.state.errors.subCity}</div>
        </div>
      </div>
    ) : (
      ""
    );

    return (
      <>
        <Sidebar />
        <div className="main">
          <div className="topbar">
            <Navbar />
          </div>

          <div className="container">
            <BackAndBreadCrmOneParent
              backLink="/collegeList"
              backName="College List"
              currentTabName="Add new college"
            />
            <div className="row mt-4">
              <div className="col-lg-8 col-md-8 col-12 paddingAll10 mx-auto">
                <div className="card rounded shadow shadow-sm">
                  <div className="card-header">
                    <h3 className="mb-0 paddingAll10"> Add College</h3>
                  </div>
                  <div className="card-body">
                    <form
                      onSubmit={this.handleSubmit}
                      method="post"
                      encType="multipart/form-data"
                    >

                      <div className="row">
                        <div
                          id="collegenameDiv"
                          className={`form-group ${this.state.errors.college_name ? "error" : ""
                            }`}
                        >
                          <label htmlFor="college_name" className="control-label">
                            College Name
                            <span style={{ color: "#ff0000" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="college_name"
                            value={this.state.college_name}
                            name="college_name"
                            className={`form-control rounded-1 ${this.state.errors.college_name ? "error" : ""
                              }`}
                            onChange={this.handleChange}
                            placeholder="College Name"
                          />
                          <div className="errorMsg">
                            {this.state.errors.college_name}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xxl-6 col-lg-12 col-md-12 col-12">
                          <div
                            id="collegetypeDiv"
                            className={`form-group ${this.state.errors.college_type ? "error" : ""
                              }`}
                          >
                            <label htmlFor="collegetypes" className="control-label">
                              Select College Type
                              <span style={{ color: "#ff0000" }}>*</span>{" "}
                            </label>
                            <select
                              id="collegetypes"
                              name="college_type"
                              className={`form-select ${this.state.errors?.college_type ? "error" : ""
                                }`}
                              onChange={this.handleChange}
                            >
                              <option value="">--Select--</option>
                              <option value="RURAL">Rural</option>
                              <option value="URBAN">Urban</option>
                            </select>
                            <div className="errorMsg">
                              {this.state.errors.college_type}
                            </div>
                          </div>
                        </div>

                        <div className="col-xxl-6 col-lg-12 col-md-12 col-12">
                          <div
                            id="collegetypeDiv"
                            className={`form-group ${this.state.errors.college_type ? "error" : ""
                              }`}
                          >
                            <label
                              htmlFor="select_college_type"
                              className="control-label"
                            >
                              Select College Type(Senior college/Junior college) <span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <select
                              id="select_college_type"
                              name="select_college_type"
                              className={`form-select rounded-1 ${this.state.errors.select_college_type ? "error" : ""
                                }`}
                              onChange={this.handleChange}
                            >
                              <option value="">--Select--</option>
                              <option value="JUNIOR">Junior</option>
                              <option value="SENIOR">Senior</option>
                            </select>
                            <div className="errorMsg">
                              {this.state.errors.select_college_type}
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6">
                          <div
                            id="address1Div"
                            className={`form-group ${this.state.errors.address1 ? "error" : ""
                              }`}
                          >
                            <label htmlFor="adddress1" className="control-label">
                              Address line 1
                              <span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <input
                              type="text"
                              id="address1"
                              value={this.state.address1}
                              name="address1"
                              className={`form-control rounded-1 ${this.state.errors.address1 ? "error" : ""
                                }`}
                              onChange={this.handleChange}
                              placeholder="Address1"
                            />
                            <div className="errorMsg">
                              {this.state.errors.address1}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                          <div
                            id="address2Div"
                            className={`form-group ${this.state.errors.address1 ? "error" : ""
                              }`}
                          >
                            <label htmlFor="adddress2" className="control-label">
                              Address line 2
                            </label>
                            <input
                              type="text"
                              id="address2"
                              value={this.state.address2}
                              name="address2"
                              className={`form-control rounded-1 ${this.state.errors.address1 ? "error" : ""
                                }`}
                              onChange={this.handleChange}
                              placeholder="Address2"
                            />
                            <div className="errorMsg">
                              {this.state.errors.address2}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-4">
                          <div className="form-group"
                            id="stateDiv"
                          // className={ `form-group ${this.state.errors.states}` ? "error" : "" }
                          >
                            <label htmlFor="states" className="control-label">
                              Select State
                              <span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <select
                              id="states"
                              className="form-select"
                              name="state_name"
                              onChange={this.handleChange}
                            >
                              <option>--Choose State--</option>
                              <option value="MH">Maharashtra</option>
                              {/* <option value="GA">Goa</option> */}
                            </select>
                            <div className="errorMsg">
                              {this.state.errors.states}
                            </div>

                          </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-4">
                          <div
                            id="stateDiv"
                            className={`form-group ${this.state.errors.city_name ? "error" : ""
                              }`}
                          >
                            <label
                              htmlFor="citys"
                              id="cityLabel"
                              className="control-label"
                            >
                              Select City
                              <span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <select
                              id="citys"
                              name="city_name"
                              className={`form-select ${this.state.errors.city_name ? "error" : ""
                                }`}
                              onChange={this.handleChange}
                            >
                              <option>--Choose City--</option>
                              {this.state.CityData.map((e, key) => {
                                return (
                                  <option key={key} value={e.name}>
                                    {e.name}
                                  </option>
                                );
                              })}
                            </select>
                            <div className="errorMsg">
                              {this.state.errors.city_name}
                            </div>
                          </div>
                        </div>

                        {subCitys}
                        <div className="col-lg-4 col-md-4">
                          <div
                            id="stateDiv"
                            className={`form-group ${this.state.errors.pincode ? "error" : ""
                              }`}
                          >
                            <label htmlFor="pincode" className="control-label">
                              Pincode<span style={{ color: "#ff0000" }}>*</span>
                            </label>
                            <input
                              className={`form-control ${this.state.errors.pincode ? "error" : ""
                                }`}
                              name="pincode"
                              onChange={this.handleChange}
                              placeholder="ex:411013"
                              maxLength={6}
                            />
                            <div className="errorMsg">
                              {this.state.errors.pincode}
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6">
                          <div
                            id="phoneDiv"
                            className={`form-group ${this.state.errors.contact ? "error" : ""
                              }`}
                          >
                            <label htmlFor="phone_no" className="control-label">
                              Mobile Number
                              <span style={{ color: "#ff0000" }}>*</span>:
                            </label>
                            <input
                              name="contact"
                              id="contact"
                              onChange={this.handleChange}
                              value={this.state.contact}
                              className={`form-control ${this.state.errors.contact ? "error" : ""
                                }`}
                              placeholder="Mobile Number"
                              maxLength={10}
                            />
                            <div className="errorMsg">
                              {this.state.errors.contact}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-6">
                          <div id="websiteDiv" className="form-group">
                            <label htmlFor="website" className="control-label">
                              Website
                            </label>
                            <input
                              type="text"
                              id="website"
                              value={this.state.website}
                              name="website"
                              className="form-control rounded-1"
                              onChange={this.handleChange}
                              placeholder="Website"
                            />
                            <div className="errorMsg">
                              {this.state.errors.website}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-6">
                          <div id="priciplenameDiv" className="form-group">
                            <label
                              htmlFor="principle_name"
                              className="control-label"
                            >
                              Principal Name
                            </label>
                            <input
                              type="text"
                              id="principle_name"
                              value={this.state.principle_name}
                              name="principle_name"
                              className="form-control rounded-1"
                              onChange={this.handleChange}
                              placeholder="Principal Name"
                            />
                            <div className="errorMsg">
                              {this.state.errors.principle_name}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-6">
                          <div id="priciplenumberDiv" className="form-group">
                            <label
                              htmlFor="principle_name"
                              className="control-label"
                            >
                              Principal Mobile Number
                            </label>
                            <input
                              type="text"
                              id="principle_number"
                              value={this.state.principle_number}
                              name="principle_number"
                              className="form-control rounded-1"
                              onChange={this.handleChange}
                              placeholder="Principal Contact Number"
                              maxLength={10}
                            />
                            <div className="errorMsg">
                              {this.state.errors.principle_number}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div id="approvestatusDiv" className="form-group">
                                                <label htmlFor="approve_status" className="control-label">Approve Status</label>
                                                    <select id="approve_status" className="form-select" name="approver_status" onChange={this.handleChange} >
                                                            <option>{this.state.approver_status}</option>
                                                            <option value="PENDING">Pending</option>
                                                            <option value="APPROVED">Approved</option>
                                                            <option value="REJECTED">Rejected</option>
                                                        </select>
                                            </div> */}

                      <div className="row">
                        <div className=" col-12 col-md-12 d-flex justify-content-center align-items-center">
                          <button
                            type="submit"
                            id="btnSubmitProfile"
                            className="btn btn-dark btn-lg btn-block mt-4 m-auto"
                          >
                            {this.state.loader ? <LoaderRounded /> : "Submit"}
                          </button>
                        </div>
                      </div>
                      {/*                                             
                      <div style={{ display: "flex", marginTop: "15px", marginBottom: "15px" }}>
                        <button type="submit" className="btn btn-success">
                          Submit
                        </button>
                        &nbsp;
                        <button
                          type="reset"
                          className="btn btn-danger"
                          onClick={this.goback}
                        >
                          Cancel
                        </button>
                      </div> */}
                      {/* <button type="submit" className="btn btn-success">Submit</button>
                                            <button type="reset" id="btnCancelCollege" className="btn btn-danger" style={{tdisplay:'inline1'}}onClick={this.goback}>Cancel</button> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    UserData: state.PersistReducer.UserData.UserData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Addcollege);
