import React, { Component } from 'react';
import profile from '../../Assets/images/profileimage.jpg';
import '../../Assets/css/Dashboard.css';
// import { Link } from '@material-ui/core';
// import { Redirect } from 'react-router';

export default class NavbarVerify extends Component {
    constructor() {
        super();

        this.state = {
            mobile: localStorage.getItem("mobile"),
            displayMenu: false,
        };

        this.showDropdownMenu = this.showDropdownMenu.bind(this);
        this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    }

    showDropdownMenu(event) {
        event.preventDefault();
        this.setState({ displayMenu: true });
    }

    hideDropdownMenu() {
        this.setState({ displayMenu: false });
    }

    logout() {
        localStorage.clear();

    }


    render() {

        return (
            <div className="container border">
                <div className="row">
                    <div className="col-6" style={{ float: "left" }}><h2>Young Inspirator Network</h2></div>

                    <div className="dropdown col-6 " style={{ textAlign: "right" }}>
                        <img src={profile} alt="photos" onClick={this.showDropdownMenu} width="60" />

                        {this.state.displayMenu ? (
                            <ul className="dropdownOnNavbarUL">

                                <li className="dropdownOnNavbarLI">
                                    {/* <Link to="/Profile">
                                    <span className="title">Profile</span>
                                    </Link> */}
                                    {/* <a href="/Profile" onClick={this.hideDropdownMenu}>Profile</a> */}
                                </li>

                                <li className="dropdownOnNavbarLI">
                                    <a href="/operator/login" onClick={this.logout}>Log Out</a>
                                </li>
                            </ul>
                        ) :
                            (
                                null
                            )
                        }
                    </div>
                </div>
            </div>

        )
    }
}
