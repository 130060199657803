import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { Edit, Add, Visibility } from '@material-ui/icons';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRounded from "./LoaderRounded";

class PendingCollegeList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            role: '',
            currentFilter: null,
            college: '',
            currentPage: '',
            counts: '',
            totalPages: '',
            previousLimits: 100,
            redirect: false,
            view_student: false,
            loader: true
        };
        this.applyFiter = this.applyFilter.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.getDataWithPagination = this.getDataWithPagination.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.pageNumber = this.pageNumber.bind(this);

    }
    applyFilter(arr) {

        if (!this.state.currentFilter) {
            return arr;
        }
        return arr.filter((x) => x.approver_status === this.state.currentFilter)

    }
    setFilter(e) {
        e.preventDefault();
        this.setState({
            currentFilter: e.target.value
        })

    }

    componentWillMount() {

        setTimeout(() => this.setState({
            loader: false, msg: false
        }), 2500);
        setTimeout(() => this.setState({ loading: false }), 1000);


    }

    UNSAFE_componentWillMount() {
        this.getData();
        this.setState({ role: localStorage.getItem('role') });
    }
    getData() {
        let currentComponent = this;
        let districts = JSON.parse(localStorage.getItem('districts'));

        axios.get(process.env.REACT_APP_APIURL + `/candidate/college/list/pending/` + districts)
            .then(res => {
                let responses = res.data;
                if (responses.message) {
                    currentComponent.setState({
                        college: "",
                        loader: false
                    });
                } else {
                    currentComponent.setState({
                        college: responses,
                        loader: false
                    });
                }


            });
    }

    getDataWithPagination(previous, currPage) {
        let currentComponent = this;
        axios.get(process.env.REACT_APP_APIURL + `/coordinator/college/status/PENDING?previous=` + previous + `&currentPage=` + currPage + `&limits=` + currentComponent.state.previousLimits)
            .then(res => {
                let responses = res.data;
                let con = Math.ceil(parseInt(responses.counts) / 100);
                currentComponent.setState({
                    college: responses.response,
                    currentPage: responses.currentPage,
                    counts: responses.counts,
                    totalPages: con,
                });

            });
    }

    nextPage() {
        let previousD = parseInt(this.state.previousLimits) + 100;
        let nextPages = parseInt(this.state.currentPage) + 1;

        this.setState({
            currentPage: parseInt(nextPages) + 1,
            previousLimits: previousD,
        });

        this.getDataWithPagination(this.state.previousLimits, this.state.currentPage);
    }

    previousPage() {

        let previousD = parseInt(this.state.previousLimits) - 100;
        let currentPages = parseInt(this.state.currentPage) - 1;
        this.setState({
            currentPage: currentPages,
            previousLimits: previousD,
        });
        if (currentPages > 0) {
            this.getDataWithPagination(previousD, currentPages);
        } else {
            console.log("previous page 00000");
        }
    }

    pageNumber(event) {
        event.preventDefault();
        let previousD = 0;
        let currentPAGES = 0;
        if (event.target.value === 1) {
            previousD = 0;
            currentPAGES = 0;
        } else {
            previousD = (parseInt(event.target.value) - 1) * 100;
            currentPAGES = parseInt(event.target.value) - 1;
        }

        this.setState({
            currentPage: currentPAGES,
            previousLimits: previousD,
        });
        this.getDataWithPagination(previousD, this.state.currentPage);

    }

    EditCollege(collegeName, collegeCode) {
        localStorage.setItem("college_name", collegeName);
        localStorage.setItem("college_code", collegeCode);
        this.setState({
            redirect: true,
        })
    }

    ViewStudentFromCollege(collegeName, collegeCode) {
        localStorage.setItem("college_name", collegeName);
        localStorage.setItem("college_code", collegeCode);
        this.setState({
            view_student: true,
        })
    }


    render() {

        if (!localStorage.getItem("mobile")) {
            return <Redirect to="/login" />
        }

        if (this.state.redirect) {
            return <Redirect to="/edit-pending-college" />
        }

        if (this.state.view_student) {
            return <Redirect to="/view/pending-student" />
        }


        let pages = [];
        for (var i = 0; i < this.state.totalPages; i++) {
            pages.push(<li className="border paddingAll10" onClick={(event) => this.pageNumber(event)} value={i + 100}> {i + 1} </li>);
        }

        return (
            <>
                <Sidebar />
                <div className="main">
                    <div className="topbar">
                        <Navbar />
                    </div>

                    <div className="container">
                        <BackAndBreadCrmOneParent currentTabName="Pending college list" />
                        <div className="row">
                            <div className="col-12">
                                <u><h2 className="text-center">Pending College List</h2></u>
                                <br></br>
                            </div>
                            {this.state.role === 'COORDINATOR' ? "" :
                                <div className="col-12">
                                    <Link to="/add-college" className="btn btn-secondary btn-sm" style={{ float: "right" }}><Add />Add New</Link>
                                </div>}

                        </div>
                    </div>
                    <br></br>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="table-responsive-sm">
                                    <table className="table table-bordered table-responsive">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Collegename</th>
                                                <th>City</th>
                                                <th>Type</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                                <th>View Student</th>
                                            </tr>
                                        </thead>
                                        {this.state.loader ? <LoaderRounded /> :
                                            <tbody>
                                                {this.state.college ? this.state.college && this.applyFilter(this.state.college).map((colleges, index) => (
                                                    <tr key={index} >
                                                        <td>{index + 1}</td>
                                                        <td style={{ width: "40%" }}>{colleges.college_name}</td>
                                                        <td>{colleges.city}</td>
                                                        <td>{colleges.type}</td>
                                                        <td>{colleges.approver_status}</td>
                                                        <td style={{ textAlign: "center" }}>
                                                            <span onClick={() => this.EditCollege(colleges.college_name, colleges.college_code)} ><Edit /></span>
                                                        </td>

                                                        <td style={{ textAlign: "center" }}>
                                                            <span onClick={() => this.ViewStudentFromCollege(colleges.college_name, colleges.college_code)} ><Visibility /></span>
                                                            {/* <Link to=""><Delete /></Link> */}
                                                        </td>

                                                    </tr>
                                                )) : "NO Pending College found"}

                                            </tbody>
                                        }
                                    </table>

                                </div>
                            </div>
                            {/* <div className="col-12 right">
                                    <ul class="pagination floatRight">
                                        <li className="border paddingAll10" onClick={this.previousPage}>Previous</li>
                                        {pages}
                                        <li className="border paddingAll10" onClick={this.nextPage}>Next</li>
                                    </ul>
                            </div> */}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default PendingCollegeList;