import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom'

class ImagePopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            showVoteCastModal: true,
        };
    }

    isShowModal = () => {
        this.handleClose();
    }

    handleClose = () => {
        this.props.onPopupCloseMain();
    }

    render() {
        return (
            <>
                <div className="DisputePopup" >
                    <Modal show={this.props.showModalPopup} onHide={this.handleClose}
                        centered>
                        <Modal.Header closeButton><b>{this.props.collegeId_card}</b></Modal.Header>
                        <Modal.Body >
                            <div>
                                <img src={this.props.imageUrl} alt="collegeId" style={{ width: "100%", display: "inline-block" }} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer >
                            <Button onClick={this.props.onPopupCloseMain} className="btn btn-sm rounded-pill DisputebtnCancel border mb-2">Close</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </>
        )
    }
}

export default withRouter(ImagePopup);