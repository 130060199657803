import React from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import { Add } from "@material-ui/icons";
import "../Assets/css/Dashboard.css";
import LoaderRounded from "./LoaderRounded";
import ReactPaginate from "react-paginate";
import Tooltip from "@material-ui/core/Tooltip";
import LoaderRoundedWithText from "./LoaderRoundedWithText";
import { setSelectedEvent } from "../Redux/Slice/UserDataSlice";
import { connect } from "react-redux";
import * as Icon from 'react-bootstrap-icons';
import { Col, Row } from "react-bootstrap";
class Eventlist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventlist: [],
      allpage: "",
      city: [],
      CityData: [],
      is_navigation_added: "",
      currentPage: "",
      counts: "",
      totalPages: "",
      selected_city: "",
      districtsLocal: JSON.parse(localStorage.getItem("districts")),
      previousLimits: 100,
      renderPageLimit: 10,
      selectedPage: 0,
      // page_selected:0,
      // limitVal: 10,
      year:2025,
      loading: true,
      loader: false,
      role: localStorage.getItem("role"),
    };
    this.getData = this.getData.bind(this);
    this.checkCity = this.checkCity.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.getCandidateWithCity = this.getCandidateWithCity.bind(this);
    this.getCollegeWithYear = this.getCollegeWithYear.bind(this);

  }

  // handlePageChange(page) {
  //   this.setState({ selectedPage: page.selected });
  // }


  handlePageChange(page) {
    let val = page.selected === 0 ? 0 : page.selected * this.state.renderPageLimit;
    this.setState({
      skip_value: val,
      selectedPage: page.selected,
    });
    this.getData(
      this.state.year,
      this.state.selected_city,
      // this.state.phase,
      // this.state.fromdate,
      // this.state.todate,

      this.state.renderPageLimit,
      val,
    );

    //this.getData();
  }

  componentWillMount() {
    setTimeout(() => this.setState({ loading: false }), 1000);
  }

  UNSAFE_componentWillMount() {
    this.getData(this.state.year,
      this.state.selected_city,
      this.state.renderPageLimit,
      this.state.selectedPage);
    let role = localStorage.getItem("role");
    this.setState({ role });
    this.showCity();
  }

  showCity() {
    let districts = JSON.parse(localStorage.getItem("districts"));
    if (districts[0] !== "*") {
      this.setState({
        CityData: districts,
      });
    } else {
      axios.get(process.env.REACT_APP_APIURL + `/address/city/MH`)
        .then((response) => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            this.setState({
              CityData: response.data,
            });
          }
        })
        .catch((error) => {
          console.log("error in showCity", error);
        });
    }
  }

  // year dropdown code
  componentDidMount() {
    const currentYear = new Date().getFullYear();
    const initialYears = this.generateYears(2021, currentYear + 1);
    this.setState({ years: initialYears });
  }

  generateYears(start, end) {
    const yearsArray = [];
    for (let year = end; year >= start; year--) {
      yearsArray.push(year);
    }
    return yearsArray;
  }

  handleCompleteYear = () => {
    const currentYear = new Date().getFullYear();
    this.setState((prevState) => ({
      years: [...prevState.years, currentYear + 1],
    }));
  };



  // handle year onChange
  getCollegeWithYear = (event) => {
    let selected_year =  event.target.value;
    this.setState({
      year: selected_year
    },()=>{
      this.getData(selected_year,
        this.state.selected_city,
        this.state.renderPageLimit,
        this.state.selectedPage)
    });
   
  }

  getCandidateWithCity(event) {

    // let selected_city = event.target.value === "All" ? "*" : event.target.value;
    this.setState({
      selected_city: event.target.value,
    });
    this.getData(this.state.year,
      event.target.value,
      this.state.renderPageLimit,
      this.state.selectedPage);
  }



  getData(selectedYear, selectedCity, pageLimit, slectedPage) {
    const getDistData = localStorage.getItem("districts");
    const districtData = JSON.parse(getDistData)
    const getNewDistData = districtData?.map((itm, index) => {
      return itm
    })
    this.setState({
      loader: true,
    });
    let currentComponent = this;

    axios.get(process.env.REACT_APP_TALK_API + `/event/all/eventhead/web?limit=${pageLimit}&view_count=${slectedPage}&year=${selectedYear ? selectedYear : this.state.year}&cities=${selectedCity ? selectedCity : getNewDistData}`)
      .then((res) => {
        let EventList = res.data.data;
        currentComponent.setState({
          eventlist: res?.data?.data,
          allpage: res?.data?.count,
          city: JSON.parse(localStorage.getItem("districts")),
          loader: false,
        });
      })
      .catch((err) => {
        this.setState({
          loader: false,
        });
        console.log("err in getData", err);
      });
  }

  checkCity(arrayOne, secondArray) {
    if (secondArray[0] === "*") {
      return true;
    } else {
      let jsonArray = arrayOne.map((i) => {
        return secondArray.includes(i);
      });
      if (jsonArray.find((value) => value === true)) {
        return true;
      } else {
        return false;
      }
    }
  }

  render() {

    let event_list = "";
    let totalCount = 0;
    if (this.state.eventlist) {
      event_list = this.state.eventlist.map(
        (eventlist, index) =>
          this.checkCity(eventlist.cities, this.state.city) ? (
            <tr key={index}>
              <td>{this.state.selectedPage * this.state.renderPageLimit + index + 1}</td>
              {/* <td>{index + 1}</td> */}
              <td> {eventlist.event_title} </td>
              <td> {eventlist.event_type} </td>
              <td>
                {new Date(eventlist.event_date).toDateString()} -{" "}
                {new Date(eventlist.event_end_date).toDateString()}{" "}
              </td>
              <td>
                {eventlist.event_start_time} - {eventlist.event_end_time}{" "}
              </td>
              <td>
                <span className="circle">{eventlist.participationCount}</span>
                <span style={{ display: "none" }}>
                  {(totalCount += eventlist.participationCount)}
                </span>
              </td>
              <td className="text-center">
                <Link to={{ pathname: "/editevent", data: eventlist, back: "event" }}>
                  <button className="editBtn btn-sm m-1 me-2">
                    <Icon.PencilSquare className="me-1" /> Edit
                  </button>
                </Link>
                {eventlist.isSubEventAdded === true ? (
                  <Link to={{ pathname: "/Subevent-List", data: eventlist }}>
                    <Tooltip title="SubEventList" style={{}}>
                      <button className="btn btn-sm btn-outline-info m-1 me-3">
                        <Icon.Eye className="me-2" />View Event SubList
                      </button>
                    </Tooltip>
                  </Link>
                ) : (
                  <>
                    <Link
                      onClick={() => this.props.Setseleted_event(eventlist)}
                      to={{ pathname: "/memberlist" }}
                    >
                      <button
                        type="button"
                        className="registerBtn btn-sm m-1"
                      >
                        <Icon.PersonAdd className="me-1" />Registration
                      </button>
                    </Link>
                    <Link
                      onClick={() => this.props.Setseleted_event(eventlist)}
                      to={{ pathname: "/transactionlist" }}
                    >
                      <button
                        type="button"
                        className="transactionBtn btn-sm m-1"
                      >
                        <Icon.CurrencyRupee className="me-1" />Transaction
                      </button>
                    </Link>

                    <Link to={{ pathname: "/Add-Location", data: eventlist, back: "eventlist" }}>
                      <button type="button" className="locationBtn btn-sm m-1">
                        <Icon.GeoAlt className="me-1" /> Edit Location
                      </button>
                    </Link>
                  </>
                )}

                {this.state.role === "COORDINATOR" ? ("") : eventlist.isSubEventAdded === true ? (
                  <Link onClick={() =>
                    localStorage.setItem("Eventname", eventlist.event_title)
                  }
                    to={{ pathname: "/addsub-event", data: eventlist }}>
                    <Tooltip title="Add Sub Event" style={{}}>
                      <button className="btn btn-sm btn-outline-success">
                        <Add />
                      </button>
                    </Tooltip>
                  </Link>
                ) : (
                  ""
                )}
              </td>
            </tr>
          ) : (
            ""
          )
      );
    }
    // else {
    //   event_list = (
    //     <tr>
    //       <td colSpan="6" className="text-center">No Data Found....</td>
    //     </tr>
    //   );
    // }

    let cities;
    if (this.state.districtsLocal[0] !== "*") {
      cities = this.state.CityData?.map((e, key) => {
        return (
          <option key={key} value={e}>
            {e}
          </option>
        );
      });
    } else {
      cities = this.state.CityData?.map((e, key) => {
        return (
          <option key={key} value={e.name}>
            {e.name}
          </option>
        );
      });
    }

    // get users district data
    const getDistData = localStorage.getItem("districts");
    const districtData = JSON.parse(getDistData)
    const getNewDistData = districtData?.map((itm, index) => {
      return itm
    })

    return (
      <>
        {this.state.loading ? (
          <LoaderRoundedWithText />
        ) : (
          <div>
            <Sidebar />
            <div className="main">
              <div className="topbar">
                <Navbar title="Event List" />
              </div>

              <Row md={12} className="mx-4 mt-4 mb-5">
                <Col md={12}>
                  <Row md={6} className="justify-content-end">


                    <Col md={2}>
                      <select
                        name="year"
                        className="btn dropdown-toggle mb-2 w-100"
                        style={{ backgroundColor: "#003147", color: "white" }}
                        onChange={this.getCollegeWithYear}
                        value={this.state.year}>
                        <option value="">All Years</option>
                        {this.state.years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))} 
                      </select>
                    </Col>

                    <Col md={2}>
                      <select
                        type="button"
                        name="city"
                        className="btn btn-md dropdown-toggle w-100"
                        style={{ backgroundColor: "#003147", color: "white" }}
                        onChange={this.getCandidateWithCity}
                        id="district"
                      >
                        <option value={getNewDistData}>All</option>
                        {cities}
                      </select>
                    </Col>

                    <Col md={2}>
                      <Link
                        to="/addnewevent"
                        className="btn secondaryBtn w-100">
                        <Icon.Plus size={20} className="me-1" />
                        Add New Event
                      </Link>
                    </Col>

                  </Row>
                </Col>
              </Row>

              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div style={{ overflowx: "auto" }}>
                      <div className="table-responsive-sm">
                        <table className="table table-bordered table-responsive table-hover">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Event Title</th>
                              <th>Event Type</th>
                              <th>Event Date</th>
                              <th>Event time</th>
                              <th>Registration Count</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.loader ? (
                              <>
                                <tr>
                                  <td colSpan={7}>
                                    <LoaderRounded />
                                  </td>
                                </tr>
                              </>
                            ) :
                              (
                                event_list.length > 0 ?
                                  event_list : <tr><td colSpan="6" className="text-center">No Data Found....</td></tr>
                              )
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="mt-3">
                  <ReactPaginate
                    nextLabel="Next"
                    previousLabel="Previous"
                    pageCount={Math.ceil(this.state.allpage / this.state.renderPageLimit)}
                    // pageCount={this.state.allpage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    containerClassName="pagination"
                    activeClassName="btn btn-primary"
                    pageClassName="btn"
                    previousClassName="btn"
                    nextClassName="btn"
                    onPageChange={this.handlePageChange}
                  />
                </div>
                <button
                  className="reaload btn  btn-success btn-sm mt-3 mb-3"
                  onClick={() => window.location.reload(true)}>
                  <Icon.ArrowClockwise size={18} className="me-2" />
                  Refresh
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    Setseleted_event: (data) => dispatch(setSelectedEvent(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Eventlist);
