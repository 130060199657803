import React from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import { Add } from "@material-ui/icons";
import { Edit, Visibility } from "@material-ui/icons";
import "../Assets/css/Dashboard.css";
import LoaderRounded from "./LoaderRounded";
import ReactPaginate from "react-paginate";
import LoaderRoundedWithText from "./LoaderRoundedWithText";
import { connect } from "react-redux";
import { setSelectedForum } from "../Redux/Slice/UserDataSlice";

class Eventlist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      forumlist: [],
      college_code: "",
      city: [],
      msg: true,
      loader: true,
      loading: true,
      CityData: [],
      CollegeData: [],
      currentPage: "",
      counts: "",
      totalPages: "",
      previousLimits: 100,
      renderPageLimit: 20,
      selectedPage: 0,
      districtsLocal: this.props.UserData.districts,
      subCityName: this.props.UserData.sub_city
        ? this.props.UserData.sub_city
        : "",
      currentFilter: "",
      currentFilterBy: "",
      filterString: "",
      filterdata: [],
      selected_college: ["*"],
    };
    this.getData = this.getData.bind(this);
    this.showCity = this.showCity.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.applyFiter = this.applyFilter.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.searchFromList = this.searchFromList.bind(this);
    this.searchList = this.searchList.bind(this);
    this.getForumWithCollege = this.getForumWithCollege.bind(this);
  }

  handlePageChange(page) {
    // this.setState({ selectedPage: page.selected });
    let val =
      page.selected === 0 ? 20 : (page.selected + 1) * this.state.limitVal;
    this.setState({
      viewCount: val,
    });

    this.getData(this.state.limitVal, val);
  }

  applyFilter(arr) {
    if (!this.state.currentFilter) {
      return arr;
    }
    let filteron = this.state.currentFilterBy;
    if (filteron === "college_code") {
      return arr.filter((x) => x.college_code === this.state.currentFilter);
    } else {
      let a = this.state.currentFilter.toLowerCase();
      var condition = new RegExp(a);
      var result = arr.filter(function (el) {
        return (
          condition.test(el.forum_name.toLowerCase()) ||
          condition.test(el.forum_type.toLowerCase())
        );
      });
      return result;
    }
  }

  setFilter(e) {
    e.preventDefault();
    this.setState({
      currentFilter: e.target.value,
    });
  }

  searchFromList(event) {
    this.setState({
      forumlist: event.target.value,
      currentFilterBy: event.target.name,
    });
  }

  searchList(char) {
    let f = this.state.filterdata?.filter(
      (item) =>
        item?.name?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
        item?.forum_created_by
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase()) ||
        item?.city?.toString().toLowerCase()?.startsWith(char?.toLowerCase()) ||
        item?.college_name
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase()) ||
        item?.forum_type
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase()) ||
        item.forum_created_date
          ?.toString()
          .toLowerCase()
          ?.startsWith(char?.toLowerCase())
    );
    this.setState({
      forumlist: f,
    });
  }

  getForumWithCollege(event) {
    // this.setState({
    //   loader: true,
    // });
    let currentComponent = this;

    let a = event.target.value;
    if (a == "--College List--") {
      this.getData();
    } else {
      axios
        .get(process.env.REACT_APP_TALK_API + `/forum/college/forum/` + a)
        .then((res) => {
          if (res.data) {
            currentComponent.setState({
              forumlist: res.data,
              filterdata: res.data,
              loader: false,
            });
          }
        })
        .catch((err) => {
          console.log("response error", err);
        });
    }
  }

  UNSAFE_componentWillMount() {
    this.getData();
    this.showCity();
    this.getCollege();
  }

  componentWillMount() {
    setTimeout(() => this.setState({ loader: false }), 2000);
    setTimeout(() => this.setState({ loading: false }), 1000);
  }

  getData() {
    this.setState({
      loader: true,
    });
    let currentComponent = this;

    let hello =
      this.state.selected_college == "Select College"
        ? ["*"]
        : this.state.selected_college;

    axios
      .get(process.env.REACT_APP_TALK_API + `/forum/all/forum/list`)
      .then((res) => {
        let ForumList = res.data;

        if (res.data.error === "NO data found") {
          alert("data not Found");
        } else {
          // alert("Success")
        }
        currentComponent.setState({
          forumlist: ForumList,
          filterdata: ForumList,
          loader: false,
        });
      });
  }

  showCity() {
    let districts = this.props.UserData.districts;
    if (districts[0] !== "*") {
      this.setState({
        CityData: districts,
      });
    } else {
      axios
        .get(process.env.REACT_APP_APIURL + `/address/city/MH`)
        .then((response) => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            this.setState({
              CityData: response.data,
            });
          }
        })
        .catch((error) => {
          console.log("error in showCity", error);
        });
    }
  }

  getCollege() {
    let districts = this.props.UserData.districts;
    axios
      .post(process.env.REACT_APP_APIURL + `/coordinator/college/all/city`, {
        districts,
      })
      .then((response) => {

        this.setState({
          CollegeData: response.data,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }

  render() {
    let cities;
    if (this.state.districtsLocal[0] !== "*") {
      cities =
        this.state.CityData &&
        this.state.CityData?.map((e, key) => {
          return (
            <option key={key} value={e}>
              {e}
            </option>
          );
        });
    } else {
      cities =
        this.state.CityData &&
        this.state.CityData?.map((e, key) => {
          return (
            <option key={key} value={e.name}>
              {e.name}
            </option>
          );
        });
    }

    let forum_list = "";
    if (this.state.forumlist.length > 0 && this.state.forumlist) {
      forum_list = this.state.forumlist.map((forumlist) => (
        <tr className="text-center">
          <td> {forumlist.name} </td>
          <td> {forumlist.forum_type} </td>
          <td> {new Date(forumlist.forum_created_date).toDateString()}</td>
          <td> {forumlist.forum_created_by} </td>

          <td className="text-center">
            <Link
              onClick={() => this.props.setForum(forumlist.forum_id)}
              to={{ pathname: "/Issue-List", forum_id: forumlist.forum_id }}
            >
              <button type="button" className="btn btn-outline-success btn-sm">
                Issue List
              </button>
            </Link>
            &nbsp;&nbsp;&nbsp;
            <Link to={{ pathname: "./view-forum", data: forumlist }}>
              <button className="btn btn-outline-success btn-sm">
                {" "}
                <Visibility />
              </button>
            </Link>
            &nbsp;&nbsp;&nbsp;
            <Link to={{ pathname: "./edit-forum", data: forumlist }}>
              <button
                className="btn btn-outline-info btn-sm"
                onClick={() => this.props.setForum(forumlist.forum_id)}
              >
                {" "}
                <Edit />
              </button>
            </Link>
          </td>
        </tr>
      ));
    } else {
      forum_list = (
        <tr>
          <td>No Data Found....</td>
        </tr>
      );
    }

    return (
      <>
        {this.state.loading ? (
          <LoaderRoundedWithText />
        ) : (
          <div>
            <Sidebar />

            <div className="main">
              <div className="topbar">
                <Navbar title="Forum List" />
              </div>
              <div
                // className="mt-3"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "2%",
                }}
              >
                <input
                  style={{ width: "15%" }}
                  type="text"
                  placeholder="Search here"
                  name="search"
                  value={this.state.filterString}
                  onChange={(e) => {
                    this.setState({ filterString: e.target.value });
                    this.searchList(e.target.value);
                  }}
                  // onChange={this.searchFromList}
                  className="form-control"
                ></input>
                <select
                  type="button"
                  name="city"
                  className="btn btn-secondary btn-md dropdown-toggle"
                  id="district"
                  style={{
                    width: "15%",
                  }}
                  onChange={this.getForumWithCollege}
                >
                  <option value={null}> --College List-- </option>
                  {this.state.CollegeData.map((e, key) => {
                    return (
                      <option
                        style={{ textAlign: "left" }}
                        key={key}
                        value={e.college_code}
                      >
                        {e.college_name}
                      </option>
                    );
                  })}
                </select>
                <Link to="/Add-Forum" className="btn btn-secondary btn-md">
                  <Add />
                  Add New
                </Link>
              </div>
              <div className="container">
                <br></br>
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="table-responsive-sm">
                        <table className="table table-bordered table-responsive">
                          <thead>
                            <tr className="text-center">
                              <th>Forum Name</th>
                              <th>Forum Type</th>
                              <th>Forum Created Date</th>
                              <th>Forum Created Person Name</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.loader ? <LoaderRounded /> : forum_list.length > 0 ? forum_list : <tr>No Data Found</tr>}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <ReactPaginate
                      nextLabel="Next"
                      previousLabel="Previous"
                      pageCount={forum_list.length / this.state.renderPageLimit}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      containerClassName="pagination"
                      activeClassName="btn btn-primary"
                      pageClassName="btn"
                      previousClassName="btn"
                      nextClassName="btn"
                      onPageChange={this.handlePageChange}
                    />
                  </div>
                </div>

                <button
                  className=" reaload btn  btn-success btn-sm "
                  onClick={() => window.location.reload(true)}
                >
                  Click to reload!
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    UserData: state.PersistReducer.UserData.UserData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setForum: (data) => dispatch(setSelectedForum(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Eventlist);
