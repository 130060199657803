import React from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import { Add, Edit, Visibility } from "@material-ui/icons";
import "../Assets/css/Dashboard.css";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRounded from "./LoaderRounded";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { setSelectedActivty } from "../Redux/Slice/UserDataSlice";
import * as Icon from 'react-bootstrap-icons';

class Eventlist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      issue_list: [],
      city: [],
      Issue: this.props.selected_forum,
      loader: true,
      currentPage: "",
      counts: "",
      totalPages: "",
      previousLimits: 100,
      renderPageLimit: 20,
      selectedPage: 0,
    };
    this.getData = this.getData.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.getData();
  }
  handlePageChange(page) {
    this.setState({ selectedPage: page.selected });
  }

  componentWillMount() {
    setTimeout(() => this.setState({ loader: false, msg: false }), 2500);
    setTimeout(() => this.setState({ loading: false }), 1000);
  }
  getData() {
    let currentComponent = this;
    let ForumId = this.state.Issue;

    axios
      // .get(process.env.REACT_APP_TALK_API + `/issue/list/forum/${ForumId}`)
      .get(process.env.REACT_APP_TALK_API + `/issue/list/forum/${ForumId}`)
      .then((res) => {
        let IssueList = res.data;

        if (Array.isArray(IssueList) && IssueList.length > 0) {
          currentComponent.setState({
            issue_list: IssueList,
            loader: false,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  render() {
    let issue_list = "";
    if (this.state.issue_list) {
      issue_list = this.state.issue_list.map((issue_list) => (
        <tr className="text-center">
          <td> {issue_list.issue_title} </td>
          <td> {issue_list.issue_types} </td>
          <td> {issue_list.issue_start_time} </td>
          <td> {issue_list.issue_end_time} </td>
          <td className="text-center">
            <Link
              to={{ pathname: "./Activity-List", data: issue_list }}
              onClick={() => {
                this.props.Setselectedactivty(issue_list);
              }}
            >
              <button type="button" className="btn btn-success btn-sm m-1">
                Activity List
              </button>
            </Link>
            &nbsp;&nbsp;&nbsp;
            <Link to={{ pathname: "./Edit-issue", data: issue_list }}>
              <button className="btn btn-outline-success btn-sm  ">
                {" "}
                <Edit />
              </button>
            </Link>
            &nbsp;&nbsp;&nbsp;
            <Link to={{ pathname: "/ViewIssue", data: issue_list }}>
              <button className="btn btn-outline-info btn-sm ">
                {" "}
                <Visibility />
              </button>
            </Link>
            &nbsp;&nbsp;&nbsp;
            {/* <Link to={{ pathname: "./EditAmplifier", data: amplifytlist }}>
              <button className="btn btn-outline-warning btn-sm">
                {" "}
                <Edit />
              </button>
            </Link> */}
          </td>
        </tr>
      ));
    } else {
      issue_list = (
        <tr>
          <td>No Data Found....</td>
        </tr>
      );
    }

    return (
      <>
        <div>
          <Sidebar />
          <div className="main">
            <div className="topbar">
              <Navbar title={"Issue List"} />
            </div>

            <div>
              <Link
                to="/Add-Issue"
                className="btn btn-secondary btn-sm"
                style={{
                  float: "right",
                  marginRight: "40px",
                  marginTop: "20px",
                }}
              >
                <Add />
                Add New Issue
              </Link>
            </div>
            {/* <select type="button" name="city" className="mx-2 btn btn-secondary btn-md dropdown-toggle" onChange={this.getCollegeWithCity} id="district" style={{ float: "right", width: "120px" }}>
                        <option value={null}> --College List-- </option>
                        {this.state.collegedata.map((e, key) => {
                                        return <option key={key} value={e.college_name}>{e.college_name}  </option>;
                                    })}
                    </select> */}
            <div className="container">
              <BackAndBreadCrmOneParent
                backLink="/ForumList"
                backName="ForumList"
                currentTabName="Issue List"
              />
              {/* <div className="row">
                <div className="col-12">
                  <h2 className="text-center">Issue List</h2>
                  <br></br>
                </div>
              </div> */}
            </div>
            <br></br>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive-sm">
                    <table className="table table-bordered table-responsive">
                      <thead>
                        <tr className="text-center">
                          <th>Issue Name</th>
                          <th>Issue Type</th>
                          <th>Issue Start Time</th>
                          <th>Issue End Time</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.loader ? (
                          <LoaderRounded />
                        ) : issue_list.length > 0 ? (
                          issue_list.slice(
                            this.state.selectedPage *
                            this.state.renderPageLimit,
                            this.state.renderPageLimit +
                            this.state.selectedPage *
                            this.state.renderPageLimit
                          )
                        ) : (
                          <tr>No Data Found</tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <ReactPaginate
                  nextLabel="Next"
                  previousLabel="Previous"
                  pageCount={issue_list.length / this.state.renderPageLimit}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  containerClassName="pagination"
                  activeClassName="btn btn-primary"
                  pageClassName="btn"
                  previousClassName="btn"
                  nextClassName="btn"
                  onPageChange={this.handlePageChange}
                />
              </div>
            </div>
            <button
              className=" reaload btn  btn-success btn-sm "
              onClick={() => window.location.reload(true)}
            >
              <Icon.ArrowClockwise size={18} className="me-2" />
              Refresh
            </button>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    selected_forum: state.PersistReducer.UserData.selected_forum,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Setselectedactivty: (data) => dispatch(setSelectedActivty(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Eventlist);
