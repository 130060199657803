import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom'
import LoaderRoundedWithText from "./LoaderRoundedWithText";
import axios from 'axios';

class ApprovedQRCodePaymentPopUP extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            showLoader: false,
            disabledTrue: false,
        };
        this.handleSubmitForDispute = this.handleSubmitForDispute.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    isShowModal = () => {
        this.handleClose();
    }

    handleClose = () => {
        this.props.onPopupCloseMain();
    }

    handleSubmitForDispute() {
        this.setState({ showLoader: true, disabledTrue: true })
        this.handleSubmit();
    }

    async handleSubmit() {
        let currentComponent = this;
        let mobile = localStorage.getItem("approved_mobile");
        let click_approved_yin_id = localStorage.getItem("approved_yin_id");
        let click_approved_qr_code_upi_id = localStorage.getItem("approved_qr_code_upi_id");
        let click_approved_qr_code_transaction_id = localStorage.getItem("approved_qr_code_transaction_id");
        let click_approved_qr_code_transaction_photo = localStorage.getItem("approved_qr_code_transaction_photo");
        let click_qr_code_transaction_approvedby = localStorage.getItem("approved_name") ? localStorage.getItem("approved_name") : "Avinash Meshram";
        let approvedData = {
            yin_id: click_approved_yin_id,
            qr_code_upi_id: click_approved_qr_code_upi_id,
            qr_code_transaction_id: click_approved_qr_code_transaction_id,
            qr_code_transaction_photo: click_approved_qr_code_transaction_photo,
            qr_code_transaction_approvedby: click_qr_code_transaction_approvedby
        }
        let response = await axios.post(process.env.REACT_APP_APIURL + `/qrcode/payment/approved`, approvedData);
        //    let response= await axios.post(process.env.REACT_APP_APIURL+`/qrcode/payment/approved`,approvedData);

        axios.get(process.env.REACT_APP_APIURL + `/automate/ldp/` + mobile)
            .then(res => {
                currentComponent.props.onPopupCloseMainAfterData();
                currentComponent.setState({ showLoader: false, disabledTrue: false })
            }).catch((error) => {
                console.log("Error", error);
            });
    }

    render() {
        return (
            <>
                <div className="DisputePopup" >
                    <Modal show={this.props.showModalPopup}
                        centered>
                        <Modal.Header><b>{this.props.confirm_text}</b></Modal.Header>
                        <Modal.Body >
                            <div>
                                <h2>Are sure want to confirm?</h2>
                            </div>
                            {this.state.showLoader ? <LoaderRoundedWithText /> : ""}
                        </Modal.Body>
                        <Modal.Footer >
                            <Button onClick={this.handleSubmitForDispute} className="btn btn-lg rounded-pill btn-success border mb-2" disabled={this.state.disabledTrue}>Confirm</Button>
                            <Button onClick={this.props.onPopupCloseMain} className="btn btn-lg rounded-pill btn-danger border mb-2" disabled={this.state.disabledTrue}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </>
        )
    }
}

export default withRouter(ApprovedQRCodePaymentPopUP);