/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "../Assets/css/card.css";
import axios from 'axios';
import LoaderRounded from "./LoaderRounded";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { TbBuildingCommunity } from "react-icons/tb";
import { useDispatch } from 'react-redux';
import { Col, Form, Row } from 'react-bootstrap';


const useStyles = makeStyles((theme) => ({

  card: {
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    position: "relative",
    border: "none",
    borderRadius: '15px',
    padding: theme.spacing(1, 2),
    margin: theme.spacing(2)
  },

  title: {
    padding: theme.spacing(1),
    margin: theme.spacing(0, 0)
  },

  persentage: {
    padding: theme.spacing(1),
    margin: theme.spacing(1)
  },

  detail: {
    padding: theme.spacing(1),
    margin: theme.spacing(2, 0, 2, 0)
  },

  boxIcon: {
    height: "100px",
    width: "100px",
    display: "flex",
    position: "absolute",
    zIndex: 1,
    top: -20,
    left: 20,
    justifyContent: "center",
    alignItems: "center",

  },

  icons: {
    minWidth: 50,
    minHeight: 50,
    color: "#ffffff"
  },

  userBoxColor: {
    background: "linear-gradient(60deg, rgba(94,53,177,1) 0%, rgba(3,155,229,1) 100%)",
    padding: 20,
    borderRadius: 25
  },

  allCollegeBoxColor: {
    background: "linear-gradient(60deg, rgba(67,160,71,1) 0%, rgba(255,235,59,1) 100%)",
    padding: 20,
    borderRadius: 25

  },

  eventBoxColor: {
    background: "linear-gradient(60deg, rgba(251,140,0,1) 0%, rgba(255,202,41,1) 100%)",
    padding: 20,
    borderRadius: 25
  }

}));

export default class Card extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      allCollege: '',
      allRegistration: '',
      allEventRegistration: '',
      role: '',
      loader: true,
      year: "",
      years: [],
    };
    this.getCollegeWithYear = this.getCollegeWithYear.bind(this);
  }



  UNSAFE_componentWillMount() {
    this.getData();
    this.setState({ role: localStorage.getItem('role') })
    localStorage.removeItem('setbacklink');
  }

  // year dropdown code
  componentDidMount() {
    const currentYear = new Date().getFullYear();
    const initialYears = this.generateYears(2021, currentYear + 1);
    this.setState({ years: initialYears });
  }

  generateYears(start, end) {
    const yearsArray = [];
    for (let year = end; year >= start; year--) {
      yearsArray.push(year);
    }
    return yearsArray;
  }

  handleCompleteYear = () => {
    const currentYear = new Date().getFullYear();
    this.setState((prevState) => ({
      years: [...prevState.years, currentYear + 1],
    }));
  };

  getData(selected_year) {
    console.log("year", this.state.year)
    this.setState({ loader: true })
    let currentComponent = this;

    let districts = JSON.parse(localStorage.getItem('districts'))

    axios.post(process.env.REACT_APP_APIURL + `/coordinator/dashboard/count`, {
      districts: districts,
      year: selected_year ? selected_year : this.state.year
    })
      .then(res => {
        let responses = res.data;
        currentComponent.setState({
          allCollege: responses.approvedCollege,
          allRegistration: responses.allRegistration,
          allEventRegistration: responses.AllEventRegistrationCount,
          loader: false
        });
      }).catch(error => {
        console.log("error in get dashboard count", error);
      });
  }

  // handle year onChange
  getCollegeWithYear(e) {
    let selected_year =  e.target.value;

    this.setState({
      year: selected_year,
    }, () => {
      this.getData(selected_year);
    });
  }



  render() {

    return (
      <>
        {/* <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <div className="card   mb-3 shadow-lg" style={{ maxwidth: "18rem", marginTop: "45px" }}>
                <Link to="/view-user" onClick={() => localStorage.setItem('setbacklink', "dashboard")}>
                  <div className="card text-center">
                    <div className="card-header ">
                      <div className="row align-items-center">
                        <div className="col ">
                          <div className="numberCircle-center2">{this.state.loader ? <LoaderRounded /> : this.state.allRegistration}</div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer bg-light">
                      <h5 className="text-dark">All YIN Members</h5>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-sm-4">
              <div className="card  mb-3 shadow-lg" style={{ maxwidth: "18rem", marginTop: "45px" }}>
                <Link to="/view-user" onClick={() => localStorage.setItem('setbacklink', "dashboard")}>
                  <div className="card text-center">
                    <Link to="/collegeList">
                      <div className="card-header bg-light">
                        <div className="row align-items-center">
                          <div className="col">
                            <div className="numberCircle-center3" style={{ maxwidth: "800px" }}>{this.state.loader ? <LoaderRounded /> : this.state.allCollege}</div>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <div className="card-footer bg-light">
                      <h5><a href="/collegeList" className="text-dark">All Colleges</a></h5>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-sm-4">
              <div className="card   mb-3 shadow-lg" style={{ maxwidth: "18rem", marginTop: "45px" }}>
                <Link to="/view-user" onClick={() => localStorage.setItem('setbacklink', "dashboard")}>
                  <Link to="/candidateList">
                    <div className="card text-center">
                      <div className="card-header ">
                        <div className="row align-items-center">
                          <div className="col ">
                            <div className="numberCircle-center1">{this.state.loader ? <LoaderRounded /> : this.state.allEventRegistration}</div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer bg-light">
                        <h5 className="text-dark">All Event User</h5>
                      </div>
                    </div>
                  </Link>
                </Link>
              </div>
            </div>

          </div>
        </div> */}

        <RenderComponent  {...this.state} year={this.state.year} years={this.state.years} getCollegeWithYear={this.getCollegeWithYear} loader={this.state.loader} />

      </>
    )
  }
}

// to bget the role
const getUserRole = localStorage.getItem("role");

function RenderComponent(props) {

  const classes = useStyles();

  return (
    <>
      <Row md={12} className='mt-4 mx-4 d-flex d-flex justify-content-end'>
        <Col md={2}>
          <Form.Select
          style={{backgroundColor: "#003147",
          color: "white",
          WebkitAppearance: "none",
          appearance: "none",
          paddingRight: "2rem",
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="white"><path d="M8 12.28l6.364-6.364a1 1 0 0 0-1.414-1.414L8 9.455 2.636 4.636A1 1 0 0 0 1.222 6.05L8 12.28z"/></svg>')`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right center",}}
            onChange={props.getCollegeWithYear}
            name="year"
            value={props.year}>
            <option value="">All Year</option>
            {props.years.map((year) => (
              <option key={year} value={year}>
                {year}  
              </option>
            ))}
          </Form.Select>
        </Col> 
      </Row>

      <div style={{ marginTop: 50 }}>
        <Grid container item spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Link to="/view-user" onClick={() => localStorage.setItem('setbacklink', "dashboard")}>
              <Paper className={classes.card}>
                <Grid container>
                  <span className={classes.boxIcon}>
                    <Grid className={classes.userBoxColor}>
                      <span className={classes.icons}>
                        <i className="fa fa-users" style={{ fontSize: 50 }}></i>
                      </span>
                    </Grid>
                  </span>
                  <Grid container item className={classes.persentage} justifyContent="flex-end">
                    <Typography variant="h4" style={{ fontWeight: '600' }}> {props.loader ? <LoaderRounded /> : props.allRegistration}</Typography>
                  </Grid>
                </Grid>
                <hr />

                <Grid container item xs className={classes.title}>
                  <Typography>
                    <h5 className="text-dark">All YIN Members</h5>
                  </Typography>
                </Grid>

              </Paper>
            </Link>

          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Link to="/collegeList" onClick={() => localStorage.setItem('setbacklink', "dashboard")}>
              <Paper className={classes.card}>
                <Grid container item>
                  <span className={classes.boxIcon}>
                    <Grid className={classes.allCollegeBoxColor}>
                      <span className={classes.icons}>
                        <i>
                          <TbBuildingCommunity
                            style={{
                              fontSize: "50px",
                              color: "white",
                            }}
                          />
                        </i>
                      </span>

                    </Grid>
                  </span>

                  <Grid item
                    container
                    className={classes.persentage}
                    justifyContent="flex-end">
                    <Typography variant="h4" style={{ fontWeight: '600' }}> {props.loader ? <LoaderRounded /> : props.allCollege}</Typography>
                  </Grid>
                </Grid>

                <hr />
                <Grid container item xs className={classes.title}>

                  <Typography>
                    <h5 className="text-dark">All collages</h5>
                  </Typography>

                </Grid>
              </Paper>
            </Link>

          </Grid>
        </Grid>
      </div>
    </>

  );
}
