import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { Edit } from "@material-ui/icons";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import ReactPaginate from "react-paginate";
import LoaderRounded from "./LoaderRounded";
import LoaderRoundedWithText from "./LoaderRoundedWithText";
import { Col, Row } from "react-bootstrap";

export default class Approvedc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFilter: null,
      candidate: "",
      selectValue: "all",
      redirect: false,
      allpage: "",
      limitVal: 10,
      year:2025,
      years: [],
      page_selected: 0,
      loader: true,
      loading: true,
    };

    this.applyFiter = this.applyFilter.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.Editcandidate = this.Editcandidate.bind(this);
    this.getData = this.getData.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.getCollegeWithYear = this.getCollegeWithYear.bind(this);
  }

  applyFilter(arr) {
    if (!this.state.currentFilter) {
      return arr;
    }

    return arr.filter((x) => x.Status === this.state.currentFilter);
  }

  setFilter(e) {
    e.preventDefault();
    this.setState({
      currentFilter: e.target.value,
    });
  }

  UNSAFE_componentWillMount() {
    this.getData();
  }

  componentWillMount() {
    setTimeout(() => this.setState({ loader: false, msg: false }), 2000);
    setTimeout(() => this.setState({ loading: false }), 1000);
    const currentYear = new Date().getFullYear();
    const initialYears = this.generateYears(2021, currentYear + 1);
    this.setState({ years: initialYears });
  }

  generateYears(start, end) {
    const yearsArray = [];
    for (let year = end; year >= start; year--) {
      yearsArray.push(year);
    }
    return yearsArray;
  }

  getData(selected_year) {
    let currentComponent = this;
    currentComponent.setState({ loader:true });
    axios
      .post(process.env.REACT_APP_APIURL + `/candidate/list/status/APPROVED`, {
        year: selected_year ? selected_year : this.state.year,
        districts: JSON.parse(localStorage.getItem("districts")),
      })
      .then((res) => {
        let responses = res?.data?.length > 0 ? res.data.slice(
          this.state.page_selected * this.state.limitVal,
          this.state.limitVal * (this.state.page_selected + 1)
        ):[];
        if (res.data.message === "NO RECORD FOUND") {
          return;
        }
        let con = Math.ceil(parseInt(res.data.length) / this.state.limitVal);
        currentComponent.setState({
          candidate: responses,
          // .slice(
          //   this.state.selectedPage *
          //   this.state.page_selected,
          // this.state.page_selected +
          //   this.state.selectedPage *
          //     this.state.page_selected
          // ),
          loader: false,
          allpage: con,
        });
      });
  }

  // handle year onChange
  getCollegeWithYear = (event) => {
    let selected_year =  event.target.value;
    this.setState({
      year: selected_year
    },()=>{
      this.getData(selected_year)
    });
  }

  Editcandidate(yinID, candidateID) {
    localStorage.setItem("candidate_id", candidateID);
    localStorage.setItem("yin_id", yinID);
    this.setState({
      redirect: true,
    });
  }
  handlePageChange(page) {
    this.setState({
      page_selected: page.selected,
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/edit_candidate" />;
    }

    if (!localStorage.getItem("mobile")) {
      return <Redirect to="/login" />;
    }

    return (
      <>
        {this.state.loading ? (
          <LoaderRoundedWithText />
        ) : (
          <div>
            <Sidebar />
            <div className="main">
              <div className="topbar">
                <Navbar title="Approved Candidate List" />
              </div>

              <div className="container">
                <BackAndBreadCrmOneParent currentTabName="Approved Candidate List" />

                <br></br>

                {/* <div className="text-center" >
                  <Link to="/Addcandidate" className="btn btn-secondary btn-sm" style={{ float: "right" }}>Add New</Link>

                </div> */}
                <br></br>
                <Row className='d-flex justify-content-end'>
                <Col md={2}>
                      <select
                        name="year"
                        className="btn dropdown-toggle mb-2 w-100"
                        style={{ backgroundColor: "#003147", color: "white" }}
                        onChange={this.getCollegeWithYear}
                        value={this.state.year}>
                        <option value="">All Years</option>
                        {this.state.years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))} 
                      </select>
                    </Col>
                </Row>

                <div className="container">
                  <div>
                    Current Filter: {this.state.currentFilter ?? "NO FIlter"}{" "}
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div style={{ overflowx: "auto" }}>
                        <div className="table-responsive-sm">
                          <table className="table table-bordered table-responsive">
                            <thead>
                              <tr>
                                <th>Sr.No</th>
                                <th>ID</th>
                                <th>Name</th>
                                <th>College</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                {/* <th>Address</th> */}
                                <th>DOB</th>
                                <th>Status</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                            {this.state.loader ? (
                                <LoaderRounded type="Spinner Type" />
                              ) : 
                              this.state.candidate ?
                                this.applyFilter(this.state.candidate) && this.state.candidate.slice(
                                  this.state.page_selected * this.state.limitVal, this.state.limitVal +
                                this.state.page_selected * this.state.limitVal).map(
                                  (candidates, id) => (
                                    <tr key={id}>
                                      <td>
                                        {this.state.page_selected *
                                          this.state.limitVal +
                                          id +
                                          1}
                                      </td>
                                      <td>{candidates.yin_id}</td>
                                      <td>{candidates.first_name}</td>
                                      <td>{candidates.college_name}</td>
                                      <td>{candidates.email}</td>
                                      <td className="noselect">
                                        {candidates.mobile}
                                      </td>
                                      {/* <td>{candidates.address_line_1}{candidates.address_line_2},{candidates.state},{candidates.city} {candidates.pincode}</td> */}
                                      <td>{candidates.dob}</td>
                                      {candidates.application_status ===
                                        "APPROVED" ? (
                                        <td className="text-success">APPROVED</td>
                                      ) : (
                                        ""
                                      )}
                                      {candidates.application_status ===
                                        "REJECT" ? (
                                        <td className="text-danger">REJECT</td>
                                      ) : (
                                        ""
                                      )}
                                      {candidates.application_status ===
                                        "PENDING" ? (
                                        <td className="text-warning">PENDING</td>
                                      ) : (
                                        ""
                                      )}
                                      <td className="text-center">
                                        <span
                                          onClick={() =>
                                            this.Editcandidate(
                                              candidates.yin_id,
                                              candidates.college_code
                                            )
                                          }
                                        >
                                          <Edit />
                                        </span>
                                      </td>
                                    </tr>
                                  )
                                ) : <tr>
                                      <p>No Data Found</p>
                                  </tr>}
                            </tbody>
                          </table>
                        </div>
                        {
                          <ReactPaginate
                            nextLabel="Next"
                            previousLabel="Previous"
                            pageCount={
                              // candidateList.length / this.state.renderPageLimit
                              this.state.allpage
                            }
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            containerClassName="pagination"
                            activeClassName="btn btn-primary"
                            pageClassName="btn"
                            previousClassName="btn"
                            nextClassName="btn"
                            onPageChange={this.handlePageChange}
                          />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
