import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { Edit } from '@material-ui/icons';
import BackAndBreadCrmOneParent from "./BackAndBreadCumOneParent";
import LoaderRounded from "./LoaderRounded";

export default class CandidateListByCollegWise extends Component {
    constructor(props) {
        super(props);
        this.state = {
            college_code: '',
            yin_id: localStorage.getItem("yin_id"),
            students: [],
            loader: false,
            editUser: '',
        };

        this.getData = this.getData.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.getData();
    }

    getData = () => {
        //  axios.get(process.env.REACT_APP_APIURL + `/candidate/Candidates/`+localStorage.getItem("college_code"));
        this.setState({
            loader: true,
        })
        axios.get(process.env.REACT_APP_APIURL + `/users/collegestudents?college_code=` + localStorage.getItem("collegeCode"))
            .then(res => {
                let studentsInfo = res.data.data;
                this.setState({
                    students: studentsInfo,
                    loader: false,
                });
            });
    }

    EditCollege(yinid, collegeName) {
        localStorage.setItem("yin_id_college_user", yinid);
        localStorage.setItem("edit_college_user_name", collegeName);
        this.setState({
            editUser: true,
        })
    }

    render() {
        if (this.state.editUser) {
            return <Redirect to="/edit/college/user" />
        }

        let student_list = '';
        if (this.state.students) {
            student_list = this.state.students.map((student, index) =>
                <tr key={index}>
                    <td> {index + 1}</td>
                    <td> {student.first_name} &nbsp;{student.last_name} </td>
                    <td className="noselect"> {student.mobile} </td>
                    <td> {student.city} </td>
                    <td> {student.gender} </td>
                    <td> {student.college_name} </td>
                    <td className="text-center"><span onClick={() => this.EditCollege(student.yin_id, student.college_name)} ><Edit /></span></td>
                </tr>
            )
        }
        else {
            student_list =
                <tr>
                    <td>No Data Found....</td>
                </tr>
        }

        return (
            <>
                <Sidebar />
                <div className="main">
                    <div className="topbar">
                        <Navbar />
                    </div>


                    <div className="container">
                        <BackAndBreadCrmOneParent backLink="/view-card" backName="View card" currentTabName="Student list" />

                        <h2 className="text-center">YIN Member List</h2>

                        <br></br>
                        <div className="row">
                            <div className="col-12">
                                <table className="table table-bordered table-responsive">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Full Name</th>
                                            <th>Mobile</th>
                                            <th>City</th>
                                            <th>Gender</th>
                                            <th>College Name</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    {this.state.loader ? <LoaderRounded /> : <tbody>
                                        {
                                            student_list
                                        }
                                    </tbody>}
                                </table>
                            </div>

                        </div>

                    </div>
                </div>
            </>
        )
    }
}
